import React, { useEffect, useState, useContext } from "react";
import API from "utils/API";
import MenuCompleto from "views/Admin/MenuCompleto";
import { Modal, Button, Row} from "react-bootstrap";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function ListarBoletos(){
  const [negocios, setNegocios] = useState();
  const [negocioId, setNegocioId] = useState([]);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [boletos, setBoletos] = useState();
  let empresa = "nome";
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  function openModal2(neg) {
    setNegocioId(neg.id);
    handleShow2();
  }
  
  useEffect(() => {
    const [run] = API.get("boletos");
    run().then((response) => {
        setBoletos(response);
    });
  }, []);
  
  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
      setNegocios(response);
    });
  }, []);
  
  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return empresa;
    });
    return (empresa );
  }

  return(
    <div>
      <MenuCompleto/>
        <Row className="w-100 justify-content-center ">
          <div className="titleRosa">Listar Boletos</div>
        </Row>
        {  
          usuarios && usuarios.map((usuario) => {
            if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.dados === 1))){
              return(
                <div id="historico">
                  <table className="tableHistorico">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Negócio</th>
                        <th className="text-center">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                     {
                      negocios && negocios.map((negocio) => {
                        if(negocio.id %2 === 0){
                          return (
                            <tr key={negocio.id}>
                              <td className="text-center">{negocio.id}</td>
                              <td className="text-center">{negocio.nome} </td>                     
                              <td className="text-center">
                              <button className="atividadeHist" onClick={openModal2.bind(null,negocio)}>Listar boletos</button> 
                              </td>
                            </tr>
                         );
                       }else{
                          return (
                            <tr key={negocio.id} >
                              <td className="text-center">{negocio.id}</td>
                              <td className="text-center">{negocio.nome} </td>
                              <td className="text-center">
                              <button className="atividadeHist" onClick={openModal2.bind(null,negocio)}>Listar boletos</button> 
                              </td>
                            </tr>
                          );
                        }
                     })
                    }
                    </tbody>
                  </table>
                </div>
              );
            }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.dados === 0)){
              return(
                <Row className="w-100 justify-content-center ">
                  <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
                </Row>
              )
            }
            return true;
          })
        }


        <Modal className="verhistorico" data-keyboard={false}  backdrop={false} show={show2} >
          <Button className="closeButton" onClick={handleClose2}><i className="fa fa-long-arrow-left"></i>   Voltar</Button>
          <Modal.Body>
            <table className="tableHistorico">
              <thead>
                <tr>
                  <th className="text-center">Data</th>
                  <th className="text-center">Negócio</th>
                  <th className="text-center">Boleto</th>
                  <th className="text-center">Valor</th>
                  <th className="text-center">E-mail destino</th>
                </tr>
              </thead>
              <tbody>
                {
                  boletos && boletos.map((boleto) =>{
                    if(boleto.negocio_id === negocioId){
                      return(
                        <tr key={boleto.id}>
                          <td className="text-center">{boleto.data} </td>           
                          <td className="text-center">{negocioEnvolvido((boleto.negocio_id) )} </td>    
                          <td className="text-center">{boleto.nome_boleto} </td>                     
                          <td className="text-center">{boleto.valor} </td> 
                          <td className="text-center">{boleto.email_destino} </td> 
                        </tr>
                      );
                    } 
                      return true;
                  })
                }
              </tbody>
            </table>
          </Modal.Body>    
        </Modal>
    </div>
  );
}

export default ListarBoletos;