import React, { useCallback, useEffect, useState, useContext } from "react";
import MenuCompleto from "./MenuCompleto";
import API from "utils/API";
import "../../assets/css/GerenciarEquipe.css";
import {Row, Modal, Button, Form, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../contexts/NotificationContext";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function GerenciarEquipe(){
  const [usuarios, setUsuarios] = useState();
  const [usuario, setUsuario] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [setReload] = useState(true);
  const notification = useContext(NotificationContext); 
   const { authUser } = useContext(AuthUserContext);
  
  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  function openModal(usuario) {
    setUsuario(usuario);
    handleShow();
  }

  const setForm = useCallback(
    (n) => {
      setValue("usuarios", n.usuarios);
      setValue("negocios", n.negocios);
      setValue("dados", n.dados);
      setValue("projecoes", n.projecoes);
      setValue("calendario", n.calendario);
      setValue("materiais", n.materiais);
      setValue("equipe", n.equipe);
      setValue("noticias", n.noticias);
    },
    [setValue]
  );

  function onSubmit(data) {
    const [run] = API.put(`usuarios/${usuario.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setUsuario(response);
        setReload(true);
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (show && usuario) {
      setForm(usuario);
    }
  }, [show, usuario, setForm]);

  return(
    <div id="bodyNoticias">
    <MenuCompleto/>
    <div className="titleGerenciaEquipe">
      Gerenciar equipe
    </div>
    {  
      usuarios && usuarios.map((usuario) => {
      if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.equipe === 1))){
        return(
          <div>
            <Row className="w-100 justify-content-center">
              <div className="equipeFotos">
                {
                  usuarios && usuarios.map((usuario) =>{
                    if(usuario.role === 'equipe'){
                    var completePath = API.getBasePath() + usuario.path;
                      return(
                        <ul>
                         <Row className="w-100 justify-content-center"> <li className="userPic"> 
                          <button className="equipebtn" onClick={openModal.bind(null, usuario)}>  <img alt="equipe" src={completePath}></img></button>
                          <div className="nomeEquipe">{usuario.nome_completo}</div></li></Row>
                        </ul>
                      );
                    }
                    return completePath;
                  })
                }
              </div>
            </Row>
          </div>
        )
      }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.equipe === 0)){
        return(
        <Row className="w-100 justify-content-center ">
          <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
        </Row>
      )
      }
      return true;
    })
  }
  <Modal className="gerenEquipeModal" data-keyboard={false} backdrop={false} show={show} >
  <Button className="closeButton" onClick={handleClose}>X</Button>
    <Modal.Body> 
      <Form onSubmit={handleSubmit(onSubmit)}>       
        <div className="listaPermissoesEdit">
          <Row>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar usuários
                <input type="checkbox" name="usuarios" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar negócios
                <input type="checkbox" name="negocios" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar dados
                <input type="checkbox" name="dados" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar projeções
                <input type="checkbox" name="projecoes" ref={register}/>
              </label>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar calendário
                <input type="checkbox" name="calendario" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar materiais
                <input type="checkbox" name="materiais" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar equipe
                <input type="checkbox" name="equipe" ref={register}/>
              </label>
            </Col>
            <Col md={3}>
              <label className="containerEquipe">Gerenciar notícias
                <input type="checkbox" name="noticias" ref={register}/>
              </label>
            </Col>
          </Row>
        </div>
                          
        <Col className="col-md-12 pt-3 mt-4 text-center">
          <Button className="botaoRosa" type="submit"
           disabled={isLoading}>
              Atualizar Usuário
          </Button>
        </Col>
        <div className="clearfix" />

      </Form>     
    </Modal.Body>
  </Modal>
    </div>
);
}

export default GerenciarEquipe;