import React, { useCallback, useEffect, useState, useContext } from "react";
import API from "utils/API";
import { Button, Col, Form, Modal, Row, FormGroup, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationContext } from "./../../contexts/NotificationContext";
import MenuCompleto from "views/Admin/MenuCompleto";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import ChangeLeads from "./ChangeLeads";

function EditarLeads () {
  const [leads, setLeads] = useState();
  const [lead, setLead] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [isLoading, setIsLoading] = useState(false);
  const {register, handleSubmit, setValue } = useForm();
  const [negocios, setNegocios] = useState();
  const notification = useContext(NotificationContext);
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [negocio] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();
  const [mes, setMes] = useState();
  let empresa = "nome";

  const setForm = useCallback(
    (n) => {
      setValue("id", n.id);
      setValue("mes", n.mes);
      setValue("numLead", n.numLead);
      setValue("CPL", n.CPL);
      setValue("investimentoLeads", n.investimentoLeads);
      setValue("investimentoEngajamento", n.investimentoEngajamento);
      setValue("investimentoFb", n.investimentoFb);
      setValue("investimentoAds", n.investimentoAds);
      setValue("investimentoTotal", n.investimentoTotal);
      setValue("unidade", n.unidade);
      setValue("ano", n.ano);
      setValue("cliquesValue", n.cliquesValue);
      setValue("cliques", n.cliques);
      setValue("negocio_id", n.negocio_id);
    },
    [setValue]
  );


  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("leads");
    run().then((response) => {
        setLeads(response);
    });
  }, []);

  useEffect(() => {
    if (show && lead) {
      setForm(lead);
    }
  }, [show, lead, setForm]);

  useEffect(() => {
    if (show && negocio) {
      setForm(negocio);
    }
  }, [show, negocio, setForm]);


  function openModal(lead) {
    setLead(lead);
    handleShow();
  }

  function openModal3(lead) {
    setLead(lead);
    handleShow3(lead);
  }

  function refreshPage() {
    window.location.reload(false);
  }

  function onSubmit(data) {
    const [run] = API.put(`leads/${lead.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setLead(response);
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return empresa;
    });
    return (empresa);
  }

  function deleteThis(lead){
    const [run] = API.delete(`leads/${lead.id}`);
      run().then(() => {
        refreshPage();
      });
  }

   /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[5]).toString();
    return IdNegocio;
  }

  function onChangeMes(event) {
    setMes(event.target.value) //update your value here
  }
return (
  <div> 
  <MenuCompleto/>
  <div className="titleResultadosEdit">Gerenciar Resultados</div>
  {  
    usuarios && usuarios.map((usuario) => {
      if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
        return(
          <div key={usuario.id} className="gerenciaResultados">
            <ChangeLeads/>
              <Col md={6}>
                <FormGroup className="selectionResultadosSearch">
                  <Form.Control id="mes" as="select"  onChange={onChangeMes} ref={register} name="mes" label="Mês">
                    <option>Janeiro</option>
                    <option>Fevereiro</option>
                    <option>Março</option>
                    <option>Abril</option>
                    <option>Maio</option>
                    <option>Junho</option>
                    <option>Julho</option>
                    <option>Agosto</option>
                    <option>Setembro</option>
                    <option>Outubro</option>
                    <option>Novembro</option>
                    <option>Dezembro</option>
                  </Form.Control>
                </FormGroup>
              </Col>
              <div className="input-group input-group-sm mb-3 srch rounded col-2 float-right"></div>
              <table>
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Empresa</th>
                    <th  className="text-center">Mês</th>
                    <th  className="text-center">Leads</th>
                    <th  className="text-center">Ano</th>
                    <th  className="text-center">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    leads && leads.map((lead) => {
                      if (lead.negocio_id.toString() === getNegocioId() && lead.mes === mes) {
                        return (  
                          <tr key={lead.id}>
                            <td className="text-center">{lead.id}</td>
                            <td className="text-center">{negocioEnvolvido(lead.negocio_id)}</td>
                            <td className="text-center">{lead.mes}</td>
                            <td className="text-center">{lead.numLead}</td>
                            <td className="text-center">{lead.ano}</td>
                            <td className="opcoes text-center">
                              <button className="acoes editar buttonEdit" onClick={openModal.bind(null, lead)}>
                               Editar
                              </button>
                              <button className="deleteBotao" onClick={openModal3.bind(null, lead)}>
                                Deletar
                              </button>
                            </td>
                          </tr>
                        );
                       } 
                        return true;
                    })
                   }
                </tbody>
              </table>
            </div>
        );
      }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.projecoes === 0)){
        return(
          <Row className="w-100 justify-content-center ">
            <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
          </Row>
       )
      }
    return true;
  })
}
    
  <Modal className="resultadosLeads" data-keyboard={true} backdrop={true} show={show} >
  <Button className="closeButton" onClick={handleClose}>X</Button>
    <Modal.Body>
    <div className="titleResultadosModal">Editar Resultados*</div>
      <Form className="formResults" onSubmit={handleSubmit(onSubmit)}>
        <input  className="leadsInput"  type="text"  name="id" ref={register}></input><br></br>
          <Row>
            <Col md={12}>
              <label className="containerEquipe mt-4">Trocar Leads para cliques
                <input type="checkbox" name="cliques" ref={register}/>
              </label>
            </Col>  
            <Col md={4}>
              <FormGroup className="selectionResultadosEdit">
                <label>Mês*</label>
                <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                  <option>Janeiro</option>
                  <option>Fevereiro</option>
                  <option>Março</option>
                  <option>Abril</option>
                  <option>Maio</option>
                  <option>Junho</option>
                  <option>Julho</option>
                  <option>Agosto</option>
                  <option>Setembro</option>
                  <option>Outubro</option>
                  <option>Novembro</option>
                  <option>Dezembro</option>
                </Form.Control>
              </FormGroup>
            </Col>
            <Col md={4}>
              <label>Número de leads </label><br></br>
              <input  className="leadsInput"  type="text" placeholder="1200"  name="numLead" ref={register}></input>
            </Col>
            <Col md={4}>
              <label>Número de cliques </label><br></br>
              <input className="leadsInput" type="number" id="cliquesValue" name="cliquesValue" ref={register}></input>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label>Ano</label><br></br>
              <input  className="leadsInput"  type="text" placeholder="2022"  name="ano" ref={register}></input>
            </Col>
            <Col md={4}>
              <label>CPL </label><br></br>
              <input  className="leadsInput"  type="number" step="0.01" min="0.00" placeholder="R$ 24,00"  name="CPL" ref={register}></input>
           </Col>
   
          </Row>
          <Row>
            <Col md={4}>
              <label>Investimento leads</label>
              <input  className="leadsInput"  type="number" step="0.01" min="0.00"  placeholder="R$ 2400,00"  name="investimentoLeads" ref={register}></input>
           </Col>
           <Col md={4}>
              <label>Investimento engajamento</label>
              <input className="leadsInput"  type="number" step="0.01" min="0.00"  placeholder="R$ 10.000,00" name="investimentoEngajamento" ref={register}></input>
            </Col>
            <Col md={4}>
              <label>Investimento consolidado Facebook</label>
              <input  className="leadsInput"  type="number" step="0.01" min="0.00"  name="investimentoFb" placeholder="R$ 10.000,00"  ref={register}  />
            </Col> 
          </Row>
          <Row>
            <Col md={4}>
              <label>Investimento Google Ads</label>
              <input   className="leadsInput"  type="number" step="0.01" min="0.00"  placeholder="R$ 15.000,00" name="investimentoAds"  ref={register}></input>
            </Col>
            <Col md={4}>
              <label>Investimento total</label>
              <input className="leadsInput" name="investimentoTotal" type="text" placeholder="R$ 30.000,00" ref={register}  />  
            </Col>
            {/*<Col md={4}>
              <input   className="ResultadosButtonEd"  onClick={onChange5} type="text" placeholder="Calcular" ref={register} readOnly />  
</Col> */}         
          </Row>
          <Row>          
            <Col md={12}>
                <FormGroup className="selectionProjecao">
                    <label>Negócio*</label>
                    <FormControl  name="negocio_id"  as="select" ref={register} >
                      {
                        negocios && negocios.map((negocio) => (
                          <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                        ))
                      }
                    </FormControl>
                </FormGroup>
            </Col>
            <Col className="col-md-12 pt-3 text-center">
              <Button className="btneditResultados" type="submit"
                disabled={isLoading}>
                  Atualizar Resultados
              </Button>
            </Col>
          </Row>
          <div className="clearfix" />
      </Form>
    </Modal.Body>
  </Modal>
  
  <Modal className="deleteAviso" data-keyboard={false} backdrop={false} show={show3} >
    <Button className="closeButton" onClick={handleClose3}>X</Button>
      <Modal.Body> 
        <Row className="w-100 justify-content-center">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
        </Row>
        <Row className="w-100 justify-content-center">
            Tem certeza que deseja deletar os resultados consolidados?
        </Row>
        <Row className="w-100 justify-content-center">   
          <div className="buttons">
            <button className="areYouSure" onClick={deleteThis.bind(null,lead)}>Sim, deletar.</button>
            <button className="notSure" onClick={handleClose3}>Não, retornar.</button>
          </div>
        </Row>
      </Modal.Body>
  </Modal>
</div>    
  );
}

EditarLeads.propTypes = {
};
export default EditarLeads;
