import React from "react";
import NavMenu from "components/Navbars/NavMenu";
import "../../assets/css/temporeal.css"
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LastAccess from './../User/LastAccess';


function TempoReal(){
  const history = useHistory();
  let negocioId = 2;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }
  
  getNegocioId();

  function Fb(){ 
    history.push(`/fbAds/${negocioId}`);
  }

  function  Analytics(){ 
    history.push(`/analytics/${negocioId}`);
  }

  return(
    <div>
      <NavMenu/>
        <div id="realTime">
          <div className="choices">
            <Row className="w-100 justify-content-center">
              <ul>
                <li><button className="buttonTR" onClick={Fb}><div className="namee">Facebook Ads</div><i className='fa fa-facebook'></i></button></li>   
                <li><button className="buttonTR" onClick={Analytics}><div className="namee">Google Analytics</div><i className="fa fa-line-chart"></i></button></li>
              </ul>
            </Row>
          </div>
        </div>
        <Row className="w-100 fixed-bottom justify-content-left">
          <LastAccess/>
        </Row>
    </div>
  );
}
export default TempoReal;