import React, { useState, useEffect, useContext, useCallback } from "react";
import { PropTypes } from "prop-types";
import { Modal } from "react-bootstrap";
import {  Button } from "react-bootstrap";
import API from "utils/API";
import { AuthUserContext } from "./../../contexts/AuthUserContext";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import validateRoute from "utils/ValidateRoute";


function ChangeFb () {
  const [showModal, setShowModal] = useState(false);
  const [negocios, setNegocios] = useState();
  const { authUser } = useContext(AuthUserContext);
  const { negocioId } = useParams();
  const history = useHistory();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [show, setShow] = useState(false);
  var teste = 'undef';
  var nomee = "";
  var neg = 0;

  const dados = () =>{ 
    history.push("/dados");
  }

  function handleClose(){
    setShow(false);
    dados();
  }

  useEffect(useCallback(() => {
    const [run] = authUser.role === "cliente" ? API.get(`usuarios/${authUser.id}/negocios`) : API.get("negocios");
    run().then((response) => {
      setNegocios(response);
      if(response[0]){
        const DEFAULT_ROUTE = `/registrar_fb/negocios/${response[0].id}`;
        validateRoute(response, negocioId, () => {
          history.push(DEFAULT_ROUTE);
        });
      }else{
      setShow(true);
      }
    });
  }, [authUser, history, negocioId]), [negocioId,teste]);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleSelection(mappedNegocio) {
    history.push(`/registrar_fb/negocios/${mappedNegocio.id}`);
    handleCloseModal();
  }

   /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[5]);
    return (neg = IdNegocio);
  }

  getNegocioId();

  function getNome() {
    negocios && negocios.map((negocio) => {
      if(negocio.id.toString() === neg){
        nomee = negocio.nome;
      }
      return nomee;
    })
    return nomee;
  }


return (
  <div>  
    <div className="empresafb">Leads do Facebook da empresa: {getNome()}
      <button className="alterarfb" onClick={handleShowModal}>Alterar empresa <i className="fas fa-exchange-alt"/></button></div>
      <Modal className="changeDados" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione a empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {
              negocios && negocios.map((mappedNegocio) => {
                return(
                  <li>
                    <button
                    key={mappedNegocio.id}
                    onClick={handleSelection.bind(null, mappedNegocio)}
                    className="botaoChangeDados">
                    {mappedNegocio.nome}
                    </button>
                  </li>
                );
              })
            }
          </ul>
        </Modal.Body>
      </Modal>
      <Modal className="aviso" data-keyboard={false} backdrop={true} show={show} >
        <Button className="closeButton" onClick={handleClose}>X</Button>
        <Modal.Body>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
          Seu usuário não está ligado a um negócio. Entre em contato com um administrador.
        </Modal.Body>
      </Modal>
  </div>
  );
}
ChangeFb.propTypes = {
  "setNegocio": PropTypes.func,
  "negocio": PropTypes.object
};
export default ChangeFb;
