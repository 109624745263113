import React, { useState, useContext,useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "components/CustomButton/CustomButton.jsx";
import API from "utils/API";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import MenuCompleto  from '../Admin/MenuCompleto';
import "../../assets/css/RegistrarUsuario.css";
import "../../assets/css/administrador.css";
import Alert from 'react-bootstrap/Alert';

function UserProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const notification = useContext(NotificationContext);  
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  const [show, setShow] = useState(false);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
      setUsuarios(response);
    });
  }, []);

  function onSubmit(data) {
    const [run] = API.post("usuarios", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Usuário adicionado com sucesso.");
        setIsLoading(false);
        reset();
      })
      .catch(() => {
        setShow(true);
        setIsLoading(false);
      });
  }

return (
<div className="content"> 
    <Container fluid> 
    <MenuCompleto/>    
    <div className="tituloUsuario">Registrar usuário</div>
  {  
    usuarios && usuarios.map((usuario) => {
    if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.usuarios === 1))){
      return(
        <div>
          <div id="registrar">
            <Row>
              <Col md={12}>
                <div className="campos">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={2}>
                          <label><div>Nome completo*</div></label>
                        </Col>
                        <Col md={6}>
                          <input className="inputUser" id="nome" type="text" placeholder="Nome Completo" name="nome_completo" ref={register}/>
                          </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <label><div>Nome de usuário*</div></label>
                        </Col>
                        <Col md={6}>
                          <input className="inputUser" id="user" type="text" placeholder="Nome de Usuário" name="username" ref={register}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <label><div>E-mail*:</div></label>
                        </Col>
                        <Col md={6}>
                          <input  className="inputUser" id="email" type="email" placeholder="E-mail" name="email" ref={register}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                        <label><div>Senha*:</div></label>
                        </Col>
                        <Col md={6}>
                          <input  className="inputUser" id="senha" type="password" placeholder="Senha" name="password" ref={register}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <label><div>Confirmar senha*:</div></label>
                        </Col>
                        <Col md={6}>
                          <input className="inputUser" id="confirma" type="password" placeholder="Confirmar Senha" name="password_confirmation" ref={register}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <label><div>Tipo*:</div></label>
                        </Col>
                        <Col md={6}>
                          <Form.Control className="selectionRole" id="role" as="select" ref={register} name="role" label="Role">
                            <option>cliente</option>
                            <option>admin</option>
                            <option>equipe</option>
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label className="containerEquipe">Usuário ativo
                          <input type="checkbox" name="ativo" ref={register}/>
                          </label>             
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="subtitlee">Se o usuário for do tipo Equipe, defina as permissões:</div>
                        </Col>
                      </Row>
                      <div className="listaPermissoes">
                      <Row className="w-100 justify-content-center ">
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar usuários
                            <input type="checkbox" name="usuarios" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar negócios
                            <input type="checkbox" name="negocios" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar dados
                            <input type="checkbox" name="dados" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar projeções
                            <input type="checkbox" name="projecoes" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar calendário
                            <input type="checkbox" name="calendario" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar materiais
                            <input type="checkbox" name="materiais" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar equipe
                            <input type="checkbox" name="equipe" ref={register}/>
                          </label>
                        </Col>
                        <Col md={3}>
                          <label className="containerEquipe">Gerenciar notícias
                            <input type="checkbox" name="noticias" ref={register}/>
                          </label>
                        </Col>
                      </Row>
                    </div>
                      <Row className="w-100 justify-content-center ">
                        <Button className="btnEnviarUser" pullRight fill
                          type="submit"
                          disabled={isLoading}>
                            Registrar Perfil
                        </Button>
                      <div className="clearfix" />
                    </Row>  
                  </Form>
                </div>
             </Col>
            </Row>
        </div>
</div>
);
  }else if(usuario.id === authUser.id &&(authUser.role !== "admin" && authUser.role ==="equipe" && authUser.usuarios === 0)){
    return(
      <Row className="w-100 justify-content-center ">
        <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contacte um administrador.</div>
     </Row>
    )
  }
    return true;
  })
}
</Container>
<Alert show={show} className="alertFields">
   <Alert.Heading>Erro de cadastro</Alert.Heading>
    <p>
     Verifique se os campos <a href="#nome">Nome completo</a>, <a href="#user">Nome de usuário</a>, <a href="#email">E-mail</a>, <a href="#senha">Senha</a>, <a href="#confirma">Confirmar senha</a> e
     <a href="#role">Tipo</a> estão preenchidos! Eles são campos obrigatórios.
    </p>
    <hr />
    <div className="d-flex justify-content-end">
      <Button onClick={() => setShow(false)} className="btnOk">
         Ok!
     </Button>
   </div>
</Alert>      
 {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
</div>
  );
}

export default UserProfile;
