import React, {useContext, useEffect, useState} from "react";
import NavMenu from "components/Navbars/NavMenu";
import API from "utils/API";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {AuthUserContext} from "../../contexts/AuthUserContext";
import "react-funnel-pipeline/dist/index.css";
import "../../assets/css/projecaoCliente.css";
import funilimg from "../../assets/img/Funil.png";
import qshd from "../../assets/img/qshd.png";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";
import LastAccess from './../User/LastAccess';
import ClickToPrintFunil from './ClickToPrintFunil';
import "../../assets/css/funilprint.css";

function Funil(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  const PorcentagemTotal = 100;
  let descobertaFb = "0";
  let descobertaInsta = "0";
  let negocioId = "2";
  let leadsFb = "0";
  let leadsInsta = "0";
  let leadsTotal = "0";
  let agendamentoFb = "0";
  let agendamentoInsta = "0";
  let totalAgendamento = "0";
  let fbComparecimento = "0";
  let InstaComparecimento = "0";
  let totalComparecimento = "0";
  let fbFechamento = "0";
  let instaFechamento = "0";
  let totalFechamento = "0";
  let fechamento = "0";
  let atualProjetado = "0";
  let projetadosG = "0";
  let ticketIndicado = "0";
  let leads = "0";
  let agendamentoG = "0";
  let comparecimentoG = "0";
  let fechamentoG = "0";
  let totalfb = "0";
  let totalads = "0";
  let totalFunil = "0";
  let totalGoogle = "0";

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
       setProjecoes(response);
    });
  }, []);

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }

  const Metas = () =>{ 
    history.push(`/projecaoLeads/${authUser.active_negocio_id}`);
  }

  /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }
  
  function openModal2() {
    handleShow2();
  }
  
  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }
    
  /*Passa as datas de numerico para extenso */
  function translateMonth(mes) {
    if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
      return "Janeiro";
    } else if (mes === 2) {
      return "Fevereiro";
    } else if (mes === 3) {
      return "Março";
    } else if (mes === 4) {
      return "Abril";
    } else if (mes === 5) {
      return "Maio";
    } else if (mes === 6) {
      return "Junho";
    } else if (mes === 7) {
      return "Julho";
    } else if (mes === 8) {
      return "Agosto";
    } else if (mes === 9) {
      return "Setembro";
    } else if (mes === 10 || mes === -2) {
      return "Outubro";
    } else if (mes === 11 || mes === -1) {
      return "Novembro";
    } else if (mes === 12 || mes === 0) {
      return "Dezembro";
    }
  }

 function getYear(mes) { ///Retorna o ano
   return new Date().getFullYear();
  }
 
 function getLastMonth() { ///Retorna o mês passado
   const mes = new Date().getMonth();
   return mes;
  }

  const mes = getLastMonth();
   
  /*Retorna os dados de variáveis calculadas */
  function getVar() {
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.mes === translateMonth()) {
        totalfb = (parseFloat(projecao.fbq1) + parseInt(projecao.fbq2) + parseInt(projecao.fbq3) + parseInt(projecao.fbq4)).toFixed(2);
        totalads = (parseFloat(projecao.ads1) + parseInt(projecao.ads2) + parseInt(projecao.ads3) + parseInt(projecao.ads4)).toFixed(2);
        totalFunil = parseInt(((totalfb)/projecao.cplFb));
        totalGoogle = parseInt((totalads/projecao.cplGoogle));
        descobertaFb = ((((projecao.porcentagemFbTotal) / 100) * (totalFunil)));
        descobertaInsta = ((((projecao.porcentagemInstaTotal) / 100) * (totalFunil)));
        leadsFb = ((descobertaFb) * (parseFloat(projecao.porcentagemLeads / 100)));
        leadsInsta = (descobertaInsta * (parseFloat((projecao.porcentagemLeads) / 100)));
        leadsTotal = (leadsFb + leadsInsta);
        agendamentoFb = (leadsFb * (parseFloat(projecao.porcentagemAgendamento / 100)));
        agendamentoInsta = (leadsInsta * (parseFloat(projecao.porcentagemAgendamento / 100)));
        totalAgendamento = (agendamentoFb + agendamentoInsta);
        fbComparecimento = (agendamentoFb * (parseFloat(projecao.porcentagemComparecimento) / 100));
        InstaComparecimento = (agendamentoInsta * (parseFloat(projecao.porcentagemComparecimento / 100)));
        totalComparecimento = (fbComparecimento + InstaComparecimento);
        fbFechamento = (fbComparecimento * (parseFloat(projecao.porcentagemFechamento / 100)));
        instaFechamento = (InstaComparecimento * (parseFloat(projecao.porcentagemFechamento / 100)));
        totalFechamento = (fbFechamento + instaFechamento);
        fechamento = Math.round(fbFechamento + instaFechamento);
        atualProjetado = (parseFloat(Math.round(fechamento)) * parseFloat(projecao.ticketAtual));
        leads = (((parseFloat(projecao.porcentagemLeadsG) / 100) * totalGoogle));
        agendamentoG = (((parseFloat(projecao.porcentagemAgendamentoG / 100) * leads)));
        comparecimentoG = (((parseFloat(projecao.porcentagemComparecimentoG / 100) * agendamentoG)));
        fechamentoG = (((parseFloat(projecao.porcentagemFechamentoG / 100) * comparecimentoG)));
        projetadosG = (parseFloat(Math.round(fechamentoG)*projecao.projetadosG));
        ticketIndicado = (parseFloat(projecao.metaTotal / (Math.round(fechamento)+ Math.round(fechamentoG))).toFixed(2));
      }
      return true;
    });
  }

return(
  <div id="contentDash">
    <NavMenu/>     
    <button className="back" onClick={Metas}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    <div className="whiteBgFunil">
      <Row className="w-100 justify-content-center"> 
        <button className="btnCalFunil" onClick={openModal2}><i className="fa fa-calendar"></i></button>
        <button className="btnPrintFunil"><ClickToPrintFunil/></button>
        <div className="metaFaturamentoTitle">Projeção de leads com base em {translateMonth(mes+1)}/{getYear(mes)}</div>
      </Row>
      <Row className="w-100">
        <Col md={6}>
          <div id="funil">
            {
              projecoes && projecoes.map((projecao) => {
                getNegocioId();
                  if (projecao.negocio_id.toString() === negocioId && projecao.mes === translateMonth()) {
                    return (
                      <div>
                        <img className="prettyfunnel" alt="funil" src={funilimg}></img>
                        <div className="titlePretty">Descoberta</div>
                        <div className="titleLPretty">Leads</div>
                        <div className="titleAPretty">Agendamento</div>
                        <div className="titleCPretty">Comparecimento</div>
                        <div className="titleFPretty">Fechamento</div>
                        <div className="descobertaPretty">100</div>
                        <div className="leadsPretty">{projecao.porcentagemLeads}</div>
                        <div className="agendaPretty">{projecao.porcentagemAgendamento}</div>
                        <div className="compPretty">{projecao.porcentagemComparecimento}</div>
                        <div className="fechPretty">{projecao.porcentagemFechamento}</div>
                      </div>
                    );
                  }
                  return true;
              })
            }
          </div>
        </Col>
        <Col md={4}>
          <div className="tablefb">
            {
              projecoes && projecoes.map((projecao) => {
                getNegocioId();    
                  if (projecao.negocio_id.toString() === negocioId && projecao.mes === translateMonth()) {
                    getVar();
                      return (
                        <div>
                          <div className="titulofunil">LEADS PROJETADOS FACEBOOK | INSTAGRAM</div>
                            <table>
                              <tr className="topp">
                                <th className="text-center">Facebook</th>
                                <th className="text-center">Instagram</th>
                                <th className="text-center">Total</th>
                              </tr>
                              <tr className="percent">
                                <td className="text-center">{projecao.porcentagemFbTotal}%</td>
                                <td className="text-center">{projecao.porcentagemInstaTotal}%</td>
                                <td className="text-center">{PorcentagemTotal}%</td>
                              </tr>
                              <tr className="descoberta">
                                <td className="text-center">{Math.round(descobertaFb)}</td>
                                <td className="text-center">{Math.round(descobertaInsta)}</td>
                                <td className="text-center">{totalFunil}</td>
                              </tr>
                              <tr className="percent">
                                <td className="text-center">{projecao.porcentagemLeads}%</td>
                                <td className="text-center">{projecao.porcentagemLeads}%</td>
                                <td className="text-center">{projecao.porcentagemLeads}%</td>
                              </tr>
                              <tr className="leads">
                                <td className="text-center">{Math.round(leadsFb)}</td>
                                <td className="text-center">{Math.round(leadsInsta)}</td>
                                <td className="text-center">{Math.round(leadsTotal)}</td>
                              </tr>
                              <tr className="percent">
                                <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                                <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                                <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                              </tr>
                              <tr className="agendamento">
                                <td className="text-center">{Math.round(agendamentoFb)}</td>
                                <td className="text-center">{Math.round(agendamentoInsta)}</td>
                                <td className="text-center">{Math.round(totalAgendamento)}</td>
                              </tr>
                              <tr className="percent">
                                <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                                <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                                <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                              </tr>
                              <tr className="comparecimento">
                                <td className="text-center">{Math.round(fbComparecimento)}</td>
                                <td className="text-center">{Math.round(InstaComparecimento)}</td>
                                <td className="text-center">{Math.round(totalComparecimento)}</td>
                              </tr>
                              <tr className="percent">
                                <td className="text-center">{projecao.porcentagemFechamento}%</td>
                                <td className="text-center">{projecao.porcentagemFechamento}%</td>
                                <td className="text-center">{projecao.porcentagemFechamento}%</td>
                              </tr>
                              <tr className="fechamento">
                                <td className="text-center">{Math.round(fbFechamento)}</td>
                                <td className="text-center">{Math.round(instaFechamento)}</td>
                                <td className="text-center">{Math.round(totalFechamento)}</td>
                              </tr>
                            </table>
                          </div>
                      );
                  }
                    return true;
               })
            }
          </div>
        </Col>
        <Col md={2}>
          <div className="tabelaGoogle">
            {
              projecoes && projecoes.map((projecao) => {
                getNegocioId();
                if (projecao.negocio_id.toString() === negocioId && projecao.mes === translateMonth() && projecao.hasGoogle === 1) {
                  getVar();
                    return (
                      <div>
                        <div className="titulofunilG">LEADS PROJETADOS GOOGLE</div>
                        <table>
                          <tr className="toppGoogle">
                            <th>Google</th>
                          </tr>
                          <tr className="percent"><td>{projecao.porcentagemLeadsG}% </td></tr>
                          <tr className ="leads"><td>{Math.round(leads)}</td></tr>
                          <tr className="percent"><td>{projecao.porcentagemAgendamentoG}% </td></tr>
                          <tr className="agendamento"><td>{Math.round(agendamentoG)}</td></tr>
                          <tr className="percent"><td>{projecao.porcentagemComparecimentoG}%</td></tr>
                          <tr className="comparecimento"><td>{Math.round(comparecimentoG)}</td></tr>
                          <tr className="percent"><td>{projecao.porcentagemFechamentoG}%</td></tr>
                          <tr className="fechamento"><td>{Math.round(fechamentoG)}</td></tr>
                        </table>
                      </div>
                    );
                }else if(projecao.negocio_id.toString() === negocioId && projecao.mes === translateMonth() && projecao.hasGoogle === 0){
                  return (
                    <div>
                      <div className="titulofunilG">LEADS PROJETADOS GOOGLE</div>
                      <table>
                        <tr className="toppGoogle">
                          <th>Google</th>
                        </tr>
                        <tr className="percent"><td>0% </td></tr>
                        <tr className="descoberta"><td>0,00</td></tr>
                        <tr className="percent"><td>0% </td></tr>
                        <tr className ="leads"><td>0,00</td></tr>
                        <tr className="percent"><td>0% </td></tr>
                        <tr className="agendamento"><td>0,00</td></tr>
                        <tr className="percent"><td>0%</td></tr>
                        <tr className="comparecimento"><td>0,00</td></tr>
                        <tr className="percent"><td>0%</td></tr>
                        <tr className="fechamento"><td>0,00</td></tr>
                      </table>
                    </div>
                  );
                }
                return true;
              })
            }
          </div>
        </Col>
      </Row>
      <Row className="w-100"> 
        <Col md={6}></Col>
        <Col md={6}>
          <div className="projetado">
          {
            projecoes && projecoes.map((projecao) => {
              getNegocioId();
                if (projecao.negocio_id.toString() === negocioId && projecao.mes === translateMonth()) {
                  getVar();
                  return (
                    <div className="tableTicket">
                      <div>
                        <div className="titulofunilT">TICKET MÉDIO - Atual/Projetado</div>
                        <table>
                          <tr>
                            <td className="titulomenor">Ticket médio Redes Sociais</td>
                            <td className="titulomenor">Projetado Redes Sociais</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(projecao.ticketAtual).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(atualProjetado).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                          </tr>
                          <tr>
                            <td className="titulomenor">Ticket médio Google</td>
                            <td className="tituloprojetado">Projetado Google</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(projecao.projetadosG).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(projetadosG).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                          </tr>
                          <tr>
                            <td className="titulomenor">Ticket médio indicado</td>
                            <td className="titulomenor">Projetado  Facebook/Instagram/Google</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(ticketIndicado).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(projecao.metaTotal).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                );
              }
              return true;
            })
          }
          </div>
        </Col>
      </Row>
      <Row className="w-100">
        <Col md={6}>
          <img className="qshdimg" alt="metodo qshd" src={qshd}></img>
        </Col>
      </Row>
      <Row className="w-100 fixed-bottom justify-content-left">
        <LastAccess/>
      </Row>
      <Modal className="selectMonthMetaInvest" data-keyboard={true} backdrop={true} show={show2} >
        <Button className="closeButton">Selecione o mês ({getYear()})</Button>
        <Modal.Body>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
              </Col>      
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
              </Col>
            </Row>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
              </Col>      
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
              </Col>
            </Row>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
              </Col>      
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
              </Col>
            </Row>
          </Modal.Body>
      </Modal>
    </div>
    <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
    <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
  </div>
  );
}

export default Funil;