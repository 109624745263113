import React, { useState, useEffect, useContext, useCallback } from "react";
import NavMenu from "components/Navbars/NavMenu";
import {  Row, Modal, Button,Col } from "react-bootstrap";
import "../../assets/css/projecaoCliente.css";
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { BarChart, Bar, Cell} from 'recharts';
import { RadialBarChart, RadialBar } from 'recharts';
import { PieChart, Pie, Sector } from 'recharts';
import API from "utils/API";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";
import ClickToPrintMeta from './ClickToPrintMeta';

function Metas(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [projections, setProjections] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  var mes = 0;
  let totalRetorno = "0";
  let totalMetaPorcento = "0";
  let fbs1 = 0;
  let fbs2 = 0;
  let fbs3 = 0;
  let fbs4 = 0;
  let gs1 = 0;
  let gs2 = 0;
  let gs3 =0;
  let gs4 = 0;
  let totalMensal = "0";
  let totalMensalPercent = "0";
  let cplanteriorC = "0";
  let cplFbC = "0";
  let cplgC = "0";
  var monthh = " ";

  const ProjecaoLeads = () =>{ 
    history.push(`/projecaoLeads/${authUser.active_negocio_id}`);
  }

  const MetaFaturamento = () =>{ 
    history.push(`/faturamento/${authUser.active_negocio_id}`);
  }

  const Investimento = () =>{ 
    history.push(`/investimento/${authUser.active_negocio_id}`);
  }

  const TotalInvestido = () =>{ 
    history.push(`/totalInvestido/${authUser.active_negocio_id}`);
  }
  
  const CPL = () =>{ 
    history.push(`/cpl/${authUser.active_negocio_id}`);
  }

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
      setProjections(response);
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  function getRedesMeta(){  
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const redeMeta = projection.metaPorcentagem;
        return (totalMetaPorcento = redeMeta);
      }
      return totalMetaPorcento;
    });
   return totalMetaPorcento;
  }

  function getTotal(){   
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const totalMeta = projection.metaTotal;
        return (totalRetorno = totalMeta);
      }
      return totalRetorno;
    });
   return totalRetorno;
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  function openModal2() {
    handleShow2();
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text x={cx} y={cy} dy={4} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`R$ ${value.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`( ${parseInt(percent * 100)}%)`}
        </text>
      </g>
    );
  };

  function getSemanasfb(){
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const f1 = projection.fbq1;
        const f2 = projection.fbq2;
        const f3 = projection.fbq3;
        const f4 = projection.fbq4;
        return (
          fbs1 = f1,
          fbs2 = f2,
          fbs3 = f3,
          fbs4 = f4
        );
      }
      return (
        fbs1,
        fbs2,
        fbs3,
        fbs4
      );
    });
  }
  
  function getSemanasGoogle(){
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const g1 = projection.ads1;
        const g2 = projection.ads2;
        const g3 = projection.ads3;
        const g4 = projection.ads4;
        return (
          gs1 = g1,
          gs2 = g2,
          gs3 = g3,
          gs4 = g4
        );
      }
      return (gs1, gs2, gs3, gs4);
    });
  }

  getInvestTotal();

  const data = [
    {
      name: "Investimento indicado",
      uv: parseFloat(totalMensalPercent).toFixed(2),
      pv: 2400,
      fill: "#f7325a"
    },
    {
      name: "Investimento atual",
      uv: parseFloat(totalMensal).toFixed(2),
      pv: 4567,
      fill: "#fe799d"
    }
  ];
  
  function getInvestTotal(){
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const f1 = projection.fbq1;
        const f2 = projection.fbq2;
        const f3 = projection.fbq3;
        const f4 = projection.fbq4;
        const g1 = projection.ads1;
        const g2 = projection.ads2;
        const g3 = projection.ads3;
        const g4 = projection.ads4;
        const meta = projection.metaTotal;
        const redespercent = projection.metaPorcentagem;
        const tot =  (parseFloat(f1) + parseFloat(f2) + parseFloat(f3) + parseFloat(f4) + parseFloat(g1) + parseFloat(g2) + parseFloat(g3) + parseFloat(g4));
        const totPercent = ((parseFloat(meta) * (redespercent/100) * 0.08));
        return (
         totalMensal = tot,
         totalMensalPercent = totPercent
          );
      }
      return (
        totalMensal,
        totalMensalPercent
         );
    });
  }
  
  /*Pie chart */    
  let valorTotal = getTotal();
  let metaPorcento = getRedesMeta();
  let outros = (parseFloat(valorTotal) - (parseFloat(valorTotal) * parseFloat(metaPorcento/100)));
  const dataPie =[
    { name: 'Outros', value: outros },
    { name: 'Redes sociais', value: (parseFloat(valorTotal) * parseFloat(metaPorcento/100))},
  ];

  function getCPL(){
    projections && projections.map((projection) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projection.negocio_id.toString() === IdNegocio && projection.mes === translateMonth()) {
        const CPL_ant = projection.cplAnterior;
        const CPL_fb = projection.cplFb;
        const CPL_g = projection.cplGoogle;
        return (
          cplanteriorC = parseFloat(CPL_ant),
          cplFbC = parseFloat(CPL_fb),
          cplgC = parseFloat(CPL_g)
        );
      }
      return(cplanteriorC, cplFbC, cplgC);
    });
  }
  
  getSemanasfb();
  getSemanasGoogle();

  const dataInvest = [
    {
      name: 'Semana 1',
      fb: fbs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      amt: 2400,
    },
    {
      name: 'Semana 2',
      fb: fbs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      amt: 2210,
    },
    {
      name: 'Semana 3',
      fb: fbs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      amt: 2290,
    },
    {
      name: 'Semana 4',
      fb: fbs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      amt: 2000,
    },
  ];

  getCPL();

  const dataCPL = [
    { name: 'CPL Anterior', value: cplanteriorC },
    { name: 'CPL Facebook', value: cplFbC },
    { name: 'CPL Google', value: cplgC },
  ];


  const COLORS = ['#f7325a', '#fe94b6', '#fe799d'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


function getMonth() { ///Retorna o mês passado
  var mes = new Date().getMonth();
  return mes = mes + 1;
}

/*Passa as datas de numerico para extenso */
function translateMonth() {
  if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
        monthh = "Janeiro";
    } else if (mes === 2) {
        monthh = "Fevereiro";
    } else if (mes === 3) {
        monthh = "Março";
    } else if (mes === 4) {
        monthh = "Abril";
    } else if (mes === 5) {
        monthh = "Maio";
    } else if (mes === 6) {
        monthh = "Junho";
    } else if (mes === 7) {
        monthh = "Julho";
    } else if (mes === 8) {
        monthh = "Agosto";
    } else if (mes === 9) {
        monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
        monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
        monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
        monthh = "Dezembro";
    }
    return monthh;
  }

 translateMonth();

 function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }
  
  return(
    <div id="contentDash">
      <NavMenu/>
        <div id="bodyMeta">
          <button className="back" onClick={ProjecaoLeads}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
            <Row className="w-100 justify-content-center">
              <button className="btnCalMetas" onClick={openModal2}><i className="fa fa-calendar"></i></button>
              <div className="metaTitle">Metas</div><button className="btnPrintMeta"><ClickToPrintMeta/></button>
            </Row>
            <Row  className="w-100 justify-content-center">
              <div className="menuMetas">
                <ul>
                  <li>
                    <button className="metaBtn" onClick={MetaFaturamento}>Meta Faturamento
                      <ResponsiveContainer max-width="85%" height="85%">
                        <PieChart width={500} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={dataPie}
                            cx="50%"
                            cy="50%"
                            innerRadius="60%"
                            outerRadius="75%"
                            fill=" #f7325a"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </button>
                  </li>
                  <li>
                    <button className="metaBtn" onClick={Investimento}>
                        Investimento
                      <ResponsiveContainer max-width="85%" height="85%">
                        <BarChart
                          width={350}
                          height={250}
                          data={dataInvest}
                          margin={{
                            top: 20,
                            right: 50,
                            left: 0,
                            bottom: 40,
                          }}
                          >          
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis axisLine={{ stroke: '#d5385a' }}  tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={18}/>
                          <YAxis axisLine={{ stroke: '#d5385a' }}  tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={18}/>
                          <Bar name="Facebook/Instagram" type="monotone" dataKey="fb" fill="#f7325a" isAnimationActive={false} />
                          <Bar name="Google" type="monotone" dataKey="google" fill="#fe799d" isAnimationActive={false}/>
                        </BarChart>
                      </ResponsiveContainer>
                    </button>
                  </li>
                  <li>
                    <button className="metaBtn" onClick={TotalInvestido}>Total Investido
                      <ResponsiveContainer max-width="85%" height="85%">
                        <RadialBarChart
                            width={700}
                            height={400}
                            cx="50%"
                            cy="50%"
                            innerRadius="45%"
                            outerRadius="90%"
                            barSize={20}
                            data={data}
                          >
                          <RadialBar
                            isAnimationActive={true}
                            minAngle={30}
                            label={{ position: "insideStart", fill: "#fff"}}
                            background= {{fill:"#8c8c8c"}}
                            clockWise
                            dataKey="uv"
                          />
                        </RadialBarChart>
    
                      </ResponsiveContainer>
                    </button>
                  </li>
                  <li>
                    <button className="metaBtn" onClick={CPL}>CPL
                      <ResponsiveContainer max-width="85%" height="85%">
                        <PieChart width={600} height={400}>
                            <Pie
                              data={dataCPL}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius="70%"
                              fill="#8884d8"
                              dataKey="value"
                              isAnimationActive={true}
                            >
                              {dataCPL.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </button>
                  </li>
                </ul>
              </div>
            </Row>
          </div>

    <Modal className="selectMonthMetas" data-keyboard={true} backdrop={true} show={show2} >
      <Button className="closeButton">Selecione o mês ({getYear()})</Button>
      <Modal.Body>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
          </Col>
        </Row>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
          </Col>
        </Row>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>

      <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
  </div>
  );
}
export default Metas;