import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import  PrintPlannerPublicacao  from './PrintPlannerPublicacao';

function ClickToPrintPublicacoes(){
  let componentRef = useRef();
  return (
    <>
      <div>
        <ReactToPrint
          trigger={() =><i className="fa fa-print"></i>}
          content={() => componentRef}
        />
        <div style={{ display: "none" }}>
          <ComponentToPrint ref={(el) => (componentRef = el)} />
        </div>
      </div>
    </>
  );
};

class ComponentToPrint extends React.Component {
  render() {
    return (
      <PrintPlannerPublicacao/>
    );
  }
}

export default ClickToPrintPublicacoes;