import React, { useEffect, useState,useContext } from "react";
import { Modal, Button, Row} from "react-bootstrap";
import API from "utils/API";
import MenuCompleto from "views/Admin/MenuCompleto";
import "../../assets/css/dadosAdmin.css";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/css/administrador.css";

function RegisterHistorico(){
  const [negocios, setNegocios] = useState();
  const [historicos, setHistoricos] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [negocioId, setNegocioId] = useState([]);
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();
  let empresa = "nome";
  
  useEffect(() => {
    const [run] = API.get("historicos");
    run().then((response) => {
        setHistoricos(response);
    });
  }, []);
  
  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  function openModal2(neg) {
    setNegocioId(neg.id);
    handleShow2();
  }
  
  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);


  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return (empresa );
    });
    return (empresa );
  }


  return(
    <div>
    <MenuCompleto/>
      <Row className="w-100 justify-content-center ">
        <div className="titleRosa">Listar históricos</div>
      </Row>
      <div id="historico">
        {  
          usuarios && usuarios.map((usuario) => {
            if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.dados === 1))){
              return(
                <div>
                  <table className="tableHistorico">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Negócio</th>
                        <th className="text-center">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        negocios && negocios.map((negocio) => {
                          if(negocio.id %2 === 0){
                            return(
                              <tr key={negocio.id}>
                                <td className="text-center">{negocio.id}</td>
                                <td className="text-center">{negocio.nome} </td>                     
                                <td className="text-center">
                                  <button className="atividadeHist" onClick={openModal2.bind(null,negocio)}>Listar histórico</button> 
                                </td>
                              </tr>
                            );
                          }else{
                            return(
                              <tr key={negocio.id} >
                                <td className="text-center">{negocio.id}</td>
                                <td className="text-center">{negocio.nome} </td>
                                <td className="text-center">
                                  <button className="atividadeHist" onClick={openModal2.bind(null,negocio)}>Listar histórico</button> 
                                </td>
                              </tr>
                          );
                          }
                        })
                      }
                    </tbody>
                  </table>
                </div>
              );
            }else if(usuario.id === authUser.id &&(authUser.role ==="equipe" && authUser.dados === 0)){
              return(
                <Row className="w-100 justify-content-center ">
                  <div className="negadoGist">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
                </Row>
              )
            }
              return true;
          })
        }

      </div>

    <Modal className="verhistorico" data-keyboard={false}  backdrop={false} show={show2} >
    <Button className="closeButton" onClick={handleClose2}><i className="fa fa-long-arrow-left"></i>   Voltar</Button>
      <Modal.Body>
        <table className="tableHistorico">
          <thead>
            <tr>
              <th className="text-center">Data</th>
              <th className="text-center">Negócio</th>
              <th className="text-center">Serviço</th>
              <th className="text-center">Status</th>
              <th className="text-center">Detalhes</th>
            </tr>
          </thead>
          <tbody>
            {
              historicos && historicos.map((historico) =>{
                if(historico.negocio_id === negocioId){
                  return(
                    <tr key={historico.id}>
                      <td className="text-center">{historico.data} </td>           
                      <td className="text-center">{negocioEnvolvido((historico.negocio_id) )} </td>    
                      <td className="text-center">{historico.servico} </td>                     
                      <td className="text-center">{historico.status} </td> 
                      <td className="text-center">{historico.detalhes} </td> 
                    </tr>
                  );
               }
                return true;
              })
            }
          </tbody>
        </table>
      </Modal.Body>        
    </Modal>
  </div>
  );
}
export default RegisterHistorico;