import React, { useEffect, useState, useContext,useCallback, useRef } from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import API from "utils/API";
import { Row,Modal, Col, FormGroup,Form, FormControl, Button } from "react-bootstrap";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import $ from "jquery";
import "../../assets/css/calendario.css"
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from 'react-datepicker';
import { AuthUserContext } from "../../contexts/AuthUserContext";
///////////// UTILIZA TAMBÉM O CSS DOS MATERIAIS

function NovoPlanejamento(){
  const [negocios, setNegocios] = useState();
  const [planejamentos, setPlanejamentos] = useState();
  const [planejamento, setPlanejamento] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [usuarios, setUsuarios] = useState();
  const { register, handleSubmit, setValue } = useForm();
  const { authUser } = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [datapost1, setDatapost1] = useState(new Date());
  const [datapost2, setDatapost2] = useState(new Date());
  const [datapost3, setDatapost3] = useState(new Date());
  const [datapost4, setDatapost4] = useState(new Date());
  const [datapost5, setDatapost5] = useState(new Date());
  const [datapost6, setDatapost6] = useState(new Date());
  const [datapost7, setDatapost7] = useState(new Date());
  const [datapost8, setDatapost8] = useState(new Date());
  const [datapost9, setDatapost9] = useState(new Date());
  const [datapost10, setDatapost10] = useState(new Date());
  const [datapost11, setDatapost11] = useState(new Date());
  const [datapost12, setDatapost12] = useState(new Date());
  const [datapost13, setDatapost13] = useState(new Date());
  const [datapost14, setDatapost14] = useState(new Date());
  const [datapost15, setDatapost15] = useState(new Date());
  const [datapost16, setDatapost16] = useState(new Date());
  const [datapost17, setDatapost17] = useState(new Date());
  const [datapost18, setDatapost18] = useState(new Date());
  const [datapost19, setDatapost19] = useState(new Date());
  const [datapost20, setDatapost20] = useState(new Date());
  const [datapost21, setDatapost21] = useState(new Date());
  const [datapost22, setDatapost22] = useState(new Date());
  const [datapost23, setDatapost23] = useState(new Date());
  const [datapost24, setDatapost24] = useState(new Date());
  const [datapost25, setDatapost25] = useState(new Date());
  const [datapost26, setDatapost26] = useState(new Date());
  const [datapost27, setDatapost27] = useState(new Date());
  const [datapost28, setDatapost28] = useState(new Date());
  const [datapost29, setDatapost29] = useState(new Date());
  const [datapost30, setDatapost30] = useState(new Date());
  const [datapost31, setDatapost31] = useState(new Date());
  const [completePath, setCompletePath] = useState();
  const [completePath1, setCompletePath1] = useState();
  const [completePath2, setCompletePath2] = useState();
  const [completePath3, setCompletePath3] = useState();
  const [completePath4, setCompletePath4] = useState();
  const [completePath5, setCompletePath5] = useState();
  const [completePath6, setCompletePath6] = useState();
  const [completePath7, setCompletePath7] = useState();
  const [completePath8, setCompletePath8] = useState();
  const [completePath9, setCompletePath9] = useState();
  const [completePath10, setCompletePath10] = useState();
  const [completePath11, setCompletePath11] = useState();
  const [completePath12, setCompletePath12] = useState();
  const [completePath13, setCompletePath13] = useState();
  const [completePath14, setCompletePath14] = useState();
  const [completePath15, setCompletePath15] = useState();
  const [completePath16, setCompletePath16] = useState();
  const [completePath17, setCompletePath17] = useState();
  const [completePath18, setCompletePath18] = useState();
  const [completePath19, setCompletePath19] = useState();
  const [completePath20, setCompletePath20] = useState();
  const [completePath21, setCompletePath21] = useState();
  const [completePath22, setCompletePath22] = useState();
  const [completePath23, setCompletePath23] = useState();
  const [completePath24, setCompletePath24] = useState();
  const [completePath25, setCompletePath25] = useState();
  const [completePath26, setCompletePath26] = useState();
  const [completePath27, setCompletePath27] = useState();
  const [completePath28, setCompletePath28] = useState();
  const [completePath29, setCompletePath29] = useState();
  const [completePath30, setCompletePath30] = useState();
  const fileNovaMidiaRef = useRef();
  let empresa = "nome";

  const setForm = useCallback(
    (n) => {
      setValue("id", n.id);
      setValue("mes", n.mes);
      setValue("startDay", n.startDay);
      setValue("texto_1", n.texto_1);
      setValue("file", n.file);
      setValue("texto_2", n.texto_2);
      setValue("texto_3", n.texto_3);
      setValue("texto_4", n.texto_4);
      setValue("texto_5", n.texto_5);
      setValue("texto_6", n.texto_6);
      setValue("texto_7", n.texto_7);
      setValue("texto_8", n.texto_8);
      setValue("texto_9", n.texto_9);
      setValue("texto_10", n.texto_10);
      setValue("texto_11", n.texto_11);
      setValue("texto_12", n.texto_12);
      setValue("texto_13", n.texto_13);
      setValue("texto_14", n.texto_14);
      setValue("texto_15", n.texto_15);
      setValue("texto_16", n.texto_16);
      setValue("texto_17", n.texto_17);
      setValue("texto_18", n.texto_18);
      setValue("texto_19", n.texto_19);
      setValue("texto_20", n.texto_20);
      setValue("texto_21", n.texto_21);
      setValue("texto_22", n.texto_22);
      setValue("texto_23", n.texto_23);
      setValue("texto_24", n.texto_24);
      setValue("texto_25", n.texto_25);
      setValue("texto_26", n.texto_26);
      setValue("texto_27", n.texto_27);
      setValue("texto_28", n.texto_28);
      setValue("texto_29", n.texto_29);
      setValue("texto_30", n.texto_30);
      setValue("texto_31", n.texto_31);
      setValue("datapost1", n.datapost1);
      setValue("datapost2", n.datapost2);
      setValue("datapost3", n.datapost3);
      setValue("datapost4", n.datapost4);
      setValue("datapost5", n.datapost5);
      setValue("datapost6", n.datapost6);
      setValue("datapost7", n.datapost7);
      setValue("datapost8", n.datapost8);
      setValue("datapost9", n.datapost9);
      setValue("datapost10", n.datapost10);
      setValue("datapost11", n.datapost11);
      setValue("datapost12", n.datapost12);
      setValue("datapost13", n.datapost13);
      setValue("datapost14", n.datapost14);
      setValue("datapost15", n.datapost15);
      setValue("datapost16", n.datapost16);
      setValue("datapost17", n.datapost17);
      setValue("datapost18", n.datapost18);
      setValue("datapost19", n.datapost19);
      setValue("datapost20", n.datapost20);
      setValue("datapost21", n.datapost21);
      setValue("datapost22", n.datapost22);
      setValue("datapost23", n.datapost23);
      setValue("datapost24", n.datapost24);
      setValue("datapost25", n.datapost25);
      setValue("datapost26", n.datapost26);
      setValue("datapost27", n.datapost27);
      setValue("datapost28", n.datapost28);
      setValue("datapost29", n.datapost29);
      setValue("datapost30", n.datapost30);
      setValue("datapost31", n.datapost31);
      setValue("check1", n.check1);
      setValue("check2", n.check2);
      setValue("check3", n.check3);
      setValue("check4", n.check4);
      setValue("check5", n.check5);
      setValue("check6", n.check6);
      setValue("check7", n.check7);
      setValue("check8", n.check8);
      setValue("check9", n.check9);
      setValue("check10", n.check10);
      setValue("check11", n.check11);
      setValue("check12", n.check12);
      setValue("check13", n.check13);
      setValue("check14", n.check14);
      setValue("check15", n.check15);
      setValue("check16", n.check16);
      setValue("check17", n.check17);
      setValue("check18", n.check18);
      setValue("check19", n.check19);
      setValue("check20", n.check20);
      setValue("check21", n.check21);
      setValue("check22", n.check22);
      setValue("check23", n.check23);
      setValue("check24", n.check24);
      setValue("check25", n.check25);
      setValue("check26", n.check26);
      setValue("check27", n.check27);
      setValue("check28", n.check28);
      setValue("check29", n.check29);
      setValue("check30", n.check30);
      setValue("check31", n.check31);
      setValue("negocio_id", n.negocio_id);
      setValue("duracao", n.duracao);
    },
    [setValue]
  );

  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    if (show && planejamento) {
      setForm(planejamento);
    }
  }, [show, planejamento, setForm]);


  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("planejamentos");
    run().then((response) => {
        setPlanejamentos(response);
    });
  }, []);


  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return empresa;
    });
    return (empresa);
  }

  function onSubmit2(data) {
    const [run] = API.put(`planejamentos/${planejamento.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setPlanejamento(response);
        handleClose();
        refreshPage();
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onSubmit(data) {
    const [run] = API.post("planejamentos", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Material adicionado com sucesso.");
        setIsLoading(false);        
        refreshPage();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
        $(".botaoAzul").show();  
      });
  }

  function openModal(planParam) {
    setPlanejamento(planParam);
    Pic(planParam.negocio_id);
    console.log(planParam.negocio_id);
    handleShow();
  }

  function createMidia(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }               

  function createMidia1(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_1 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia2(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_2 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia3(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_3 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia4(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_4 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia5(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_5 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia6(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_6 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia7(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_7 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia8(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_8 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia9(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_9 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia10(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_10 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia11(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_11 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia12(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_12 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia13(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_13 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia14(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_14 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia15(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_15 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia16(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_16 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia17(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_17 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia18(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_18 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia19(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_19 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia20(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_20 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia21(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_21 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia22(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_22 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia23(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_23 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia24(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_24 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia25(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_25 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia26(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_26 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia27(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_27 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia28(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_28 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia29(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_29 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function createMidia30(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.file_30 = e.target.files[FIRST];
      const [run] = API.put(`planejamentos/${planejamento.id}`, data);
      run().then(() => {
      });
    }
  }

  function Pic(planId){
    planejamentos && planejamentos.map((planejamento) => {
      if(planejamento.negocio_id === planId){

        if(planejamento.path !== null){
          setCompletePath(API.getBasePath() + planejamento.path);
        }else{
          setCompletePath("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_1 !== null){
           setCompletePath1(API.getBasePath() + planejamento.path_1);
        }else{
          setCompletePath1("https://i.imgur.com/8DZqWIW.png");
        }
        
        if(planejamento.path_2 !== null){
          setCompletePath2(API.getBasePath() + planejamento.path_2);
        }else{
          setCompletePath2("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_3  !== null){
          setCompletePath3(API.getBasePath() + planejamento.path_3);
        }else{
          setCompletePath3("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_4  !== null){
          setCompletePath4(API.getBasePath() + planejamento.path_4);;
        }else{
          setCompletePath4("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_5  !== null){
          setCompletePath5(API.getBasePath() + planejamento.path_5);
        }else{
          setCompletePath5("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_6  !== null){
         setCompletePath6(API.getBasePath() + planejamento.path_6);
        }else{
         setCompletePath6("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_7 !== null){
          setCompletePath7(API.getBasePath() + planejamento.path_7);
        }else{
          setCompletePath7("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_8 !== null ){
          setCompletePath8(API.getBasePath() + planejamento.path_8);
        }else{
         setCompletePath8("https://i.imgur.com/8DZqWIW.png");
        }
       
        if(planejamento.path_9 !== null ){
          setCompletePath9(API.getBasePath() + planejamento.path_9);
        }else{
          setCompletePath9("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_10 !== null ){
          setCompletePath10(API.getBasePath() + planejamento.path_10);
        }else{
          setCompletePath10("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_11 !== null ){
          setCompletePath11(API.getBasePath() + planejamento.path_11);
        }else{
          setCompletePath11("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_12  !== null){
         setCompletePath12(API.getBasePath() + planejamento.path_12);
        }else{
          setCompletePath12("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_13  !== null){
          setCompletePath13(API.getBasePath() + planejamento.path_13);
        }else{
          setCompletePath13("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_14  !== null){
          setCompletePath14(API.getBasePath() + planejamento.path_14);
        }else{
          setCompletePath14("https://i.imgur.com/8DZqWIW.png");
        }
      
        if(planejamento.path_15  !== null){
          setCompletePath15(API.getBasePath() + planejamento.path_15);
        }else{
          setCompletePath15("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_16  !== null){
          setCompletePath16(API.getBasePath() + planejamento.path_16);
        }else{
          setCompletePath16("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_17  !== null){
          setCompletePath17(API.getBasePath() + planejamento.path_17);
        }else{
          setCompletePath17("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_18 !== null ){
          setCompletePath18(API.getBasePath() + planejamento.path_18);
        }else{
          setCompletePath18("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_19 !== null){
          setCompletePath19(API.getBasePath() + planejamento.path_19);
        }else{
          setCompletePath19("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_20 !== null){
          setCompletePath20(API.getBasePath() + planejamento.path_20);
        }else{
          setCompletePath20("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_21 !== null){
          setCompletePath21(API.getBasePath() + planejamento.path_21);
        }else{
          setCompletePath21("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_22 !== null){
          setCompletePath22(API.getBasePath() + planejamento.path_22);
        }else{
          setCompletePath22("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_23 !== null){
          setCompletePath23(API.getBasePath() + planejamento.path_23);
        }else{
          setCompletePath23("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_24 !== null){
          setCompletePath24(API.getBasePath() + planejamento.path_24);
        }else{
          setCompletePath24("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_25 !== null){
          setCompletePath25(API.getBasePath() + planejamento.path_25);
        }else{
          setCompletePath25("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_26 !== null){
          setCompletePath26(API.getBasePath() + planejamento.path_26);
        }else{
          setCompletePath26("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_27 !== null){
          setCompletePath27(API.getBasePath() + planejamento.path_27);
        }else{
          setCompletePath27("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_28 !== null){
          setCompletePath28(API.getBasePath() + planejamento.path_28);
        }else{
          setCompletePath28("https://i.imgur.com/8DZqWIW.png");
        }

        if(planejamento.path_29 !== null){
          setCompletePath29(API.getBasePath() + planejamento.path_29);
        }else{
          setCompletePath29("https://i.imgur.com/8DZqWIW.png");
        }

      if(planejamento.path_30 !== null){
        setCompletePath30(API.getBasePath() + planejamento.path_30);
      }else{
        setCompletePath30("https://i.imgur.com/8DZqWIW.png");
      }
    }
   return true;
  })
}

return(
  <div id="bodyNoticias">
  <MenuCompleto/>
    <div className="tituloEditCal">Gerenciar Calendário</div>
      {   
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.calendario === 1))){      
            return(
              <div id="materiais">
                <p>
                  <div> 
                    <Dropdown>
                      <Dropdown.Toggle className="MatDrop" id="dropdown-basic">
                      Cadastrar calendário
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="dropCal">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={12}>
                                <label>Mês</label><br/>
                                  <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                                    <option>Janeiro</option>
                                    <option>Fevereiro</option>
                                    <option>Março</option>
                                    <option>Abril</option>
                                    <option>Maio</option>
                                    <option>Junho</option>
                                    <option>Julho</option>
                                    <option>Agosto</option>
                                    <option>Setembro</option>
                                    <option>Outubro</option>
                                    <option>Novembro</option>
                                    <option>Dezembro</option>
                                  </Form.Control>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <label>Primeiro dia do mês</label><br/>
                                  <Form.Control id="startDay" as="select" ref={register} name="startDay" label="startDay">
                                    <option>Domingo</option>
                                    <option>Segunda-feira</option>
                                    <option>Terça-feira</option>
                                    <option>Quarta-feira</option>
                                    <option>Quinta-feira</option>
                                    <option>Sexta-feira</option>
                                    <option>Sábado</option>
                                  </Form.Control>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <label>Duração do mês</label><br/>
                                  <Form.Control id="duracao" as="select" ref={register} name="duracao" label="duracao">
                                      <option>28</option>
                                      <option>29</option>
                                      <option>30</option>
                                      <option>31</option>
                                  </Form.Control>
                              </Col>
                              <Col md={12}>
                                <FormGroup className="plannerSelection">
                                  <label>Negócio</label>
                                  <FormControl  name="negocio_id" as="select" ref={register} >
                                    {
                                      negocios && negocios.map((negocio) => (
                                        <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                      ))
                                    }
                                  </FormControl>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Button className="botaoEditMat"
                                  type="submit"
                                  disabled={isLoading}>
                                      Salvar
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </p>
      
                <table className="tableMateriais">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Negócio</th>
                      <th className="text-center">Mês</th>
                      <th className="text-center">Primeiro dia do mês</th>
                      <th className="text-center">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      planejamentos && planejamentos.map((planejamento) => {
                        if(planejamento.id %2 === 0){
                          return(
                            <tr key={planejamento.id} className="matData">
                                <td className="text-center">{planejamento.id}</td>
                                <td className="text-center">{negocioEnvolvido(planejamento.negocio_id)} </td>
                                <td className="text-center">{planejamento.mes}</td>
                                <td className="text-center">{planejamento.startDay}</td>
                                <td>
                                  <button className="acoes editar materiaisBtn"  onClick={openModal.bind(null, planejamento)}>
                                    Editar
                                  </button>
                                </td>
                            </tr>
                           );
                        }else{
                          return (
                            <tr key={planejamento.id}>
                              <td className="text-center grayClass">{planejamento.id}</td>
                                <td className="text-center grayClass">{negocioEnvolvido(planejamento.negocio_id)} </td>
                                <td className="text-center grayClass">{planejamento.mes}</td>
                                <td className="text-center grayClass">{planejamento.startDay}</td>
                                <td className="grayClass">
                                  <button className="acoes editar materiaisBtn "  onClick={openModal.bind(null, planejamento)}>
                                    Editar
                                  </button>
                                </td>
                            </tr>
                          );
                        }
                      })
                    }
                  </tbody>
                </table>
              </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.calendario === 0)){
            return(     
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
          return true;
        })
      }

      <Modal className="editcalendario" data-keyboard={false}  backdrop={false} show={show} >
      <Button className="closeButton" onClick={handleClose}>X</Button>
        <Modal.Body>   
          <div className="titleEditCalendario">Editar Planejamento </div>
            <Form className="formCalendario" onSubmit={handleSubmit(onSubmit2)}>
              <Row>
                <Col className="col-md-5 pb-5">
                  <label>Mês</label>
                  <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                    <option>Janeiro</option>
                    <option>Fevereiro</option>
                    <option>Março</option>
                    <option>Abril</option>
                    <option>Maio</option>
                    <option>Junho</option>
                    <option>Julho</option>
                    <option>Agosto</option>
                    <option>Setembro</option>
                    <option>Outubro</option>
                    <option>Novembro</option>
                    <option>Dezembro</option>
                  </Form.Control>
                </Col>
                <Col className="col-md-6 pl-4 pb-5">
                  <label>Primeiro dia do mês</label>
                  <Form.Control id="startDay" as="select" ref={register} name="startDay" label="startDay">
                      <option>Domingo</option>
                      <option>Segunda-feira</option>
                      <option>Terça-feira</option>
                      <option>Quarta-feira</option>
                      <option>Quinta-feira</option>
                      <option>Sexta-feira</option>
                      <option>Sábado</option>
                  </Form.Control>
                </Col>
                <FormGroup className="calendarioInput">
                  <Row className="w-100 justify-content-center">
                    <label>Dia 1</label>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <label className="containerPost">
                      <input type="checkbox" className="checkboxPostad" name="check1" ref={register}/><div className="txtPost">Postado</div>
                      <div className="analytisHas"></div> 
                    </label>
                   </Row>
                  <Row className="w-100 justify-content-center">
                    <div className="box-img">
                      <img className="img-cal" alt="Post dia 1" src={completePath}></img>
                    </div>
                  </Row>
                  <br></br>
                  <FormControl  name="texto_1" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" name="file" type="file" onChange={createMidia}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div>
                        <div className="inputCal">
                            <DatePicker selected={datapost1} locale="ptBR"
                          onChange={date => setDatapost1(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <input value={datapost1.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost1" ref={register}></input>
                    </Row>
                  </FormGroup>     
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                    <label>Dia 2</label> <br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check2" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 2"  src={completePath1}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_2" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia1}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div><div className="inputCal">
                          <DatePicker selected={datapost2} locale="ptBR"
                        onChange={date => setDatapost2(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <input value={datapost2.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost2" ref={register}></input>
                    </Row>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 3</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check3" ref={register}/>
                        <div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 3"  src={completePath2}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_3" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia2}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div>
                      <div className="inputCal">
                          <DatePicker selected={datapost3} locale="ptBR"
                        onChange={date => setDatapost3(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <input value={datapost3.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost3" ref={register}></input>
                    </Row>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 4</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check4" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal"  alt="Post dia 4"  src={completePath3}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_4" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia3}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <Row className="w-100 justify-content-center">
                        <div className="tcal"> Agendado em:</div>
                        <div className="inputCal">
                            <DatePicker selected={datapost4} locale="ptBR"
                          onChange={date => setDatapost4(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </Row>
                      <input value={datapost4.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost4" ref={register}></input>
                    </Row>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 5</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check5" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">     
                        <img className="img-cal" alt="Post dia 5"  src={completePath4}></img>
                      </div>
                    </Row><br></br>
                    <FormControl  name="texto_5" as="textarea"  rows={5}  ref={register} ></FormControl>     <br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia4}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <Row className="w-100 justify-content-center">
                        <div className="tcal"> Agendado em:</div> 
                        <div className="inputCal">
                            <DatePicker selected={datapost5} locale="ptBR"
                          onChange={date => setDatapost5(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </Row>
                      <input value={datapost5.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost5" ref={register}></input>
                    </Row>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 6</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check6" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                      <img className="img-cal" alt="Post dia 6"  src={completePath5}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_6" as="textarea"  rows={5}  ref={register} ></FormControl> <br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia5}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div>
                      <div className="inputCal">
                        <DatePicker selected={datapost6} locale="ptBR"
                        onChange={date => setDatapost6(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost6.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost6" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 7</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check7" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 7" src={completePath6}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_7" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia6}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost7} locale="ptBR"
                        onChange={date => setDatapost7(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost7.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost7" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 8</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check8" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 8" src={completePath7}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_8" as="textarea"  rows={5}  ref={register} ></FormControl> <br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia7}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost8} locale="ptBR"
                        onChange={date => setDatapost8(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost8.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost8" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                      <Row className="w-100 justify-content-center">
                        <label>Dia 9</label><br></br>
                      </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                      <input type="checkbox" className="checkboxPostad" name="check9" ref={register}/><div className="txtPost">Postado</div>
                      <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 9"  src={completePath8}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_9" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia8}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost9} locale="ptBR"
                        onChange={date => setDatapost9(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost9.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost9" ref={register}></input>
                </FormGroup>
                <FormGroup className="calendarioInput">
                  <Row className="w-100 justify-content-center">
                    <label>Dia 10</label><br></br>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <label className="containerPost">
                      <input type="checkbox" className="checkboxPostad" name="check10" ref={register}/><div className="txtPost">Postado</div>
                      <div className="analytisHas"></div> 
                    </label>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <div className="box-img">
                      <img className="img-cal" alt="Post dia 10" src={completePath9}></img>
                    </div>
                  </Row>
                  <br></br>
                  <FormControl  name="texto_10" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia9}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost10} locale="ptBR"
                        onChange={date => setDatapost10(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost10.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost10" ref={register}></input>
                </FormGroup>
                <FormGroup className="calendarioInput">
                  <Row className="w-100 justify-content-center">
                    <label>Dia 11</label><br></br>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <label className="containerPost">
                      <input type="checkbox" className="checkboxPostad" name="check11" ref={register}/><div className="txtPost">Postado</div>
                      <div className="analytisHas"></div> 
                    </label>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <div className="box-img">
                      <img className="img-cal" alt="Post dia 11"  src={completePath10}></img>
                    </div>
                  </Row><br></br>
                  <FormControl  name="texto_11" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia10}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                            <DatePicker selected={datapost11} locale="ptBR"
                          onChange={date => setDatapost11(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost11.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost11" ref={register}></input>
                </FormGroup>
                <FormGroup className="calendarioInput">
                  <Row className="w-100 justify-content-center">
                    <label>Dia 12</label><br></br>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <label className="containerPost">
                      <input type="checkbox" className="checkboxPostad" name="check12" ref={register}/><div className="txtPost">Postado</div>
                      <div className="analytisHas"></div> 
                    </label>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <div className="box-img">
                      <img className="img-cal" alt="Post dia 12"  src={completePath11}></img>
                    </div>
                  </Row>
                  <br></br>
                  <FormControl  name="texto_12" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia11}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div>
                      <div className="inputCal">
                          <DatePicker selected={datapost12} locale="ptBR"
                        onChange={date => setDatapost12(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost12.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost12" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 13</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check13" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 13"  src={completePath12}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_13" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia12}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                            <DatePicker selected={datapost13} locale="ptBR"
                          onChange={date => setDatapost13(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost13.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost13" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 14</label><br></br>
                    </Row>  
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check14" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 14" src={completePath13}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_14" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia13}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost14} locale="ptBR"
                        onChange={date => setDatapost14(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost14.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost14" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 15</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                          <input type="checkbox" className="checkboxPostad" name="check15" ref={register}/><div className="txtPost">Postado</div>
                          <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 15"  src={completePath14}></img>
                      </div>
                    </Row><br></br>
                    <FormControl  name="texto_15" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia14}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost15} locale="ptBR"
                        onChange={date => setDatapost15(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost15.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost15" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 16</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                          <input type="checkbox" className="checkboxPostad" name="check16" ref={register}/><div className="txtPost">Postado</div>
                          <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 16"  src={completePath15}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_16" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia15}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost16} locale="ptBR"
                        onChange={date => setDatapost16(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost16.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost16" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 17</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check17" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 17"  src={completePath16}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_17" as="textarea"  rows={5}  ref={register} > </FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia16}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                            <DatePicker selected={datapost17} locale="ptBR"
                          onChange={date => setDatapost17(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost17.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost17" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 18</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check18" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                   </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 18" src={completePath17}></img>
                      </div>
                   </Row>
                   <br></br>
                    <FormControl  name="texto_18" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia17}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                            <DatePicker selected={datapost18} locale="ptBR"
                          onChange={date => setDatapost18(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost18.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost18" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 19</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check19" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 19"  src={completePath18}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_19" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia18}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost19} locale="ptBR"
                          onChange={date => setDatapost19(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost19.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost19" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 20</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check20" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 20"  src={completePath19}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_20" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia19}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost20} locale="ptBR"
                        onChange={date => setDatapost20(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost20.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost20" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 21</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check21" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 21"  src={completePath20}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_21" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia20}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                            <DatePicker selected={datapost21} locale="ptBR"
                          onChange={date => setDatapost21(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost21.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost21" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 22</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check22" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 22"  src={completePath21}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_22" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia21}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost22} locale="ptBR"
                          onChange={date => setDatapost22(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost22.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost22" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 23</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check23" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 23"  src={completePath22}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_23" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia22}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost23} locale="ptBR"
                        onChange={date => setDatapost23(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost23.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost23" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 24</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check24" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 24"  src={completePath23}></img>
                      </div>
                     </Row>
                     <br></br>
                    <FormControl  name="texto_24" as="textarea"  rows={5}  ref={register} > </FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia23}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost24} locale="ptBR"
                        onChange={date => setDatapost24(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost24.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost24" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 25</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check25" ref={register}/>
                        <div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 25"  src={completePath24}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_25" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia24}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost25} locale="ptBR"
                        onChange={date => setDatapost25(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost25.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost25" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 26</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check26" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 26"  src={completePath25}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_26" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia25}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost26} locale="ptBR"
                        onChange={date => setDatapost26(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost26.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost26" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 27</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check27" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 27"  src={completePath26}></img>
                      </div>
                    </Row><br></br>
                    <FormControl  name="texto_27" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia26}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> <div className="inputCal">
                          <DatePicker selected={datapost27} locale="ptBR"
                        onChange={date => setDatapost27(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost27.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost27" ref={register}></input>
                  </FormGroup>             
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 28</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check28" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img">
                        <img className="img-cal" alt="Post dia 28"  src={completePath27}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_28" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia27}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost28} locale="ptBR"
                        onChange={date => setDatapost28(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost28.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost28" ref={register}></input>
                    </FormGroup>
                    <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 29</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check29" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img"> 
                        <img className="img-cal" alt="Post dia 29"  src={completePath28}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_29" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia28}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost29} locale="ptBR"
                        onChange={date => setDatapost29(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost29.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost29" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 30</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check30" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img"> 
                        <img className="img-cal" alt="Post dia 30"  src={completePath29}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_30" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia29}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div>
                      <div className="inputCal">
                            <DatePicker selected={datapost30} locale="ptBR"
                          onChange={date => setDatapost30(date)} 
                          dateFormat="dd/MM/yyyy"
                          />
                      </div>
                    </Row>
                    <input value={datapost30.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost30" ref={register}></input>
                  </FormGroup>
                  <FormGroup className="calendarioInput">
                    <Row className="w-100 justify-content-center">
                      <label>Dia 31</label><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <label className="containerPost">
                        <input type="checkbox" className="checkboxPostad" name="check31" ref={register}/><div className="txtPost">Postado</div>
                        <div className="analytisHas"></div> 
                      </label>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="box-img"> 
                        <img className="img-cal" alt="Post dia  31"  src={completePath30}></img>
                      </div>
                    </Row>
                    <br></br>
                    <FormControl  name="texto_31" as="textarea"  rows={5}  ref={register} ></FormControl><br></br>
                    <Row className="w-100 justify-content-center">
                      <input className="day1" type="file" onChange={createMidia30}  ref={fileNovaMidiaRef}/><br></br>
                    </Row>
                    <Row className="w-100 justify-content-center">
                      <div className="tcal"> Agendado em:</div> 
                      <div className="inputCal">
                          <DatePicker selected={datapost31} locale="ptBR"
                        onChange={date => setDatapost31(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Row>
                    <input value={datapost31.toLocaleDateString()} style={{ "display": "none" }} id="data" name="datapost31" ref={register}></input>
                  </FormGroup>
                  <Col className="col-md-6 pl-4 pt-4">
                    <label>Duração do mês</label>
                      <Form.Control id="duracao" as="select" ref={register} name="duracao" label="duracao">
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                      </Form.Control>
                  </Col>


                  <Col md={12}>
                    <FormGroup className="plannerSelection">
                      <label>Negócio</label>
                      <FormControl  name="negocio_id" as="select" ref={register} >
                        {
                          negocios && negocios.map((negocio) => (
                            <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                          ))
                        }
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col className="col-md-12 pt-3 text-center">
                    <Button className="btnCalendario" type="submit"
                      disabled={isLoading}>
                      Atualizar Planejamento
                    </Button>
                  </Col>
                </Row>
            <div className="clearfix" />
          </Form>
        </Modal.Body>
      </Modal>
</div>

  );
}

export default NovoPlanejamento;