import React, { useState, useEffect } from "react";
import NavMenu from "components/Navbars/NavMenu";
import { useHistory} from "react-router-dom";
import {Row} from "react-bootstrap";
import API from "utils/API";
import LastAccess from "views/User/LastAccess";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function Briefing(){
  const history = useHistory();
  const [negocios, setNegocios] = useState();
  var briefing = 0;   
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  var negocioId = "2";
  
  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);


    /*Retorna a Id do negócio selecionado */
    function getNegocioId() {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      return (negocioId = IdNegocio); 
    } 
  
    getNegocioId();

    
  
  const Documentos = () =>{ 
    history.push(`/documentos/${negocioId}`);
  }

  
  function getBriefing(){
    negocios && negocios.map((negocio) => {
      if(negocio.id === negocioId){
        return(
          briefing = (API.getBasePath() + negocio.briefing)
        );
      }
      return briefing;
    })
    return briefing;
 }

 getBriefing();

return(
  <div id="contentDash">
    <NavMenu/>
    <button className="back" onClick={Documentos}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    <Row className="w-100 justify-content-center"> 
      <div className="titleContrato">Briefing</div>
    </Row>
    <Row className="w-100 justify-content-center"> 
      <button className="btnContrato"><a href={`${
      briefing}?download=true`}
              target="_blank" rel="noopener noreferrer"  
              > <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar o seu arquivo</a></button>
    </Row>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>

    <img class="position-absolute opacity line-1" src={linhas} alt=""></img>
    <img class="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img class="position-absolute opacity line-3" src={linhas3} alt=""></img>
  </div>
  );
}

export default Briefing;