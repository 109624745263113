import React, { useContext } from "react";
import { AuthUserContext } from "../../contexts/AuthUserContext.js";
import ChangeCliente from './ChangeCliente';
import { useHistory} from "react-router-dom";

function SelectNegocio(){
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  
  const backToAdm = () =>{ 
    history.push("/registro_usuarios");
  }

  return(
    <div id="contentDash">
          <button className="backDash" onClick={backToAdm}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    {
      authUser.role !== "cliente" &&
      <>      
        <div key="" id="selectYourProfile">
        <ChangeCliente/>
        </div>
      </>
    }
    </div>
  );
}

export default SelectNegocio;