import React, { useCallback, useEffect, useState, useContext } from "react";
import API from "utils/API";
import { Button, Col, Form, Modal, Row, FormGroup, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationContext } from "./../../contexts/NotificationContext";
import MenuCompleto from "views/Admin/MenuCompleto";
import "../../assets/css/EditProjecao.css";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import ChangeProjecao from "./ChangeProjecao";

function EditarProjecao () {
  const [projecoes, setProjecoes] = useState();
  const [projecao, setProjecao] = useState();
  const [values, setValues] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [negocios, setNegocios] = useState();
  const [google, setGoogle] = useState();
  const notification = useContext(NotificationContext);
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let empresa = "nome";

  const setForm = useCallback(
    (n) => {
      setValue("id", n.id);
      setValue("metaTotal", n.metaTotal);
      setValue("metaPorcentagem", n.metaPorcentagem);
      setValue("fbq1", n.fbq1);
      setValue("fbq2", n.fbq2);
      setValue("fbq3", n.fbq3);
      setValue("fbq4", n.fbq4);
      setValue("ads1", n.ads1);
      setValue("ads2", n.ads2);
      setValue("ads3", n.ads3);
      setValue("ads4", n.ads4);
      setValue("cplAnterior", n.cplAnterior);
      setValue("cplFb", n.cplFb);
      setValue("cplGoogle", n.cplGoogle);
      setValue("porcentagemInstaTotal", n.porcentagemInstaTotal);
      setValue("porcentagemFbTotal", n.porcentagemFbTotal);
      setValue("porcentagemLeads", n.porcentagemLeads);
      setValue("porcentagemAgendamento", n.porcentagemAgendamento);
      setValue("porcentagemComparecimento", n.porcentagemComparecimento);
      setValue("porcentagemFechamento", n.porcentagemFechamento);
      setValue("porcentagemGTotal", n.porcentagemGTotal);
      setValue("porcentagemLeadsG", n.porcentagemLeadsG);
      setValue("porcentagemAgendamentoG", n.porcentagemAgendamentoG);
      setValue("porcentagemComparecimentoG", n.porcentagemComparecimentoG);
      setValue("porcentagemFechamentoG", n.porcentagemFechamentoG);
      setValue("ticketAtual", n.ticketAtual);
      setValue("negocio_id", n.negocio_id);
      setValue("projetadosG", n.projetadosG);
      setValue("gpercent", n.gpercent);
      setValue("mes", n.mes);

    },
    [setValue]
  );


  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  
  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);


  useEffect(() => {
    if (show && projecao) {
      setForm(projecao);
    }
  }, [show, projecao, setForm]);

  function openModal(projecao) {
    setProjecao(projecao);
    handleShow();
  }

  function refreshPage() {
    window.location.reload(false);
  }

  function onSubmit(data) {
    const [run] = API.put(`projections/${projecao.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setProjecao(response);
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
        refreshPage();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = negocio.nome
        );
      }
      return empresa;
    });
    return (empresa);
  }

  function onChange(event) {
    setValues(event.target.value) //update your value here
  }

  function onChange2(event) {
    setGoogle(100-(values));
  }

   /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[6])
    var neg = IdNegocio;
    return (neg);
  }

  function has(ha){
    if(ha === 1){
      return(
        <div className="roseColored"><i className="fa fa-check"></i></div> 
      )
    }else{
      return(
        <div className="roseColored"> <i className="fa fa-window-close"></i></div>
      );
    }
  }



  
return (
  <div> 
    <MenuCompleto/>
      <div className="titleProjEdit">Gerenciar Projeção</div>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
            return(
              <div className="gerenciaProjecoes">
                <ChangeProjecao/>
                  <div className="input-group input-group-sm mb-3 srch rounded col-2 float-right"></div>
                    <table>
                      <thead>
                        <tr>
                          <th className="text-center">ID</th>
                          <th className="text-center">Empresa</th>
                          <th className="text-center">Mês</th>
                          <th className="text-center">Analytics</th>
                          <th className="text-center">Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          projecoes && projecoes.map((projecao) => {
                            if (projecao.negocio_id.toString() === getNegocioId()) {
                              return (
                                <tr key={projecao.id}>
                                  <td className="text-center">{projecao.id}</td>
                                  <td className="text-center">{negocioEnvolvido(projecao.negocio_id)}</td>
                                  <td className="text-center">{projecao.mes}</td>
                                  <td className="text-center">{has(projecao.hasAnalytics)}</td>
                                  <td className="opcoes text-center">
                                    <button className="acoes editar" onClick={openModal.bind(null, projecao)}>
                                  Editar
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                            return true;
                          })

                         }
                      </tbody>
                    </table>
                    <Modal className="modalEditProjecao" data-keyboard={true} backdrop={true} show={show} >
                    <Button className="closeButton" onClick={handleClose}>X</Button>
                      <Modal.Body>
                        <div className="tituloEProj">Editar projeção</div> 
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo">Informações gerais</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={4}>
                                <input  className="inputProjecoesEdit"  type="text" name="id" ref={register}></input><br></br>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label><div className="red">Negócio*</div></label>
                              </Col>
                              <Col md={6}>
                                <FormGroup className="selectionProjecaoMesEdit">
                                  <FormControl  name="negocio_id" as="select" ref={register} >
                                    {
                                      negocios && negocios.map((negocio) => (
                                        <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                      ))
                                    }
                                  </FormControl>
                                </FormGroup>
                             </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label><div className="red">Mês*</div></label>
                              </Col>
                              <Col md={6}>
                                <FormGroup className="selectionProjecaoMesEdit">
                                  <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                                    <option>Janeiro</option>
                                    <option>Fevereiro</option>
                                    <option>Março</option>
                                    <option>Abril</option>
                                    <option>Maio</option>
                                    <option>Junho</option>
                                    <option>Julho</option>
                                    <option>Agosto</option>
                                    <option>Setembro</option>
                                    <option>Outubro</option>
                                    <option>Novembro</option>
                                    <option>Dezembro</option>
                                  </Form.Control>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={12}>
                                <label className="containerEquipe">Possui Analytics
                                  <input type="checkbox" name="hasAnalytics" ref={register}/>
                                </label>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={12}>
                                <label className="containerEquipe">Possui Google
                                  <input type="checkbox" name="hasGoogle" ref={register}/>
                                </label>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={12} className="mt-2">* Preencher apenas com números e "." ou "," para casas decimais. Números acima de mil não devem conter ponto ou vírgula após a casa de milhares.</Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label className="mt-2"><div className="red">Meta de faturamento*</div></label>
                              </Col>
                              <Col md={6}>
                                <input  type="number" step="0.01" min="0.00"  className="inputProjecoesEdit mt-2" placeholder="Total - R$" ref={register} name="metaTotal"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label><div className="red"> Porcentagem redes sociais*</div></label>
                              </Col>
                              <Col md={6}>
                                <input  type="number" step="0.01" min="0.00"   className="inputProjecoesEdit" placeholder="80%" name="metaPorcentagem" onChange={onChange} ref={register}></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label> <div className="red">Porcentagem Google*</div></label>
                              </Col>
                              <Col md={6}>
                                <input  type="number" step="0.01" min="0.00"  className="inputProjecoesEdit"  placeholder="20%" name="gpercent" value={google} onSelect={onChange2} ref={register}></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo">Informações Redes Sociais</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">Investimentos Facebook/Instagram</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Facebook/Instagram - Semana 1</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="Semana 1 - R$" ref={register} name="fbq1"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Facebook/Instagram - Semana 2</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="Semana 2 - R$" ref={register} name="fbq2"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Facebook/Instagram - Semana 3</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="Semana 3 - R$" ref={register} name="fbq3"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Facebook/Instagram - Semana 4</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="Semana 4 - R$" ref={register} name="fbq4"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">CPL Facebook/Instagram</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label> CPL Anterior </label>
                              </Col>
                              <Col md={6}>
                              <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="CPL Anterior - R$" ref={register} name="cplAnterior"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label> CPL Redes Sociais </label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder="CPL Facebook/Instagram - R$"  ref={register} name="cplFb"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">Funil de Leads</div>
                            </Row>
                            <Row className="w-100 justify-content-center "> 
                              * A porcentagem de descoberta do Instagram e do Facebook devem somar 100%.
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label className="mt-2">Porcentagem de Descoberta do Instagram</label>
                              </Col>
                              <Col md={6}>
                                <input type="text" className="inputProjecoesEdit" placeholder="Porcentagem Descoberta Instagram - %"  ref={register} name="porcentagemInstaTotal"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Descoberta do Facebook</label>
                              </Col>
                              <Col md={6}>
                                <input type="text" className="inputProjecoesEdit" placeholder="Porcentagem Descoberta Facebook - %"  ref={register} name="porcentagemFbTotal"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Leads</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Leads - %"  ref={register} name="porcentagemLeads"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Agendamentos</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Agendamentos - %"  ref={register} name="porcentagemAgendamento"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Comparecimento</label>
                              </Col>
                              <Col md={6}>
                              <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Comparecimento - %"  ref={register} name="porcentagemComparecimento"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Fechamento</label>
                              </Col>
                              <Col md={6}>
                              <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Fechamento - %" ref={register} name="porcentagemFechamento"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label><div className="red">Ticket médio atual*</div></label>
                              </Col>
                              <Col md={6}>
                                <input type="number" step="0.01" min="0.00" className="inputProjecoesEdit" placeholder= "Ticket Médio R$" ref={register} name="ticketAtual"></input>
                              </Col>
                            </Row>
                           
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo">Informações Google</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">Investimentos Google</div>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Google Semana 1</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Semana 1 - R$" ref={register} name="ads1"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Google Semana 2</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Semana 2 - R$" ref={register} name="ads2"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Google Semana 3</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Semana 3 - R$" ref={register} name="ads3"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Google Semana 4</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Semana 4 - R$" ref={register} name="ads4"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">Projeção com base no mês anterior</div>
                            </Row> 
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Total Google</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Descoberta - %" ref={register} name="porcentagemGTotal"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Leads</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Leads - %" ref={register} name="porcentagemLeadsG"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Agendamento</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Agendamento - %" ref={register} name="porcentagemAgendamentoG"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Comparecimento</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit"  placeholder="Porcentagem Comparecimento - %" ref={register} name="porcentagemComparecimentoG"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>Porcentagem de Fechamento</label>
                              </Col>
                              <Col md={6}>
                               <input type="number" className="inputProjecoesEdit" placeholder="Porcentagem Fechamento - %" ref={register} name="porcentagemFechamentoG"></input>
                              </Col>
                            </Row>   
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label><div className="red">Ticket projetado Google*</div></label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" defaultValue= "0" placeholder= "Ticket Projetado Google R$" ref={register} name="projetadosG"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <div className="allinfo2">CPL Google</div>
                            </Row> 
                            <Row className="w-100 justify-content-center ">
                              <Col md={2}>
                                <label>CPL Google</label>
                              </Col>
                              <Col md={6}>
                                <input type="number" className="inputProjecoesEdit" placeholder="CPL Google"  ref={register} name="cplGoogle"></input>
                              </Col>
                            </Row>
                            <Row className="w-100 justify-content-center ">
                              <Col className="col-md-12 pt-3 text-center">
                                <Button className="btnEditProj" type="submit"
                                  disabled={isLoading}>
                                  Atualizar Projeção
                                </Button>
                              </Col>
                          </Row>
                            <div className="clearfix" />
                          </Form>
                      </Modal.Body>
                    </Modal>
                  </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.projecoes === 0)){
            return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
          return true;
        })
      }
    </div>
  );
}

EditarProjecao.propTypes = {
};
export default EditarProjecao;
