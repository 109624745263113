import React, { useContext, useEffect, useState, useRef} from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import "../../assets/css/notas.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { useForm } from "react-hook-form";
import API from "utils/API";
import { NotificationContext } from "../../contexts/NotificationContext";
import $ from "jquery";
import {Row,Modal, Col, FormGroup,Form, FormControl, Button } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import ChangeDados from "./ChangeDados";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function AdicionarNotas(){
  const { register, handleSubmit, } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [negocios, setNegocios] = useState();
  const [notas, setNotas] = useState();
  const [nota, setNota] = useState();
  const [idd, setIdd] = useState();
  const [reload, setReload] = useState(true);
  const [month, setMonth] = useState();
  const [yearNow, setYearNow] = useState();
  const [startDate, setStartDate] = useState(new Date());
  let empresa = "nome";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const fileNovaMidiaRef = useRef();
  const [pathh, setPathh] = useState();
  var aux = 0;
  const [notasName, setNotasName] = useState();
  var tipo = "teste"; 
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let negocioId = "0";
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();


  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);
  

  useEffect(() => {
    const [run] = API.get("notas");
    run().then((response) => {
        setNotas(response);
    });
  }, []);


  function refreshPage() {
    window.location.reload(false);
  }

  function onSubmit(data) {
    const [run] = API.post("notas", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Material adicionado com sucesso.");
        setIsLoading(false);        
        refreshPage();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
        $(".botaoAzul").show();  
      });
  }

  function onSubmit2(data) {
    const [run] = API.put(`notas/${nota.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setNota(response);
        setReload(true);
        handleClose();
        refreshPage();
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function openModal(notaParam) {
    setNota(notaParam);
    setIdd(notaParam.id);
    handleShow();
  }

  function openModal2(notaParam) {
    setNota(notaParam);
    setIdd(notaParam.id);
    handleShow2();
    if(notaParam.path !== null){
      aux = notaParam.path.split("/");
      setNotasName(aux[4]);
    }else{
      setNotasName("Sem arquivo");
    }
    setPathh(API.getBasePath() + notaParam.path)
  }

  function createMidia(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.midia = e.target.files[FIRST];
      const [run] = API.put(`notas/${idd}/carregaNota`, data);
      run().then(() => {
        refreshPage();
      });
    }
  }

function DataSet(date){
  setStartDate(date);
  var dateA = startDate.toLocaleDateString();
  dateA = dateA.split("/");
  var aux = dateA[1];
  setMonth(aux);
  var anoAux = dateA[2];
  setYearNow(anoAux);
}
  
useEffect(() => {
  const [run] = API.get("usuarios");
  setReload(false);
  run().then((response) => {
    setUsuarios(response);
  });
}, [reload]);


function negocioEnvolvido(neg) {
  negocios && negocios.map((negocio) => {
    if (neg === negocio.id) {
      return (empresa = (negocio.nome)
      );
    }
      return true;
  });
  return (empresa);
}

      /*Retorna a Id do negócio selecionado */
function getNegocioId() {
  const it = getUrl.split("/");
  const IdNegocio = (it[5]);
  return (negocioId = IdNegocio);
 }

 getNegocioId();

  return(
    <div>
      <MenuCompleto/>
        {  
          usuarios && usuarios.map((usuario) => {
            if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.dados === 1))){
              return(
                <div>
                  <div id="notas">
                    <div>
                      <Row className="w-100 justify-content-center">
                        <div className="titleRosaNotas">Notas fiscais</div>
                      </Row>
                      <ChangeDados/>
                    <div>
                  <Dropdown>
                    <Dropdown.Toggle className="DropNotas" id="dropdown-basic">
                     Cadastrar Notas
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropNotes">
                        <form onSubmit={handleSubmit(onSubmit)}>
                         <Row>
                          <Col md={12}>
                            <div className="inptCalendar">
                              <label className="text-center">Data de registro</label><br/>
                                <DatePicker selected={startDate} locale="ptBR"
                                onChange={date => DataSet(date)} 
                                dateFormat="dd/MM/yyyy"
                                />
                            </div>
                          </Col>
                        </Row>
                        <input value={startDate.toLocaleDateString()} style={{ "display": "none" }} id="data" name="data" ref={register}></input>
                        <input value={month} style={{ "display": "none" }} id="mes" name="mes" ref={register}></input>
                        <input value={yearNow} style={{ "display": "none" }} id="ano" name="ano" ref={register}></input>
                        <Row>
                          <div className="containerNotas">
                           <label></label>
                           <input type="checkbox" name="ads" ref={register}/>
                            Nota Google Ads
                          </div>
                        </Row>
                        <Row>
                          <div className="containerNotas">
                            <label></label>
                           <input type="checkbox" name="facebook" ref={register}/>
                            Nota Facebook Ads
                          </div>
                       </Row>
                       <Row>
                        <div className="containerNotas">
                          <label ></label>
                          <input type="checkbox" name="gerenciamento" ref={register}/>
                            Nota Gerenciamento
                        </div>
                      </Row>
                      <Row>
                       <Col className="col-md-6 pl-4 pt-4">
                        <FormGroup className="plannerSelection">
                          <label className="labelMat">Negócio</label>
                            <FormControl className="selectMat" name="negocio_id" as="select" ref={register} >
                              {
                                negocios && negocios.map((negocio) => (
                                  <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                              ))
                              }
                            </FormControl>
                        </FormGroup>
                        </Col>
                     </Row>
                    <Button className="botaoMat"
                      type="submit"
                      disabled={isLoading}>
                            Salvar
                    </Button>
              <div className="clearfix" />
            </form>
          </div>
      </Dropdown.Menu>
    </Dropdown>
    </div>
  </div>
</div>

    <table className="tableNotas">
        <thead>
          <tr>
            <th className="text-center">ID</th>
            <th className="text-center">Negócio</th>
            <th className="text-center">Data</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Opções</th>
          </tr>
        </thead>
        <tbody>
          {
            notas && notas.map((nota) => {
              if(nota.id %2 === 0 && negocioId === nota.negocio_id){
                if(nota.gerenciamento === 1){
                 tipo = "Gerenciamento";
                }else if(nota.facebook === 1){
                 tipo = "Facebook Ads";
                }else if(nota.ads === 1){
                 tipo = "Google Ads";
                }
                  return (
                    <tr key={nota.id} className="matData">
                        <td className="text-center">{nota.id}</td>
                        <td className="text-center">{negocioEnvolvido(nota.negocio_id)} </td>
                        <td className="text-center">{nota.data}</td>
                        <td className="text-center">{tipo}</td>

                        <td className="text-center">
                          <button className="acoes editar editbtn"  onClick={openModal.bind(null, nota)}>
                            Editar
                          </button>
                          <button className="acoes  archivebtn"  onClick={openModal2.bind(null, nota)}>
                            Adicionar arquivo
                          </button>
                        </td>
                    </tr>
                );
              }else if(nota.id %2 !== 0 && negocioId === nota.negocio_id){
                if(nota.gerenciamento === 1){
                  tipo = "Gerenciamento";
                }else if(nota.facebook === 1){
                  tipo = "Facebook Ads";
                }else if(nota.ads === 1){
                  tipo = "Google Ads";
                }
                return (
                  <tr key={nota.id} className="matData">
                    <td className="text-center">{nota.id}</td>
                    <td className="text-center">{negocioEnvolvido(nota.negocio_id)} </td>
                    <td className="text-center">{nota.data}</td>
                    <td className="text-center">{tipo}</td>
                    <td className="text-center">
                   
                      <button className="acoes editar editbtn"  onClick={openModal.bind(null, nota)}>
                        Editar
                      </button>
                      <button className="acoes  archivebtn"  onClick={openModal2.bind(null, nota)}>
                        Adicionar arquivo
                      </button>
                   </td>
                  </tr>
              
                );
              }
              return true;
            })
          }
        </tbody>
      </table>

    <Modal className="edicaoMat" data-keyboard={false}  backdrop={false} show={show} >
    <Button className="closeButton" onClick={handleClose}>X</Button>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit2)}>
          <Row>
            <Col md={12}>
              <label>Data de registro</label>
              <div className="inptCalendar">
                <DatePicker selected={startDate} locale="ptBR"
                onChange={date => setStartDate(date)} 
                dateFormat="dd/MM/yyyy"
                />
                </div>
            </Col>
          </Row>
            <input value={startDate.toLocaleDateString()} style={{ "display": "none" }} id="data" name="data" ref={register}></input>
            <input value={idd} style={{ "display": "none" }} id="negocio_id" name="negocio_id" ref={register}></input>
          <Row>
            <div className="containerNotas">
              <label></label>
              <input type="checkbox" name="ads" ref={register}/>
                Nota Google Ads
            </div>
          </Row>
          <Row>
            <div className="containerNotas">
              <label></label>
              <input type="checkbox" name="facebook" ref={register}/>
                Nota Facebook Ads
            </div>
          </Row>
          <Row>
            <div className="containerNotas">
              <label ></label>
                <input type="checkbox" name="gerenciamento" ref={register}/>
                  Nota Gerenciamento
            </div>
          </Row>
          <Row>
            <Col md={6}>
              <Button className="botaoEditMat" pullRight fill
                    type="submit"
                    disabled={isLoading}>
                          Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
      
    <Modal className="edicaoNota" data-keyboard={false}  backdrop={false} show={show2} >
    <Button className="closeButton" onClick={handleClose2}>X</Button>
      <Modal.Body>
        <div className="logotipo">
          <Row className="w-100 justify-content-center">
            <button className="btnNota"><a href={`${
            pathh}?download=true`}
            target="_blank" rel="noopener noreferrer"  
            > <i className="fa fa-file-download"></i></a></button> 
          </Row>
          <Row className="w-100 justify-content-center inpt mt-3">
            <div className="nameNota">{notasName}</div>
          </Row>
          <Row className="w-100 justify-content-center inpt mt-3">
            <input  type="file" onChange={createMidia}  ref={fileNovaMidiaRef}/>
          </Row> 
        </div>            
      </Modal.Body>
    </Modal>
  </div>
  );
  }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.dados === 0)){
    return(
      <Row className="w-100 justify-content-center ">
        <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
      </Row>
      )
  }
  return true;
  })
}
</div>

  );

}

export default AdicionarNotas;