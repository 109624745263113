import React, {useCallback, useState, useContext, useEffect, useRef } from "react";
import {Col } from "react-bootstrap";
import { Button, Modal, Row } from "react-bootstrap";
import Cookies from "js-cookie";
import { useHistory, Link } from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/sass/navbarlinks.scss";
import API from "utils/API";
import Dropdown from 'react-bootstrap/Dropdown';
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../contexts/NotificationContext";
import $ from "jquery";
import Suporte from "views/User/Suporte";

function logout() {
  Cookies.set(
    "accessToken",
    "logout"
  );
  document.location.reload();
}

function NavMenu(){
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();
  const [usuario, setUsuario] = useState();
  const [setIsLoading] = useState(true);
  const { register, handleSubmit, setValue } = useForm();
  const notification = useContext(NotificationContext);
  const fileNovaMidiaRef = useRef();
  const defaultAvatar = 'https://i.imgur.com/bmvMKmF.png';
  const [negocios, setNegocios] = useState();
  let negocioId = 2;
  var empresa = "0";
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

  const setForm = useCallback(
    (n) => {
      setValue("username", n.username);
      setValue("email", n.email);
      setValue("password", "");
      setValue("password_confirmation", "");
    },
    [setValue]
  );


  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);
  
  getNegocioId();

  function openModal(usuario) {
    setUsuario(usuario)
    handleShow();
    $(".editPassword").hide();
  }
  
  function openModal2() {
    handleShow2();
  }

  function handleClose2(){
    setShow2(false);
  }

  function handleClose(){
    setShow(false);
    $(".editPassword").show();
  }

  useEffect(() => {
    if (show && usuario) {
      setForm(usuario);
    }
  }, [show, usuario, setForm]);


  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);


    function Avatare(){
      if((authUser.path)){
        return (API.getBasePath() + authUser.path);
      }else{
        return defaultAvatar;
      }
    }


const Inicio = () =>{ 
  history.push("/dashboard");
}

function Materiais(){ 
  history.push(`/projetos/${negocioId}`)
}

function Fb(){  
  history.push(`/fbAds/${negocioId}`);
}

function Analytics(){ 
  history.push(`/analytics/${negocioId}`);
}

function RealTime(){ 
  history.push(`/realtime/${negocioId}`);
}

function Calendario(){ 
  history.push(`/planejamentos/${negocioId}`);
}

function Projecao(){ 
  history.push(`/projecaoLeads/${negocioId}`);
}

function Metas(){ 
  history.push(`/metas/${negocioId}`);
}

function Funil(){ 
  history.push(`/funil/${negocioId}`);
}

function Documentos(){ 
  history.push(`/documentos/${negocioId}`);
}

function Servicos(){ 
  history.push(`/servicos/${negocioId}`);
}

function Historico(){ 
  history.push(`/historico/${negocioId}`);
}

function Dados(){
  history.push(`/dados_gerais/${negocioId}`);
}

function Resultados(){ 
  history.push(`/leads/${negocioId}`);
}

function createMidia(e) {
  const FIRST = 0;
  if (e.target.files[FIRST]) {
    const data = {};
    data.file = e.target.files[FIRST];
    const [run] = API.put(`usuarios/${authUser.id}/`, data);
    run().then(() => {
      refreshPage();
    });
  }
}

function refreshPage() {
  window.location.reload();
}

function onSubmit(data) {
  const [run] = API.put(`usuarios/${authUser.id}`, data);
  setIsLoading(true);
  run()
    .then((response) => {
      setIsLoading(false);
      notification.addNotification("Usuário atualizado com sucesso.");
    })
    .catch(() => {
      notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
      setIsLoading(false);
    });
}

function getNome(){
  negocios && negocios.map((negocio) =>{
    if(negocio.id.toString() === getNegocioId()){
      return(
        empresa = negocio.nome
      );
    }
    return empresa;
  });
  return empresa;
}

empresa = getNome();

return(

<Col md={12}>
    <div className="LoginContainer">
      <Modal className="changePassword"  data-keyboard={false} backdrop={false} show={show} >
      <Button className="closeButton" onClick={handleClose}>X</Button>
        <Modal.Body>
        <Button className="cameraButton"  onClick={() => fileNovaMidiaRef.current.click()}> <i className="fa fa-camera"></i></Button>
        <input type="file" id="imagem" onChange={createMidia} ref={fileNovaMidiaRef}  style={{ "display": "none" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
              <Col md={12}>
                <input  name="username" className="inputPass" type="text"  ref={register}/>
              </Col>
              <Col md={12}>
                <input className="inputPass" type="text" ref={register} name="email"  id="email"/>
              </Col>
              <Col md={12}>
                <input className="inputPass" type="password" ref={register} name="password" placeholder="Nova senha" id="password"/>
              </Col>
              <Col md={12}>
                <input className="inputPass" type="password" ref={register} placeholder="Confirmar senha" name="password_confirmation"/>
              </Col>
          </Row>
          <Button className="perfilUpdate" pullRight fill type="submit" >
              Atualizar Perfil
          </Button>
          <div className="clearfix" />
        </form>
        </Modal.Body>
      </Modal>

    <div id="navbar" className="NavbarMenu">
      <nav className="navbar navbar-expand-md fixed-top">
        <div className="container-md w-md-100 justify-content-between">
          <button
          className="navbar-toggler   pl-4"
          type="button"
          data-toggle="collapse"
          data-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">  <i className="fa fa-bars color-white"></i>  </span>
          </button>  
          <Link className="navbar-brand mr-3" to="/">
              <img src="https://g6publicidade.com/assets/images/logo_branca.png" alt="Logotipo G6 Publicidade"/>
          </Link>
                    
          <div className=" collapse navbar-collapse " id="navbarContent">
            <ul className="navbar-nav w-100   justify-content-around">
              <li className="navbar-item">
              <button className="nav-text-link" onClick={Inicio}>Início </button>
              </li>
              <li className="nav-item dropdown">
                <div className="nav-text-link dropdown-toggle" onClick={Dados} id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Dados Gerais
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <button className="dropdown-item" onClick={Documentos}>Documentos</button>
                  <button className="dropdown-item" onClick={Servicos}>Serviços contratados</button>
                  <button className="dropdown-item" onClick={Historico}>Histórico de atendimento</button>
                </div>
              </li>
              <li className="navbar-item important">
                <button className="nav-text-link" onClick={Materiais}>Materiais
                </button>
              </li>
              <li className="navbar-item important">
                <button className="nav-text-link" onClick={Calendario}>Calendário de Publicações
                </button>
              </li>

              <li className="nav-item dropdown">
                <div className="nav-text-link dropdown-toggle" onClick={Projecao} id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Projeção de Leads
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <button className="dropdown-item"  onClick={Metas}>Metas</button>
                    <button className="dropdown-item" onClick={Funil}>Funil de Leads</button>
                </div>
              </li>
              <li className="navbar-item important">
                <button className="nav-text-link" onClick={Resultados}>
                Resultados Consolidados
                </button>
              </li>
              <li className="nav-item dropdown">
                <div className="nav-text-link dropdown-toggle" onClick={RealTime} id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Tempo Real
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <button className="dropdown-item" onClick={Fb}>Facebook Ads</button>
                  <button className="dropdown-item" onClick={Analytics}>Google Analytics</button>
                </div>
              </li>
            </ul>
                            
              {
                  usuarios && usuarios.map((usuario) => {
                  if(usuario.id === authUser.id){
                    return(
                      <div key="menu">
                        <Dropdown>
                          <Dropdown.Toggle className="profilebutton" id="dropdown-basic">
                            <img className="profilemenu" src={Avatare()} alt=""/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item><button className="editSenha" onClick={openModal.bind(null,usuario)}>Alterar senha</button></Dropdown.Item>
                            <Dropdown.Item ><i className="fa fa-user"></i>{usuario.username}</Dropdown.Item>
                            <Dropdown.Item ><i className="fa fa-envelope"></i>{usuario.email}</Dropdown.Item>
                            <Dropdown.Item onClick={openModal2}><i className="fa fa-question "></i>Suporte</Dropdown.Item>
                            <hr></hr>
                            <Dropdown.Item onClick={logout}><i className="fa fa-power-off"></i>SAIR</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button className="editPassword" onClick={openModal}>
                          <i className="fa fa-pen"></i>
                        </button>
                      </div>
                    );
                  }
                  return true;
                })   
              } 
          </div>              
          <div className="widthThing"><div className="atualNegocio">Negócio atual: {empresa}</div></div>
       </div>
    </nav>
  </div>
</div>

<Modal className="suporte"  data-keyboard={false} backdrop={false} show={show2} >
  <Button className="closeButton" onClick={handleClose2}>X</Button>
    <Modal.Body>
    <Suporte/>
    </Modal.Body>
</Modal>
</Col>
  );
}
export default NavMenu;