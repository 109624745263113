import React, {useEffect, useState} from "react";
import "../../assets/css/noticiasCliente.css";
import {Row} from "react-bootstrap";
import API from "utils/API";
import { useHistory } from "react-router-dom";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function NoticiasCliente(){

  const [noticias, setNoticias] = useState();
  const history = useHistory();

  useEffect(() => {
    const [run] = API.get("noticias");
    run().then((response) => {
        setNoticias(response);
    });
  }, []);

  const Users = () =>{ 
    history.push("/selectUsuario");
  }

  return(
    <div id="contentDash">
      <Row className="w-100 justify-content-center ">
        <div className="noticiaDoDia">
          Notícia do dia
        </div>
      </Row>
     <Row className="w-100 justify-content-center ">
        <div className="whiteNoticias">
          <Row className="w-100 justify-content-center ">
            {
              noticias && noticias.map((noticia) =>{
                return(
                  <Row className="w-100 justify-content-center ">
                    <div className="noticiaBody">
                      <div className="tituloNot">{noticia.titulo}</div>
                      <div>{noticia.noticia}</div>
                      <div><b>Fonte:</b> {noticia.fonte}</div>
                    </div>
                  </Row>
                )
              })
            }
           </Row>
        </div>
      </Row>
      <Row className="w-100 justify-content-center ">
        <button className="btnNoticia" onClick={Users}>Acessar o sistema</button>
      </Row>
        <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
        <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
        <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );
}

export default NoticiasCliente;