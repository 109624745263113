import React, { useContext, useEffect, useState} from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import {Row, Form, Col, Button, FormControl} from "react-bootstrap";
import API from "utils/API";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import DatePicker,{registerLocale} from 'react-datepicker';
import "react-datepicker/src/stylesheets/datepicker.scss";
import ptBR from 'date-fns/locale/pt-BR';
import { AuthUserContext } from "../../contexts/AuthUserContext";

registerLocale('ptBR', ptBR);

function AdicionarBoletos(){
  const notification = useContext(NotificationContext);
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [negocios, setNegocios] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  function onSubmit(data) {
    const [run] = API.post("boletos", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Contrato adicionado com sucesso.");
        setIsLoading(false);        
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  return(
    <div>
      <MenuCompleto/>
        <Row className="w-100 justify-content-center ">
          <div className="titleRosa">Adicionar boletos</div>
        </Row>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.dados === 1))){
            return(
              <div>
                <Form className="formProjecoes" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <div className="calendarInput">
                        <label>Data de registro</label><br/>
                        <DatePicker selected={startDate} locale="ptBR"
                        onChange={date => setStartDate(date)} 
                        dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Col>
                   <input value={startDate.toLocaleDateString()} style={{ "display": "none" }} id="data" name="data" ref={register}></input>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label>Boleto enviado</label><br/>
                      <input className="inptHistorico" type="text" id="link" name="nome_boleto" ref={register}></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label>Valor</label><br/>
                      <input className="inptHistorico" type="number" step="0.01" min="0.00" placeholder="R$ 10.000,00" name="valor"  ref={register}></input>
                    </Col>
                  </Row>
                  <Row>
                   <Col md={12}>
                      <label>Email destino</label><br/>
                      <input  className="inptHistorico" type="email" id="detalhes" name="email_destino" ref={register}></input>
                    </Col>
                    <Col md={12}>
                      <label>Negócio</label>
                      <FormControl className="selectHist" name="negocio_id" as="select" ref={register} >
                        {
                         negocios && negocios.map((negocio) => (
                          <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                         ))
                        }
                      </FormControl>
                     </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                     <Button className="botaoEdithist" 
                      type="submit"
                      disabled={isLoading}>
                      Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
     
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.dados === 0)){
            return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
         return true;
        })
      }
    </div>
  );
}
export default AdicionarBoletos;