import React, { useEffect, useState, useContext,useCallback } from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import API from "utils/API";
import { Row,Modal, Col, FormGroup,Form, FormControl, Button } from "react-bootstrap";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import $ from "jquery";
import "../../assets/css/materiais.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { AuthUserContext } from "../../contexts/AuthUserContext";

function RegistrarMateriais(){
  const [negocios, setNegocios] = useState();
  const [materiais, setMateriais] = useState();
  const [material, setMaterial] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [usuarios, setUsuarios] = useState();
  const { authUser } = useContext(AuthUserContext);
  let empresa = "nome";

  const setForm = useCallback(
    (n) => {
      setValue("id", n.id);
      setValue("link", n.link);
      setValue("link_2", n.link_2);
      setValue("detalhes", n.detalhes);
      setValue("negocio_id", n.negocio_id);
    },
    [setValue]
  );
  
  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("materiais");
    run().then((response) => {
        setMateriais(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
       setUsuarios(response);
    });
  }, []);


  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    if (show && material) {
      setForm(material);
    }
  }, [show, material, setForm]);

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return true;
    });
    return (empresa);
  }

  function onSubmit2(data) {
    const [run] = API.put(`materiais/${material.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setMaterial(response);
        handleClose();
        refreshPage();
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onSubmit(data) {
    const [run] = API.post("materiais", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Material adicionado com sucesso.");
        setIsLoading(false);        
        refreshPage();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
        $(".botaoAzul").show();  
      });
  }

  function openModal(matParam) {
    setMaterial(matParam);
    handleShow();
  }

  return(
  <div id="bodyNoticias">
    <MenuCompleto/>
    <div className="tituloMat">Gerenciar Materiais</div>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id && (authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.materiais === 1))){
            return(
              <div id="materiaisAdd">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle className="MatDrop" id="dropdown-basic">
                    Cadastrar materiais
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="drop">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Row>
                            <Col md={6}>
                              <input className="inputMat" placeholder="Link da logotipo" type="text" ref={register} name="link"></input>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <input className="inputMat" placeholder="Link do Drive" type="text" ref={register} name="link_2"></input>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <input className="inputMat"  placeholder="Detalhes"  type="text" ref={register} name="detalhes"></input>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-md-6 pl-4 pt-4">
                              <FormGroup className="plannerSelection">
                                <label className="labelMat">Negócio</label>
                                <FormControl className="selectMat" name="negocio_id" as="select" ref={register} >
                                  {
                                    negocios && negocios.map((negocio) => (
                                      <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                    ))
                                  }
                                </FormControl>
                              </FormGroup>
                              </Col>
                          </Row>
                          <Button className="botaoMat"
                            type="submit"
                            disabled={isLoading}>
                                Salvar
                          </Button>
                          <div className="clearfix" />
                        </form>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <table className="tableMateriais">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Negócio</th>
                      <th className="text-center">Link Logo</th>
                      <th className="text-center">Link Drive</th>
                      <th className="text-center">Comentário</th>
                      <th className="text-center">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      materiais && materiais.map((material) => {
                        if(material.id %2 === 0){
                          return (
                            <tr key={material.id} className="matData">
                                <td className="text-center">{material.id}</td>
                                <td className="text-center">{negocioEnvolvido(material.negocio_id)} </td>
                                <td className="text-center"><a className="linkMat" href={material.link} target="_blank" rel="noopener noreferrer">{material.link}</a></td>
                                <td className="text-center"><a className="linkMat" href={material.link_2} target="_blank" rel="noopener noreferrer">{material.link_2}</a></td>
                                <td className="text-center">{material.detalhes}</td>
                                <td>
                                  <button className="acoes editar materiaisBtn"  onClick={openModal.bind(null, material)}>
                                      Editar
                                  </button>
                                </td>
                            </tr> 
                         );
                        }else{
                          return(
                            <tr key={material.id}>
                              <td className="text-center grayClass">{material.id}</td>
                              <td className="text-center grayClass">{negocioEnvolvido(material.negocio_id)} </td>
                              <td className="text-center grayClass"><a className="linkMat" href={material.link} target="_blank" rel="noopener noreferrer">{material.link}</a></td>
                              <td className="text-center grayClass"><a className="linkMat" href={material.link_2} target="_blank" rel="noopener noreferrer">{material.link_2}</a></td>
                              <td className="text-center grayClass">{material.detalhes}</td>
                              <td className="grayClass">
                                <button className="acoes editar materiaisBtn "  onClick={openModal.bind(null, material)}>
                                  Editar
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })
                    }
                  </tbody>
                </table>
              </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.materiais === 0)){
            return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
      return true;
    })
  }

  <Modal className="edicaoMat" data-keyboard={false}  backdrop={false} show={show} >
  <Button className="closeButton" onClick={handleClose}>X</Button>
    <Modal.Body>
      <Form onSubmit={handleSubmit(onSubmit2)}>
        <Row>
          <Col md={6}>
            <label>Id</label><br/>
            <input className="inptedit" type="text" id="id" name="id" ref={register}></input>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Link Logo</label><br/>
            <input className="inptedit" type="text" id="link" name="link" ref={register}></input>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Link Drive</label><br/>
            <input className="inptedit" type="text" id="link_2" name="link_2" ref={register}></input>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Comentário</label><br/>
            <input className="inptedit" type="text" id="detalhes" name="detalhes" ref={register}></input>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Button className="botaoEditMat" pullRight fill
              type="submit"
              disabled={isLoading}>
                Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
</div>

  );
}

export default RegistrarMateriais;