import React from "react";
import NavMenu from "components/Navbars/NavMenu";
import {Row} from "react-bootstrap";
import { useHistory} from "react-router-dom";
import LastAccess from "views/User/LastAccess";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";


function Documentos(){
  const history = useHistory();
  let negocioId = 2;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
    

 /*} useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);
*/

  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }
  
  getNegocioId();


  function Contrato(){ 
    history.push(`/contrato/${negocioId}`);
  }

  function Dados(){ 
    history.push(`/dados_gerais/${negocioId}`);
  }

  function Briefing(){ 
    history.push(`/briefing/${negocioId}`);
  }

  function Boletos(){ 
    history.push(`/boletos/${negocioId}`);
  }

  function NotasFiscais(){
    history.push(`/notas_fiscais/${negocioId}`);
  }
  
return(
  <div id="contentDash">
    <NavMenu/>
    <button className="back" onClick={Dados}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    <Row className="w-100 justify-content-center"> 
      <div className="dadosTitle">Documentos</div>
    </Row>

    <Row className="w-100 justify-content-center">
      <div className="dadosList">
        <ul>
          <li><button className="buttonDados" onClick={Contrato}>Contrato</button></li>
          <li><button className="buttonDados" onClick={Briefing}>Briefing</button></li>
          <li><button  className="buttonDados" onClick={NotasFiscais}>Notas fiscais</button></li>
          <li><button  className="buttonDados" onClick={Boletos}>Registro de envio de boletos</button></li>
        </ul>
      </div>
    </Row>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>
    <img class="position-absolute opacity line-doc1" src={linhas} alt=""></img>
    <img class="position-absolute opacity line-doc2" src={linhas2} alt=""></img>
    <img class="position-absolute opacity line-doc3" src={linhas3} alt=""></img>
  </div>
  );
}
export default Documentos;