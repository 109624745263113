
import { Form, Input, Button, Row } from "antd";
import EmailService  from "../../views/mail-service";
import React, {  } from "react";
 
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const Suporte = () => {
    const onFinish = (values) => {
      console.log(values);
      EmailService.sendEmail(values)
    };

  return (
    <Row>
      <Form layout="vertical" className="supp" name="nest-messages" onFinish={onFinish}>
        <div class="callSup">Tire suas dúvidas!</div>
          <Form.Item name="from_name" label="Cliente:">
            <Input className="inptSuporte"/>
          </Form.Item>
          <Form.Item name="to_name" label="To name"    style={{ "display": "none" }} value="Administrador">
            <Input className="inptSuporte"/>
          </Form.Item>
          <Form.Item name="subject" label="Assunto:">
            <Input className="inptSuporte"/>
          </Form.Item>
          <Form.Item
            name="to_email"
            label="To email"
            value="sistemag6@gmail.com"
            rules={[{ type: "email" }]}
            style={{ "display": "none" }}
          >
            <Input className="inptSuporte"/>
          </Form.Item>
          <Form.Item name="message" label="Mensagem:">
            <Input.TextArea className="inptMessage"/>
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" className="sendSup" htmlType="submit">
            Enviar
            </Button>
          </Form.Item>
      </Form>
    </Row>
  );
};

export default Suporte;