import React, { useState, useEffect } from "react";
import NavMenu from "components/Navbars/NavMenu";
import "../../assets/css/temporeal.css"
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import qshd from "../../assets/img/qs.png"
import $, {  } from "jquery";
import { gapi } from "gapi-script";
import API from "utils/API";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif"
import LastAccess from './../User/LastAccess';

  ///Integração com Analytics
window.$ = $;
(function (w, d, s, g, js, fjs) {
  g = w.gapi || (w.gapi = {});
  g.analytics = { q: [], ready: function (cb) { this.q.push(cb); } };
  js = d.createElement(s);
  fjs = d.getElementsByTagName(s)[0];
  js.src = "https://apis.google.com/js/platform.js";
  js.onload = function () { g.load("analytics"); };
  fjs.parentNode.insertBefore(js, fjs);
})(window, document, "script");

function Analytics(){
  const history = useHistory();
  const [analytics, setAnalytics] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let vieww = 0; //Auxiliar
  let clientsecrett =0;
  let tokenn = 0; //Auxiliar
  let clientidd =0;
  let updates = "0";
  var negocioId = "2";
  


 const RealTime = () =>{ 
    history.push(`/realtime/${negocioId}`);
  }

  useEffect(() => {
    const [run] = API.get("analytics");
    run().then((response) => {
        setAnalytics(response);
    });
  }, []);


  function getprojecao() { ///Retorna a View Id para o Google Analytics API
    analytics && analytics.map((analytic) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
        if (analytic.negocio_id.toString() === IdNegocio) {
          return (vieww = (analytic.viewId)
        );
      }
      return vieww;
    });
    return (vieww);
  }

  var viewid = getprojecao();
  
  function getprojecao_token() { ///Retorna a View Id para o Google Analytics API
    analytics && analytics.map((analytic) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (analytic.negocio_id.toString() === IdNegocio) {
        return (tokenn = (analytic.token));
      }
      return tokenn;
    });
    return (tokenn);
  }

  var token = getprojecao_token();

  function getprojecao_clienteid() { ///Retorna a View Id para o Google Analytics API
    analytics && analytics.map((analytic) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
        if (analytic.negocio_id.toString() === IdNegocio) {
          return (clientidd = (analytic.clientId));
        }
        return clientidd;
    });
    return (clientidd);
  }

  var client_id = getprojecao_clienteid();

  function getprojecao_secretid() { ///Retorna a View Id para o Google Analytics API
    analytics && analytics.map((analytic) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (analytic.negocio_id.toString() === IdNegocio) {
        return (clientsecrett = (analytic.clientSecret));
      }
      return clientsecrett;
    });
    return (clientsecrett);
  }

  var client_secret = getprojecao_secretid();

  /*Gráficos gerados pelo Google Analytics API a partir da ViewId do negócio */
  function CXAuth() { //Autorização
    const credits = { client_secret,
      "grant_type": "refresh_token",
      "refresh_token": token,
      client_id };
    $.ajax({
      "type": "POST",
      "url": "https://www.googleapis.com/oauth2/v4/token",
      "contentType": "application/x-www-form-urlencoded",
      "data": credits,
      "dataType": "json",
      "success" (credits) {
        gapi.analytics.auth.authorize({
          "serverAuth": {
            "access_token": credits.access_token,
            "expires_in": credits.expires_in,
            "token_type": credits.token_type
          },
          "container": "embed-api-auth-container",
          "clientid": client_id,
          "clientsecret": client_secret
        });

      },
      "error" (xhr, textStatus, errorThrown) {
        console.log(`cx ajax post error:${xhr.statusText}`);
      }
    });
  }
  gapi.analytics.ready(() => {
    CXAuth();
    const report = new gapi.analytics.report.Data({
      "query": {
        "ids": viewid,
        "metrics": "ga:users",
        "dimensions": "ga:eventCategory, ga:eventAction,ga:eventLabel",
        "start-date": "today",
        "end-date": "today"
      }
    });
    let total = 0;
    report.on("success", (resultsAsObject) => {
      if (resultsAsObject.rows.length > 0) {
        resultsAsObject.rows.forEach((row) => {
          total = Number(total) + Number(row[1]);
        });
        document.getElementById("totalCounter").textContent = total;
      }
    });
    report.execute();
    const newChart = new gapi.analytics.googleCharts.DataChart({
      "query": { //Grafico novos usuários por pais
        "ids": viewid,
        "start-date": "30daysAgo",
        "end-date": "today",
        "metrics": "ga:newUsers",
        "dimensions": "ga:country"
      },
      "chart": {
        "container": "charting",
        "type": "GEO",
        "options": {
          "width": "100%",
          "backgroundColor": "#fff",
          "colors": ["#d5385a", "#d5385a", "#008000", "#858997"],
          "chartArea": {
            "backgroundColor": "#fff"
          }
        }
      }
    });
    const dataChart = new gapi.analytics.googleCharts.DataChart({
      "query": { //Usuarios e sessoes por dia
        "ids": viewid,
        "start-date": "30daysAgo",
        "end-date": "today",
        "metrics": "ga:sessions,ga:users",
        "dimensions": "ga:day"
      },
      "chart": {
        "container": "site_statistics",
        "type": "COLUMN",
        "options": {
          "width": "100%",
          "backgroundColor": "#fff",
          "colors": ["#858997", "#d5385a", "#5589AD", "#858997"],
          "chartArea": {
            "backgroundColor": "#fff"
          }
        }
      }
    });
    const pieChart = new gapi.analytics.googleCharts.DataChart({
      "query": { //Tipo de dispositivo usado para acessar o site
        "ids": viewid,
        "start-date": "30daysAgo",
        "end-date": "today",
        "metrics": "ga:users",
        "dimensions": "ga:deviceCategory"
      },
      "chart": {
        "container": "statistics_pie",
        "type": "PIE",
        "options": {
          "width": "100%",
          "backgroundColor": "#fff",
          "colors": ["#858997", "#d5385a", "#6A2631", "#FFB6C1", "#FF7F50", "#AFEEEE", "#D8BFD8", "#DB7093"],
          "chartArea": {
            "backgroundColor": "#fff"
          }
        }
      }
    });
    const columnChart = new gapi.analytics.googleCharts.DataChart({
      "query": { //Visitantes por hora hoje
        "ids": viewid,
        "start-date": "today",
        "end-date": "today",
        "metrics": "ga:visitors",
        "dimensions": "ga:hour"
      },
      "chart": {
        "container": "statistics_column",
        "type": "LINE",
        "options": {
          "width": "100%",
          "backgroundColor": "#fff",
          "colors": ["#d5385a", "#d5385a", "#5589AD", "#FFB6C1", "#FF7F50", "#AFEEEE", "#D8BFD8", "#DB7093"],
          "chartArea": {
            "backgroundColor": "#fff"
          }
        }
      }
    });
    const columnChart2 = new gapi.analytics.googleCharts.DataChart({
      "query": { //Origem dos usuários hoje
        "ids": viewid,
        "start-date": "today",
        "end-date": "today",
        "metrics": "ga:users",
        "dimensions": "ga:source"
      },
      "chart": {
        "container": "statistics_column2",
        "type": "BAR",
        "options": {
          "width": "100%",
          "backgroundColor": "#fff",
          "colors": ["#858997", "#d5385a", "#d5385a", "#FFB6C1", "#FF7F50", "#AFEEEE", "#D8BFD8", "#DB7093"],
          "chartArea": {
            "backgroundColor": "#fff"
          }
        }
      }
    });
    window.addEventListener("resize", () => {
      dataChart.execute();
      newChart.execute();
      pieChart.execute();
      columnChart.execute();
      columnChart2.execute();
    });
    dataChart.execute();
    newChart.execute();
    pieChart.execute();
    columnChart.execute();
    columnChart2.execute();
  });

  /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      return (negocioId = IdNegocio);
  }


  function formatDate(dateFromAPI) {
    var dataToday = new Date();
    dateFromAPI = dataToday.getFullYear()+"-"+(dataToday.getMonth()+1)+"-"+ dataToday.getDate();
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);
    return `${dateDay}/${dateMonth}/${dateYear}`;
  }
  getNegocioId();
  
  return(
    <div>
      <NavMenu/>
        <div id="realTime">
          <button className="back" onClick={RealTime}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
          <Row className="w-100 justify-content-center">
            <div className="analyticsTitle">Google Analytics</div>
              <Row className="bgAnalytics">
                {
                  analytics && analytics.map((analytic) => {
                    getNegocioId();
                      if (analytic.negocio_id.toString()=== negocioId)  {
                        return (
                          <div>
          
                              <div className="boxAnalyticsG">
                                <div className="chartTitle3">Usuários por hora - Hoje</div>
                                <div id="statistics_column" className="chart"></div>
                                <div id="embed-api-auth-container"></div>
                              </div>
                       
                              <div className="boxAnalyticsG">
                                <div className="chartTitle">Fluxo de usuários no site - Últimos 30 dias</div>
                                <div id="embed-api-auth-container"></div>
                                <div id="site_statistics" className="chart"> </div>
                              </div>
                        
                              <div className="boxAnalyticsG">
                                <div className="chartTitle">Localização dos usuários - Últimos 30 dias</div>
                                <div id="embed-api-auth-container"></div>
                                <div id="charting" className="chart"> </div>
                              </div>
                           
                              <div className="boxAnalyticsG">
                                <div className="chartTitle3">Origem dos usuários - Últimas 24 horas</div>
                                <div id="embed-api-auth-container"></div>
                                <div id="statistics_column2" className="chart"></div>
                              </div>
                           
                              <div className="boxAnalyticsG">
                                <div className="chartTitle3">Meio de acesso - Últimos 30 dias</div>
                                <div id="embed-api-auth-container"></div>
                                <div id="statistics_pie" className="chart "></div>
                              </div>
                      
                          </div>
                        );
                      }
                    return true;
                  })
                }
              </Row>
              <Row>
                <Col md={6}>
                  <div className="upAnalytics">Última atualização: {formatDate(updates)}</div>
                </Col>
                <Col md={6}>
                  <img className="metodo" alt="Método QSHD" src={qshd}/>
                </Col>
              </Row>
              </Row>
            </div>
       
          <Row className="w-100 fixed-bottom justify-content-left">
            <LastAccess/>
          </Row>

    <img className="position-absolute opacity line-V" src={linhas} alt=""></img>
    <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>

</div>
  );
}

export default Analytics;