import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import PrintMetas from './PrintMetas';


function ClickToPrintMeta(){
  let componentRef = useRef();
    return (
      <>
        <div>
          {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => <div className='printM'> <i className="fa fa-print"></i></div>}
              content={() => componentRef}
            />
          <div style={{ display: "none" }}>
            <ComponentToPrint ref={(el) => (componentRef = el)} />
          </div>
        </div>
      </>
    );
};

class ComponentToPrint extends React.Component {
  render() {
    return (
      <PrintMetas/>
    );
  }
}

export default ClickToPrintMeta;