import React, { useState, useEffect} from "react";
import {  Row, Col } from "react-bootstrap";
import API from "utils/API";
import funilimg from "../../assets/img/Funil.png";

function PrintFunil(){
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const PorcentagemTotal = 100;
  const porcentagemGTotal = 100;
  let negocioId = 0;
  let dataLeads = "";
  let descobertaFb = 0;
  let descobertaInsta = 0;
  let leadsFb = 0;
  let leadsInsta = 0;
  let leadsTotal = 0;
  let agendamentoFb = 0;
  let agendamentoInsta = 0;
  let totalAgendamento = 0;
  let fbComparecimento =0;
  let InstaComparecimento = 0;
  let totalComparecimento = 0;
  let fbFechamento = 0;
  let instaFechamento = 0;
  let totalFechamento = 0;
  let fechamento = 0;
  let atualProjetado = 0;
  let redesSociais = 0;
  let ticketIndicado = 0;
  let indicadoProjetado = 0;
  let leads = 0;
  let agendamentoG = 0;
  let comparecimentoG = 0;
  let fechamentoG = 0;
  let totalfb = 0;
  let totalads =0;
  let totalFunil = 0;
  let totalGoogle =0;
  let projetadosG = "0";
  var mes = 0;
  var monthh = " ";

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

   /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      return (negocioId = IdNegocio);
    });
    return negocioId;
  }

  /*Retorna os dados de variáveis calculadas */
  function getVar() {
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio) {
        totalfb = (parseFloat(projecao.fbq1) + parseInt(projecao.fbq2) + parseInt(projecao.fbq3) + parseInt(projecao.fbq4)).toFixed(2);
        totalads = (parseFloat(projecao.ads1) + parseInt(projecao.ads2) + parseInt(projecao.ads3) + parseInt(projecao.ads4)).toFixed(2);
        totalFunil = parseInt(((totalfb)/projecao.cplFb));
        totalGoogle = parseInt((totalads/projecao.cplGoogle));
        descobertaFb = ((((projecao.porcentagemFbTotal) / 100) * (totalFunil)));
        descobertaInsta = ((((projecao.porcentagemInstaTotal) / 100) * (totalFunil)));

        leadsFb = ((descobertaFb) * (parseFloat(projecao.porcentagemLeads / 100)));
        leadsInsta = (descobertaInsta * (parseFloat((projecao.porcentagemLeads) / 100)));
        leadsTotal = (leadsFb + leadsInsta);
        agendamentoFb = (leadsFb * (parseFloat(projecao.porcentagemAgendamento / 100)));
        agendamentoInsta = (leadsInsta * (parseFloat(projecao.porcentagemAgendamento / 100)));
        totalAgendamento = (agendamentoFb + agendamentoInsta);
        fbComparecimento = (agendamentoFb * (parseFloat(projecao.porcentagemComparecimento) / 100));
        InstaComparecimento = (agendamentoInsta * (parseFloat(projecao.porcentagemComparecimento / 100)));
        totalComparecimento = (fbComparecimento + InstaComparecimento);
        fbFechamento = (fbComparecimento * (parseFloat(projecao.porcentagemFechamento / 100)));
        instaFechamento = (InstaComparecimento * (parseFloat(projecao.porcentagemFechamento / 100)));
        totalFechamento = (fbFechamento + instaFechamento);
        fechamento = Math.round(fbFechamento + instaFechamento);
        atualProjetado = (parseFloat(Math.round(fechamento)) * parseFloat(projecao.ticketAtual));
        redesSociais = (parseFloat(projecao.metaTotal) * (parseFloat((projecao.metaPorcentagem)/100)));
        indicadoProjetado = (parseFloat(Math.round(fechamento) * ticketIndicado));
        leads = (((parseFloat(projecao.porcentagemLeadsG) / 100) * totalGoogle));
        agendamentoG = (((parseFloat(projecao.porcentagemAgendamentoG / 100) * leads)));
        comparecimentoG = (((parseFloat(projecao.porcentagemComparecimentoG / 100) * agendamentoG)));
        fechamentoG = (((parseFloat(projecao.porcentagemFechamentoG / 100) * comparecimentoG)));
        projetadosG = (parseFloat(Math.round(fechamentoG)*projecao.projetadosG));
        ticketIndicado = (parseFloat(projecao.metaTotal / (Math.round(fechamento)+ Math.round(fechamentoG))).toFixed(2));
      }
        return (descobertaFb, descobertaInsta, leadsFb, leadsInsta, leadsTotal, agendamentoFb, agendamentoInsta, totalAgendamento, fbComparecimento, InstaComparecimento, totalComparecimento,
        fbFechamento, instaFechamento, totalFechamento, fechamento, atualProjetado, redesSociais, ticketIndicado, indicadoProjetado, leads, agendamentoG, comparecimentoG, fechamentoG, totalfb, totalads);
    });
    return (dataLeads);
  }

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }
  
  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    mes = getMonth()
    if (mes === 1) {
      monthh = "Janeiro";
    } else if (mes === 2) {
      monthh = "Fevereiro";
    } else if (mes === 3) {
      monthh = "Março";
    } else if (mes === 4) {
      monthh = "Abril";
    } else if (mes === 5) {
      monthh = "Maio";
    } else if (mes === 6) {
      monthh = "Junho";
    } else if (mes === 7) {
      monthh = "Julho";
    } else if (mes === 8) {
      monthh = "Agosto";
    } else if (mes === 9) {
      monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
    }
    return monthh;
  }

  return(
  <div id ="bgFunile">
    <Row className="w-100 justify-content-center"> 
      <div className="tituloFunilPrint"> Funil de leads com base em { translateMonth()}</div>
    </Row>
    <Row>
      <Col md={2}>
        <div id="funilPrint">
          {
             projecoes && projecoes.map((projecao) => {
              getNegocioId();
              if (projecao.negocio_id.toString() === negocioId) {
                return (
                  <div>
                    <img className="prettyfunnelPrint" alt="Funil" src={funilimg}></img>
                    <div className="titlePrettyPrint">Descoberta</div>
                    <div className="titleLPrettyPrint">Leads</div>
                    <div className="titleAPrettyPrint">Agendamento</div>
                    <div className="titleCPrettyPrint">Comparecimento</div>
                    <div className="titleFPrettyPrint">Fechamento</div>
                    <div className="descobertaPrettyPrint">100</div>
                    <div className="leadsPrettyPrint">{projecao.porcentagemLeads}</div>
                    <div className="agendaPrettyPrint">{projecao.porcentagemAgendamento}</div>
                    <div className="compPrettyPrint">{projecao.porcentagemComparecimento}</div>
                    <div className="fechPrettyPrint">{projecao.porcentagemFechamento}</div>
                  </div>
                );
              }
            return true;
            })
          }
        </div>
      </Col>
      <Col md={4}>
        <div className="tablefbPrint">
          {
            projecoes && projecoes.map((projecao) => {
              getNegocioId();
                if (projecao.negocio_id.toString() === negocioId) {
                  getVar();
                  return (
                    <div>
                      <div className="titulofunilPrint">LEADS PROJETADOS FACEBOOK | INSTAGRAM</div>
                        <table>
                          <tr className="topp">
                            <th className="text-center">Facebook</th>
                            <th className="text-center">Instagram</th>
                            <th className="text-center">Total</th>
                          </tr>
                          <tr className="percent">
                            <td className="text-center">{projecao.porcentagemFbTotal}%</td>
                            <td className="text-center">{projecao.porcentagemInstaTotal}%</td>
                            <td className="text-center">{PorcentagemTotal}%</td>
                          </tr>
                          <tr className="descoberta">
                            <td className="text-center">{Math.round(descobertaFb)}</td>
                            <td className="text-center">{Math.round(descobertaInsta)}</td>
                            <td className="text-center">{totalFunil}</td>
                          </tr>
                          <tr className="percent">
                            <td className="text-center">{projecao.porcentagemLeads}%</td>
                            <td className="text-center">{projecao.porcentagemLeads}%</td>
                            <td className="text-center">{projecao.porcentagemLeads}%</td>
                          </tr>
                          <tr className="leads">
                            <td className="text-center">{Math.round(leadsFb)}</td>
                            <td className="text-center">{Math.round(leadsInsta)}</td>
                            <td className="text-center">{Math.round(leadsTotal)}</td>
                          </tr>
                          <tr className="percent">
                            <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                            <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                            <td className="text-center">{projecao.porcentagemAgendamento}%</td>
                          </tr>
                          <tr className="agendamento">
                            <td className="text-center">{Math.round(agendamentoFb)}</td>
                            <td className="text-center">{Math.round(agendamentoInsta)}</td>
                            <td className="text-center">{Math.round(totalAgendamento)}</td>
                          </tr>
                          <tr className="percent">
                            <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                            <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                            <td className="text-center">{projecao.porcentagemComparecimento}%</td>
                          </tr>
                          <tr className="comparecimento">
                            <td className="text-center">{Math.round(fbComparecimento)}</td>
                            <td className="text-center">{Math.round(InstaComparecimento)}</td>
                            <td className="text-center">{Math.round(totalComparecimento)}</td>
                          </tr>
                          <tr className="percent">
                            <td className="text-center">{projecao.porcentagemFechamento}%</td>
                            <td className="text-center">{projecao.porcentagemFechamento}%</td>
                            <td className="text-center">{projecao.porcentagemFechamento}%</td>
                          </tr>
                          <tr className="fechamento">
                            <td className="text-center">{Math.round(fbFechamento)}</td>
                            <td className="text-center">{Math.round(instaFechamento)}</td>
                            <td className="text-center">{Math.round(totalFechamento)}</td>
                          </tr>
                        </table>
                      </div>
                    );
                }
                return true;
              })
            }
        </div>
      </Col>
    </Row>
    <Row>   
      <Col md={4}>
        <div className="tabelaGooglePrint">
          {
            projecoes && projecoes.map((projecao) => {
              getNegocioId();
              if (projecao.negocio_id.toString() === negocioId) {
                getVar();
                return (
                  <div>
                    <div className="titulofunilGPrint">LEADS PROJETADOS GOOGLE</div>
                      <table className="googlePrint">
                        <tr className="toppGoogle">
                          <th>Google</th>
                        </tr>
                        <tr className="percent"><td>{porcentagemGTotal}% </td></tr>
                        <tr className="descoberta"><td>{totalGoogle}</td></tr>
                        <tr className="percent"><td>{projecao.porcentagemLeadsG}% </td></tr>
                        <tr className ="leads"><td>{Math.round(leads)}</td></tr>
                        <tr className="percent"><td>{projecao.porcentagemAgendamentoG}% </td></tr>
                        <tr className="agendamento"><td>{Math.round(agendamentoG)}</td></tr>
                        <tr className="percent"><td>{projecao.porcentagemComparecimentoG}%</td></tr>
                        <tr className="comparecimento"><td>{Math.round(comparecimentoG)}</td></tr>
                        <tr className="percent"><td>{projecao.porcentagemFechamentoG}%</td></tr>
                        <tr className="fechamento"><td>{Math.round(fechamentoG)}</td></tr>
                      </table>
                  </div>
                );
              }
               return true;
             })
           }
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={10}>
        <div className="projetadoPrint">
          {
            projecoes && projecoes.map((projecao) => {
              getNegocioId();
              if (projecao.negocio_id.toString() === negocioId) {
                getVar();
                return (
                  <div className="tableTicketPrint">
                    <div>
                      <div className="ticketPrint">TICKET MÉDIO - ATUAL/PROJETADO</div>
                        <table>
                          <tr>
                            <td className="titulomenor">Ticket médio Redes Sociais</td>
                            <td className="titulomenor">Projetado Redes Sociais</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(projecao.ticketAtual).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(atualProjetado).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                          </tr>
                          <tr>
                            <td className="titulomenor">Ticket médio Google</td>
                            <td className="tituloprojetado">Projetado Google</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(projecao.projetadosG).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(projetadosG).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                          </tr>
                          <tr>      
                            <td className="titulomenor">Ticket médio indicado</td>
                            <td className="titulomenor">Projetado  Facebook/Instagram/Google</td>
                          </tr>
                          <tr className="percent">
                            <td>R$ {parseFloat(ticketIndicado).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                            <td>R$ {parseFloat(projecao.metaTotal).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>      
                          </tr>  
                        </table>
                    </div>
                  </div>
                );
              }
               return true;
            })
          }
        </div>
      </Col>
    </Row>
  </div>
  );
}
export default PrintFunil;