import React, { useEffect, useState, useContext } from "react";
import MenuCompleto from "./MenuCompleto";
import "../../assets/css/noticias.css";
import API from "utils/API";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { NotificationContext } from "../../contexts/NotificationContext";


function GerenciarNoticias(){
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const { register, handleSubmit, reset } = useForm();
  const [noticias, setNoticias] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  function onSubmit(data) {
    const [run] = API.post("noticias", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Notícia adicionado com sucesso.");
        setIsLoading(false);
        reset();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
      setUsuarios(response);
    });
 }, []);


  useEffect(() => {
    const [run] = API.get("noticias");
    run().then((response) => {
      setNoticias(response);
    });
  }, []);

  return(
    <div>
      <MenuCompleto/>
      {  
       usuarios && usuarios.map((usuario) => {
        if(usuario.id === authUser.id && (authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.noticias === 1))){
          return(
           <div id="bodyNoticias">
            <div className="tituloNoticias">Gerenciar notícias</div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputNoticia">
                <Row>
                  <Col md={6}>
                    <label>Título</label>
                    <Form.Control id="mes" as="input" ref={register} rows="8" name="titulo"></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label>Notícia</label>
                    <Form.Control id="mes" as="textarea" ref={register} rows="8" name="noticia" label="Noticia"></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label>Fonte</label>
                    <Form.Control id="mes" as="input" ref={register} rows="8" name="fonte"></Form.Control>
                  </Col>
                </Row>
              </div>
                <button className="botaoNoticia"
                    type="submit"
                    disabled={isLoading}>
                      Salvar notícia
                </button>
            </Form>

              <div id="quadroNoticias">
                <label className="titleNot">Últimas notícias</label>
                  <div className="noticiasinside">
                    {
                      noticias && noticias.map((noticia)=>{
                        return(
                          <Row className="w-100 justify-content-center ">
                           <ul>
                              <li className="text-center">
                                <a href={noticia.fonte}> <b> Título:</b> {noticia.titulo} - <b> Notícia:</b> {noticia.noticia}</a>
                              </li>
                            </ul>
                          </Row>
                          );
                        })
                      }
                  </div>
              </div>
            </div>
              )
        }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.noticias === 0)){
          return(
            <Row className="w-100 justify-content-center ">
              <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
            </Row>
         )
       }
         return true;
      })
    }
  </div>

  );
}

export default GerenciarNoticias;

