import React, { useContext, useEffect, useState, useRef} from "react";
import "../../assets/css/historico.css"
import NavMenu from "components/Navbars/NavMenu";
import {Row} from "react-bootstrap";
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import DatePicker,{registerLocale} from 'react-datepicker';
import "react-datepicker/src/stylesheets/datepicker.scss";
import ptBR from 'date-fns/locale/pt-BR';
import ReactToPrint from "react-to-print";
import API from "utils/API";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

registerLocale('ptBR', ptBR);


function Historico(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [startDate, setStartDate] = useState(new Date());
  const [periodo, setPeriodo] = useState(false);
  const [starts, setStarts] = useState(new Date());
  const [historicos, setHistoricos] = useState();
  const [negocios, setNegocios] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let empresa = "nome";
  var aux;
  var year = 2022;
  var month = 10;
  var day = 1;
  var year_2 = 2022;
  var month_2 = 0;
  var day_2 = 1;
  var year_3 = 2022;
  var month_3 = 0;
  var day_3 = 1;
  var negocioNome = "Nome";
  let componentRef = useRef();
  let negocioId = 2;
  /*
  useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);
*/
 

  const DadosGerais = () =>{ 
    history.push(`/dados_gerais/`);
  }

  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setPeriodo(current => !current);
  };

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return empresa;
    });
    return (empresa );
  }

  
  useEffect(() => {
    const [run] = API.get("historicos");
    run().then((response) => {
        setHistoricos(response);
    });
  }, []);

  
  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  function monthToNumber(aux){
    var varia;
    if(aux === "Jan"){
       varia = 1;
    }else if(aux === "Feb"){
       varia = 2;
    }else if(aux === "Mar"){ 
       varia = 3;
    }else if(aux === "Apr"){
       varia =4;
    }else if(aux === "May"){
       varia =5;
    }else if(aux === "Jun"){
       varia =6;
    }else if(aux === "Jul"){
       varia = 7;
    }else if(aux === "Aug"){
       varia = 8;
    }else if(aux === "Sep"){
       varia = 9;
    }else if(aux === "Oct"){
       varia = 10;
    }else if(aux === "Nov"){
       varia = 11;
    }else if(aux === "Dec"){
       varia = 12;
    }
    return varia;
  }


  /*Retorna a Id do negócio selecionado */
 function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]);
  return IdNegocio;
}

negocioId = getNegocioId();

function getNome(){
  negocios && negocios.map((negocio) => {
    if(negocio.id.toString() === getNegocioId()){
      negocioNome = negocio.nome.toString();
    }
    return negocioNome;
    });
    return negocioNome;
}

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div id="printhistorico">
          <Row className="w-100 justify-content-center titlehistorico">Histórico do cliente</Row>
            <Row className="w-100 justify-content-center">
              <div className="nomeNegTitulo"> {getNome()}</div></Row>
                <table>
                  <thead>
                    <tr>
                      <th className="text-center">Data</th>
                      <th className="text-center">Negócio</th>
                      <th className="text-center">Serviço</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Detalhes</th>
                    </tr>
                  </thead>
                  {
                    historicos && historicos.map((historico)=>{
                      if(periodo === true && (historico.negocio_id === negocioId)){
                        return(
                          <tbody>
                            <tr>
                              <td>{historico.data}</td>
                              <td className="text-center">{negocioEnvolvido((historico.negocio_id) )} </td>    
                              <td className="text-center">{historico.servico} </td>                     
                              <td className="text-center">{historico.status} </td> 
                              <td className="text-center">{historico.detalhes} </td> 
                            </tr>
                          </tbody>
                        )
                      }else{ 
                        aux = historico.data.split("/");
                        day =parseInt(aux[0]);
                        month = parseInt(aux[1]);
                        year = parseInt(aux[2]);
                        var data1 = year*1000+month*100+day;
                      
                        var ini = startDate.toString();
                        var inicio = JSON.stringify(ini);
                        aux = inicio.split(" ");
                        month_2 = monthToNumber(aux[1]);
                        day_2=parseInt(aux[2]);
                        year_2 =parseInt(aux[3]);
                        var data2 = year_2*1000+month_2*100+day_2;
        
                        var fimm = starts.toString();
                        var fim = JSON.stringify(fimm);
                        aux = fim.split(" ");
                        month_3 = monthToNumber(aux[1]);
                        day_3=parseInt(aux[2]);
                        year_3 = parseInt(aux[3]);
                        var data3 = year_3*1000+month_3*100+day_3;
      
                        if((historico.negocio_id === authUser.active_negocio_id) && (data1 >= data2 && data1 <= data3)){
                          return(
                            <tbody>
                              <tr>
                                <td>{historico.data}</td>
                                <td className="text-center">{negocioEnvolvido((historico.negocio_id) )} </td>    
                                <td className="text-center">{historico.servico} </td>                     
                                <td className="text-center">{historico.status} </td> 
                                <td className="text-center">{historico.detalhes} </td> 
                              </tr>
                            </tbody>
                          )
                        }
                     }
                    return true;
                  }) }
                      
                </table>
        </div>
      );
    }
  }
    
return( 
  <div id="contentDash">
    <NavMenu/>
      <button className="back" onClick={DadosGerais}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
        <Row className="w-100 justify-content-center"> 
          <div className="titleHistorico">Histórico de atendimento</div>
        </Row>
        <div className="filterHist">
          <Row className="w-100 justify-content-center"> 
            <div className="txtHistoricoD">De:</div>  
              <div className="inputFltr">
                <DatePicker selected={startDate} locale="ptBR"
                onChange={date => setStartDate(date)} 
                dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="txtHistorico">a:</div>
              <div className="inputFltr">
                <DatePicker selected={starts} locale="ptBR"
                onChange={date => setStarts(date)} 
                dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="littleTxt">Todo o período: </div>
                <label className="containerAnalytics">
                  <input type="checkbox" name="timeHist" onChange={handleChange}/>
                  <span className="checkmarkH"></span>
                  <div className="timeHist"></div> 
                </label>
          </Row>
        </div>
        <Row className="w-100 justify-content-center"> 
          <>
            <div>
              <ReactToPrint
                trigger={() => <button className="btnHistorico"> <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar o seu arquivo</button>}
                content={() => componentRef}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint ref={(el) => (componentRef = el)} />
              </div>
            </div>
          </>
        </Row>
        <Row className="w-100 fixed-bottom justify-content-left">
          <LastAccess/>
        </Row>

    <img class="position-absolute opacity line-V" src={linhas} alt=""></img>
    <img class="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img class="position-absolute opacity line-3" src={linhas3} alt=""></img>
    
</div>
  );
}

export default Historico;

