import React, { useState, useEffect, useContext, useCallback } from "react";
import { PropTypes } from "prop-types";
import { Modal, Row } from "react-bootstrap";
import {  Button } from "react-bootstrap";
import API from "utils/API";
import { AuthUserContext } from "./../../contexts/AuthUserContext";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import validateRoute from "utils/ValidateRoute";
import "../../assets/css/dashboard.css";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function ChangeUsers () {
  const [negocios, setNegocios] = useState();
  const { authUser } = useContext(AuthUserContext);
  const { negocioId } = useParams();
  const history = useHistory();
  const [show, setShow] = useState(false);
  var teste = 'undef';

  function handleClose(){
    setShow(false);  
  }

  useEffect(useCallback(() => {
    const [run] = authUser.role === "cliente" ? API.get(`usuarios/${authUser.id}/negocios`) : API.get("negocios");
    run().then((response) => {
      setNegocios(response);
      if(response[0]){
      const DEFAULT_ROUTE = `/selectUsuario/negocios/${response[0].id}`;
      validateRoute(response, negocioId, () => {
        history.push(DEFAULT_ROUTE);
      });
    }else{
      setShow(true);
    }
    });
  }, [authUser, history, negocioId]), [negocioId,teste]);

  function handleSelection(mappedNegocio) {
    NegocioAtivo(mappedNegocio.id);
  }

  function NegocioAtivo(id){
    const data = {};
    data.active_negocio_id = id;
    const [run] = API.put(`usuarios/${authUser.id}/setNegocioAtivo`, data);
    run().then(() => {
      history.push(`/dados_gerais/${id}`);
    });
  }

  return(
    <div>
      <Row className="tituloselecao w-100 justify-content-center">Selecione a conta desejada</Row>
      <Row className="w-100 justify-content-center">
        <div className="selecaoContas">
          <ul>
            {
              negocios && negocios.map((mappedNegocio) => {
                if(authUser.role === 'cliente'){
                  return(
                    <li>
                      <button
                        key={mappedNegocio.id}
                        onClick={handleSelection.bind(null, mappedNegocio)}
                        className="botao-empresa">
                        <img alt="conta" class="selectConta" src={API.getBasePath() + mappedNegocio.path}></img>
                      </button>
                    </li>
                  );
                }
               return true;
              })
            }
          </ul>
          <Modal className="aviso" data-keyboard={false} backdrop={true} show={show} >
            <Button className="closeButton" onClick={handleClose}>X</Button>
            <Modal.Body>
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
              Seu usuário não está ligado a um negócio. Entre em contato com um administrador.
            </Modal.Body>
          </Modal>
        </div>
      </Row>
      
      <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
      
    </div>
  );
}

ChangeUsers.propTypes = {
  "setNegocio": PropTypes.func,
  "negocio": PropTypes.object
};
export default ChangeUsers;
