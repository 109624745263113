import React, { useState, useEffect, useContext } from "react";
import { PropTypes } from "prop-types";
import { Modal, Row } from "react-bootstrap";
import {  Button } from "react-bootstrap";
import API from "utils/API";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { useHistory } from "react-router";
import "../../assets/css/dashboard.css";


function ChangeCliente () {
  const [negocios, setNegocios] = useState();
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  var empresa = "0";

  function handleClose(){
    setShow(false);
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  function Search(event) {
    setFilter(event.target.value)
  }

  function NegocioAtivo(id){
    history.push(`/dados_gerais/${id}`);
  }

  function getNome(neg) {
    negocios && negocios.map((negocio) =>{
      if(negocio.id === neg){
        return(
          empresa = negocio.nome
        );
      }
      return empresa;
    });
    return empresa;
  }


  return (
    <div>
      <Row className="tituloselecao w-100 justify-content-center">Selecione a conta desejada</Row>
      <Row className="w-100 justify-content-center">
      <div className="selecaoContasAdm">
        <div className="input-group input-group-sm mb-3 rounded srch col-2 float-right">
          <input id="search_box" onChange={Search} placeholder="Pesquisar"  type="search" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
          <div className="input-group-prepend rounded">
            <span className="rounded-right input-group-text" id="inputGroup-sizing-sm"><i className="fas fa-search"/></span>
          </div>
        </div>
        <ul>
        {
          negocios &&
            negocios
              .slice() // Faz uma cópia do array para evitar alterar a ordem original dos negócios
              .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os negócios pelo nome em ordem alfabética
              .map((mappedNegocio) => {
                if(authUser.role !== 'cliente'){
                      if(filter === ""){
                        if(mappedNegocio.estado !== "Inativo"){
                          if(mappedNegocio.path === null){mappedNegocio.path = "negocios/1/logotipo/v6zdPaiko4zlGTyUwdVw/339149736_252402547128678_1579402677495162999_n.jpg"}
                          return(
                            <li key={mappedNegocio.id} title={getNome(mappedNegocio.id)} className="m-1">
                              <div className="boxCliente text-center m-0">
                                <button
                                  key={mappedNegocio.id}
                                  onClick={NegocioAtivo.bind(null, mappedNegocio.id)}
                                  className="botao-empresaAdmin">
                                  <img  alt="Conta" className="selectContaAdm" src={API.getBasePath() + mappedNegocio.path}></img>
                                </button>
                                <p className="text-empresa text-light text-truncate pt-2" title={mappedNegocio.nome}>{mappedNegocio.nome}</p>
                              </div>
                            </li>

                          );

                        }
                      }
                      else if(mappedNegocio.nome.toString().includes(filter) && mappedNegocio.estado  !== "Inativo"){
                        return(
                          <li key={mappedNegocio.id} title={getNome(mappedNegocio.id)} className="m-1">
                            <div className="boxCliente text-center m-0">
                              <button
                                key={mappedNegocio.id}
                                onClick={NegocioAtivo.bind(null, mappedNegocio.id)}
                                className="botao-empresaAdmin">
                                <img  alt="Conta" className="selectContaAdm" src={API.getBasePath() + mappedNegocio.path}></img>
                              </button>
                              <p className="text-empresa text-light text-truncate pt-2" title={mappedNegocio.nome}>{mappedNegocio.nome}</p>
                            </div>
                          </li>
                        );
                      }


                  }

                  return true;
                })
        }
       </ul>
      </div>
      </Row>
      <Modal className="aviso" data-keyboard={false} backdrop={true} show={show} >
        <Button className="closeButton" onClick={handleClose}>X</Button>
          <Modal.Body>
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
          Seu usuário não está ligado a um negócio. Entre em contato com um administrador.
          </Modal.Body>
      </Modal>
    </div>
  );
}

ChangeCliente.propTypes = {
  "setNegocio": PropTypes.func,
  "negocio": PropTypes.object
};
export default ChangeCliente;
