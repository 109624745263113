import React, {  useEffect, useState } from "react";
import NavMenu from "components/Navbars/NavMenu";
import LastAccess from './../User/LastAccess';
import { Row, Modal, Button,Col } from "react-bootstrap";
import "../../assets/css/calendarioCliente.css";
import API from "utils/API";
import ClickToPrintPublicacoes from "./ClickToPrintPublicacoes";


function Planejamento () {
  const [planejamentos, setPlanejamentos] = useState();
  const [planner, setPlanner] = useState();
  const [atual, setAtual] = useState("none");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [last, setLast] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  var completePath, completePath1, completePath2, completePath3, completePath4, completePath5, completePath6, completePath7, completePath8, completePath9, completePath10, completePath11, completePath12, completePath13, completePath14 = '0';
  var  completePath15, completePath16, completePath17, completePath18, completePath19, completePath20, completePath21, completePath22, completePath23, completePath24, completePath25, completePath26 = '0';
  var completePath27,completePath28,completePath29,completePath30 = '0';
  let negocioId = "2";
  var arr = [31];
  var cont = 0;
  var check1, check2, check3, check4, check5, check6, check7, check8, check9, check10, check11, check12, check13 = "";
  var check14, check15, check16, check17, check18, check19, check20, check21, check22, check23, check24, check25, check26, check27 = "";
  var check28, check29, check30, check31 = " ";
  var  mes = 0;
  var year = 0;
 
 function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }


  year  = getYear().toString();
  if(last === 9){
    year = parseInt(year)-1;
    year = year.toString();
  }else if(last === 8){
    year  = parseInt(year)+1;
    year = year.toString();
  }else if(last===7){
    year  = getYear().toString();
  }

  function volta(){
    setLast(9);
  }
  
  function vai(){
    setLast(8);
  }

  function fica(){
    setLast(7);
  }

  useEffect(() => {
    const [run] = API.get("planejamentos");
    run().then((response) => {
      setPlanejamentos(response);
    });
  },[]);
  
  function isChecked(){
    planejamentos && planejamentos.map((planejamento) => {
      if((planejamento.negocio_id.toString() === getNegocioId()) && (planejamento.mes === getMonthTranslate() || planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase()) ){
        if(planejamento.check1 === 1 && planejamento.texto_1 !== null){
           check1 = "fa fa-calendar-check";
        }else if(planejamento.check1 === 0 && planejamento.texto_1 !== null){
          check1 = "fa fa-clock";
        }

        if(planejamento.check2 === 1  && planejamento.texto_2 !== null){
          check2 = "fa fa-calendar-check";
        }else if(planejamento.check2 === 0  && planejamento.texto_2 !== null){
          check2 = "fa fa-clock";
        }

        if(planejamento.check3 === 1  && planejamento.texto_3 !== null){
          check3 = "fa fa-calendar-check";
        }else if(planejamento.check3 === 0  && planejamento.texto_3 !== null){
          check3 = "fa fa-clock";
        }

        if(planejamento.check4 === 1 && planejamento.texto_4 !== null){
          check4 = "fa fa-calendar-check";
        }else if(planejamento.check4 === 0 && planejamento.texto_4 !== null){
          check4 = "fa fa-clock";
        }

        if(planejamento.check5 === 1 && planejamento.texto_5 !== null){
          check5 = "fa fa-calendar-check";
        }else if(planejamento.check5 === 0 && planejamento.texto_5 !== null){
          check5 = "fa fa-clock";
        }
      
        if(planejamento.check6 === 1 && planejamento.texto_6 !== null){
          check6 = "fa fa-calendar-check";
        }else  if(planejamento.check6 === 0 && planejamento.texto_6 !== null){
          check6 = "fa fa-clock";
        }

        if(planejamento.check7 === 1 && planejamento.texto_7 !== null){
          check7 = "fa fa-calendar-check";
        }else if(planejamento.check7 === 0 && planejamento.texto_7 !== null){
          check7 = "fa fa-clock";
        }  
   
        if(planejamento.check8 === 1 && planejamento.texto_8 !== null){
          check8 = "fa fa-calendar-check";
        }else if(planejamento.check8 === 0 && planejamento.texto_8 !== null){
          check8 = "fa fa-clock";
        }
   
        if(planejamento.check9 === 1 && planejamento.texto_9 !== null){
          check9 = "fa fa-calendar-check";
        }else if(planejamento.check9 === 0 && planejamento.texto_9 !== null){
          check9 = "fa fa-clock";
        }

        if(planejamento.check10 === 1 && planejamento.texto_10 !== null){
          check10 = "fa fa-calendar-check";
        }else if(planejamento.check10 === 0 && planejamento.texto_10 !== null)
          check10 = "fa fa-clock";
        }
   
        if(planejamento.check11 === 1 && planejamento.texto_11 !== null){
          check11 = "fa fa-calendar-check";
        }else if(planejamento.check11 === 0 && planejamento.texto_11 !== null){
          check11 = "fa fa-clock";
        }

        if(planejamento.check12 === 1 && planejamento.texto_12 !== null){
          check12 = "fa fa-calendar-check";
        }else if(planejamento.check12 === 0 && planejamento.texto_12 !== null){
          check12 = "fa fa-clock";
        }

        if(planejamento.check13 === 1 && planejamento.texto_13 !== null){
          check13 = "fa fa-calendar-check";
        }else if(planejamento.check13 === 0 && planejamento.texto_13 !== null){
          check13 = "fa fa-clock";
        }
      
        if(planejamento.check14 === 1 && planejamento.texto_14 !== null){
          check14 = "fa fa-calendar-check";
        }else if(planejamento.check14 === 0 && planejamento.texto_14 !== null){
          check14 = "fa fa-clock";
        }
       
        if(planejamento.check15 === 1 && planejamento.texto_15 !== null){
          check15 = "fa fa-calendar-check";
        }else if(planejamento.check15 === 0 && planejamento.texto_15 !== null){
          check15 = "fa fa-clock";
        }
      
        if(planejamento.check16 === 1 && planejamento.texto_16 !== null){
          check16 = "fa fa-calendar-check";
        }else if(planejamento.check16 === 0 && planejamento.texto_16 !== null){
          check16 = "fa fa-clock";
        }

        if(planejamento.check17 === 1 && planejamento.texto_17 !== null){
          check17 = "fa fa-calendar-check";
        }else if(planejamento.check17 === 0 && planejamento.texto_17 !== null){
          check17 = "fa fa-clock";
        }
    
        if(planejamento.check18 === 1 && planejamento.texto_18 !== null){
          check18 = "fa fa-calendar-check";
        }else if(planejamento.check18 === 0 && planejamento.texto_18 !== null){
          check18 = "fa fa-clock";
        }
        
        if(planejamento.check19 === 1 && planejamento.texto_19 !== null){
          check19 = "fa fa-calendar-check";
        }else if(planejamento.check19 === 0 && planejamento.texto_19 !== null){
          check19 = "fa fa-clock";
        }
        
        if(planejamento.check20 === 1 && planejamento.texto_20 !== null){
          check20 = "fa fa-calendar-check";
        }else if(planejamento.check20 === 0 && planejamento.texto_20 !== null){
          check20 = "fa fa-clock";
        }

        if(planejamento.check21 === 1 && planejamento.texto_21 !== null){
          check21 = "fa fa-calendar-check";
        }else if(planejamento.check21 === 0 && planejamento.texto_21 !== null){
          check21 = "fa fa-clock";
        }
    
        if(planejamento.check22 === 1 && planejamento.texto_22 !== null){
          check22 = "fa fa-calendar-check";
        }else if(planejamento.check22 === 0 && planejamento.texto_22 !== null){
          check22 = "fa fa-clock";
        }
     
        if(planejamento.check23 === 1 && planejamento.texto_23 !== null){
          check23 = "fa fa-calendar-check";
        }else if(planejamento.check23 === 0 && planejamento.texto_23 !== null){
          check23 = "fa fa-clock";
        }     

        if(planejamento.check24 === 1 && planejamento.texto_24 !== null){
          check24 = "fa fa-calendar-check";
        }else if(planejamento.check24 === 0 && planejamento.texto_24 !== null){
          check24 = "fa fa-clock";
        }
    
        if(planejamento.check25 === 1 && planejamento.texto_25 !== null){
          check25 = "fa fa-calendar-check";
        }else if(planejamento.check25 === 0 && planejamento.texto_25 !== null){
          check25 = "fa fa-clock";
        }
        
        if(planejamento.check26 === 1 && planejamento.texto_26 !== null){
          check26 = "fa fa-calendar-check";
        }else if(planejamento.check26 === 0 && planejamento.texto_26 !== null){
          check26 = "fa fa-clock";
        }

        if(planejamento.check27 === 1 && planejamento.texto_27 !== null){
          check27 = "fa fa-calendar-check";
        }else if(planejamento.check27 === 0 && planejamento.texto_27 !== null){
          check27 = "fa fa-clock";
        }

        if(planejamento.check28 === 1 && planejamento.texto_28 !== null){
          check28 = "fa fa-calendar-check";
        }else if(planejamento.check28 === 0 && planejamento.texto_28 !== null){
          check28 = "fa fa-clock";
        }

        if(planejamento.check29 === 1  && planejamento.texto_29 !== null){
          check29 = "fa fa-calendar-check";
        }else if(planejamento.check29 === 0  && planejamento.texto_29 !== null){
          check29 = "fa fa-clock";
        }
    
        if(planejamento.check30 === 1 && planejamento.texto_30 !== null){
          check30 = "fa fa-calendar-check";
        }else if(planejamento.check30 === 0 && planejamento.texto_30 !== null){
          check30 = "fa fa-clock";
        }
        
        if(planejamento.check31 === 1 && planejamento.texto_31 !== null){
          check31 = "fa fa-calendar-check";
        }else if(planejamento.check31 === 0 && planejamento.texto_31 !== null){
          check31 = "fa fa-clock";
        }
        return true;    
    });
 }

  function Pic(){
    planejamentos && planejamentos.map((planejamento) => {
      if((planejamento.negocio_id.toString() === getNegocioId()) && (planejamento.mes === getMonthTranslate())){
        if(planejamento.path !== null){
          completePath = API.getBasePath() + planejamento.path;
        }else{
          completePath = "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_1 !== null){
          completePath1 = API.getBasePath() + planejamento.path_1;
        }else{
          completePath1= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_2 !== null){
          completePath2 = API.getBasePath() + planejamento.path_2;
        }else{
          completePath2= "https://i.imgur.com/8DZqWIW.png";
        }
        
        if(planejamento.path_2 !== null){
          completePath2 = API.getBasePath() + planejamento.path_2;
        }else{
          completePath2= "https://i.imgur.com/8DZqWIW.png";
        } 
        
        if(planejamento.path_3 !== null){
          completePath3 = API.getBasePath() + planejamento.path_3;
        }else{
          completePath3= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_4 !== null){
          completePath4 = API.getBasePath() + planejamento.path_4;
        }else{
        completePath4= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_5 !== null){
          completePath5 = API.getBasePath() + planejamento.path_5;
        }else{
          completePath5= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_6 !== null){
          completePath6 = API.getBasePath() + planejamento.path_6;
        }else{
          completePath6= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_7 !== null){
          completePath7 = API.getBasePath() + planejamento.path_7;
        }else{
          completePath7= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_8 !== null){
          completePath8 = API.getBasePath() + planejamento.path_8;
        }else{
          completePath8= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_9 !== null){
          completePath9 = API.getBasePath() + planejamento.path_9;
        }else{
          completePath9= "https://i.imgur.com/8DZqWIW.png";
        }
  
        if(planejamento.path_10 !== null){
          completePath10 = API.getBasePath() + planejamento.path_10;
        }else{
          completePath10= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_11 !== null){
          completePath11 = API.getBasePath() + planejamento.path_11;
        }else{
          completePath11= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_12 !== null){
          completePath12 = API.getBasePath() + planejamento.path_12;
        }else{
          completePath12= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_13 !== null){
          completePath13 = API.getBasePath() + planejamento.path_13;
        }else{
          completePath13= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_14 !== null){
          completePath14 = API.getBasePath() + planejamento.path_14;
        }else{
          completePath14= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_15 !== null){
          completePath15 = API.getBasePath() + planejamento.path_15;
        }else{
          completePath15= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_16 !== null){
          completePath16 = API.getBasePath() + planejamento.path_16;
        }else{
          completePath16= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_17 !== null){
          completePath17 = API.getBasePath() + planejamento.path_17;
        }else{
          completePath17= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_18 !== null){
          completePath18 = API.getBasePath() + planejamento.path_18;
        }else{
          completePath18= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_19 !== null){
          completePath19 = API.getBasePath() + planejamento.path_19;
        }else{
          completePath19= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_20 !== null){
          completePath20 = API.getBasePath() + planejamento.path_20;
        }else{
          completePath20= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_21 !== null){
          completePath21 = API.getBasePath() + planejamento.path_21;
        }else{
          completePath21= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_22 !== null){
          completePath22 = API.getBasePath() + planejamento.path_22;
        }else{
          completePath22= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_23 !== null){
          completePath23 = API.getBasePath() + planejamento.path_23;
        }else{
          completePath23= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_24 !== null){
          completePath24 = API.getBasePath() + planejamento.path_24;
        }else{
          completePath24= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_25 !== null){
          completePath25= API.getBasePath() + planejamento.path_25;
        }else{
          completePath25= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_26 !== null){
          completePath26 = API.getBasePath() + planejamento.path_26;
        }else{
          completePath26= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_27 !== null){
          completePath27 = API.getBasePath() + planejamento.path_27;
        }else{
          completePath27= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_28 !== null){
          completePath28 = API.getBasePath() + planejamento.path_28;
        }else{
          completePath28= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_29 !== null){
          completePath29 = API.getBasePath() + planejamento.path_29;
        }else{
          completePath29= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_30 !== null){
          completePath30 = API.getBasePath() + planejamento.path_30;
        }else{
          completePath30= "https://i.imgur.com/8DZqWIW.png";
        }
      }
      return true;
    })
  }
  

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }
  
  function openModal(plan) {
    setPlanner(plan);
    handleShow();
  }

  function openModal2() {
    handleShow2();
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  function getMonthTranslate() {
    if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
      return "Janeiro";
    } else if (mes === 2) {
      return "Fevereiro";
    } else if (mes === 3) {
      return "Março";
    } else if (mes === 4) {
      return "Abril";
    } else if (mes === 5) {
      return "Maio";
    } else if (mes === 6) {
      return "Junho";
    } else if (mes === 7) {
      return "Julho";
    } else if (mes === 8) {
      return "Agosto";
    } else if (mes === 9) {
      return "Setembro";
    } else if (mes === 10 || mes === -2) {
      return "Outubro";
    } else if (mes === 11 || mes === -1) {
      return "Novembro";
    } else if (mes === 12 || mes === 0) {
      return "Dezembro";
    }
  }  
    
  Pic();

  isChecked();
  
  function arr_cont(conta){
    cont=0;
    for (cont =1; cont<= conta; cont = cont +1){
      arr.push(cont);
    }
    if(cont< 31){
      for(cont; cont <=31;cont++){
        arr.push(null);
      }
    }
    return arr;
  }

 function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]);
    negocioId = IdNegocio;
    return negocioId;
  }

  function formatDate(dateFromAPI) {  
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);
    return `${dateDay}/${dateMonth}/${dateYear}`;
  }

return(
  <div id="contentDash">
    <NavMenu/>
    <Row className="w-100 justify-content-center">
      <div className="titleCal">Calendário de publicações</div>
    </Row>
    <div id="calendarGrid">
      <header>
        <div className="weekdays">
          <Row className="w-100 justify-content-center">
            <div className="monthCalendar">{getMonthTranslate()}
              <button className="btnCal" onClick={openModal2}><i className="fa fa-calendar"></i></button><button className="printTransparent"><ClickToPrintPublicacoes/></button>
            </div>
          </Row>
          <ul>
            <li>Domingo</li>
            <li>Segunda-feira</li>
            <li>Terça-feira</li>
            <li>Quarta-feira</li>
            <li>Quinta-feira</li>
            <li>Sexta-feira</li>
            <li>Sábado</li>
          </ul>
        </div>
      </header>
      <div> 
        {
          planejamentos && planejamentos.map((planejamento) => {
            getNegocioId();
            if(planejamento.negocio_id.toString() === negocioId && planejamento.mes === getMonthTranslate() && planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase() ){
              if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Domingo'){
                arr_cont(planejamento.duracao) 
                return(
                  <div>
                    <div className="Cal-endar">
                      <ul className="days">
                        <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li> 
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="bodyCaloff"></div></li>
                      </ul>
                    </div>   
                    <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                 </div>
                );
              }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Segunda-feira'){
                arr_cont(planejamento.duracao)
                  return(
                    <div>
                      <div className="Cal-endar">
                        <ul className="days">
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="bodyCaloff"></div></li>
                        </ul>
                      </div>
                      <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                    </div>
                  );
              }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Terça-feira'){
                  arr_cont(planejamento.duracao)
                    return(
                      <div>
                        <div className="Cal-endar">
                          <ul class="days">
                            <li><div className="bodyCaloff"></div></li>
                            <li><div className="bodyCaloff"></div></li>
                            <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                            <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                            <li><div className="bodyCaloff"></div></li>
                            <li><div className="bodyCaloff"></div></li>
                          </ul>
                        </div>
                        <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                      </div>
                    );
            }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quarta-feira'){
              arr_cont(planejamento.duracao)
                return(
                  <div>
                    <div className="Cal-endar">
                      <ul className="days">
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="bodyCaloff"></div></li>
                        <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                        <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                        <li><div className="bodyCaloff"></div></li>
                     </ul>
                    </div>
                    <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                 </div>
                );
            }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quinta-feira'){
                arr_cont(planejamento.duracao)
                  return(
                    <div>
                      <div className="Cal-endar">
                        <ul className="days">
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="bodyCaloff"></div></li>
                          <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg"  onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                          <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                        </ul>
                      </div>
                      <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                    </div>
                  );
          }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sexta-feira'){
            arr_cont(planejamento.duracao)
              return(
                <div>
                  <div className="Cal-endar">
                    <ul className="days">
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                    </ul>
                  </div>
                  <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                </div>
              );
          }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sábado'){
            arr_cont(planejamento.duracao)
              return(
                <div>
                  <div className="Cal-endar">
                    <ul className="days">
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="bodyCaloff"></div></li>
                      <li><div className="day">{arr[1]}<i className={check1}></i></div><div className="bodyCal"><img className="imagem" src={completePath} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_1)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[2]}<i className={check2}></i></div><div className="bodyCal"><img className="imagem" src={completePath1} alt="Post dia 2"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_2)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[3]}<i className={check3}></i></div><div className="bodyCal"><img className="imagem" src={completePath2} alt="Post dia 3"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_3)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[4]}<i className={check4}></i></div><div className="bodyCal"><img className="imagem" src={completePath3} alt="Post dia 4"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_4)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[5]}<i className={check5}></i></div><div className="bodyCal"><img className="imagem" src={completePath4} alt="Post dia 5"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_5)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[6]}<i className={check6}></i></div><div className="bodyCal"><img className="imagem" src={completePath5} alt="Post dia 6"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_6)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[7]}<i className={check7}></i></div><div className="bodyCal"><img className="imagem" src={completePath6} alt="Post dia 7"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_7)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[8]}<i className={check8}></i></div><div className="bodyCal"><img className="imagem" src={completePath7} alt="Post dia 8"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_8)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[9]}<i className={check9}></i></div><div className="bodyCal"><img className="imagem" src={completePath8} alt="Post dia 9"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_9)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[10]}<i className={check10}></i></div><div className="bodyCal"><img className="imagem" src={completePath9} alt="Post dia 10"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_10)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[11]}<i className={check11}></i></div><div className="bodyCal"><img className="imagem" src={completePath10} alt="Post dia 11"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_11)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[12]}<i className={check12}></i></div><div className="bodyCal"><img className="imagem" src={completePath11} alt="Post dia 12"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_12)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[13]}<i className={check13}></i></div><div className="bodyCal"><img className="imagem" src={completePath12} alt="Post dia 13"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_13)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[14]}<i className={check14}></i></div><div className="bodyCal"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_14)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[15]}<i className={check15}></i></div><div className="bodyCal"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_15)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[16]}<i className={check16}></i></div><div className="bodyCal"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_16)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[17]}<i className={check17}></i></div><div className="bodyCal"><img className="imagem" src={completePath16} alt="Post dia 17"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_17)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[18]}<i className={check18}></i></div><div className="bodyCal"><img className="imagem" src={completePath17} alt="Post dia 18"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_18)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[19]}<i className={check19}></i></div><div className="bodyCal"><img className="imagem" src={completePath18} alt="Post dia 19"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_19)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[20]}<i className={check20}></i></div><div className="bodyCal"><img className="imagem" src={completePath19} alt="Post dia 20"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_20)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[21]}<i className={check21}></i></div><div className="bodyCal"><img className="imagem" src={completePath20} alt="Post dia 21"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_21)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[22]}<i className={check22}></i></div><div className="bodyCal"><img className="imagem" src={completePath21} alt="Post dia 22"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_22)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[23]}<i className={check23}></i></div><div className="bodyCal"><img className="imagem" src={completePath22} alt="Post dia 23"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_23)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[24]}<i className={check24}></i></div><div className="bodyCal"><img className="imagem" src={completePath23} alt="Post dia 24"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_24)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[25]}<i className={check25}></i></div><div className="bodyCal"><img className="imagem" src={completePath24} alt="Post dia 25"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_25)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[26]}<i className={check26}></i></div><div className="bodyCal"><img className="imagem" src={completePath25} alt="Post dia 26"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_26)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[27]}<i className={check27}></i></div><div className="bodyCal"><img className="imagem" src={completePath26} alt="Post dia 27"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_27)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[28]}<i className={check28}></i></div><div className="bodyCal"><img className="imagem" src={completePath27} alt="Post dia 28"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_28)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[29]}<i className={check29}></i></div><div className="bodyCal"><img className="imagem" src={completePath28} alt="Post dia 29"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_29)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[30]}<i className={check30}></i></div><div className="bodyCal"><img className="imagem" src={completePath29} alt="Post dia 30"></img><br></br><button className="planLeg"onClick={openModal.bind(null, planejamento.texto_30)}>Leia mais</button></div></li>
                      <li><div className="day">{arr[31]}<i className={check31}></i></div><div className="bodyCal"><img className="imagem" src={completePath30} alt="Post dia 31"></img><br></br><button className="planLeg" onClick={openModal.bind(null, planejamento.texto_31)}>Leia mais</button></div></li>
                   </ul>
                  </div>
                  <div className="upCal">Última atualização: {formatDate(planejamento.updated_at)}</div>
                </div>
              );
          }
        }
        return true;
      })
    }     
      </div>
    </div>
    <Modal className="selectMonth" data-keyboard={true} backdrop={true} show={show2} >
      <div className="closeButton">
        <Row className="w-100 justify-content-center">
          <button className="btnAnoLeads" onClick={volta} ><i className="fa fa-arrow-left"></i></button>
          <button className="btnAnoLeads3" onClick={fica}>{year}</button>
          <button className="btnAnoLeads2" onClick={vai}><i className="fa fa-arrow-right"></i></button>
        </Row>
      </div>
      <Modal.Body>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
          </Col>
        </Row>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
          </Col>
        </Row>
        <Row className="w-100 justify-content-center">
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
          </Col>      
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
          </Col>
          <Col md={3}>
            <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>
    <Modal className="modalPostDia" data-keyboard={true} show={show} >
      <Button className="closeButton" onClick={handleClose}>X Fechar</Button>
        <Modal.Body>
          {
            planejamentos && planejamentos.map((planejamento) => {
              if(planner === planejamento.texto_1 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">
                      <img className="fotoClass" alt="Post 1" src={completePath}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_1}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_2 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">
                      <img className="fotoClass" alt="Post 2" src={completePath1}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_2}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_3 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">
                      <img className="fotoClass" alt="Post 3" src={completePath2}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_3}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_4 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 4" src={completePath3}></img>
                    </Row> 
                   <div className="textPlan"><b>Legenda:</b> {planejamento.texto_4}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_5 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt= "Post 5" src={completePath4}></img>
                      <div className="textPlan"><b>Legenda:</b> {planejamento.texto_5}</div>
                    </Row>
                  </div>
                )
              }else if(planner === planejamento.texto_6 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center"> 
                      <img className="fotoClass" alt="Post 6" src={completePath5}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_6}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_7 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt= "Post 7" src={completePath6}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_7}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_8 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass"  alt="Post 8" src={completePath7}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_8}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_9 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 9" src={completePath8}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_9}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_10 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt= "Post 10" src={completePath9}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_10}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_11 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 11" src={completePath10}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_11}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_12 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 12" src={completePath11}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_12}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_13 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 13" src={completePath12}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_13}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_14 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 14" src={completePath13}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_14}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_15 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt ="Post 15" src={completePath14}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_15}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_16 && planner !== null){
                return(
                 <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 16" src={completePath15}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_16}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_17 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 17" src={completePath16}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_17}</div>
                  </div>
                )
             }else if(planner === planejamento.texto_18 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 18" src={completePath17}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_18}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_19 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 19" src={completePath18}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_19}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_20 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt= "Post 20" src={completePath19}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_20}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_21 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 21" src={completePath20}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_21}</div>
                  </div>
                )
             }else if(planner === planejamento.texto_22 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 22" src={completePath21}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_22}</div>
                  </div>
               )
             }else if(planner === planejamento.texto_23 && planner !== null){
                return(
                 <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass"  alt= "Post 23" src={completePath22}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_23}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_24 && planner !== null){
                return(
                  <div>
                    <img className="fotoClass" alt=" Post 24" src={completePath23}></img>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_24}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_25 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 25" src={completePath24}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_25}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_26 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 26" src={completePath25}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_26}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_27 && planner !== null){
                return(
                  <div> 
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 27" src={completePath26}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_27}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_28 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 28" src={completePath27}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_28}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_29 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass"  alt="Post 29" src={completePath28}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_29}</div>
                  </div>
                )
              }else if(planner === planejamento.texto_30 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 30" src={completePath29}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_30}</div>
                  </div>
               )
              }else if(planner === planejamento.texto_31 && planner !== null){
                return(
                  <div>
                    <Row className="w-100 justify-content-center">  
                      <img className="fotoClass" alt="Post 31" src={completePath30}></img>
                    </Row>
                    <div className="textPlan"><b>Legenda:</b> {planejamento.texto_31}</div>
                  </div>
                ) 
              }
            return true;
           })
          }
        </Modal.Body>
    </Modal>
  </div>
  );
}

Planejamento.propTypes = {
};
export default Planejamento;
