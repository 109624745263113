import React, { useEffect, useState} from "react";
import NavMenu from "components/Navbars/NavMenu";
import "../../assets/css/materiais.css";
import {Row } from "react-bootstrap";
import API from "utils/API";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function Materiais(){
  const [materiais, setMateriais] = useState();
  var link = 0;
  var link_2 = 1;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let negocioId = 2;

  useEffect(() => {
    const [run] = API.get("materiais");
    run().then((response) => {
        setMateriais(response);
    });
  }, []);

  
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }
  
  getNegocioId();

  function Links(){
    materiais && materiais.map((material) =>{
      if(material.negocio_id === negocioId){
        link = material.link;
        link_2 = material.link_2;
        return(
          link, link_2
        )
      }
      return true;
    })
  }

  Links();
return(
  <div id="contentDash">
    <NavMenu/>
    <Row className="w-100 justify-content-center">  
      <div className="tituloNeonMateriais">Materiais</div>
    </Row>
    <Row className="w-100 justify-content-center">
      <div className="menuMateriais">
        <ul className="p-0">
          <li className="pl-0 ml-0">
            <a href={link}><button className="buttonMatCliente">Logotipo</button></a>
          </li>
          <li>
            <a href={link_2}><button className="buttonMatCliente">Postagens</button></a>
          </li>
        </ul>
        <div className="avisoDrive">Você será redirecionado à pasta do drive.</div>
      </div>
    </Row>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>

    <img className="position-absolute opacity line-1" src={linhas} alt=""></img>  
    <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>

  </div>
  );
}
export default Materiais;