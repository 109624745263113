import React, {useState, useEffect} from "react";
import { Button,  Modal, Row, Col } from "react-bootstrap";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip} from "recharts";
import API from "utils/API";
import NavMenu from "components/Navbars/NavMenu";
import $ from "jquery";
import ClickToPrintLeads from "./ClickToPrintLeads";
import "../../assets/css/resultadosCliente.css";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function LeadsConsolidados(){
  const [show2, setShow2] = useState(false);
  const [leads, setLeads] = useState();
  const [last, setLast] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  var negocioId;
  var leadsDeJaneiro = 0;
  var leadsDeFevereiro = 0;
  var leadsDeMarco = 0;
  var leadsDeAbril = 0;
  var leadsDeMaio = 0;
  var leadsDeJunho = 0;
  var leadsDeJulho = 0;
  var leadsDeAgosto = 0; 
  var leadsDeSetembro = 0;
  var leadsDeOutubro = 0;
  var leadsDeNovembro = 0;
  var leadsDeDezembro = 0;
  var CPLJaneiro = 0;
  var CPLFevereiro = 0;
  var CPLMarco = 0;
  var CPLAbril = 0;
  var CPLMaio = 0;
  var CPLJunho = 0;
  var CPLJulho = 0;
  var CPLAgosto = 0;
  var CPLSetembro = 0;
  var CPLOutubro = 0;
  var CPLNovembro = 0;
  var CPLDezembro = 0;
  var FbJaneiro = 0;
  var FbFevereiro = 0;
  var FbMarco = 0;
  var FbAbril = 0;
  var FbMaio = 0;
  var FbJunho = 0;
  var FbJulho = 0;
  var FbAgosto = 0;
  var FbSetembro = 0;
  var FbOutubro = 0;
  var FbNovembro = 0;
  var FbDezembro = 0;
  var adsJaneiro = 0;
  var adsFevereiro = 0;
  var adsMarco = 0;
  var adsAbril = 0;
  var adsMaio = 0;
  var adsJunho = 0;
  var adsJulho = 0;
  var adsAgosto = 0;
  var adsSetembro = 0;
  var adsOutubro = 0;
  var adsNovembro = 0;
  var adsDezembro = 0;
  var totalJaneiro = 0;
  var totalFevereiro = 0;
  var totalMarco = 0;
  var totalAbril = 0; 
  var totalMaio = 0;
  var totalJunho = 0;
  var totalJulho = 0;
  var totalAgosto = 0;
  var totalSetembro = 0;
  var totalOutubro = 0;
  var totalNovembro = 0;
  var totalDezembro = 0;
  var isLead = "";
  var year = 0;

  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }

  function handleClose2(){
    setShow2(false);
  }

  useEffect(() => {
    const [run] = API.get("leads");
    run().then((response) => {
        setLeads(response);
    });
  }, []);

  year  = getYear().toString();
if(last === 9){
  year = parseInt(year)-1;
  year = year.toString();
}else if(last === 8){
  year  = parseInt(year)+1;
  year = year.toString();
}else if(last===7){
  year  = getYear().toString();
}

  function getData(){
    leads && leads.map((lead) =>{
      const it = window.location.href.split("/");
      const IdNegocio = (it[4]);
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeJaneiro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeJaneiro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeFevereiro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeFevereiro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeMarco = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeMarco = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.cliques === 0  && lead.ano === year){
        isLead = "Leads";
        leadsDeAbril = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeAbril = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeMaio = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeMaio = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeJunho = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeJunho = lead.cliquesValue;
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeJulho = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeJulho = lead.cliquesValue;
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeAgosto = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeAgosto = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeSetembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeSetembro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeOutubro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeOutubro = lead.cliquesValue;
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeNovembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeNovembro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.cliques === 0 && lead.ano === year){
        isLead = "Leads";
        leadsDeDezembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.cliques === 1 && lead.ano === year){
        isLead = "Cliques";
        leadsDeDezembro = lead.cliquesValue;
      }

     return negocioId;
    });
  }

  getData();


  function getDataDois(){
    leads && leads.map((lead) =>{
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.ano === year){
        parseFloat(CPLJaneiro = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.ano === year){
        parseFloat(CPLFevereiro = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.ano === year){
        parseFloat(CPLMarco = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.ano === year){
        parseFloat(CPLAbril = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.ano === year){
        parseFloat(CPLMaio = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.ano === year){
        parseFloat(CPLJunho = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.ano === year){
        parseFloat(CPLJulho = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.ano === year){
        parseFloat(CPLAgosto = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.ano === year){
        parseFloat(CPLSetembro = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.ano === year){
        parseFloat(CPLOutubro = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.ano === year){
        parseFloat(CPLNovembro = lead.CPL);
      }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.ano === year){
        parseFloat(CPLDezembro = lead.CPL);
      }
      return true;
    });
  }

getDataDois();

function getDataTres(){
  leads && leads.map((lead) =>{
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.ano === year){
      parseFloat(FbJaneiro = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.ano === year){
      parseFloat(FbFevereiro = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.ano === year){
      parseFloat(FbMarco = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.ano === year){
      parseFloat(FbAbril = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.ano === year){
      parseFloat(FbMaio = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.ano === year){
      parseFloat(FbJunho = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.ano === year){
      parseFloat(FbJulho = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.ano === year){
      parseFloat(FbAgosto = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.ano === year){
      parseFloat(FbSetembro = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.ano === year){
      parseFloat(FbOutubro = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.ano === year){
      parseFloat(FbNovembro = lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.ano === year){
      parseFloat(FbDezembro = lead.investimentoFb);
    }
    return true;
  });
}

getDataTres();

function getDataQuatro(){
  leads && leads.map((lead) =>{
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.ano === year){
      adsJaneiro = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.ano === year){
      adsFevereiro = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.ano === year){
      adsMarco = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.ano === year){
      adsAbril = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.ano === year){
      adsMaio = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.ano === year){
      adsJunho = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.ano === year){
      adsJulho = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.ano === year){
      adsAgosto = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.ano === year){
      adsSetembro = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.ano === year){
      adsOutubro = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.ano === year){
      adsNovembro = parseFloat(lead.investimentoAds);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.ano === year){
      adsDezembro = parseFloat(lead.investimentoAds);
    }
    return true;
  });
}

getDataQuatro();


function getDataCinco(){
  leads && leads.map((lead) =>{
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.ano === year){
      totalJaneiro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.ano === year){
      totalFevereiro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.ano === year){
      totalMarco = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.ano === year){
      totalAbril = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.ano === year){
      totalMaio = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.ano === year){
      totalJunho = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.ano === year){
      totalJulho = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.ano === year){
      totalAgosto = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.ano === year){
      totalSetembro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.ano === year){
      totalOutubro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.ano === year){
      totalNovembro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
    }if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.ano === year){
      totalDezembro = (parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb));
    }
    return true;
  });
}

  getDataCinco();


  var difLeadsDezembro =((((leadsDeJaneiro/leadsDeDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJaneiro =((((leadsDeFevereiro/leadsDeJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsFevereiro =((((leadsDeMarco/leadsDeFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsMarco = ((((leadsDeAbril/leadsDeMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsAbril = ((((leadsDeMaio/leadsDeAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsMaio =((((leadsDeJunho/leadsDeMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJunho = ((((leadsDeJulho/leadsDeJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJulho = ((((leadsDeAgosto/leadsDeJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsAgosto = ((((leadsDeSetembro/leadsDeAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsSetembro = ((((leadsDeOutubro/leadsDeSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsOutubro = ((((leadsDeNovembro/leadsDeOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsNovembro = ((((leadsDeDezembro/leadsDeNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difLeadsDezembro = isFinite(difLeadsDezembro) ? difLeadsDezembro : 0.0;
  difLeadsJaneiro = isFinite(difLeadsJaneiro) ? difLeadsJaneiro : 0.0;
  difLeadsFevereiro = isFinite(difLeadsFevereiro) ? difLeadsFevereiro : 0.0;
  difLeadsMarco = isFinite(difLeadsMarco) ? difLeadsMarco : 0.0;
  difLeadsAbril = isFinite(difLeadsAbril) ? difLeadsAbril : 0.0;
  difLeadsMaio = isFinite(difLeadsMaio) ? difLeadsMaio : 0.0;
  difLeadsJunho = isFinite(difLeadsJunho) ? difLeadsJunho : 0.0;
  difLeadsJulho = isFinite(difLeadsJulho) ? difLeadsJulho : 0.0;
  difLeadsAgosto = isFinite(difLeadsAgosto) ? difLeadsAgosto : 0.0;
  difLeadsSetembro = isFinite(difLeadsSetembro) ? difLeadsSetembro : 0.0;
  difLeadsOutubro = isFinite(difLeadsOutubro) ? difLeadsOutubro : 0.0;
  difLeadsNovembro = isFinite(difLeadsNovembro) ? difLeadsNovembro : 0.0;

  var difCPLDezembro = ((((CPLJaneiro/CPLDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJaneiro = ((((CPLFevereiro/CPLJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLFevereiro = ((((CPLMarco/CPLFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLMarco = ((((CPLAbril/CPLMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLAbril = ((((CPLMaio/CPLAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLMaio = ((((CPLJunho/CPLMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJunho = ((((CPLJulho/CPLJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJulho = ((((CPLAgosto/CPLJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLAgosto = ((((CPLSetembro/CPLAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLSetembro = ((((CPLOutubro/CPLSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLOutubro = ((((CPLNovembro/CPLOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLNovembro = ((((CPLDezembro/CPLNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difCPLDezembro = isFinite(difCPLDezembro) ? difCPLDezembro : 0.0;
  difCPLJaneiro = isFinite(difCPLJaneiro) ? difCPLJaneiro : 0.0;
  difCPLFevereiro = isFinite(difCPLFevereiro) ? difCPLFevereiro : 0.0;
  difCPLMarco = isFinite(difCPLMarco) ? difCPLMarco : 0.0;
  difCPLAbril = isFinite(difCPLAbril) ? difCPLAbril : 0.0;
  difCPLMaio = isFinite(difCPLMaio) ? difCPLMaio : 0.0;
  difCPLJunho = isFinite(difCPLJunho) ? difCPLJunho : 0.0;
  difCPLJulho = isFinite(difCPLJulho) ? difCPLJulho : 0.0;
  difCPLAgosto = isFinite(difCPLAgosto) ? difCPLAgosto : 0.0;
  difCPLSetembro = isFinite(difCPLSetembro) ? difCPLSetembro : 0.0;
  difCPLOutubro = isFinite(difCPLOutubro) ? difCPLOutubro : 0.0;
  difCPLNovembro = isFinite(difCPLNovembro) ? difCPLNovembro : 0.0;

  var difFbDezembro = ((((FbJaneiro/FbDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJaneiro = ((((FbFevereiro/FbJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbFevereiro = ((((FbMarco/FbFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbMarco = ((((FbAbril/FbMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbAbril = ((((FbMaio/FbAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbMaio = ((((FbJunho/FbMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJunho = ((((FbJulho/FbJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJulho = ((((FbAgosto/FbJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbAgosto = ((((FbSetembro/FbAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbSetembro = ((((FbOutubro/FbSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbOutubro = ((((FbNovembro/FbOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbNovembro = ((((FbDezembro/FbNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difFbDezembro = isFinite(difFbDezembro) ? difFbDezembro : 0.0;
  difFbJaneiro = isFinite(difFbJaneiro) ? difFbJaneiro : 0.0;
  difFbFevereiro = isFinite(difFbFevereiro) ? difFbFevereiro : 0.0;
  difFbMarco = isFinite(difFbMarco) ? difFbMarco : 0.0;
  difFbAbril = isFinite(difFbAbril) ? difFbAbril : 0.0;
  difFbMaio = isFinite(difFbMaio) ? difFbMaio : 0.0;
  difFbJunho = isFinite(difFbJunho) ? difFbJunho : 0.0;
  difFbJulho = isFinite(difFbJulho) ? difFbJulho : 0.0;
  difFbAgosto = isFinite(difFbAgosto) ? difFbAgosto : 0.0;
  difFbSetembro = isFinite(difFbSetembro) ? difFbSetembro : 0.0;
  difFbOutubro = isFinite(difFbOutubro) ? difFbOutubro : 0.0;
  difFbNovembro = isFinite(difFbNovembro) ? difFbNovembro : 0.0;


  var difAdsDezembro = ((((adsJaneiro/adsDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 }); 
  var difAdsJaneiro = ((((adsFevereiro/adsJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsFevereiro = ((((adsMarco/adsFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsMarco = ((((adsAbril/adsMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsAbril = ((((adsMaio/adsAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsMaio = ((((adsJunho/adsMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsJunho = ((((adsJulho/adsJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsJulho = ((((adsAgosto/adsJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsAgosto = ((((adsSetembro/adsAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 }); 
  var difAdsSetembro = ((((adsOutubro/adsSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsOutubro = ((((adsNovembro/adsOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsNovembro = ((((adsDezembro/adsNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });


  difAdsDezembro = isFinite(difAdsDezembro) ? difAdsDezembro : 0.0;
  difAdsJaneiro = isFinite(difAdsJaneiro) ? difAdsJaneiro : 0.0;
  difAdsFevereiro = isFinite(difAdsFevereiro) ? difAdsFevereiro : 0.0;
  difAdsMarco = isFinite(difAdsMarco) ? difAdsMarco : 0.0;
  difAdsAbril = isFinite(difAdsAbril) ? difAdsAbril : 0.0;
  difAdsMaio = isFinite(difAdsMaio) ? difAdsMaio : 0.0;
  difAdsJunho = isFinite(difAdsJunho) ? difAdsJunho : 0.0;
  difAdsJulho = isFinite(difAdsJulho) ? difAdsJulho : 0.0;
  difAdsAgosto = isFinite(difAdsAgosto) ? difAdsAgosto : 0.0;
  difAdsSetembro = isFinite(difAdsSetembro) ? difAdsSetembro : 0.0;
  difAdsOutubro = isFinite(difAdsOutubro) ? difAdsOutubro : 0.0;
  difAdsNovembro = isFinite(difAdsNovembro) ? difAdsNovembro : 0.0;


  var difTotalDezembro = ((((totalJaneiro/totalDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJaneiro = ((((totalFevereiro/totalJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalFevereiro = ((((totalMarco/totalFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalMarco = ((((totalAbril/totalMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalAbril = ((((totalMaio/totalAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalMaio = ((((totalJunho/totalMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJunho = ((((totalJulho/totalJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJulho = ((((totalAgosto/totalJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalAgosto = ((((totalSetembro/totalAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalSetembro = ((((totalOutubro/totalSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalOutubro = ((((totalNovembro/totalOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalNovembro = ((((totalDezembro/totalNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difTotalDezembro = isFinite(difTotalDezembro) ? difTotalDezembro : 0.0;
  difTotalJaneiro = isFinite(difTotalJaneiro) ? difTotalJaneiro : 0.0;
  difTotalFevereiro = isFinite(difTotalFevereiro) ? difTotalFevereiro : 0.0;
  difTotalMarco = isFinite(difTotalMarco) ? difTotalMarco : 0.0;
  difTotalAbril = isFinite(difTotalAbril) ? difTotalAbril : 0.0;
  difTotalMaio = isFinite(difTotalMaio) ? difTotalMaio : 0.0;
  difTotalJunho = isFinite(difTotalJunho) ? difTotalJunho : 0.0;
  difTotalJulho = isFinite(difTotalJulho) ? difTotalJulho : 0.0;
  difTotalAgosto = isFinite(difTotalAgosto) ? difTotalAgosto : 0.0;
  difTotalSetembro = isFinite(difTotalSetembro) ? difTotalSetembro : 0.0;
  difTotalOutubro = isFinite(difTotalOutubro) ? difTotalOutubro : 0.0;
  difTotalNovembro = isFinite(difTotalNovembro) ? difTotalNovembro : 0.0;

  const data = [
    {
      name: 'Jan.',
      Leads: leadsDeJaneiro,
    },
    {
      name: 'Fev.',
      Leads: leadsDeFevereiro,
    },
    {
      name: 'Mar.',
      Leads: leadsDeMarco,
    },
    {
      name: 'Abr.',
      Leads: leadsDeAbril,
    },
    {
      name: 'Maio',
      Leads: leadsDeMaio,
    },
    {
      name: 'Jun.',
      Leads: leadsDeJunho,
    },
    {
      name: 'Jul.',
      Leads: leadsDeJulho,
    },
    {
      name: 'Ago.',
      Leads: leadsDeAgosto,
    },
    {
      name: 'Set.',
      Leads: leadsDeSetembro,
    },
    {
      name: 'Out.',
      Leads: leadsDeOutubro,
    },
    {
      name: 'Nov.',
      Leads: leadsDeNovembro,
    },
    {
      name: 'Dez.',
      Leads: leadsDeDezembro,
    },
  ];
  
  const dataDois = [
    {
      name: 'Jan.',
      CPL: CPLJaneiro,

    },
    {
      name: 'Fev.',
      CPL: CPLFevereiro,
    },
    {
      name: 'Mar.',
      CPL: CPLMarco,
    },
    {
      name: 'Abr.',
      CPL: CPLAbril,
    },
    {
      name: 'Maio',
      CPL: CPLMaio,
    },
    {
      name: 'Jun.',
      CPL: CPLJunho,
    },
    {
      name: 'Jul.',
      CPL: CPLJulho,
    },
    {
      name: 'Ago.',
      CPL: CPLAgosto,
    },
    {
      name: 'Set.',
      CPL: CPLSetembro,
    },
    {
      name: 'Out.',
      CPL: CPLOutubro,
    },
    {
      name: 'Nov.',
      CPL: CPLNovembro,
    },
    {
      name: 'Dez.',
      CPL: CPLDezembro,
    },
  ];

  const dataTres = [
    {
      name: 'Jan.',
      Valor: FbJaneiro,
    },
    {
      name: 'Fev.',
      Valor: FbFevereiro,
    },
    {
      name: 'Mar.',
      Valor: FbMarco,
    },
    {
      name: 'Abr.',
      Valor: FbAbril,
    },
    {
      name: 'Maio',
      Valor: FbMaio,
    },
    {
      name: 'Jun.',
      Valor: FbJunho,
    },
    {
      name: 'Jul.',
      Valor: FbJulho,
    },
    {
      name: 'Ago.',
      Valor: FbAgosto,
    },
    {
      name: 'Set.',
      Valor: FbSetembro,
    },
    {
      name: 'Out.',
      Valor: FbOutubro,
    },
    {
      name: 'Nov.',
      Valor: FbNovembro,
    },
    {
      name: 'Dez.',
      Valor: FbDezembro,
    },
  ];

  const dataQuatro = [
    {
      name: 'Jan.',
      Investimento: adsJaneiro,
    },
    {
      name: 'Fev.',
      Investimento: adsFevereiro,
    },
    {
      name: 'Mar.',
      Investimento: adsMarco,
    },
    {
      name: 'Abr.',
      Investimento: adsAbril,
    },
    {
      name: 'Maio',
      Investimento: adsMaio,
    },
    {
      name: 'Jun.',
      Investimento: adsJunho,
    },
    {
      name: 'Jul.',
      Investimento: adsJulho,
    },
    {
      name: 'Ago.',
      Investimento: adsAgosto,
    },
    {
      name: 'Set.',
      Investimento: adsSetembro,
    },
    {
      name: 'Out.',
      Investimento: adsOutubro,
    },
    {
      name: 'Nov.',
      Investimento: adsNovembro,
    },
    {
      name: 'Dez.',
      Investimento: adsDezembro,
    },
  ];
  
  const dataCinco = [
    {
      name: 'Jan.',
      Total: totalJaneiro,
    },
    {
      name: 'Fev.',
      Total: totalFevereiro,
    },
    {
      name: 'Mar.',
      Total: totalMarco,
    },
    {
      name: 'Abr.',
      Total: totalAbril,
    },
    {
      name: 'Maio',
      Total: totalMaio,
    },
    {
      name: 'Jun.',
      Total: totalJunho,
    },
    {
      name: 'Jul.',
      Total: totalJulho,
    },
    {
      name: 'Ago.',
      Total: totalAgosto,
    },
    {
      name: 'Set.',
      Total: totalSetembro,
    },
    {
      name: 'Out.',
      Total: totalOutubro,
    },
    {
      name: 'Nov.',
      Total: totalNovembro,
    },
    {
      name: 'Dez.',
      Total: totalDezembro,
    },
  ];
  
  function volta(){
    setLast(9);
  }
    
  function vai(){
    setLast(8);
  }

  function fica(){
    setLast(7);
  }

  function colorChange(){
    if(difLeadsDezembro < 0){
      $(".dezLeads").css("color","red");
    }
    if(difLeadsJaneiro < 0){
      $(".janLeads").css("color","red");
    }
     if(difLeadsFevereiro < 0){
      $(".fevLeads").css("color","red");
    }
     if(difLeadsMarco < 0){
      $(".marLeads").css("color","red");
    }
     if(difLeadsAbril < 0 ){
      $(".abrilLeads").css("color","red");
    }
     if(difLeadsMaio < 0){
      $(".maioLeads").css("color","red");
    }
     if(difLeadsJunho < 0){
      $(".junhoLeads").css("color","red");
    }
     if(difLeadsJulho < 0){
      $(".julhoLeads").css("color","red");
    }
     if(difLeadsAgosto < 0){
      $(".agostoLeads").css("color","red");
    }
     if(difLeadsSetembro < 0){
      $(".setLeads").css("color","red");
    }
     if(difLeadsOutubro < 0){
      $(".outLeads").css("color","red");
    }
     if(difLeadsNovembro < 0){
      $(".novLeads").css("color","red");
    }
  }

  function changeColorCPL(){
    if(difCPLDezembro < 0){
      $(".dezCPL").css("color","blue");
    }
    if(difCPLJaneiro < 0){
      $(".janCPL").css("color","blue");
    }
     if(difCPLFevereiro < 0){
      $(".fevCPL").css("color","blue");
    }
     if(difCPLMarco < 0){
      $(".marCPL").css("color","blue");
    }
     if(difCPLAbril < 0 ){
      $(".abrilCPL").css("color","blue");
    }
     if(difCPLMaio < 0){
      $(".maioCPL").css("color","blue");
    }
     if(difCPLJunho < 0){
      $(".junhoCPL").css("color","blue");
    }
     if(difCPLJulho < 0){
      $(".julhoCPL").css("color","blue");
    }
     if(difCPLAgosto < 0){
      $(".agostoCPL").css("color","blue");
    }
     if(difCPLSetembro < 0){
      $(".setembroCPL").css("color","blue");
    }
     if(difCPLOutubro < 0){
      $(".outubroCPL").css("color","blue");
    }
     if(difCPLNovembro < 0){
      $(".novembroCPL").css("color","blue");
    }
  }

  function changeColorFb(){
    if(difFbDezembro < 0){
      $(".dezFb").css("color","red");
    }
    if(difFbJaneiro < 0){
      $(".janFb").css("color","red");
    }
     if(difFbFevereiro < 0){
      $(".fevFb").css("color","red");
    }
     if(difFbMarco < 0){
      $(".marFb").css("color","red");
    }
     if(difFbAbril < 0 ){
      $(".abrilFb").css("color","red");
    }
     if(difFbMaio < 0){
      $(".maioFb").css("color","red");
    }
     if(difFbJunho < 0){
      $(".junhoFb").css("color","red");
    }
     if(difFbJulho < 0){
      $(".julhoFb").css("color","red");
    }
     if(difFbAgosto < 0){
      $(".agostoFb").css("color","red");
    }
     if(difFbSetembro < 0){
      $(".setFb").css("color","red");
    }
     if(difFbOutubro < 0){
      $(".outFb").css("color","red");
    }
     if(difFbNovembro < 0){
      $(".novFb").css("color","red");
    }
  }

  function changeColorAds(){
    if(difAdsDezembro < 0){
      $(".adsDez").css("color","red");
    }
    if(difAdsJaneiro < 0){
      $(".adsJan").css("color","red");
    }
     if(difAdsFevereiro < 0){
      $(".adsFev").css("color","red");
    }
     if(difAdsMarco < 0){
      $(".adsMar").css("color","red");
    }
     if(difAdsAbril < 0 ){
      $(".adsAbril").css("color","red");
    }
     if(difAdsMaio < 0){
      $(".adsMaio").css("color","red");
    }
     if(difAdsJunho < 0){
      $(".adsJunho").css("color","red");
    }
     if(difAdsJulho < 0){
      $(".adsJulho").css("color","red");
    }
     if(difAdsAgosto < 0){
      $(".adsAgosto").css("color","red");
    }
     if(difAdsSetembro < 0){
      $(".adsSet").css("color","red");
    }
     if(difAdsOutubro < 0){
      $(".adsOut").css("color","red");
    }
     if(difAdsNovembro < 0){
      $(".adsNov").css("color","red");
    }
  }

  function changeColorTotal(){
    if(difTotalDezembro < 0){
      $(".totalDez").css("color","red");
    }
    if(difTotalJaneiro < 0){
      $(".totalJan").css("color","red");
    }
     if(difTotalFevereiro < 0){
      $(".totalFev").css("color","red");
    }
     if(difTotalMarco < 0){
      $(".totalMar").css("color","red");
    }
     if(difTotalAbril < 0 ){
      $(".totalAbril").css("color","red");
    }
     if(difTotalMaio < 0){
      $(".totalMaio").css("color","red");
    }
     if(difTotalJunho < 0){
      $(".totalJunho").css("color","red");
    }
     if(difTotalJulho < 0){
      $(".totalJulho").css("color","red");
    }
     if(difTotalAgosto < 0){
      $(".totalAgosto").css("color","red");
    }
     if(difTotalSetembro < 0){
      $(".totalSetembro").css("color","red");
    }
     if(difTotalOutubro < 0){
      $(".totalOut").css("color","red");
    }
     if(difTotalNovembro < 0){
      $(".totalNov").css("color","red");
    }
  }

return(
  <div id="contentDash">
  <NavMenu/>
    <Row className="w-100 justify-content-center">
      <div className="resultTitulo">Resultados consolidados ({year})</div>
    </Row>
    <Row className="w-100 justify-content-center">
      <div className="neonTr">
        <button className="btnAnoLeads"  onClick={volta}><i className="fa fa-arrow-left"></i></button>
        <button className="btnAnoLeads3" onClick={fica}>{getYear()}</button>
        <button className="btnAnoLeads2"  onClick={vai}><i className="fa fa-arrow-right"></i></button>
        <ClickToPrintLeads/>
        <div className="months">
          <table>
            <thead>
              <tr>
                <th className="monthsEach">Janeiro</th>
                <th className="monthsEach">Fevereiro</th>
                <th className="monthsEach">Março</th>
                <th className="monthsEach">Abril</th>
                <th className="monthsEach">Maio</th>
                <th className="monthsEach">Junho</th>
                <th className="monthsEach">Julho</th>
                <th className="monthsEach">Agosto</th>
                <th className="monthsEach">Setembro</th>
                <th className="monthsEach">Outubro</th>
                <th className="monthsEach">Novembro</th>
                <th className="monthsEach">Dezembro</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="backgroundLeads">
        <div className="tableresults">
          <table className="tableLeads">
            <tbody>
              <tr className="grayy">
                <td><div className="firstColumn"><b>{isLead}</b></div></td>
                <td><div className="secondColumn">{leadsDeJaneiro}</div></td>
                <td><div className="col1">{leadsDeFevereiro}</div></td>
                <td><div className="col2">{leadsDeMarco}</div></td>
                <td><div className="col3"> {leadsDeAbril}</div></td>
                <td><div className="col4">{leadsDeMaio}</div></td>
                <td><div className="col5">{leadsDeJunho}</div></td>
                <td><div className="col6">{leadsDeJulho}</div></td>
                <td><div className="col7">{leadsDeAgosto}</div></td>
                <td><div className="col8">{leadsDeSetembro}</div></td>
                <td ><div className="col9">{leadsDeOutubro}</div></td>
                <td><div className="col10">{leadsDeNovembro}</div></td>
                <td><div className="col11">{leadsDeDezembro}</div></td>
              </tr>
              <tr className="grayy" onLoad={colorChange()}>
                <td><div className="firstColumn">Diferença de {isLead} </div></td>
                <td className="dezLeads"><div className="secondColumn">{parseFloat(difLeadsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="janLeads"><div className="col1">{parseFloat(difLeadsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="fevLeads"><div className="col2">{parseFloat(difLeadsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="marLeads"><div className="col3">{parseFloat(difLeadsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="abrilLeads"><div className="col4">{parseFloat(difLeadsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="maioLeads"><div className="col5">{parseFloat(difLeadsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="junhoLeads"><div className="col6">{parseFloat(difLeadsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="julhoLeads"><div className="col7">{parseFloat(difLeadsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="agostoLeads"><div className="col8">{parseFloat(difLeadsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="setLeads"><div className="col9">{parseFloat(difLeadsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="outLeads"><div className="col10">{parseFloat(difLeadsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="novLeads"><div className="col11">{parseFloat(difLeadsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
              </tr>
              <tr>
                <td><div className="firstColumn"><b>CPL</b></div></td>
                <td ><div className="secondColumn">R$ {parseFloat(CPLJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="coll"><div className="col1">R$ {parseFloat(CPLFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col2"><div className="col2">R$ {parseFloat(CPLMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col3"><div className="col3">R$ {parseFloat(CPLAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col4"><div className="col4">R$ {parseFloat(CPLMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col5"><div className="col5">R$ {parseFloat(CPLJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col6"><div className="col6">R$ {parseFloat(CPLJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col7"><div className="col7">R$ {parseFloat(CPLAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col8"><div className="col8">R$ {parseFloat(CPLSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col9"><div className="col9">R$ {parseFloat(CPLOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col10"><div className="col10">R$ {parseFloat(CPLNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td className="col11"><div className="col11">R$ {parseFloat(CPLDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
              </tr>
              <tr onLoad={changeColorCPL()}>
                <td><div className="firstColumn">Diferença de CPL</div></td>
                <td className="dezCPL"><div className="secondColumn">{parseFloat(difCPLDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="janCPL"><div className="col1">{parseFloat(difCPLJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="fevCPL"><div className="col2">{parseFloat(difCPLFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="marCPL"><div className="col3">{parseFloat(difCPLMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="abrilCPL"><div className="col4">{parseFloat(difCPLAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="maioCPL"><div className="col5">{parseFloat(difCPLMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="junhoCPL"><div className="col6">{parseFloat(difCPLJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="julhoCPL"><div className="col7">{parseFloat(difCPLJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="agostoCPL"><div className="col8">{parseFloat(difCPLAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="setembroCPL"><div className="col9">{parseFloat(difCPLSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="outubroCPL" ><div className="col10">{parseFloat(difCPLOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="novembroCPL"><div className="col11">{parseFloat(difCPLNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
              </tr>
              <tr className="grayy"> 
                <td><div className="firstColumn"><b>Investimento Facebook Ads</b></div></td>
                <td><div className="secondColumn">R$ {parseFloat(FbJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col1">R$ {parseFloat(FbFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col2">R$ {parseFloat(FbMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col3">R$ {parseFloat(FbAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col4">R$ {parseFloat(FbMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col5">R$ {parseFloat(FbJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col6">R$ {parseFloat(FbJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col7">R$ {parseFloat(FbAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col8">R$ {parseFloat(FbSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col9">R$ {parseFloat(FbOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col10">R$ {parseFloat(FbNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td ><div className="col11">R$ {parseFloat(FbDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
              </tr>
              <tr className="grayy" onLoad={changeColorFb()}>
                <td><div className="firstColumn">Diferença de Investimento Consolidado</div></td>
                <td className="dezFb"><div className="secondColumn">{parseFloat(difFbDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="janFb"><div className="col1">{parseFloat(difFbJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="fevFb"><div className="col2">{parseFloat(difFbFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="marFb"><div className="col3">{parseFloat(difFbMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="abrilFb"><div className="col4">{parseFloat(difFbAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="maioFb"><div className="col5">{parseFloat(difFbMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="junhoFb"><div className="col6">{parseFloat(difFbJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="julhoFb"><div className="col7">{parseFloat(difFbJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="agostoFb"><div className="col8">{parseFloat(difFbAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="setFb"><div className="col9">{parseFloat(difFbSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="outFb"><div className="col10">{parseFloat(difFbOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="novFb"><div className="col11">{parseFloat(difFbNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
             </tr>
             <tr>
                <td><div className="firstColumn"><b>Investimento Google Ads</b></div></td>
                <td><div className="secondColumn">R$ {parseFloat(adsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col1">R$ {parseFloat(adsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col2">R$ {parseFloat(adsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col3">R$ {parseFloat(adsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col4">R$ {parseFloat(adsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col5">R$ {parseFloat(adsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col6">R$ {parseFloat(adsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col7">R$ {parseFloat(adsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col8">R$ {parseFloat(adsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col9">R$ {parseFloat(adsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col10">R$ {parseFloat(adsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col11">R$ {parseFloat(adsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
              </tr>
              <tr onLoad={changeColorAds()}>
                <td><div className="firstColumn">Diferença de Investimento Consolidado</div></td>
                <td className="adsDez"><div className="secondColumn">{parseFloat(difAdsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsJan"><div className="col1">{parseFloat(difAdsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsFev"><div className="col2">{parseFloat(difAdsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsMar"><div className="col3">{parseFloat(difAdsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsAbril"><div className="col4">{parseFloat(difAdsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsMaio"><div className="col5">{parseFloat(difAdsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsJunho"><div className="col6">{parseFloat(difAdsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsJulho"><div className="col7">{parseFloat(difAdsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsAgosto"><div className="col8">{parseFloat(difAdsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsSet"><div className="col9">{parseFloat(difAdsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsOut"><div className="col10">{parseFloat(difAdsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="adsNov"><div className="col11">{parseFloat(difAdsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
              </tr>
              <tr className="grayy">
                <td><div className="firstColumn"><b>Investimento Total (Facebook + Google)</b></div></td>
                <td><div className="secondColumn">R$ {parseFloat(totalJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td ><div className="col1">R$ {parseFloat(totalFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col2">R$ {parseFloat(totalMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col3">R$ {parseFloat(totalAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col4">R$ {parseFloat(totalMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col5">R$ {parseFloat(totalJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col6">R$ {parseFloat(totalJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col7">R$ {parseFloat(totalAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col8">R$ {parseFloat(totalSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col9">R$ {parseFloat(totalOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col10">R$ {parseFloat(totalNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
                <td><div className="col11">R$ {parseFloat(totalDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div></td>
              </tr>
              <tr className="grayy" onLoad={changeColorTotal()}>
                <td><div className="firstColumn">Diferença de Investimento Consolidado</div></td>
                <td className="totalDez"><div className="secondColumn">{parseFloat(difTotalDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalJan"><div className="col1">{parseFloat(difTotalJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalFev"><div className="col2">{parseFloat(difTotalFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalMar"><div className="col3">{parseFloat(difTotalMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalAbril"><div className="col4">{parseFloat(difTotalAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalMaio"><div className="col5">{parseFloat(difTotalMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalJunho"><div className="col6">{parseFloat(difTotalJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalJulho"><div className="col7">{parseFloat(difTotalJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalAgosto"><div className="col8">{parseFloat(difTotalAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalSetembro"><div className="col9">{parseFloat(difTotalSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalOut"><div className="col10">{parseFloat(difTotalOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
                <td className="totalNov"><div className="col11">{parseFloat(difTotalNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</div></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row>
          <Col md={5}>
            <div className="graficoA">   
              <div className="titleChartLeads">Quantidade de <a href="#!" className="anotherFont">Leads</a></div> 
              <div style={{ "width": "90%","height": 300 }}>
                <LineChart
                  className="chartA"
                  width={830}
                  height={450}
                  data={data}
                  margin={{
                    "top": 15,
                    "right": 20,
                    "left": 40,
                    "bottom": 150
                  }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"  axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <YAxis axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <Tooltip />
                  <Line className="lineshadowLeads" type="monotone" dataKey="Leads" stackId="1" stroke="#d5375a"   />
                </LineChart>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="graficoB"> 
              <div className="titleChartLeads">CPL <a href="/" className="anotherFont">(R$)</a></div> 
              <div style={{ "width": "90%","height": 300 }}>
                <LineChart
                    className="chartB"
                    width={830}
                    height={450}
                    data={dataDois}
                    margin={{
                      "top": 15,
                      "right": 20,
                      "left": 100,
                      "bottom": 150
                    }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"  axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <YAxis axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <Tooltip />
                  <Line className="lineshadowLeads" type="monotone" dataKey="CPL" stackId="1"  stroke="#d5375a" />
                </LineChart>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <div className="graficoA">  
              <div className="titleChartLeads">Investimento Consolidado <a href="#!" className="anotherFont"> Facebook (R$)</a></div> 
              <div style={{ "width": "90%","height": 300 }}>
                <LineChart
                  className="chartC"
                        width={830}
                        height={450}
                        data={dataTres}
                        margin={{
                          "top": 15,
                          "right": 20,
                          "left": 40,
                          "bottom": 150
                        }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"  axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <YAxis axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <Tooltip />
                  <Line className="lineshadowLeads" type="monotone" dataKey="Valor" stackId="1"  stroke="#d5375a" />
                </LineChart>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="graficoB"> 
              <div className="titleChartLeads">Investimento Consolidado <a href="#!" className="anotherFont"> Google (R$)</a></div> 
              <div style={{ "width": "90%","height": 300 }}>
                <LineChart
                    className="chartD"
                        width={830}
                        height={450}
                        data={dataQuatro}
                        margin={{
                          "top": 15,
                          "right": 20,
                          "left": 100,
                          "bottom": 150
                        }}
                      >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"  axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <YAxis axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <Tooltip />
                  <Line className="lineshadowLeads" type="monotone" dataKey="Investimento" stackId="1"  stroke="#d5375a" />
                </LineChart>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="graficoC"> 
              <div className="titleChartLeadsLast">Investimento Total <a href="#!" className="anotherFont">  (FB + ADS) (R$)</a></div> 
              <div style={{ "width": "90%","height": 300 }}>
                <LineChart
                  className="chartE"
                          width={830}
                          height={450}
                          data={dataCinco}
                          margin={{
                            "top": 15,
                            "right": 20,
                            "left": 15,  
                          "bottom": 150
                          }}
                    >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"  axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <YAxis axisLine={{ stroke: '#a3989a' }}  tick={{ fill: '#a3989a' }} tickLine={{ stroke: '#a3989a' }}  fontSize={16}/>
                  <Tooltip />
                  <Line className="lineshadowLeads" type="monotone" dataKey="Total" stackId="1"  stroke="#d5375a" />
                </LineChart>
             </div>
            </div>
          </Col>
        </Row>
      
        <Modal className="PrintModalCalopenendar" data-keyboard={false} backdrop={false} show={show2} >
          <Button className="closeButton" onClick={handleClose2}>X</Button>
          <Modal.Body> 
            <ClickToPrintLeads/>
          </Modal.Body>
        </Modal>
      </div>
    </Row>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>

    <img className="position-absolute opacity line-V" src={linhas} alt=""></img>
    <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>

</div>

  );
}

LeadsConsolidados.propTypes = {};
export default LeadsConsolidados;
