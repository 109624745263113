import React, { useState, useContext, useEffect } from "react";
import API from "utils/API";
import MenuCompleto from './../Admin/MenuCompleto';
import { NotificationContext } from "../../contexts/NotificationContext";
import $ from "jquery";
import { useForm } from "react-hook-form";
import {Row, Col, FormGroup, FormControl } from "react-bootstrap";
import { useCep } from "cep-hook";
import Button from "components/CustomButton/CustomButton.jsx";
import "../../assets/css/negocios.css";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import Alert from 'react-bootstrap/Alert';

function RegisterBusiness() {
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const { register, handleSubmit} = useForm();
  const [cep, setCep] = useState(null);
  const [usuarios, setUsuarios] = useState();
  const [setValue, getZip] = useCep();
  const handleClickFindCep = async () => setCep(await getZip());
  const { authUser } = useContext(AuthUserContext);
  const [show, setShow] = useState(false);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  function onSubmit(data) {
    const [run] = API.post("negocios", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Negócio adicionado com sucesso.");
        setIsLoading(false);
        refreshPage();
      })
      .catch(() => {
        setShow(true);
        setIsLoading(false);
        $(".botaoAzul").show();  
      });
  }

  $(document).ready(function() {
    $(".mais").hide();
    $(".maisum").hide();
    $(".maisdois").hide();
    $(".maistres").hide();
    $(".l1").hide();
    $(".l2").hide();
    $(".l3").hide();
    $(".l4").hide();
    $(".p2").hide();
    $(".p3").hide();
    $(".p4").hide();
    
});

  function hideAndShow(){
    $(".mais").show();
    $(".l1").show();
    $(".p2").show();
  }

  function hideAndShow2(){
    $(".maisum").show();
    $(".l2").show();
    $(".p3").show();
  }

  function hideAndShow3(){
    $(".maisdois").show();
    $(".l3").show();
    $(".p4").show();
  }

  function hideAndShow4(){
    $(".maistres").show();
    $(".l4").show();
    $(".p4").show();
  }

  
  return (
    <div>
       <MenuCompleto/>
      <div className="tituloNegocio">Registrar negócio</div>
       {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.negocios === 1))){
            return(
              <div className="formNegocio">
                <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={2}>
                    <label><div className="red">Nome*:</div></label> 
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio" id="nome" type="text" ref={register} name="nome"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label><div className="red">CNPJ*:</div></label>  
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio" id="cnpj" type="text" ref={register} name="cnpj"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Telefone:</label>
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio" type="text" ref={register} name="telefone"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Endereço:</label> 
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio" defaultValue={cep && cep.logradouro} type="text" ref={register} name="endereco"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Cidade:</label>  
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio"  defaultValue={cep && cep.localidade} type="text" ref={register} name="cidade"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Estado:</label> 
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio" defaultValue={cep && cep.uf} type="text" ref={register} name="uf"></input>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>CEP:</label>
                  </Col>
                  <Col md={6}>
                    <input className="inputNegocio"   onChange={e => setValue(e.target.value)}  type="text" ref={register} name="cep"></input>
                  </Col>
                  <Col md={2}>
                    <input   className="buttonNegocio"  onClick={handleClickFindCep} type="text" placeholder="Busca de CEP" ref={register} readOnly />  
                  </Col>     
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Serviços:</label>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label className="containerEquipe">Criação
                      <input type="checkbox" name="criacao" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEquipe">Roteiros
                      <input type="checkbox" name="roteiros" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEquipe">Gestão de Tráfego pago
                      <input type="checkbox" name="trafego" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEquipe">Gestão de Google Ads
                      <input type="checkbox" name="ads" ref={register}/>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label className="containerEquipe">Landing Page
                      <input type="checkbox" name="lp" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEquipe">Site
                      <input type="checkbox" name="site" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEquipe">Acesso ao sistema
                      <input type="checkbox" name="sistema" ref={register}/>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                   <label>Situação</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios">
                      <FormControl name="estado" as="select" ref={register} >
                        <option>Em negociação</option>
                        <option>Ativo</option>
                        <option>Inativo</option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Responsável</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios">
                      <FormControl name="usuario_id" as="select" ref={register} >
                        {
                          usuarios && usuarios.map((usuario) => {
                            if (usuario.ativo === 1) {
                              return (
                                    <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                                    );
                            }
                              return null;
                          })
                        }
                     </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Administrador</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios">
                      <FormControl name="admin_id" as="select" ref={register} >
                        {
                          usuarios && usuarios.map((usuario) => {
                            if (usuario.role === "admin" && usuario.ativo === 1 ) {
                              return (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                                  );
                            }
                              return null;
                          })
                        }

                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios">
                      <FormControl name="equipe_id" as="select" ref={register} >
                        {
                         usuarios && usuarios.map((usuario) => {
                            if (usuario.role === "equipe" && usuario.ativo === 1 ) {
                              return (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                                 );
                                  }
                                    return null;
                                  })
                        }

                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <input className="plusMembros" placeholder="+ Equipe" onClick={hideAndShow} readOnly/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l1">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios mais">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe1_id" as="select" ref={register} >
                                <option>Selecione uma opção</option>
                                <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                              );
                          }
                              return null;
                        })
                      }
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <input className="plusMembros p2" placeholder="+ Equipe" onClick={hideAndShow2} readOnly/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l2">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                   <FormGroup  className="selectNegocios maisum">
                        {
                          usuarios && usuarios.map((usuario) => {
                            if (usuario.role === "equipe" && usuario.ativo === 1) {
                              return (
                                <FormControl name="equipe2_id" as="select" ref={register} >
                                    <option>Selecione uma opção</option>
                                    <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                                </FormControl>
                              );
                            }
                              return null;
                            })
                          }
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <input className="plusMembros p3" placeholder="+ Equipe" onClick={hideAndShow3} readOnly/>
                  </Col>
               </Row>
               <Row>
                  <Col md={2}>
                    <label className="l3">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios maisdois">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe3_id" as="select" ref={register} >
                                  <option>Selecione uma opção</option>
                                  <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                              );
                          } 
                              return null;
                        })
                     }
                    </FormGroup>
                            
                  </Col>
                 <Col md={2}>
                    <input className="plusMembros p4" placeholder="+ Equipe" onClick={hideAndShow4} readOnly/>
                 </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l4">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegocios maistres">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe4_id" as="select" ref={register} >
                                  <option>Selecione uma opção</option>
                                  <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                              );
                          }
                            return null;
                        })
                      }
                   </FormGroup>
                  </Col>
                </Row>
                  <Button className="btnEnviarNeg"
                          type="submit"
                          disabled={isLoading}>
                                Registrar Negócio
                  </Button>
                  <div className="clearfix" />
              </form>
            </div>
             );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.dados === 0)){
            return(
            <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
            </Row>
            )
          }
            return true;
        })
      }

<Alert show={show} className="alertFields">
  <Alert.Heading>Erro de cadastro</Alert.Heading>
  <p>
    Verifique se os campos <a href="#nome">Nome</a> e <a href="#cnpj">CNPJ</a> estão preenchidos! Eles são campos obrigatórios.
  </p>
  <hr />
  <div className="d-flex justify-content-end">
    <Button onClick={() => setShow(false)} className="btnOk">
      Ok!
    </Button>
  </div>
</Alert>
      
</div>
  );
}

export default RegisterBusiness;
