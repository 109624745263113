import React, { useEffect, useState, useContext,useCallback } from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import API from "utils/API";
import { Row,Modal, Col, FormGroup,Form, FormControl, Button } from "react-bootstrap";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import "../../assets/css/fbregister.css";
import ChangeFb from "../TempoReal/ChangeFb";
import Dropdown from 'react-bootstrap/Dropdown';
import { AuthUserContext } from "../../contexts/AuthUserContext";

function Criarfb(){
  const [negocios, setNegocios] = useState();
  const [facebooks, setFacebooks] = useState();
  const [facebook, setFacebook] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [usuarios, setUsuarios] = useState();
  const { authUser } = useContext(AuthUserContext);
  let empresa = "nome";
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("facebooks");
    run().then((response) => {
       setFacebooks(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
       setUsuarios(response);
    });
  }, []);


  const setForm = useCallback((n) => {
    const fieldKeys = ["id", "mes", "negocio_id"];
    const arrayKeys = Array.from({ length: 31 }, (_, i) => `array${i + 1}`);
    const priceKeys = Array.from({ length: 31 }, (_, i) => `price${i + 1}`);

    fieldKeys.forEach((key) => {
      setValue(key, n[key]);
    });

    arrayKeys.forEach((key) => {
      setValue(key, n[key]);
    });

    priceKeys.forEach((key) => {
      setValue(key, n[key]);
    });
  }, [setValue]);

  function refreshPage() {
    window.location.reload();
  }
  
  function enableD1(){
    document.getElementById("array1").disabled = "";
  }
  
  function enableD2(){
    document.getElementById("array2").disabled = "";
  }
      
  function enableD3(){
    document.getElementById("array3").disabled = "";
  }
        
  function enableD4(){
    document.getElementById("array4").disabled = "";
  }

  function enableD5(){
    document.getElementById("array5").disabled = "";
  }
        
  function enableD6(){
    document.getElementById("array6").disabled = "";
    }

  function enableD7(){
    document.getElementById("array7").disabled = "";
  }

  function enableD8(){
    document.getElementById("array8").disabled = "";
  }

  function enableD9(){
    document.getElementById("array9").disabled = "";
  }

  function enableD10(){
    document.getElementById("array10").disabled = "";
  }

  function enableD11(){
    document.getElementById("array11").disabled = "";
  }

  function enableD12(){
    document.getElementById("array12").disabled = "";
  }

  function enableD13(){
    document.getElementById("array13").disabled = "";
  }

  function enableD14(){
    document.getElementById("array14").disabled = "";
  }
    
  function enableD15(){
    document.getElementById("array15").disabled = "";
  }

  function enableD16(){
    document.getElementById("array16").disabled = "";
  } 

  function enableD17(){
    document.getElementById("array17").disabled = "";
  }
    
  function enableD18(){
    document.getElementById("array18").disabled = "";
  }

  function enableD19(){
    document.getElementById("array19").disabled = "";
  }

  function enableD20(){
    document.getElementById("array20").disabled = "";
  }

  function enableD21(){
    document.getElementById("array21").disabled = "";
  }
      
  function enableD22(){
    document.getElementById("array22").disabled = "";
  }   

  function enableD23(){
    document.getElementById("array23").disabled = "";
  }

  function enableD24(){
    document.getElementById("array24").disabled = "";
  }

  function enableD25(){
    document.getElementById("array25").disabled = "";
  }

  function enableD26(){
    document.getElementById("array26").disabled = "";
  }

  function enableD27(){
    document.getElementById("array27").disabled = "";
  }

  function enableD28(){
    document.getElementById("array28").disabled = "";
  }

  function enableD29(){
    document.getElementById("array29").disabled = "";
  }

  function enableD30(){
    document.getElementById("array30").disabled = "";
  }

  function enableD31(){
    document.getElementById("array31").disabled = "";
  }                                

  function enableP1(){
    document.getElementById("price1").disabled = "";
  }   

  function enableP2(){
    document.getElementById("price2").disabled = "";
  }

  function enableP3(){
    document.getElementById("price3").disabled = "";
  }  

  function enableP4(){
    document.getElementById("price4").disabled = "";
  }

  function enableP5(){
    document.getElementById("price5").disabled = "";
  }

  function enableP6(){
    document.getElementById("price6").disabled = "";
  }

  function enableP7(){
    document.getElementById("price7").disabled = "";
  }

  function enableP8(){
    document.getElementById("price8").disabled = "";
  }

  function enableP9(){
    document.getElementById("price9").disabled = "";
  }

  function enableP10(){
    document.getElementById("price10").disabled = "";
  }

  function enableP11(){
    document.getElementById("price11").disabled = "";
  }  

  function enableP12(){
    document.getElementById("price12").disabled = "";
  } 

  function enableP13(){
    document.getElementById("price13").disabled = "";
  }  

  function enableP14(){
    document.getElementById("price14").disabled = "";
  }  

  function enableP15(){
    document.getElementById("price15").disabled = "";
  }   

  function enableP16(){
    document.getElementById("price16").disabled = "";
  } 

  function enableP17(){
    document.getElementById("price17").disabled = "";
  } 

  function enableP18(){
    document.getElementById("price18").disabled = "";
  } 

  function enableP19(){
    document.getElementById("price19").disabled = "";
  }

  function enableP20(){
    document.getElementById("price20").disabled = "";
  }

  function enableP21(){
    document.getElementById("price21").disabled = "";
  }

  function enableP22(){
    document.getElementById("price22").disabled = "";
  }

  function enableP23(){
    document.getElementById("price23").disabled = "";
  }

  function enableP24(){
    document.getElementById("price24").disabled = "";
  }

  function enableP25(){
    document.getElementById("price25").disabled = "";
  }

  function enableP26(){
    document.getElementById("price26").disabled = "";
  }

  function enableP27(){
    document.getElementById("price27").disabled = "";
  }

  function enableP28(){
    document.getElementById("price28").disabled = "";
  }

  function enableP29(){
    document.getElementById("price29").disabled = "";
  }

  function enableP30(){
    document.getElementById("price30").disabled = "";
  }

  function enableP31(){
    document.getElementById("price31").disabled = "";
  }

  ///OUTRAS VARIÁVEIS
  function getYear() { ///PEGA O ANO
    return new Date().getFullYear();
  }
  const ano = getYear();

  function translateMonth() {
    const mes = new Date().getMonth() +1;
    if (mes === 1) {
      return "Janeiro";
    } else if (mes === 2) {
      return "Fevereiro";
    } else if (mes === 3) {
      return "Março";
    } else if (mes === 4) {
      return "Abril";
    } else if (mes === 5) {
      return "Maio";
    } else if (mes === 6) {
      return "Junho";
    } else if (mes === 7) {
      return "Julho";
    } else if (mes === 8) {
      return "Agosto";
    } else if (mes === 9) {
      return "Setembro";
    } else if (mes === 10) {
      return "Outubro";
    } else if (mes === 11) {
      return "Novembro";
    } else if (mes === 12) {
      return "Dezembro";
    }
  }

    /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[5])
    var neg = IdNegocio;
    return (neg);
  }

  useEffect(() => {
    if (show && facebook) {
      setForm(facebook);
    }
  }, [show, facebook, setForm]);

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return true;
    });
    return (empresa);
  }

  function onSubmit2(data) {
    const [run] = API.put(`facebooks/${facebook.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setFacebook(response);
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }


  function onSubmit(data) {
    const [run] = API.post("facebooks", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Leads adicionados com sucesso.");
        setIsLoading(false);        
        refreshPage();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  function openModal(matParam) {
    setFacebook(matParam);
    handleShow();
  }

  
  function formatDate(dateFromAPI) {
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);
      
    if(dateDay === "01" && dateMonth === "01" && dateYear === "00"){
      return "Sem registros feitos no mês atual";
    }else{
      return `${dateDay} de ${translateMonth(dateMonth)} de 20${dateYear}`;
    }
  }

  
  return(
  <div id="bodyNoticias">
    <MenuCompleto/>
    <div className="tituloMat">Gerenciar Facebook Ads</div>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id && (authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
            return(
              <div>
                <Dropdown>
                  <Dropdown.Toggle className="dropFb" id="dropdown-basic">
                  Cadastrar Leads 
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropFbhover">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={4}>
                            <label><div className="fbInput">Negócio*</div></label>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="selectionFb">
                              <FormControl  name="negocio_id" as="select" ref={register} >
                                {
                                  negocios && negocios.map((negocio) => (
                                    <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                    ))
                                }
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label><div className="fbInput">Mês*</div></label>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="selectionFb">
                              <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                                  <option>Janeiro</option>
                                  <option>Fevereiro</option>
                                  <option>Março</option>
                                  <option>Abril</option>
                                  <option>Maio</option>
                                  <option>Junho</option>
                                  <option>Julho</option>
                                  <option>Agosto</option>
                                  <option>Setembro</option>
                                  <option>Outubro</option>
                                  <option>Novembro</option>
                                  <option>Dezembro</option>
                              </Form.Control>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="w-100 justify-content-center ">
                          <Button className="botaoMat"
                            type="submit"
                            disabled={isLoading}>
                                Salvar
                          </Button>
                        </Row>
                        <div className="clearfix" />
                      </form>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <ChangeFb/>
                <div id="facebookAdd">
                  <table className="tableFacebooks">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Negócio</th>
                        <th className="text-center">Mês</th>
                        <th className="text-center">Última atualização</th>
                        <th className="text-center">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        facebooks && facebooks.map((facebook) => {
                          if (facebook.negocio_id.toString() === getNegocioId()) {
                          return(
                            <tr key={facebook.id}>
                              <td className="text-center">{facebook.id}</td>
                              <td className="text-center grayClass">{negocioEnvolvido(facebook.negocio_id)} </td>
                              <td className="text-center ">{facebook.mes}</td>
                              <td className="text-center grayClass">{formatDate(facebook.updated_at)}</td>
                              <td className="">
                                <button className="text-center fbBtn" onClick={openModal.bind(null, facebook)}>
                                    Editar
                                </button>
                              </td>
                            </tr>
                            );
                          }
                          return true;
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.facebooks === 0)){
            return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
      return true;
    })
  }

  <Modal className="edicaoFb" data-keyboard={false}  backdrop={false} show={show} >
    <Button className="closeButton" onClick={handleClose}>X</Button>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit2)}>
          <Row className="w-100 justify-content-center ">
            <div className="allinfo2">Informações Facebook Ads</div><br></br>
          </Row>
          <Row className="w-100 justify-content-center ">
            <div className="">* Para desbloquear os campos de Leads, clique no botão de editar <i className="fa fa-pencil"></i></div>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel d-none">Id:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputProjecoes3 d-none" id="id" placeholder= "3" ref={register} name="id" readOnly/>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={4}>
              <label><div className="fbInput">Negócio*</div></label>
            </Col>
            <Col md={4}>
              <FormGroup className="selectionFb">
                <FormControl  name="negocio_id" as="select" ref={register} >
                  {
                    negocios && negocios.map((negocio) => (
                      <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                    ))
                  }
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label><div className="fbInput">Mês*</div></label>
            </Col>
            <Col md={4}>
              <FormGroup className="selectionFb">
                <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                  <option>Janeiro</option>
                  <option>Fevereiro</option>
                  <option>Março</option>
                  <option>Abril</option>
                  <option>Maio</option>
                  <option>Junho</option>
                  <option>Julho</option>
                  <option>Agosto</option>
                  <option>Setembro</option>
                  <option>Outubro</option>
                  <option>Novembro</option>
                  <option>Dezembro</option>
                </Form.Control>
              </FormGroup>
            </Col>
          </Row>
          <Row className="vl">
            <Col md={8}>
              <label>Número de Leads  - {translateMonth()}/{ano}</label>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel">Dia 1:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputProjecoes3" id="array1" placeholder= "3" ref={register} name="array1" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD1} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 2:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array2" className="inputProjecoes3" placeholder= "3" ref={register} name="array2" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD2} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 3:</label>
            </Col>
            <Col md={1}> 
              <input type="string" id="array3" className="inputProjecoes3" placeholder= "3" ref={register} name="array3" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD3} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 4:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array4" className="inputProjecoes3" placeholder= "3" ref={register} name="array4" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD4} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 5:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array5" className="inputProjecoes3" placeholder= "3" ref={register} name="array5" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD5} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 6:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array6" className="inputProjecoes3" placeholder= "3" ref={register} name="array6" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD6} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 7:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array7" className="inputProjecoes3" placeholder= "3" ref={register} name="array7" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD7} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 8:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array8" className="inputProjecoes3" placeholder= "3" ref={register} name="array8" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD8} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 9:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array9" className="inputProjecoes3" placeholder= "3" ref={register} name="array9" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD9} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 10:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array10" className="inputProjecoes3" placeholder= "3" ref={register} name="array10" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD10} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 11:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array11" className="inputProjecoes3" placeholder= "3" ref={register} name="array11" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD11} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 12:</label>
            </Col>
            <Col md={1}>
              <input type="string"   id="array12" className="inputProjecoes3" placeholder= "3" ref={register} name="array12" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD12} className="btnEditPen" readOnly/>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel">Dia 13:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array13"  className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array13" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD13} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 14:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array14" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array14" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD14} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 15:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array15" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array15" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD15} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 16:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array16"  className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array16" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD16} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 17:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array17" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array17" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD17} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 18:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array18" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array18" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD18} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 19:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array19" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array19" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD19} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 20:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array20" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array20" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD20} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 21:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array21" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array21" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD21} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 22:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array22" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array22" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD22} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 23:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array23" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array23" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD23} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 24:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array24" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array24" disabled/>
            </Col> 
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD24} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 25:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array25" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array25" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD25} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 26:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array26" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array26" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD26} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 27:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array27" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array27" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD27} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 28:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array28" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array28" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD28} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 29:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array29" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array29" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD29} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 30:</label>
            </Col>
            <Col md={1}>
              <input type="string"  id="array30" className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array30" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD30} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel">Dia 31:</label>
            </Col>
            <Col md={1}>
              <input type="string" id="array31"  className="inputProjecoes3 mt-2" placeholder= "3" ref={register} name="array31" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableD31} className="btnEditPen" readOnly/>
            </Col>
          </Row>
          <Row className="vl">
            <Col md={8}>
              <label>Valores dos Leads  - {translateMonth()}/{ano}</label>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel2">Valor dia 1:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price1" id="price1" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP1} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 2:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price2" id="price2" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP2} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 3:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price3" id="price3" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP3} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 4:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price4" id="price4" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP4} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 5:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price5" id="price5" disabled/>
            </Col>
            <Col md={1}> 
              <input type="text"  placeholder="&#xf040;"  onClick={enableP5} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 6:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price6" id="price6" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP6} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 7:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00" className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price7" id="price7" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP7} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 8:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price8" id="price8" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP8} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 9:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price9" id="price9" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP9} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 10:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price10" id="price10" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP10} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 11:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price11" id="price11" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP11} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 12:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price12" id="price12" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP12} className="btnEditPen" readOnly/>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel2">Valor dia 13:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price13" id="price13" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP13} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 14:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price14" id="price14" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP14} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 15:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price15" id="price15" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP15} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 16:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00" className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price16" id="price16" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP16} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 17:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price17" id="price17" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP17} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 18:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price18" id="price18" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP18} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 19:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price19" id="price19"/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP19} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 20:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price20" id="price20"/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP20} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 21:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price21" id="price21" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP21} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 22:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price22" id="price22" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP22} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 23:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price23" id="price23" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP23} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 24:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price24" id="price24" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP24} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 25:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price25" id="price25" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP25} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 26:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price26" id="price26" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP26} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 27:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price27" id="price27" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP27} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 28:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00" className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price28" id="price28" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP28} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 29:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price29" id="price29" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP29} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 30:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"   className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price30" id="price30" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP30} className="btnEditPen" readOnly/>
            </Col>
            <Col md={1}>
              <label className="datalabel2">Valor dia 31:</label>
            </Col>
            <Col md={1}>
              <input type="number" step="0.01" min="0.00"  className="inputProjecoes3 mt-2" placeholder= "30,00" ref={register} name="price31" id="price31" disabled/>
            </Col>
            <Col md={1}>
              <input type="text"  placeholder="&#xf040;"  onClick={enableP31} className="btnEditPen" readOnly/>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center mt-4">
            <Col className="col-md-12 pt-3 text-center">
              <Button className="btnEditProj" type="submit"
                disabled={isLoading}>
                Registrar Facebook Ads
              </Button>
            </Col>
          </Row>
        </Form>
    </Modal.Body>
  </Modal>
</div>
  );
}

export default Criarfb;