import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import API from "utils/API";
import { Button, Col, Form, Modal, Row, FormGroup, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useCep } from "cep-hook";
import $ from "jquery";
import "../../assets/css/editNegocios.css"
import MenuCompleto from "../Admin/MenuCompleto";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import Alert from 'react-bootstrap/Alert';


function BussinessManager () {
  const { register, handleSubmit, setValue } = useForm();
  const [negocios, setNegocios] = useState();
  const [negocio, setNegocio] = useState();
  const [usuarios, setUsuarios] = useState();
  const [show, setShow] = useState(false);
  const [estado, setEstado] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filter, setFilter] = useState("");
  const [show5, setShow5] = useState(false);
  const handleShow5 = () => setShow5(true);
  const handleClose5 = () => setShow5(false);
  const [show6, setShow6] = useState(false);
  const handleShow6 = () => setShow6(true);
  const handleClose6 = () => setShow6(false);
  const [show7, setShow7] = useState(false);
  const handleShow7 = () => setShow7(true);
  const handleClose7 = () => setShow7(false);
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [deletar, setDeletar] = useState();
  const [ getZip] = useCep();
  const [cep, setCep] = useState(null);
  const fileNovaMidiaRef = useRef();
  const [pathh, setPathh] = useState();
  const [contrato, setContrato] = useState();
  const [briefing, setBriefing] = useState();
  const [idd, setIdd] = useState();
  const handleClickFindCep = async () => setCep(await getZip());
  var aux = 0;
  const [nameContrato, setNameContrato] = useState();
  const [briefingName, setBriefingName] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [showA, setShowA] = useState(false);

  function Search(event) {
    setFilter(event.target.value)
  }

  function EditarNegocioModal(negocioParam) {
    setNegocio(negocioParam);
    handleShow();
  }

  function DeletarNegocioModal(negocio) {
    setNegocio(negocio);
    setDeletar(negocio.id);
    handleShow4(negocio);
  }

  function AlterarLogoModal(negocio) {
    setIdd(negocio.id);
    setNegocio(negocio);
    setPathh(API.getBasePath() + negocio.path)
    handleShow5(negocio);
  }

  function ContratoNegocioModal(negocio) {
    setIdd(negocio.id);
    setNegocio(negocio);
    if(negocio.contrato !== null){
    aux = negocio.contrato.split("/");
    setNameContrato(aux[4]);
    }else{
      setNameContrato("Ainda não há arquivo");
    }
    setContrato(API.getBasePath() + negocio.contrato)
    handleShow6(negocio);
  }

  function BriefingNegocioModal(negocio) {
    setIdd(negocio.id);
    setNegocio(negocio);
    if(negocio.briefing !== null){
    aux = negocio.briefing.split("/");
    setBriefingName(aux[4]);
    }else{
      setBriefingName("Ainda não há arquivo");
    }
    setBriefing(API.getBasePath() + negocio.briefing)
    handleShow7(negocio);
  }

  function deleteThis(idd){
    const [run] = API.delete(`negocios/${idd}/deletar`);
      run().then(() => {
        refreshPage();
      });
  }

  const setForm = useCallback(
    (n) => {
      setValue("nome", n.nome);
      setValue("cnpj", n.cnpj);
      setValue("telefone", n.telefone);
      setValue("endereco", n.endereco);
      setValue("cidade", n.cidade);
      setValue("uf", n.uf);
      setValue("cep", n.cep);
      setValue("estado", n.estado);
      setValue("criacao", n.criacao);
      setValue("roteiros", n.roteiros);
      setValue("trafego", n.trafego);
      setValue("ads", n.ads);
      setValue("lp", n.lp);
      setValue("site", n.site);
      setValue("sistema", n.sistema);
      setValue("admin_id", n.admin_id);
      setValue("equipe_id", n.equipe_id);
      setValue("equipe1_id", n.equipe_id);
      setValue("equipe2_id", n.equipe_id);
      setValue("equipe3_id", n.equipe_id);
      setValue("equipe4_id", n.equipe_id);
      setValue("logo", n.logo);
    },
    [setValue]
  );


  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);


  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  useEffect(() => {
    if (show && negocio) {
      setForm(negocio);
    }
  }, [show, negocio, setForm]);



  $(document).ready(function() {
    $(".mais").hide();
    $(".maisum").hide();
    $(".maisdois").hide();
    $(".maistres").hide();
    $(".l1").hide();
    $(".l2").hide();
    $(".l3").hide();
    $(".l4").hide();
    $(".p2").hide();
    $(".p3").hide();
    $(".p4").hide();
  });

  function hideAndShow(){
    $(".mais").show();
    $(".l1").show();
    $(".p2").show();
  }

  function hideAndShow2(){
    $(".maisum").show();
    $(".l2").show();
    $(".p3").show();
  }

  function hideAndShow3(){
    $(".maisdois").show();
    $(".l3").show();
    $(".p4").show();
  }

  function hideAndShow4(){
    $(".maistres").show();
    $(".l4").show();
    $(".p4").show();
  }

  function updateNegocio(){
    const [run] = API.get("negocios");
    run().then((response) => {
      setNegocios(response);
    });
  }

  function submitChecked(data) {
    const [run] = API.put(`/negocios/${negocio.id}`, data);
    setIsLoading(true);
    run().then((response) => {
        setNegocio(response);
        notification.addNotification("Negócio atualizado com sucesso.");
        updateNegocio();
        handleClose();
      })
      .catch((error) => {
        console.log(error)
        setShowA(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onSubmit(data) {
    const [run] = API.get("negocios");
    run().then((response) => {
      const usuarioEncontrado = response[negocio.id-1].usuarios.find(usuario => usuario.id === Number(data.usuario_id));

      if (usuarioEncontrado) {
        console.log(`O usuário ${usuarioEncontrado.username} foi encontrado.`);
        data.usuario_id = ""
        console.log(data)
        submitChecked(data);
      } else {
        console.log("Nenhum usuário encontrado.");
        submitChecked(data);
      }
    })
  }

  function EditarLogoArte(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.midia = e.target.files[FIRST];
      const [run] = API.put(`negocios/${idd}/carregaLogo`, data);
      run().then(() => {
        refreshPage();
      });
    }
  }

  function createMidia1(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.contrato_file = e.target.files[FIRST];
      const [run] = API.put(`negocios/${idd}/carregaContrato`, data);
      run().then(() => {
        refreshPage();
      });
    }
  }

  function createMidia2(e) {
    const FIRST = 0;
    if (e.target.files[FIRST]) {
      const data = {};
      data.briefing_file = e.target.files[FIRST];
      const [run] = API.put(`negocios/${idd}/carregaBriefing`, data);
      run().then(() => {
        refreshPage();
      });
    }
  }


  function Showlist(negocioParam){
    return (
      <tr className="tr-border" key={negocioParam.id}>
        <td className="text-center">{negocioParam.id}</td>
        <td className="text-center">{negocioParam.nome}/{(negocioParam.cnpj)}</td>
        <td className="text-center">{negocioParam.usuarios.map((usuario) => " | " + (usuario.username) + " | ")}</td>
        <td className="text-center">{negocioParam.estado}</td>
        <td className="text-center">
          <button className="acoes editar" onClick={EditarNegocioModal.bind(null, negocioParam)}>
            Editar
          </button>
          <button className="acoes deletar" onClick={DeletarNegocioModal.bind(null, negocioParam)}>
            Deletar
          </button>
          <button className="acoes logo" onClick={AlterarLogoModal.bind(null, negocioParam)}>
            Logotipo
          </button>
        </td>
        <td className="text-center" >
          <button className="acoes contratos" onClick={ContratoNegocioModal.bind(null, negocioParam)}>
            Contrato
          </button>
          <button className="acoes logo" onClick={BriefingNegocioModal.bind(null, negocioParam)}>
            Briefing
          </button>
        </td>
      </tr>
    );
  }

  function refreshPage() {
    window.location.reload();
  }
 
  return(
  <div>
      <MenuCompleto/>
      <div className="tituloGerenciaUsers">Gerenciar Negócios</div>
      {  
        usuarios && usuarios.map((usuario) => {
        if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.negocios === 1))){
          return(
            <div id="gerenciaNegocio" key="gerenciaNegocio">
             <div className="filtering pb-3">
                Filtros:
                <button className="filter" onClick={() => setEstado("All")}>
                    <i className="fas fa-filter"/> Todos
                </button>

                <button className="filter" onClick={() => setEstado("Ativo")}>
                    <i className="fas fa-filter"/> Ativos
                </button>

                <button className="filter" onClick={() => setEstado("Inativo")}>
                     <i className="fas fa-filter"/> Inativo
                </button>

                <button className="filter" onClick={() => setEstado("Em negociação")}>
                    <i className="fas fa-filter"/> Em negociação
                </button>

                <div className="input-group input-group-sm mb-3 rounded srch col-2 float-right">
                  <input id="search_box" onChange={Search} placeholder="Pesquisar" type="search" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  <div className="input-group-prepend rounded">
                    <span className="rounded-right input-group-text" id="inputGroup-sizing-sm"><i className="fas fa-search"/></span>
                  </div>
                </div>
            
              </div>
              <table>
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Nome/CNPJ</th>
                    <th className="text-center">Responsáveis</th>
                    <th className="text-center">Estado</th>
                    <th className="text-center opcoesTd">Opções</th>
                    <th className="text-center">Arquivos</th>
                  </tr>
                </thead>

                <tbody>
                {
                  negocios && negocios
                    .slice() // Faz uma cópia do array para evitar alterar a ordem original dos negócios
                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os negócios pelo nome em ordem alfabética
                    .map((negocioParam) => {
                    if (filter === "") {
                      let bo;

                      if (estado === "Em negociação") { bo = "Em negociação"; }
                      else if (estado === "Ativo") { bo = "Ativo"; }
                      else if (estado === "Inativo") { bo = "Inativo"; }
                      else { bo = "All"; }

                      if (negocioParam.estado === bo || (bo === "All")) {
                        return Showlist(negocioParam);
                      }
                    }
                    else if ((negocioParam.nome.toString().includes(filter) || negocioParam.id.toString().includes(filter)) && negocioParam.estado !== "Inativo") {
                      return Showlist(negocioParam);
                    }

                    return true;
                  })
                }
                </tbody>

              </table>
            </div>
          );
        }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.negocios === 0)){
          return(
            <Row className="w-100 justify-content-center ">
              <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
            </Row>
          )
        }
          return true;
      })
    }


        

{/*--------------------------------------------------------------------------MODAIS EXTRAS-------------------------------------------------------------------------- */}

    <Modal className="edicaoNegocio" data-keyboard={true} backdrop={true} show={show} >
      <Button className="closeButton" onClick={handleClose}>X</Button>
        <Modal.Body>
          <div className="tituloENegocio">Editar negócio</div>
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={2}>
                  <label><div className="red">Nome*:</div></label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit" type="text" ref={register} name="nome"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label><div className="red">CNPJ*:</div></label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit" type="text" ref={register} name="cnpj"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Telefone:</label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit" type="text" ref={register} name="telefone"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Endereço:</label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit" defaultValue={cep && cep.logradouro} type="text" ref={register} name="endereco"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Cidade:</label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit"  defaultValue={cep && cep.localidade} type="text" ref={register} name="cidade"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Estado:</label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit" defaultValue={cep && cep.uf} type="text" ref={register} name="uf"></input>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>CEP:</label>
                </Col>
                <Col md={6}>
                  <input className="inputNegocioEdit"   onChange={e => setValue(e.target.value)}  type="text" ref={register} name="cep"></input>
                </Col>
                <Col md={2}>
                  <input   className="buttonNegocioEdit"  onClick={handleClickFindCep} type="text" name="buscacep" placeholder="Busca de CEP" ref={register} readOnly />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Serviços:</label>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="containerEditBuzz">Criação
                    <input type="checkbox" name="criacao" ref={register}/>
                  </label>
               </Col>
               <Col md={3}>
                  <label className="containerEditBuzz">Roteiros
                    <input type="checkbox" name="roteiros" ref={register}/>
                  </label>
                </Col>

                <Col md={3}>
                  <label className="containerEditBuzz"> Tráfego pago
                    <input type="checkbox" name="trafego" ref={register}/>
                  </label>
                </Col>
                <Col md={3}>
                  <label className="containerEditBuzz"> Google Ads
                    <input type="checkbox" name="ads" ref={register}/>
                  </label>
                 </Col>
                </Row>
                <Row>
                  <Col md={3}>
                          <label className="containerEditBuzz">Landing Page
                          <input type="checkbox" name="lp" ref={register}/>
                          </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEditBuzz">Site
                      <input type="checkbox" name="site" ref={register}/>
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="containerEditBuzz">Acesso ao sistema
                      <input type="checkbox" name="sistema" ref={register}/>
                    </label>
                   </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <label>Estado</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit">
                      <FormControl name="estado" as="select" ref={register} >
                        <option>{register}</option>
                        <option value="Ativo">Ativo</option>
                        <option value="Em negociação">Em negociação</option>
                        <option value="Inativo">Inativo</option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>


                <Row>
                <Col md={2}>
                 <label>Responsável</label>
                </Col>
                <Col md={6}>
                  <FormGroup  className="selectNegociosEdit">
                    <FormControl name="usuario_id" as="select" ref={register} >


                     {
                      usuarios && usuarios.map((usuario) => {
                       if (usuario.ativo === 1) {
                          return (
                           <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                          );
                        }
                      return null;
                      })
                      }

                    </FormControl>
                  </FormGroup>
                </Col>
                </Row>


                <Row>
                  <Col md={2}>
                   <label>Administrador</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit">
                      <FormControl name="admin_id" as="select" ref={register} >
                        {
                         usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "admin" && usuario.ativo === 1 ) {
                            return (
                              <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                              );
                          }
                            return null;
                          })
                        }
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <label>Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit">
                      <FormControl name="equipe_id" as="select" ref={register} >
                        {
                          usuarios && usuarios.map((usuario) => {
                            if (usuario.role === "equipe" && usuario.ativo === 1 ) {
                              return (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome_completo}</option>
                                  );
                                }
                                return null;
                              })
                            }

                          </FormControl>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                      <input className="plusMembrosEdit" placeholder="+ Equipe" onClick={hideAndShow} readOnly/>
                    </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l1">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit mais">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe1_id" as="select" ref={register} key="">
                                <option>Selecione uma opção</option>
                                <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                          );
                        }
                          return null;
                        })
                      }
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <input className="plusMembrosEdit p2" placeholder="+ Equipe" onClick={hideAndShow2} readOnly/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                   <label className="l2">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit maisum">
                          {
                            usuarios && usuarios.map((usuario) => {
                                if (usuario.role === "equipe" && usuario.ativo === 1) {
                                  return (
                                    <FormControl name="equipe2_id" as="select" ref={register} key="">
                                              <option>Selecione uma opção</option>
                                              <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                                    </FormControl>
                                 );
                                }
                          return null;
                        })
                      }
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                      <input className="plusMembrosEdit p3" placeholder="+ Equipe" onClick={hideAndShow3} readOnly/>
                    </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l3">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit maisdois">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe3_id" as="select" ref={register} key="">
                                <option>Selecione uma opção</option>
                                <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                            );
                          }
                          return null;
                        })
                      }
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <input className="plusMembrosEdit p4" placeholder="+ Equipe" onClick={hideAndShow4} readOnly/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="l4">Membro da equipe:</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup  className="selectNegociosEdit maistres">
                      {
                        usuarios && usuarios.map((usuario) => {
                          if (usuario.role === "equipe" && usuario.ativo === 1) {
                            return (
                              <FormControl name="equipe4_id" as="select" ref={register} key="">
                                <option>Selecione uma opção</option>
                                <option key={usuario.id}  defaultValue={0} value={usuario.id}>{usuario.nome_completo}</option>
                              </FormControl>
                           );
                          }
                          return null;
                        })
                      }
                    </FormGroup>
                  </Col>
                </Row>

                <Col md={12} className="text-center">
                  <Button className="btnEditNeg"
                        type="submit"
                        disabled={isLoading}>
                              Atualizar Negócio
                  </Button>
                </Col>
                <div className="clearfix" />
          </Form>
      </Modal.Body>
    </Modal>

    <Modal className="arquivosModal" data-keyboard={true} backdrop={true} show={show5} >
    <Button className="closeButton" onClick={handleClose5}>X</Button>
      <Modal.Body>
        <div className="logotipo">
          <Row className="w-100 justify-content-center">
            <img alt="fotoPerfil" className="perfile" src={pathh}/>
          </Row>
          <Row className="w-100 justify-content-center mt-3">
            <input  type="file" onChange={EditarLogoArte}  ref={fileNovaMidiaRef}/>
          </Row>
        </div>
      </Modal.Body>
    </Modal>

    <Modal className="arquivosModal" data-keyboard={true} backdrop={true} show={show6} >
    <Button className="closeButton" onClick={handleClose6}>X</Button>
      <Modal.Body>
        <div className="logotipo">
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <div className="downloadContrato">  <a href={`${contrato}?download=true`}
              target="_blank" rel="noopener noreferrer"
              > <i className="fas fa-file-download midiaIco"></i></a>
              </div>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <div className="nomeContrato">{nameContrato}</div>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <input type="file" onChange={createMidia1}  ref={fileNovaMidiaRef}/>
              </Col>
          </Row>
        </div>
        </Modal.Body>
    </Modal>

    <Modal className="arquivosModal" data-keyboard={true} backdrop={true} show={show7} >
    <Button className="closeButton" onClick={handleClose7}>X</Button>
      <Modal.Body>
        <div className="logotipo">
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <div className="downloadContrato">  <a href={`${briefing}?download=true`}
              target="_blank" rel="noopener noreferrer"
              > <i className="fas fa-file-download midiaIco"></i></a>
              </div>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <div className="nomeContrato">{briefingName}</div>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center">
            <Col md={12}>
              <input type="file" onChange={createMidia2}  ref={fileNovaMidiaRef}/>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>

    <Alert show={showA} className="alertFields">
      <Alert.Heading>Erro de cadastro</Alert.Heading>
        <p>
          Verifique se os campos <a href="#negocio">Negócio</a> e <a href="#cnpj">CPNJ</a> estão preenchidos! Eles são campos obrigatórios.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShowA(false)} className="btnOk">
            Ok!
          </Button>
        </div>
    </Alert>

    <Modal className="deleteAviso" data-keyboard={false} backdrop={false} show={show4} >
      <Button className="closeButton" onClick={handleClose4}>X</Button>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
          </Row>
          <Row className="w-100 justify-content-center">
            Tem certeza que deseja deletar o usuário?
          </Row>
          <Row className="w-100 justify-content-center">
            <div className="buttons">
              <button className="areYouSure" onClick={deleteThis.bind(null,deletar)}>Sim, deletar.</button>
              <button className="notSure" onClick={handleClose4}>Não, retornar.</button>
           </div>
          </Row>
        </Modal.Body>
    </Modal>
  </div>
    );
}
export default BussinessManager;