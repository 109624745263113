import React, {useState, useEffect} from "react";
import {Row} from "react-bootstrap";
import {Area, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart} from 'recharts';
import API from "utils/API";
import $ from "jquery";
import "../../assets/css/printCSS.css";


function PrintLeads(){
  const [leads, setLeads] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  var leadsDeJaneiro = 0;
  var leadsDeFevereiro = 0;
  var leadsDeMarco = 0;
  var leadsDeAbril = 0;
  var leadsDeMaio = 0;
  var leadsDeJunho = 0;
  var leadsDeJulho = 0;
  var leadsDeAgosto = 0; 
  var leadsDeSetembro = 0;
  var leadsDeOutubro = 0;
  var  leadsDeNovembro = 0;
  var leadsDeDezembro = 0;
  var CPLJaneiro = 0;
  var  CPLFevereiro = 0;
  var CPLMarco = 0;
  var CPLAbril = 0;
  var  CPLMaio = 0;
  var CPLJunho = 0;
  var CPLJulho = 0;
  var CPLAgosto = 0;
  var CPLSetembro = 0;
  var CPLOutubro = 0;
  var CPLNovembro = 0;
  var CPLDezembro = 0;
  var FbJaneiro = 0;
  var FbFevereiro = 0;
  var FbMarco = 0;
  var FbAbril = 0;
  var FbMaio = 0;
  var FbJunho = 0;
  var FbJulho = 0;
  var FbAgosto = 0;
  var FbSetembro = 0;
  var FbOutubro = 0;
  var FbNovembro = 0;
  var FbDezembro = 0;
  var adsJaneiro = 0;
  var adsFevereiro = 0;
  var  adsMarco = 0;
  var adsAbril = 0;
  var adsMaio = 0;
  var adsJunho = 0;
  var adsJulho = 0;
  var adsAgosto = 0;
  var adsSetembro = 0;
  var adsOutubro = 0;
  var adsNovembro = 0;
  var adsDezembro = 0;
  var totalJaneiro = 0;
  var totalFevereiro = 0;
  var totalMarco = 0;
  var totalAbril = 0; 
  var totalMaio = 0;
  var totalJunho = 0;
  var totalJulho = 0;
  var totalAgosto = 0;
  var totalSetembro = 0;
  var totalOutubro = 0;
  var totalNovembro = 0;
  var totalDezembro = 0;
  var isLead = "";

  useEffect(() => {
    const [run] = API.get("leads");
    run().then((response) => {
       setLeads(response);
    });
  }, []);

  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }

  function getData(){
    leads && leads.map((lead) =>{
      const it = window.location.href.split("/");
      const IdNegocio = (it[4]);
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.cliques === 0)
      {
        isLead = "Leads";
        leadsDeJaneiro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeJaneiro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeFevereiro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeFevereiro = lead.cliquesValue;
      }
    
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeMarco = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeMarco = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeAbril = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeAbril = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeMaio = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeMaio = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeJunho = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeJunho = lead.cliquesValue;
      }
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeJulho = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeJulho = lead.cliquesValue;
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeAgosto = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeAgosto = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeSetembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeSetembro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeOutubro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeOutubro = lead.cliquesValue;
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeNovembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeNovembro = lead.cliquesValue;
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.cliques === 0){
        isLead = "Leads";
        leadsDeDezembro = lead.numLead;
      }else if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro" && lead.cliques === 1){
        isLead = "Cliques";
        leadsDeDezembro = lead.cliquesValue;
      }

      return true;

    });
    
  }

  getData();

  function getDataDois(){
    leads && leads.map((lead) =>{
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro"){
        parseFloat(CPLJaneiro = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro"){
        parseFloat(CPLFevereiro = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março"){
        parseFloat(CPLMarco = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril"){
        parseFloat(CPLAbril = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio"){
        parseFloat(CPLMaio = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho"){
        parseFloat(CPLJunho = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho"){
        parseFloat(CPLJulho = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto"){
        parseFloat(CPLAgosto = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro"){
        parseFloat(CPLSetembro = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro"){
        parseFloat(CPLOutubro = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro"){
        parseFloat(CPLNovembro = lead.CPL);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro"){
        parseFloat(CPLDezembro = lead.CPL);
      }

      return true;
    });
    
  }

  getDataDois();

  function getDataTres(){
    leads && leads.map((lead) =>{
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro"){
        parseFloat(FbJaneiro = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro"){
        parseFloat(FbFevereiro = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março"){
        parseFloat(FbMarco = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril"){
        parseFloat(FbAbril = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio"){
        parseFloat(FbMaio = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho"){
        parseFloat(FbJunho = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho"){
        parseFloat(FbJulho = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto"){
        parseFloat(FbAgosto = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro"){
        parseFloat(FbSetembro = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro"){
        parseFloat(FbOutubro = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro"){
        parseFloat(FbNovembro = lead.investimentoFb);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro"){
        parseFloat(FbDezembro = lead.investimentoFb);
      }

      return true;
    });
  }

  getDataTres();

  function getDataQuatro(){
    leads && leads.map((lead) =>{
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro"){
        adsJaneiro = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro"){
        adsFevereiro = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março"){
        adsMarco = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril"){
        adsAbril = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio"){
        adsMaio = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho"){
        adsJunho = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho"){
        adsJulho = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto"){
        adsAgosto = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro"){
        adsSetembro = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro"){
        adsOutubro = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro"){
        adsNovembro = parseFloat(lead.investimentoAds);
      }

      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro"){
        adsDezembro = parseFloat(lead.investimentoAds);
      }
      
      return true;
    });
    
  }

  getDataQuatro();


  function getDataCinco(){
    leads && leads.map((lead) =>{
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Janeiro"){
        totalJaneiro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Fevereiro"){
        totalFevereiro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Março"){
        totalMarco = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Abril"){
        totalAbril = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Maio"){
        totalMaio = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Junho"){
        totalJunho = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Julho"){
        totalJulho = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Agosto"){
        totalAgosto = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Setembro"){
        totalSetembro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Outubro"){
        totalOutubro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Novembro"){
        totalNovembro = parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb);
      }
      
      if(lead.negocio_id.toString() ===  IdNegocio && lead.mes.toString() === "Dezembro"){
        totalDezembro = (parseFloat(lead.investimentoAds) + parseFloat(lead.investimentoFb));
      }
      return true;
    });
    
  }

  getDataCinco();

  var difLeadsDezembro =((((leadsDeJaneiro/leadsDeDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJaneiro =((((leadsDeFevereiro/leadsDeJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsFevereiro =((((leadsDeMarco/leadsDeFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsMarco = ((((leadsDeAbril/leadsDeMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsAbril = ((((leadsDeMaio/leadsDeAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsMaio =((((leadsDeJunho/leadsDeMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJunho = ((((leadsDeJulho/leadsDeJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsJulho = ((((leadsDeAgosto/leadsDeJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsAgosto = ((((leadsDeSetembro/leadsDeAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsSetembro = ((((leadsDeOutubro/leadsDeSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsOutubro = ((((leadsDeNovembro/leadsDeOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difLeadsNovembro = ((((leadsDeDezembro/leadsDeNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difLeadsDezembro = isFinite(difLeadsDezembro) ? difLeadsDezembro : 0.0;
  difLeadsJaneiro = isFinite(difLeadsJaneiro) ? difLeadsJaneiro : 0.0;
  difLeadsFevereiro = isFinite(difLeadsFevereiro) ? difLeadsFevereiro : 0.0;
  difLeadsMarco = isFinite(difLeadsMarco) ? difLeadsMarco : 0.0;
  difLeadsAbril = isFinite(difLeadsAbril) ? difLeadsAbril : 0.0;
  difLeadsMaio = isFinite(difLeadsMaio) ? difLeadsMaio : 0.0;
  difLeadsJunho = isFinite(difLeadsJunho) ? difLeadsJunho : 0.0;
  difLeadsJulho = isFinite(difLeadsJulho) ? difLeadsJulho : 0.0;
  difLeadsAgosto = isFinite(difLeadsAgosto) ? difLeadsAgosto : 0.0;
  difLeadsSetembro = isFinite(difLeadsSetembro) ? difLeadsSetembro : 0.0;
  difLeadsOutubro = isFinite(difLeadsOutubro) ? difLeadsOutubro : 0.0;
  difLeadsNovembro = isFinite(difLeadsNovembro) ? difLeadsNovembro : 0.0;

  var difCPLDezembro = ((((CPLJaneiro/CPLDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJaneiro = ((((CPLFevereiro/CPLJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLFevereiro = ((((CPLMarco/CPLFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLMarco = ((((CPLAbril/CPLMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLAbril = ((((CPLMaio/CPLAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLMaio = ((((CPLJunho/CPLMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJunho = ((((CPLJulho/CPLJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLJulho = ((((CPLAgosto/CPLJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLAgosto = ((((CPLSetembro/CPLAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLSetembro = ((((CPLOutubro/CPLSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLOutubro = ((((CPLNovembro/CPLOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difCPLNovembro = ((((CPLDezembro/CPLNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difCPLDezembro = isFinite(difCPLDezembro) ? difCPLDezembro : 0.0;
  difCPLJaneiro = isFinite(difCPLJaneiro) ? difCPLJaneiro : 0.0;
  difCPLFevereiro = isFinite(difCPLFevereiro) ? difCPLFevereiro : 0.0;
  difCPLMarco = isFinite(difCPLMarco) ? difCPLMarco : 0.0;
  difCPLAbril = isFinite(difCPLAbril) ? difCPLAbril : 0.0;
  difCPLMaio = isFinite(difCPLMaio) ? difCPLMaio : 0.0;
  difCPLJunho = isFinite(difCPLJunho) ? difCPLJunho : 0.0;
  difCPLJulho = isFinite(difCPLJulho) ? difCPLJulho : 0.0;
  difCPLAgosto = isFinite(difCPLAgosto) ? difCPLAgosto : 0.0;
  difCPLSetembro = isFinite(difCPLSetembro) ? difCPLSetembro : 0.0;
  difCPLOutubro = isFinite(difCPLOutubro) ? difCPLOutubro : 0.0;
  difCPLNovembro = isFinite(difCPLNovembro) ? difCPLNovembro : 0.0;

  var difFbDezembro = ((((FbJaneiro/FbDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJaneiro = ((((FbFevereiro/FbJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbFevereiro = ((((FbMarco/FbFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbMarco = ((((FbAbril/FbMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbAbril = ((((FbMaio/FbAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbMaio = ((((FbJunho/FbMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJunho = ((((FbJulho/FbJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbJulho = ((((FbAgosto/FbJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbAgosto = ((((FbSetembro/FbAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbSetembro = ((((FbOutubro/FbSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbOutubro = ((((FbNovembro/FbOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difFbNovembro = ((((FbDezembro/FbNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difFbDezembro = isFinite(difFbDezembro) ? difFbDezembro : 0.0;
  difFbJaneiro = isFinite(difFbJaneiro) ? difFbJaneiro : 0.0;
  difFbFevereiro = isFinite(difFbFevereiro) ? difFbFevereiro : 0.0;
  difFbMarco = isFinite(difFbMarco) ? difFbMarco : 0.0;
  difFbAbril = isFinite(difFbAbril) ? difFbAbril : 0.0;
  difFbMaio = isFinite(difFbMaio) ? difFbMaio : 0.0;
  difFbJunho = isFinite(difFbJunho) ? difFbJunho : 0.0;
  difFbJulho = isFinite(difFbJulho) ? difFbJulho : 0.0;
  difFbAgosto = isFinite(difFbAgosto) ? difFbAgosto : 0.0;
  difFbSetembro = isFinite(difFbSetembro) ? difFbSetembro : 0.0;
  difFbOutubro = isFinite(difFbOutubro) ? difFbOutubro : 0.0;
  difFbNovembro = isFinite(difFbNovembro) ? difFbNovembro : 0.0;


  var difAdsDezembro = ((((adsJaneiro/adsDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 }); 
  var difAdsJaneiro = ((((adsFevereiro/adsJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsFevereiro = ((((adsMarco/adsFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsMarco = ((((adsAbril/adsMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsAbril = ((((adsMaio/adsAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsMaio = ((((adsJunho/adsMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsJunho = ((((adsJulho/adsJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsJulho = ((((adsAgosto/adsJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsAgosto = ((((adsSetembro/adsAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 }); 
  var difAdsSetembro = ((((adsOutubro/adsSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsOutubro = ((((adsNovembro/adsOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difAdsNovembro = ((((adsDezembro/adsNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });


  difAdsDezembro = isFinite(difAdsDezembro) ? difAdsDezembro : 0.0;
  difAdsJaneiro = isFinite(difAdsJaneiro) ? difAdsJaneiro : 0.0;
  difAdsFevereiro = isFinite(difAdsFevereiro) ? difAdsFevereiro : 0.0;
  difAdsMarco = isFinite(difAdsMarco) ? difAdsMarco : 0.0;
  difAdsAbril = isFinite(difAdsAbril) ? difAdsAbril : 0.0;
  difAdsMaio = isFinite(difAdsMaio) ? difAdsMaio : 0.0;
  difAdsJunho = isFinite(difAdsJunho) ? difAdsJunho : 0.0;
  difAdsJulho = isFinite(difAdsJulho) ? difAdsJulho : 0.0;
  difAdsAgosto = isFinite(difAdsAgosto) ? difAdsAgosto : 0.0;
  difAdsSetembro = isFinite(difAdsSetembro) ? difAdsSetembro : 0.0;
  difAdsOutubro = isFinite(difAdsOutubro) ? difAdsOutubro : 0.0;
  difAdsNovembro = isFinite(difAdsNovembro) ? difAdsNovembro : 0.0;


  var difTotalDezembro = ((((totalJaneiro/totalDezembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJaneiro = ((((totalFevereiro/totalJaneiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalFevereiro = ((((totalMarco/totalFevereiro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalMarco = ((((totalAbril/totalMarco)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalAbril = ((((totalMaio/totalAbril)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalMaio = ((((totalJunho/totalMaio)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJunho = ((((totalJulho/totalJunho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalJulho = ((((totalAgosto/totalJulho)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalAgosto = ((((totalSetembro/totalAgosto)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalSetembro = ((((totalOutubro/totalSetembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalOutubro = ((((totalNovembro/totalOutubro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });
  var difTotalNovembro = ((((totalDezembro/totalNovembro)-1)*100).toFixed(2)).toLocaleString("pt-br", { "minimumFractionDigits": 2 });

  difTotalDezembro = isFinite(difTotalDezembro) ? difTotalDezembro : 0.0;
  difTotalJaneiro = isFinite(difTotalJaneiro) ? difTotalJaneiro : 0.0;
  difTotalFevereiro = isFinite(difTotalFevereiro) ? difTotalFevereiro : 0.0;
  difTotalMarco = isFinite(difTotalMarco) ? difTotalMarco : 0.0;
  difTotalAbril = isFinite(difTotalAbril) ? difTotalAbril : 0.0;
  difTotalMaio = isFinite(difTotalMaio) ? difTotalMaio : 0.0;
  difTotalJunho = isFinite(difTotalJunho) ? difTotalJunho : 0.0;
  difTotalJulho = isFinite(difTotalJulho) ? difTotalJulho : 0.0;
  difTotalAgosto = isFinite(difTotalAgosto) ? difTotalAgosto : 0.0;
  difTotalSetembro = isFinite(difTotalSetembro) ? difTotalSetembro : 0.0;
  difTotalOutubro = isFinite(difTotalOutubro) ? difTotalOutubro : 0.0;
  difTotalNovembro = isFinite(difTotalNovembro) ? difTotalNovembro : 0.0;

  const data = [
    {
      name: 'Jan.',
      Leads: leadsDeJaneiro,

    },
    {
      name: 'Fev.',
      Leads: leadsDeFevereiro,
    },
    {
      name: 'Mar.',
      Leads: leadsDeMarco,
    },
    {
      name: 'Abr.',
      Leads: leadsDeAbril,
    },
    {
      name: 'Maio',
      Leads: leadsDeMaio,
    },
    {
      name: 'Jun.',
      Leads: leadsDeJunho,
    },
    {
      name: 'Jul.',
      Leads: leadsDeJulho,
    },
    {
      name: 'Ago.',
      Leads: leadsDeAgosto,
    },
    {
      name: 'Set.',
      Leads: leadsDeSetembro,
    },
    {
      name: 'Out.',
      Leads: leadsDeOutubro,
    },
    {
      name: 'Nov.',
      Leads: leadsDeNovembro,
    },
    {
      name: 'Dez.',
      Leads: leadsDeDezembro,
    },
  ];
  
  const dataDois = [
    {
      name: 'Jan.',
      CPL: CPLJaneiro,

    },
    {
      name: 'Fev.',
      CPL: CPLFevereiro,
    },
    {
      name: 'Mar.',
      CPL: CPLMarco,
    },
    {
      name: 'Abr.',
      CPL: CPLAbril,
    },
    {
      name: 'Maio',
      CPL: CPLMaio,
    },
    {
      name: 'Jun.',
      CPL: CPLJunho,
    },
    {
      name: 'Jul.',
      CPL: CPLJulho,
    },
    {
      name: 'Ago.',
      CPL: CPLAgosto,
    },
    {
      name: 'Set.',
      CPL: CPLSetembro,
    },
    {
      name: 'Out.',
      CPL: CPLOutubro,
    },
    {
      name: 'Nov.',
      CPL: CPLNovembro,
    },
    {
      name: 'Dez.',
      CPL: CPLDezembro,
    },
  ];

  const dataTres = [
    {
      name: 'Jan.',
      Valor: FbJaneiro,

    },
    {
      name: 'Fev.',
      Valor: FbFevereiro,
    },
    {
      name: 'Mar.',
      Valor: FbMarco,
    },
    {
      name: 'Abr.',
      Valor: FbAbril,
    },
    {
      name: 'Maio',
      Valor: FbMaio,
    },
    {
      name: 'Jun.',
      Valor: FbJunho,
    },
    {
      name: 'Jul.',
      Valor: FbJulho,
    },
    {
      name: 'Ago.',
      Valor: FbAgosto,
    },
    {
      name: 'Set.',
      Valor: FbSetembro,
    },
    {
      name: 'Out.',
      Valor: FbOutubro,
    },
    {
      name: 'Nov.',
      Valor: FbNovembro,
    },
    {
      name: 'Dez.',
      Valor: FbDezembro,
    },
  ];

  const dataQuatro = [
    {
      name: 'Jan.',
      Investimento: adsJaneiro,

    },
    {
      name: 'Fev.',
      Investimento: adsFevereiro,
    },
    {
      name: 'Mar.',
      Investimento: adsMarco,
    },
    {
      name: 'Abr.',
      Investimento: adsAbril,
    },
    {
      name: 'Maio',
      Investimento: adsMaio,
    },
    {
      name: 'Jun.',
      Investimento: adsJunho,
    },
    {
      name: 'Jul.',
      Investimento: adsJulho,
    },
    {
      name: 'Ago.',
      Investimento: adsAgosto,
    },
    {
      name: 'Set.',
      Investimento: adsSetembro,
    },
    {
      name: 'Out.',
      Investimento: adsOutubro,
    },
    {
      name: 'Nov.',
      Investimento: adsNovembro,
    },
    {
      name: 'Dez.',
      Investimento: adsDezembro,
    },
  ];
  
  const dataCinco = [
    {
      name: 'Jan.',
      Total: totalJaneiro,

    },
    {
      name: 'Fev.',
      Total: totalFevereiro,
    },
    {
      name: 'Mar.',
      Total: totalMarco,
    },
    {
      name: 'Abr.',
      Total: totalAbril,
    },
    {
      name: 'Maio',
      Total: totalMaio,
    },
    {
      name: 'Jun.',
      Total: totalJunho,
    },
    {
      name: 'Jul.',
      Total: totalJulho,
    },
    {
      name: 'Ago.',
      Total: totalAgosto,
    },
    {
      name: 'Set.',
      Total: totalSetembro,
    },
    {
      name: 'Out.',
      Total: totalOutubro,
    },
    {
      name: 'Nov.',
      Total: totalNovembro,
    },
    {
      name: 'Dez.',
      Total: totalDezembro,
    },
  ];

  function colorChange(){
    if(difLeadsDezembro < 0){
      $(".dezLeads").css("color","red");
    }
    if(difLeadsJaneiro < 0){
      $(".janLeads").css("color","red");
    }
     if(difLeadsFevereiro < 0){
      $(".fevLeads").css("color","red");
    }
     if(difLeadsMarco < 0){
      $(".marLeads").css("color","red");
    }
     if(difLeadsAbril < 0 ){
      $(".abrilLeads").css("color","red");
    }
     if(difLeadsMaio < 0){
      $(".maioLeads").css("color","red");
    }
     if(difLeadsJunho < 0){
      $(".junhoLeads").css("color","red");
    }
     if(difLeadsJulho < 0){
      $(".julhoLeads").css("color","red");
    }
     if(difLeadsAgosto < 0){
      $(".agostoLeads").css("color","red");
    }
     if(difLeadsSetembro < 0){
      $(".setLeads").css("color","red");
    }
     if(difLeadsOutubro < 0){
      $(".outLeads").css("color","red");
    }
     if(difLeadsNovembro < 0){
      $(".novLeads").css("color","red");
    }
  }

  function changeColorCPL(){
    if(difCPLDezembro < 0){
      $(".dezCPL").css("color","blue");
    }
    if(difCPLJaneiro < 0){
      $(".janCPL").css("color","blue");
    }
     if(difCPLFevereiro < 0){
      $(".fevCPL").css("color","blue");
    }
     if(difCPLMarco < 0){
      $(".marCPL").css("color","blue");
    }
     if(difCPLAbril < 0 ){
      $(".abrilCPL").css("color","blue");
    }
     if(difCPLMaio < 0){
      $(".maioCPL").css("color","blue");
    }
     if(difCPLJunho < 0){
      $(".junhoCPL").css("color","blue");
    }
     if(difCPLJulho < 0){
      $(".julhoCPL").css("color","blue");
    }
     if(difCPLAgosto < 0){
      $(".agostoCPL").css("color","blue");
    }
     if(difCPLSetembro < 0){
      $(".setembroCPL").css("color","blue");
    }
     if(difCPLOutubro < 0){
      $(".outubroCPL").css("color","blue");
    }
     if(difCPLNovembro < 0){
      $(".novembroCPL").css("color","blue");
    }
  }

  function changeColorFb(){
    if(difFbDezembro < 0){
      $(".dezFb").css("color","red");
    }
    if(difFbJaneiro < 0){
      $(".janFb").css("color","red");
    }
     if(difFbFevereiro < 0){
      $(".fevFb").css("color","red");
    }
     if(difFbMarco < 0){
      $(".marFb").css("color","red");
    }
     if(difFbAbril < 0 ){
      $(".abrilFb").css("color","red");
    }
     if(difFbMaio < 0){
      $(".maioFb").css("color","red");
    }
     if(difFbJunho < 0){
      $(".junhoFb").css("color","red");
    }
     if(difFbJulho < 0){
      $(".julhoFb").css("color","red");
    }
     if(difFbAgosto < 0){
      $(".agostoFb").css("color","red");
    }
     if(difFbSetembro < 0){
      $(".setFb").css("color","red");
    }
     if(difFbOutubro < 0){
      $(".outFb").css("color","red");
    }
     if(difFbNovembro < 0){
      $(".novFb").css("color","red");
    }
  }

  function changeColorAds(){
    if(difAdsDezembro < 0){
      $(".adsDez").css("color","red");
    }
    if(difAdsJaneiro < 0){
      $(".adsJan").css("color","red");
    }
     if(difAdsFevereiro < 0){
      $(".adsFev").css("color","red");
    }
     if(difAdsMarco < 0){
      $(".adsMar").css("color","red");
    }
     if(difAdsAbril < 0 ){
      $(".adsAbril").css("color","red");
    }
     if(difAdsMaio < 0){
      $(".adsMaio").css("color","red");
    }
     if(difAdsJunho < 0){
      $(".adsJunho").css("color","red");
    }
     if(difAdsJulho < 0){
      $(".adsJulho").css("color","red");
    }
     if(difAdsAgosto < 0){
      $(".adsAgosto").css("color","red");
    }
     if(difAdsSetembro < 0){
      $(".adsSet").css("color","red");
    }
     if(difAdsOutubro < 0){
      $(".adsOut").css("color","red");
    }
     if(difAdsNovembro < 0){
      $(".adsNov").css("color","red");
    }
  }

  function changeColorTotal(){
    if(difTotalDezembro < 0){
      $(".totalDez").css("color","red");
    }
    if(difTotalJaneiro < 0){
      $(".totalJan").css("color","red");
    }
     if(difTotalFevereiro < 0){
      $(".totalFev").css("color","red");
    }
     if(difTotalMarco < 0){
      $(".totalMar").css("color","red");
    }
     if(difTotalAbril < 0 ){
      $(".totalAbril").css("color","red");
    }
     if(difTotalMaio < 0){
      $(".totalMaio").css("color","red");
    }
     if(difTotalJunho < 0){
      $(".totalJunho").css("color","red");
    }
     if(difTotalJulho < 0){
      $(".totalJulho").css("color","red");
    }
     if(difTotalAgosto < 0){
      $(".totalAgosto").css("color","red");
    }
     if(difTotalSetembro < 0){
      $(".totalSetembro").css("color","red");
    }
     if(difTotalOutubro < 0){
      $(".totalOut").css("color","red");
    }
     if(difTotalNovembro < 0){
      $(".totalNov").css("color","red");
    }
  }

  return(
    <div>
    <div id="printLead">
      <Row className="w-100 justify-content-center titleConsolidados">Resultados consolidados ({getYear()})</Row>
        <div className="backgroundLeadsPrint">
          <div className="tableresultsPrint">
            <table className="tableLeadsPrint">
              <thead>
                <tr>
                  <th><div className="firstColumnPrint"></div></th>
                  <th className="monthsEachPrint">Jan.</th>
                  <th className="monthsEachPrint">Fev.</th>
                  <th className="monthsEachPrint">Mar.</th>
                  <th className="monthsEachPrint">Abr.</th>
                  <th className="monthsEachPrint">Maio</th>
                  <th className="monthsEachPrint">Jun.</th>
                  <th className="monthsEachPrint">Jul.</th>
                  <th className="monthsEachPrint">Ago.</th>
                  <th className="monthsEachPrint">Set.</th>
                  <th className="monthsEachPrint">Out.</th>
                  <th className="monthsEachPrint">Nov.</th>
                  <th className="monthsEachPrint">Dez.</th>
                </tr>
              </thead>
              <tbody>
                <tr className="grayy">
                  <td><div className="firstColumnPrint"><b>{isLead}</b></div></td>
                  <td>{leadsDeJaneiro}</td>
                  <td>{leadsDeFevereiro}</td>
                  <td>{leadsDeMarco}</td>
                  <td>{leadsDeAbril}</td>
                  <td>{leadsDeMaio}</td>
                  <td>{leadsDeJunho}</td>
                  <td>{leadsDeJulho}</td>
                  <td>{leadsDeAgosto}</td>
                  <td>{leadsDeSetembro}</td>
                  <td >{leadsDeOutubro}</td>
                  <td>{leadsDeNovembro}</td>
                  <td>{leadsDeDezembro}</td>
                </tr>
                <tr className="grayy" onLoad={colorChange()}>
                  <td><div className="firstColumnPrint">Diferença de {isLead} </div></td>
                  <td className="dezLeads">{parseFloat(difLeadsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="janLeads">{parseFloat(difLeadsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="fevLeads">{parseFloat(difLeadsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="marLeads">{parseFloat(difLeadsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="abrilLeads">{parseFloat(difLeadsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="maioLeads">{parseFloat(difLeadsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="junhoLeads">{parseFloat(difLeadsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="julhoLeads">{parseFloat(difLeadsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="agostoLeads">{parseFloat(difLeadsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="setLeads">{parseFloat(difLeadsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="outLeads">{parseFloat(difLeadsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="novLeads">{parseFloat(difLeadsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                </tr>
                <tr>
                  <td><div className="firstColumnPrint"><b>CPL</b></div></td>
                  <td>R$ {parseFloat(CPLJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(CPLDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                </tr>
                <tr onLoad={changeColorCPL()}>
                  <td><div className="firstColumnPrint"> Diferença de CPL</div></td>
                  <td className="dezCPL">{parseFloat(difCPLDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="janCPL">{parseFloat(difCPLJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="fevCPL">{parseFloat(difCPLFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="marCPL">{parseFloat(difCPLMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="abrilCPL">{parseFloat(difCPLAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="maioCPL">{parseFloat(difCPLMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="junhoCPL">{parseFloat(difCPLJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="julhoCPL">{parseFloat(difCPLJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="agostoCPL">{parseFloat(difCPLAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="setembroCPL">{parseFloat(difCPLSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="outubroCPL" >{parseFloat(difCPLOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="novembroCPL">{parseFloat(difCPLNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                </tr>
                <tr className="grayy"> 
                  <td><div className="firstColumnPrint"><b>Investimento Facebook</b></div></td>
                  <td>R$ {parseFloat(FbJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(FbDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                </tr>
                <tr className="grayy" onLoad={changeColorFb()}>
                  <td><div className="firstColumnPrint">Diferença de Investimento</div></td>
                  <td className="dezFb">{parseFloat(difFbDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="janFb">{parseFloat(difFbJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="fevFb">{parseFloat(difFbFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="marFb">{parseFloat(difFbMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="abrilFb">{parseFloat(difFbAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="maioFb">{parseFloat(difFbMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="junhoFb">{parseFloat(difFbJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="julhoFb">{parseFloat(difFbJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="agostoFb">{parseFloat(difFbAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="setFb">{parseFloat(difFbSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="outFb">{parseFloat(difFbOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="novFb">{parseFloat(difFbNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                </tr>
                <tr>
                  <td><div className="firstColumnPrint"><b>Investimento Google</b></div></td>
                  <td>R$ {parseFloat(adsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(adsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                </tr>
                <tr onLoad={changeColorAds()}>
                  <td><div className="firstColumnPrint">Diferença de Investimento </div></td>
                  <td className="adsDez">{parseFloat(difAdsDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsJan">{parseFloat(difAdsJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsFev">{parseFloat(difAdsFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsMar">{parseFloat(difAdsMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsAbril">{parseFloat(difAdsAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsMaio">{parseFloat(difAdsMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsJunho">{parseFloat(difAdsJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsJulho">{parseFloat(difAdsJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsAgosto">{parseFloat(difAdsAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsSet">{parseFloat(difAdsSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsOut">{parseFloat(difAdsOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="adsNov">{parseFloat(difAdsNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                </tr>
                <tr className="grayy">
                  <td><div className="firstColumnPrint"><b>Investimento Total</b></div></td>
                  <td>R$ {parseFloat(totalJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                  <td>R$ {parseFloat(totalDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</td>
                </tr>
                <tr className="grayy" onLoad={changeColorTotal()}>
                  <td><div className="firstColumnPrint">Diferença de Investimento</div></td>
                  <td className="totalDez">{parseFloat(difTotalDezembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalJan">{parseFloat(difTotalJaneiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalFev">{parseFloat(difTotalFevereiro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalMar">{parseFloat(difTotalMarco).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalAbril">{parseFloat(difTotalAbril).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalMaio">{parseFloat(difTotalMaio).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalJunho">{parseFloat(difTotalJunho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalJulho">{parseFloat(difTotalJulho).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalAgosto">{parseFloat(difTotalAgosto).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalSetembro">{parseFloat(difTotalSetembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalOut">{parseFloat(difTotalOutubro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                  <td className="totalNov">{parseFloat(difTotalNovembro).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      <Row>
        <div className="graficoAPrint">           
          <div className="titleChartsPrint">Quantidade de Leads</div> 
            <AreaChart
              width={400}
              height={250}
              data={data}
              margin={{
                top: 26,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500} fontSize={15} />
              <YAxis  axisLine={{ stroke: '#000' }} stroke="#000"   fontWeight={500} fontSize={15}/>
              <Tooltip />
              <Area   className="lineshadowLeads" type="monotone" dataKey="Leads" stackId="1" stroke="#d5375a" fill="#d5375a" />
            </AreaChart>
          </div>
          <div className="graficoBPrint"> 
            <div className="titleChartsPrint">CPL (R$)</div>
            <AreaChart
              width={400}
              height={250}
              data={dataDois}
              margin={{
                top: 26,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500} fontSize={15}/>
              <YAxis  axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500} fontSize={15}/>
              <Tooltip />
              <Area   className="lineshadowLeads" type="monotone" dataKey="CPL" stackId="1" stroke="#d5375a" fill="#d5375a" />
            </AreaChart>
          </div>
      </Row>
    </div>
    <div id="PrintLeads2">
      <Row>
        <div className="graficoAPrint">  
          <div className="titleChartsPrint2">Investimento Consolidado Facebook (R$)</div>
            <AreaChart
              width={400}
              height={250}
              data={dataTres}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500}  fontSize={15}/>
              <YAxis  axisLine={{ stroke: '#000' }} stroke="#000"   fontWeight={500}  fontSize={15}/>
              <Tooltip />
              <Area   className="lineshadowLeads" type="monotone" dataKey="Valor" stackId="1" stroke="#d5375a" fill="#d5375a" />
            </AreaChart>

        </div>
        <div className="graficoBPrint2"> 
          <div className="titleChartsPrint2">Investimento Consolidado Google (R$)</div>
            <AreaChart
              width={400}
              height={250}
              data={dataQuatro}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500} fontSize={15} />
              <YAxis  axisLine={{ stroke: '#000' }} stroke="#000"   fontWeight={500} fontSize={15}/>
              <Tooltip />
              <Area   className="lineshadowLeads" type="monotone" dataKey="Investimento" stackId="1" stroke="#d5375a" fill="#d5375a" />
            </AreaChart>
        </div>
      </Row>
      <Row>
        <div className="graficoCPrint"> 
          <div className="titleChartsPrint2">Investimento Total (FB + ADS) (R$)</div>
            <AreaChart
              width={800}
              height={250}
              data={dataCinco}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={{ stroke: '#000' }} stroke="#000" fontWeight={500}  fontSize={15}/>
              <YAxis  axisLine={{ stroke: '#000' }} stroke="#000"  fontWeight={500}  fontSize={15}/>
              <Tooltip />
              <Area   className="lineshadowLeads" type="monotone" dataKey="Total" stackId="1" stroke="#d5375a" fill="#d5375a" />
            </AreaChart>
        </div>
      </Row>
    </div>
  </div>
   );
}

   PrintLeads.propTypes = {};
   export default PrintLeads;
   