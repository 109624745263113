import React, { useContext, useState } from "react";
import { AuthUserContext } from "../contexts/AuthUserContext.js";
import "../assets/css/dashboard.css"
import { Route, Switch, Redirect } from "react-router-dom";

function Dashboard (props) {

  const { authUser } = useContext(AuthUserContext);
  const [routes] = useState();

  function getRoutes() {
    if (routes) {
      return routes.map((group) => group.routes.map((route) => (
        <Route
          path={route.path}
          render={() => <route.component {...props} />}
          key={route.path}
        />
      )));
    }
    return null;
  }

  return (
    <div id="contentDash">
      {
        authUser.role === "cliente" &&
          <Switch>
            {getRoutes(routes)}
            <Route exact path="/dashboard">
              <Redirect to="selectUsuario" />
            </Route>
            <Route exact path="/login">             
             <Redirect to="selectUsuario" />
            </Route>
          </Switch>
      }
      {
        authUser.role !== "cliente"  &&
          <>      
            <Route exact path="/dashboard">
              <Redirect to="gerenciar_noticias" />
            </Route>
          </>
      }
    </div>
  );
}
Dashboard.propTypes = {
};
export default Dashboard;
