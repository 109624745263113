import React, { useState, useEffect} from "react";
import API from "utils/API";
import "../../assets/css/calendarioPrint.css";
import { Row } from "react-bootstrap";

function PrintPlannerPublicacao(){

  const [planejamentos, setPlanejamentos] = useState();
  const [negocios, setNegocios] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  var completePath, completePath1, completePath2, completePath3, completePath4, completePath5, completePath6, completePath7, completePath8, completePath9, completePath10, completePath11, completePath12, completePath13, completePath14 = '0';
  var  completePath15, completePath16, completePath17, completePath18, completePath19, completePath20, completePath21, completePath22, completePath23, completePath24, completePath25, completePath26 = '0';
  var completePath27,completePath28,completePath29,completePath30 = '0';
  let negocioId = "2";
  var arr = [31];
  var cont = 0;
  var negocioNome = "Nome";
 
  function Pic(){
    planejamentos && planejamentos.map((planejamento) => {
      if((planejamento.negocio_id.toString() === getNegocioId()) && (planejamento.mes === getMonthTranslate() || planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase()) ){
        if(planejamento.path !== null){
          completePath = API.getBasePath() + planejamento.path;
        }else{
          completePath = "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_1 !== null){
          completePath1 = API.getBasePath() + planejamento.path_1;
        }else{
          completePath1= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_2 !== null){
          completePath2 = API.getBasePath() + planejamento.path_2;
        }else{
          completePath2= "https://i.imgur.com/8DZqWIW.png";
        }
        
        if(planejamento.path_2 !== null){
          completePath2 = API.getBasePath() + planejamento.path_2;
        }else{
          completePath2= "https://i.imgur.com/8DZqWIW.png";
        } 
        
        if(planejamento.path_3 !== null){
          completePath3 = API.getBasePath() + planejamento.path_3;
        }else{
          completePath3= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_4 !== null){
          completePath4 = API.getBasePath() + planejamento.path_4;
        }else{
          completePath4= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_5 !== null){
          completePath5 = API.getBasePath() + planejamento.path_5;
        }else{
          completePath5= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_6 !== null){
          completePath6 = API.getBasePath() + planejamento.path_6;
        }else{
          completePath6= "https://i.imgur.com/8DZqWIW.png";
        }

       if(planejamento.path_7 !== null){
        completePath7 = API.getBasePath() + planejamento.path_7;
        }else{
        completePath7= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_8 !== null){
          completePath8 = API.getBasePath() + planejamento.path_8;
        }else{
          completePath8= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_9 !== null){
          completePath9 = API.getBasePath() + planejamento.path_9;
        }else{
          completePath9= "https://i.imgur.com/8DZqWIW.png";
        }
  
        if(planejamento.path_10 !== null){
          completePath10 = API.getBasePath() + planejamento.path_10;
        }else{
          completePath10= "https://i.imgur.com/8DZqWIW.png";
        }
    
        if(planejamento.path_11 !== null){
          completePath11 = API.getBasePath() + planejamento.path_11;
        }else{
          completePath11= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_12 !== null){
          completePath12 = API.getBasePath() + planejamento.path_12;
        }else{
          completePath12= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_13 !== null){
          completePath13 = API.getBasePath() + planejamento.path_13;
        }else{
          completePath13= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_14 !== null){
          completePath14 = API.getBasePath() + planejamento.path_14;
        }else{
          completePath14= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_15 !== null){
          completePath15 = API.getBasePath() + planejamento.path_15;
        }else{
          completePath15= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_16 !== null){
          completePath16 = API.getBasePath() + planejamento.path_16;
        }else{
          completePath16= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_17 !== null){
          completePath17 = API.getBasePath() + planejamento.path_17;
        }else{
          completePath17= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_18 !== null){
          completePath18 = API.getBasePath() + planejamento.path_18;
        }else{
          completePath18= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_19 !== null){
          completePath19 = API.getBasePath() + planejamento.path_19;
        }else{
          completePath19= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_20 !== null){
          completePath20 = API.getBasePath() + planejamento.path_20;
        }else{
          completePath20= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_21 !== null){
          completePath21 = API.getBasePath() + planejamento.path_21;
        }else{
          completePath21= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_22 !== null){
          completePath22 = API.getBasePath() + planejamento.path_22;
        }else{
          completePath22= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_23 !== null){
          completePath23 = API.getBasePath() + planejamento.path_23;
        }else{
          completePath23= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_24 !== null){
          completePath24 = API.getBasePath() + planejamento.path_24;
        }else{
          completePath24= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_25 !== null){
          completePath25= API.getBasePath() + planejamento.path_25;
        }else{
          completePath25= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_26 !== null){
          completePath26 = API.getBasePath() + planejamento.path_26;
        }else{
          completePath26= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_27 !== null){
          completePath27 = API.getBasePath() + planejamento.path_27;
        }else{
          completePath27= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_28 !== null){
          completePath28 = API.getBasePath() + planejamento.path_28;
        }else{
          completePath28= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_29 !== null){
          completePath29 = API.getBasePath() + planejamento.path_29;
        }else{
          completePath29= "https://i.imgur.com/8DZqWIW.png";
        }

        if(planejamento.path_30 !== null){
          completePath30 = API.getBasePath() + planejamento.path_30;
        }else{
          completePath30= "https://i.imgur.com/8DZqWIW.png";
        }

      }
      return true;
    })
    
  }

      
  /*Retorna a Id do negócio selecionado */
 function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]);
    negocioId = IdNegocio;
    return negocioId;
  }

  useEffect(() => {
    const [run] = API.get("negocios");
      run().then((response) => {
          setNegocios(response);
      });
  }, []);
  
  useEffect(() => { 
    const [run] = API.get("planejamentos");
      run().then((response) => {
          setPlanejamentos(response);
      });
  }, []);

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }
  
  function getMonthTranslate() {
    var mes = getMonth();
    if (mes === 1) {
      return "Janeiro";
    } else if (mes === 2) {
      return "Fevereiro";
    } else if (mes === 3) {
        return "Março";
    } else if (mes === 4) {
        return "Abril";
    } else if (mes === 5) {
        return "Maio";
    } else if (mes === 6) {
        return "Junho";
    } else if (mes === 7) {
        return "Julho";
    } else if (mes === 8) {
        return "Agosto";
    } else if (mes === 9) {
        return "Setembro";
    } else if (mes === 10 || mes === -2) {
        return "Outubro";
    } else if (mes === 11 || mes === -1) {
        return "Novembro";
    } else if (mes === 12 || mes === 0) {
        return "Dezembro";
      }
  }
  
  Pic();

  function arr_cont(conta){
    cont=0;
    for (cont =1; cont<= conta; cont = cont +1){
      arr.push(cont);
    }
    if(cont< 31){
      for(cont; cont <=31;cont++){
        arr.push(null);
      }
    }
    return arr;
  }

  function getNome(){
    negocios && negocios.map((negocio) => {
      if(negocio.id.toString() === getNegocioId()){
         negocioNome = negocio.nome.toString();
      }
      return negocioNome;
    });
    return negocioNome;
  }

return(
  <div>
    <div id="contentPlannerPrint">
      <Row className="w-100 justify-content-center">
        <div className="tituloPrintPlanner">Calendário de publicações {getMonthTranslate()}</div>
      </Row>
      <Row className="w-100 justify-content-center">
        <div className="nomeNegTitulo"> {getNome()}</div>
      </Row>
      <div id="calendarGrid">
        <header>
          <div className="weekdaysPrint">
            <Row className="w-100 justify-content-center">
              <div className="monthCalendar">{getMonthTranslate()}</div>
            </Row>
            <ul>
              <li>Domingo</li>
              <li>Segunda-feira</li>
              <li>Terça-feira</li>
              <li>Quarta-feira</li>
              <li> Quinta-feira</li>
              <li>Sexta-feira</li>
              <li>Sábado</li>
            </ul>
          </div>
        </header>
        <div>
          <div>
            {
              planejamentos && planejamentos.map((planejamento) => {
                getNegocioId();
                  if(planejamento.negocio_id.toString() === negocioId && planejamento.mes === getMonthTranslate() && planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase() ){
                    if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Domingo'){
                      arr_cont(planejamento.duracao) 
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                                <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                                <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagemPrint" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                                <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                                <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><br></br><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              </ul>
                            </div>   
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Segunda-feira'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div> </div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                                <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                                <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                                <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                              </ul>
                            </div> 
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Terça-feira'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                                <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                                <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>

                              </ul>
                            </div>
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quarta-feira'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                                <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                              </ul>
                            </div>
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quinta-feira'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                              </ul>
                            </div>
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sexta-feira'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                              </ul>
                            </div>
                          </div>
                        );
                    }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sábado'){
                      arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="bodyCaloffPrint"></div></li>
                                <li><div className="dayPrint">{arr[1]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_1}</div></div></li>
                                <li><div className="dayPrint">{arr[2]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath1} alt="Post dia 2"></img><div className="legendPrintPlan">{planejamento.texto_2}</div></div></li>
                                <li><div className="dayPrint">{arr[3]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath2} alt="Post dia 3"></img><div className="legendPrintPlan">{planejamento.texto_3}</div></div></li>
                                <li><div className="dayPrint">{arr[4]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath3} alt="Post dia 4"></img><div className="legendPrintPlan">{planejamento.texto_4}</div></div></li>
                                <li><div className="dayPrint">{arr[5]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath4} alt="Post dia 5"></img><div className="legendPrintPlan">{planejamento.texto_5}</div></div></li>
                                <li><div className="dayPrint">{arr[6]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath5} alt="Post dia 6"></img><div className="legendPrintPlan">{planejamento.texto_6}</div></div></li>
                                <li><div className="dayPrint">{arr[7]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath6} alt="Post dia 7"></img><div className="legendPrintPlan">{planejamento.texto_7}</div></div></li>
                                <li><div className="dayPrint">{arr[8]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath7} alt="Post dia 8"></img><div className="legendPrintPlan">{planejamento.texto_8}</div></div></li>     
                              </ul>
                            </div>
                          </div>
                        );
                    }
                  }
                    return true;
                })
            }   
          </div>
        </div>
      </div>
    </div>
    <div id="contentPlannerPrintBottom">
      <div id="calendarGrid">
        <header>
          <div className="weekdaysPrint">
            <Row className="w-100 justify-content-center">
              <div className="monthCalendar">{getMonthTranslate()} </div>
            </Row>
            <ul>
              <li>Domingo</li>
              <li>Segunda-feira</li>
              <li>Terça-feira</li>
              <li>Quarta-feira</li>
              <li>Quinta-feira</li>
              <li>Sexta-feira</li>
              <li>Sábado</li>
            </ul>
          </div>
        </header>
          <div>
            <div>
              {
                planejamentos && planejamentos.map((planejamento) =>{
                getNegocioId();
                if(planejamento.negocio_id.toString() === negocioId && planejamento.mes === getMonthTranslate() && planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase() ){
                  if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Domingo'){
                    return(
                      <div  className="Cal-endarPrint">
                        <ul className="daysPrint">
                          <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><br></br><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                          <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><br></br><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                          <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                          <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                          <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                          <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                          <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                          <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                          <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                          <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                          <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                          <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                          <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                          <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                        </ul>
                      </div>
                  );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Segunda-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div>
                          <div className="Cal-endarPrint">
                            <ul className="daysPrint">
                              <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                              <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                              <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                              <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                              <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                              <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                              <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                              <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                              <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                              <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                              <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                              <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                            </ul>
                        </div>
                        </div>
                      );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Terça-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div>
                          <div className="Cal-endarPrint">
                            <ul className="daysPrint">
                              <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                              <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                              <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                              <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                              <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                              <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                              <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                              <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                              <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                              <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                              <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                              <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                            </ul>
                          </div>
                        </div>
                      );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quarta-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div>
                          <div className="Cal-endarPrint">
                            <ul className="daysPrint">
                              <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                              <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                              <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                              <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                              <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                              <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                              <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                              <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                              <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                              <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                              <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                              <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                            </ul>
                          </div>
                        </div>
                      );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quinta-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div>
                          <div className="Cal-endarPrint">
                            <ul className="daysPrint">
                              <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                              <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                              <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                              <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                              <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                              <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                              <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                              <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                              <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                              <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                              <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                              <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                              <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                            </ul>
                          </div> 
                        </div>
                      );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sexta-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div>
                          <div className="Cal-endarPrint">
                            <ul className="daysPrint">
                              <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                              <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                              <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                              <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                              <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                              <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                              <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                              <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                              <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                              <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                              <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                              <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                              <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                            </ul>
                          </div>   
                        </div>
                    );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sábado'){
                    arr_cont(planejamento.duracao)
                        return(
                          <div>
                            <div className="Cal-endarPrint">
                              <ul className="daysPrint">
                                <li><div className="dayPrint">{arr[9]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath8} alt="Post dia 9"></img><div className="legendPrintPlan">{planejamento.texto_9}</div></div></li>
                                <li><div className="dayPrint">{arr[10]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath9} alt="Post dia 10"></img><div className="legendPrintPlan">{planejamento.texto_10}</div></div></li>
                                <li><div className="dayPrint">{arr[11]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath10} alt="Post dia 11"></img><div className="legendPrintPlan">{planejamento.texto_11}</div></div></li>
                                <li><div className="dayPrint">{arr[12]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath11} alt="Post dia 12"></img><div className="legendPrintPlan">{planejamento.texto_12}</div></div></li>
                                <li><div className="dayPrint">{arr[13]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath12} alt="Post dia 13"></img><div className="legendPrintPlan">{planejamento.texto_13}</div></div></li>
                                <li><div className="dayPrint">{arr[14]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath13} alt="Post dia 14"></img><div className="legendPrintPlan">{planejamento.texto_14}</div></div></li>
                                <li><div className="dayPrint">{arr[15]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath14} alt="Post dia 15"></img><div className="legendPrintPlan">{planejamento.texto_15}</div></div></li>
                                <li><div className="dayPrint">{arr[16]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath15} alt="Post dia 16"></img><div className="legendPrintPlan">{planejamento.texto_16}</div></div></li>
                                <li><div className="dayPrint">{arr[17]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath16} alt="Post dia 17"></img><div className="legendPrintPlan">{planejamento.texto_17}</div></div></li>
                                <li><div className="dayPrint">{arr[18]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath17} alt="Post dia 18"></img><div className="legendPrintPlan">{planejamento.texto_18}</div></div></li>
                                <li><div className="dayPrint">{arr[19]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath18} alt="Post dia 19"></img><div className="legendPrintPlan">{planejamento.texto_19}</div></div></li>
                                <li><div className="dayPrint">{arr[20]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath19} alt="Post dia 20"></img><div className="legendPrintPlan">{planejamento.texto_20}</div></div></li>
                                <li><div className="dayPrint">{arr[21]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath20} alt="Post dia 21"></img><div className="legendPrintPlan">{planejamento.texto_21}</div></div></li>
                                <li><div className="dayPrint">{arr[22]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath21} alt="Post dia 22"></img><div className="legendPrintPlan">{planejamento.texto_22}</div></div></li>
                              </ul>
                            </div>
                          </div>
                      );
                  }
                } 
                return true;
              })
            } 
          </div>
        </div>
      </div>
    </div>
    <div id="contentPlannerPrintBottom">
      <div id="calendarGrid">
        <header>
          <div className="weekdaysPrint">
            <Row className="w-100 justify-content-center">
              <div className="monthCalendar">{getMonthTranslate()}</div>
            </Row>
            <ul>
              <li>Domingo</li>
              <li>Segunda-feira</li>
              <li>Terça-feira</li>
              <li>Quarta-feira</li>
              <li>Quinta-feira</li>
              <li>Sexta-feira</li>
              <li>Sábado</li> 
            </ul>
          </div>
        </header>
        <div>
          <div>
            {
              planejamentos && planejamentos.map((planejamento) =>{
                getNegocioId();    
                if(planejamento.negocio_id.toString() === negocioId && planejamento.mes === getMonthTranslate() && planejamento.mes.toLowerCase() === getMonthTranslate().toLowerCase() ){
                  if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Domingo'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div  className="Cal-endarPrint">
                          <ul className="daysPrint">
                            <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                            <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                            <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                          </ul>
                        </div>
                      );
                  }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Segunda-feira'){
                    arr_cont(planejamento.duracao)
                      return(
                        <div  className="Cal-endarPrint">
                          <ul className="daysPrint">
                            <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                            <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                            <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                            <li><div className="bodyCaloffPrint"></div></li>
                          </ul>
                      </div>
                      );
                }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Terça-feira'){
                  arr_cont(planejamento.duracao)
                    return(
                      <div  className="Cal-endarPrint">
                        <ul className="daysPrint">
                          <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                          <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                          <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                          <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                          <li><div className="bodyCaloffPrint"></div></li>
                          <li><div className="bodyCaloffPrint"></div></li>
                        </ul>
                      </div>
                    );
                }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quarta-feira'){
                  arr_cont(planejamento.duracao)
                    return(
                      <div  className="Cal-endarPrint">
                        <ul className="daysPrint">
                          <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                          <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                          <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                          <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                          <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                          <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                          <li><div className="bodyCaloffPrint"></div></li>
                        </ul>
                      </div>
                    );
                }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Quinta-feira'){
                  arr_cont(planejamento.duracao)
                  return(
                    <div  className="Cal-endarPrint">
                      <ul className="daysPrint">
                        <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                        <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                        <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                        <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                        <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                        <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                        <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                      </ul>
                    </div>
                  );
                }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sexta-feira'){
                  arr_cont(planejamento.duracao)
                    return(
                      <div  className="Cal-endarPrint">
                        <ul className="daysPrint">
                          <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                          <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                          <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                          <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                          <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                          <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                          <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                          <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                      </ul>
                      </div>
                    );
                }else if(planejamento.negocio_id.toString() === negocioId && planejamento.startDay.toString()==='Sábado'){
                  arr_cont(planejamento.duracao)
                    return(
                      <div  className="Cal-endarPrint">
                        <ul className="daysPrint">
                          <li><div className="dayPrint">{arr[23]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath22} alt="Post dia 23"></img><div className="legendPrintPlan">{planejamento.texto_23}</div></div></li>
                          <li><div className="dayPrint">{arr[24]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath23} alt="Post dia 24"></img><div className="legendPrintPlan">{planejamento.texto_24}</div></div></li>
                          <li><div className="dayPrint">{arr[25]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath24} alt="Post dia 25"></img><div className="legendPrintPlan">{planejamento.texto_25}</div></div></li>
                          <li><div className="dayPrint">{arr[26]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath25} alt="Post dia 26"></img><div className="legendPrintPlan">{planejamento.texto_26}</div></div></li>
                          <li><div className="dayPrint">{arr[27]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath26} alt="Post dia 27"></img><div className="legendPrintPlan">{planejamento.texto_27}</div></div></li>
                          <li><div className="dayPrint">{arr[28]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath27} alt="Post dia 28"></img><div className="legendPrintPlan">{planejamento.texto_28}</div></div></li>
                          <li><div className="dayPrint">{arr[29]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath28} alt="Post dia 29"></img><div className="legendPrintPlan">{planejamento.texto_29}</div></div></li>
                          <li><div className="dayPrint">{arr[30]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath29} alt="Post dia 30"></img><div className="legendPrintPlan">{planejamento.texto_30}</div></div></li>
                          <li><div className="dayPrint">{arr[31]}</div><div className="bodyCalPrint"><img className="imagem" src={completePath30} alt="Post dia 31"></img><div className="legendPrintPlan">{planejamento.texto_31}</div></div></li>
                        </ul>
                      </div>
                    );
                }
             }
          return true;
          })
        } 
        </div>
      </div>
    </div>
  </div>
</div>
);
}

PrintPlannerPublicacao.propTypes = {
};
export default PrintPlannerPublicacao;
