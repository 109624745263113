import React, { useState, useEffect, useContext } from "react";
import NavMenu from "components/Navbars/NavMenu";
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import {Row} from "react-bootstrap";
import API from "utils/API";
import LastAccess from "views/User/LastAccess";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function Servicos(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [negocios, setNegocios] = useState();
  var l1, l2, l3, l4, l5, l6, l7 = 0;
  var classL1 = "";
  var classL2 = "";
  var classL3 = "";
  var classL4 = "";
  var classL5 = "";
  var classL6 = "";
  var classL7 = "";
  let negocioId = 2;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
      
  /*useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);
*/


function getNegocioId() {
  const it = getUrl.split("/");
  const IdNegocio = (it[4]).toString();
  return  negocioId = IdNegocio;
}

getNegocioId();

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
      setNegocios(response)
    });
  }, []);
 
  
  function getServicos(){
    negocios && negocios.map((negocio) => {
      if(negocio.id === authUser.active_negocio_id){
        l1 = negocio.criacao
        l2 = negocio.roteiros
        l3 = negocio.trafego
        l4 = negocio.ads
        l5 = negocio.lp
        l6 = negocio.site 
        l7 = negocio.sistema
        return(
        l1,l2,l3,l4,l5,l6,l7
        );
      }
      return true;
    })
  }

  getServicos();

  function getClass(){
    if(l1 === 1){
      classL1 = "boxCheck fa fa-check";
    }else{
      classL1 = "boxCheck fa fa-close"
    }

    if(l2 ===1){
      classL2 = "boxCheck fa fa-check";
    }else{
      classL2 = "boxCheck fa fa-close"
    }

    if(l3 ===1){
      classL3 = "boxCheck fa fa-check";
    }else{
      classL3 = "boxCheck fa fa-close"
    }

    if(l4 ===1){
      classL4 = "boxCheck fa fa-check";
    }else{
      classL4 = "boxCheck fa fa-close"
    }
    
    if(l5 ===1){
      classL5 = "boxCheck fa fa-check";
    }else{
      classL5 = "boxCheck fa fa-close"
    }

    if(l6 ===1){
      classL6 = "boxCheck fa fa-check";
    }else{
      classL6 = "boxCheck fa fa-close"
    }

    if(l7 ===1){
      classL7 = "boxCheck fa fa-check";
    }else{
      classL7 = "boxCheck fa fa-close"
    }
  }
  getClass();

  const Dados = () =>{ 
    history.push(`/dados_gerais/${negocioId}`);
  }


return(
  <div id="contentDash">
    <NavMenu/>
    <button className="back" onClick={Dados}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    <Row className="w-100 justify-content-center"> 
      <div className="titleServicos">Serviços contratados</div>
    </Row>
    <Row className="w-100 justify-content-center"> 
      <div className="boxServicos">
        <ul>
          <li><i className={classL1}></i>Criação de conteúdo - Facebook e Instagram - 3 posts semanais</li>
          <li><i className={classL2}></i>Roteiros para vídeos - 3 roteiros mensais</li>
          <li><i className={classL3}></i>Gestão de tráfego pago - Facebook e Instagram</li>
          <li><i className={classL4}></i>Gestão Google Ads</li>
          <li><i className={classL5}></i>Landing Pages</li>
          <li><i className={classL6}></i>Site</li>
          <li><i className={classL7}></i>Acesso ao sistema</li>
        </ul>
      </div>
    </Row>
    <Row className="w-100 fixed-bottom justify-content-left">
      <LastAccess/>
    </Row>

      <img className="position-absolute opacity line-V" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );
}

export default Servicos;