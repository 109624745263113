import React, { useState, useEffect, useContext } from "react";
import {Row, Form, Col, Button, FormControl} from "react-bootstrap";
import  MenuCompleto from '../Admin/MenuCompleto';
import API from "utils/API";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import DatePicker,{registerLocale} from 'react-datepicker';
import "react-datepicker/src/stylesheets/datepicker.scss";
import ptBR from 'date-fns/locale/pt-BR';
import { AuthUserContext } from "../../contexts/AuthUserContext";

registerLocale('ptBR', ptBR);
function AdicionarHistorico(){
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const { register, handleSubmit } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [negocios, setNegocios] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  function onSubmit(data) {
    const [run] = API.post("historicos", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Contrato adicionado com sucesso.");
        setIsLoading(false);        
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);


  return(
  <div>
    <MenuCompleto/>
    <Row className="w-100 justify-content-center ">
      <div className="titleRosa">Adicionar ao histórico</div>
    </Row>
    {  
    usuarios && usuarios.map((usuario) => {
      if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.dados === 1))){
        return(
          <div>
            <Form className="formProjecoes" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12}>
                  <div className="calendarInput">
                    <label>Data de registro</label><br/>
                    <DatePicker selected={startDate} locale="ptBR"
                    onChange={date => setStartDate(date)} 
                    dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </Col>
                <input value={startDate.toLocaleDateString()} style={{ "display": "none" }} id="data" name="data" ref={register}></input>
              </Row>
              <Row>
                <Col md={12}>
                  <label>Serviço</label><br/>
                  <input className="inptHistorico" type="text" id="link" name="servico" ref={register}></input>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <label>Status</label><br/>
                  <input className="inptHistorico" type="text" id="link_2" name="status" ref={register}></input>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <label>Descrição</label><br/>
                  <input  className="inptHistorico" type="text" id="detalhes" name="detalhes" ref={register}></input>
                </Col>
                <Col md={12}>
                  <label>Negócio</label>
                    <FormControl className="selectHist" name="negocio_id" as="select" ref={register} >
                      {
                        negocios && negocios.map((negocio) => (
                          <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                        ))
                      }
                    </FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button className="botaoEdithist" pullRight fill
                      type="submit"
                      disabled={isLoading}>
                            Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        );
     }else if(usuario.id === authUser.id &&(authUser.role !== "admin" && authUser.role ==="equipe" && authUser.usuarios === 0)){
        return(
          <Row className="w-100 justify-content-center ">
            <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contacte um administrador.</div>
          </Row>
        )
      }
      return true;
    })
    }
  </div>
  );  
}
export default AdicionarHistorico;