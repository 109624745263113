import React from "react";
import NavMenu from "components/Navbars/NavMenu";
import {Row} from "react-bootstrap";
import "../../assets/css/dados.css";
import { useHistory} from "react-router-dom";
import LastAccess from "views/User/LastAccess";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";


function DadosGerais(){
const history = useHistory();
let negocioId = 2;
const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function getNegocioId() {
  const it = getUrl.split("/");
  const IdNegocio = (it[4]).toString();
  return  negocioId = IdNegocio;
}

  getNegocioId();

  /*useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);
*/

async function Documentos(){ 
  await sleep(1000);
    history.push(`/documentos/${negocioId}`);
}

async function Servicos(){ 
  await sleep(1000);
  history.push(`/servicos/${negocioId}`);
}

async function Historico(){
  await sleep(1000);
  history.push(`/historico/${negocioId}`);
}
  

return(
  <div id="contentDash">
    <NavMenu/>
      <Row className="w-100 justify-content-center"> 
        <div className="dadosGTitle">Dados Gerais</div>
      </Row>
      <Row className="w-100 justify-content-center">
        <div className="dadosList">
          <ul className="p-0">
            <li><button className="buttonDados" onClick={Documentos}>Documentos</button></li>
            <li><button className="buttonDados" onClick={Servicos}>Serviços contratados</button></li>
            <li><button  className="buttonDados" onClick={Historico}>Histórico de atendimento</button></li>
          </ul>
        </div>
      </Row>
      <Row className="w-100 fixed-bottom justify-content-left">
        <LastAccess/>
      </Row>
      <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
  </div>
);
}
export default DadosGerais;