import React, { useEffect, useState, useContext } from "react";
import {  Button, Row, Col,FormGroup, Form, FormControl} from "react-bootstrap";
import API from "utils/API";
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../contexts/NotificationContext";
import "../../assets/css/resultados.css"
import { AuthUserContext } from "../../contexts/AuthUserContext";
import MenuCompleto from "views/Admin/MenuCompleto";


function CriarLeads () {
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const { register, handleSubmit } = useForm();
  const [negocios, setNegocios] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();

  function onSubmit(data) {
    const [run] = API.post("leads", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Planejamento adicionado com sucesso");
        setIsLoading(false);
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
       setNegocios(response);
    });
  }, []);
  
  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);


return(
  <div>
  <MenuCompleto/>
  <div>
  <div className="tituloResultados">Criar Resultados</div>
    <Row>
      <div className="avisoResultado">*Preencha todos os campos, caso não haja valor deve ser colocado "0". </div> 
    </Row>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
            return(
              <div className="formResultados">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <label className="containerEquipe">Trocar Leads para cliques
                        <input type="checkbox" name="cliques" ref={register}/>
                      </label>
                    </Col>   
                    <Col md={2}>
                      <label><div className="red">Mês*</div></label>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="selectionResultados">
                        <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                          <option>Janeiro</option>
                          <option>Fevereiro</option>
                          <option>Março</option>
                          <option>Abril</option>
                          <option>Maio</option>
                          <option>Junho</option>
                          <option>Julho</option>
                          <option>Agosto</option>
                          <option>Setembro</option>
                          <option>Outubro</option>
                          <option>Novembro</option>
                          <option>Dezembro</option>
                        </Form.Control>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <label>Ano</label>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="selectionResultados">
                        <Form.Control id="ano" as="select" ref={register} name="ano" label="Ano">
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                          <option>2028</option>
                          <option>2029</option>
                          <option>2030</option>
                        </Form.Control>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <label>Número de Leads</label>
                    </Col>
                    <Col md={6}>
                      <input className="inputResultados" type="number" id="numLead" name="numLead" ref={register}></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <label>Número de Cliques</label>
                    </Col>
                    <Col md={6}>
                      <input className="inputResultados" type="number" id="cliquesValue" name="cliquesValue" ref={register}></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <label>CPL</label>
                    </Col>
                    <Col md={6}>
                      <input  className="inputResultados"  type="number" step="0.01" min="0.00" placeholder="R$ 24,00"  name="CPL" ref={register}></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <label>Investimento Leads</label>
                    </Col>
                    <Col md={6}>
                      <input className="inputResultados" type="number" step="0.01" min="0.00" placeholder="R$ 10.000,00" name="investimentoLeads" ref={register}></input>
                    </Col>
                  </Row> 
                  <Row>
                    <Col md={2}>
                      <label>Investimento engajamento</label>
                    </Col>
                    <Col md={6}>
                      <input className="inputResultados" type="number" step="0.01" min="0.00" placeholder="R$ 10.000,00" name="investimentoEngajamento" o ref={register}></input>
                    </Col>
                  </Row>      
                  <Row>
                    <Col md={2}>
                      <label>Investimento consolidado Facebook</label>
                    </Col>
                    <Col md={6}>
                      <input className="inputResultados" type="number" step="0.01" min="0.00" placeholder="R$ 10.000,00"  name="investimentoFb"  ref={register}></input>
                    </Col>
                  </Row>  
                  <Row>
                    <Col md={2}>
                      <label>Investimento Google Ads</label>
                    </Col>
                    <Col md={6}>
                      <input   className="inputResultados" type="number" step="0.01" min="0.00" placeholder="R$ 15.000,00" name="investimentoAds" ref={register}></input>
                    </Col>
                  </Row>   
                  <Row>
                    <Col md={2}>
                      <label>Investimento total</label>
                    </Col>
                    <Col md={6}>
                      <input   className="inputResultados" name="investimentoTotal"  type="text" placeholder="R$ 30.000,00" ref={register} />  
                    </Col>
                    {/*<Col md={2}>
                      <input   className="ResultadosButton"  onClick={onChange5} type="text" placeholder="Calcular" ref={register} name="rdonly" />  
            </Col>*/}
                  </Row>   
                  <Row>
                    <Col md={2}>
                      <label><div className="red">Negócio*</div></label>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="selectionResultados">
                        <FormControl  name="negocio_id"  as="select" ref={register} >
                          {
                            negocios && negocios.map((negocio) => (
                              <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                            ))
                          }
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>     
                  <Button className="btnResultados" 
                    type="submit"
                    disabled={isLoading}>
                      Registrar Resultados
                  </Button>
                  <div className="clearfix" />
                </form>
            </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.projecoes === 0)){
              return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
        return true;
      })
    }
  </div>     
</div>      
  );
}

CriarLeads.propTypes = {
};
export default CriarLeads;