import React, { useState, useEffect, useContext} from "react";
import NavMenu from "components/Navbars/NavMenu";
import { CartesianGrid, XAxis, YAxis, Tooltip} from "recharts";
import { BarChart, Bar} from 'recharts';
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/css/projecaoCliente.css";
import API from "utils/API";
import {  Row, Modal, Button,Col } from "react-bootstrap";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function Investimento(){ 
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let fbs1 = "0";
  let fbs2 = "0";
  let fbs3 = "0";
  let fbs4 = "0";
  let gs1 = "0";
  let gs2 = "0";
  let gs3 = "0";
  let gs4 = "0";
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  var monthh = " ";
  var mes = 0;

  const Metas = () =>{ 
    history.push(`/metas/${authUser.active_negocio_id}`);
  }

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

  function openModal2() {
    handleShow2();
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }

  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    if(atual !== "none"){
          mes = atual;
        }else{
          mes = getMonth()
        }
    if (mes === 1) {
        monthh = "Janeiro";
      } else if (mes === 2) {
        monthh = "Fevereiro";
      } else if (mes === 3) {
      monthh = "Março";
      } else if (mes === 4) {
      monthh = "Abril";
      } else if (mes === 5) {
      monthh = "Maio";
      } else if (mes === 6) {
      monthh = "Junho";
      } else if (mes === 7) {
      monthh = "Julho";
      } else if (mes === 8) {
      monthh = "Agosto";
      } else if (mes === 9) {
      monthh = "Setembro";
      } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
      } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
      } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
      }
    return monthh;
    }
  

  function getSemanasfb(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.mes === translateMonth()) {
        const f1 = projecao.fbq1;
        const f2 = projecao.fbq2;
        const f3 = projecao.fbq3;
        const f4 = projecao.fbq4;
        return (
          fbs1 = f1,
          fbs2 = f2,
          fbs3 = f3,
          fbs4 = f4
          );
      }
      return (
        fbs1,
        fbs2,
        fbs3,
        fbs4
        );
    });
  }

  
  function getSemanasGoogle(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.mes === translateMonth()) {
        const g1 = projecao.ads1;
        const g2 = projecao.ads2;
        const g3 = projecao.ads3;
        const g4 = projecao.ads4;
        return (
          gs1 = g1,
          gs2 = g2,
          gs3 = g3,
          gs4 = g4
          );
      }
      return (gs1, gs2, gs3, gs4);
    });
  
  }

  getSemanasfb();
  getSemanasGoogle();

  const dataInvest = [
    {
      name: 'Semana 1',
      fb: fbs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 1',
      amt: 2400,
    },
    {
      name: 'Semana 2',
      fb: fbs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 2',
      amt: 2210,
    },
    {
      name: 'Semana 3',
      fb: fbs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 3',
      amt: 2290,
    },
    {
      name: 'Semana 4',
      fb: fbs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 4',
      amt: 2000,
    },

  ];

  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }

  return(
    <div id="contentDash">
      <NavMenu/>
      <button className="back" onClick={Metas}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
        <Row className="w-100 justify-content-center">
          <div className="whiteBg">
            <Row className="w-100 justify-content-center"> 
              <button className="btnCalInvest" onClick={openModal2}><i className="fa fa-calendar"></i></button>
              <div className="metaFaturamentoTitle">Investimentos </div>
            </Row>
            <Row className="w-100 justify-content-center">
              <div className="barchart">
                <BarChart
                  className="pchartBar"
                    width={900}
                    height={450}
                    data={dataInvest}
                    margin={{
                      top: 100,
                      right: 0,
                      left: 0,
                      bottom: 40,
                    }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis  dataKey="week" axisLine={{ stroke: '#d5385a' }}  tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={18}/>
                  <YAxis axisLine={{ stroke: '#d5385a' }}  tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={18}/>
                  <Tooltip cursor={{ fill: "transparent" }} />
                  <Bar name="Facebook/Instagram" type="monotone" dataKey="fb" fill="#f7325a" isAnimationActive={false} />
                  <Bar name="Google" type="monotone" dataKey="google" fill="#fe799d" isAnimationActive={false}/>
                </BarChart>
                <div className="legendafb"><button className="legendButtonfb"></button> <i className="fa fa-facebook"></i> / <i className="fa fa-instagram"></i> -  Investimento Total: R${(parseFloat(fbs1)+parseFloat(fbs2)+parseFloat(fbs3)+parseFloat(fbs4)).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
                <div className="legendafb"><button className="legendButtong"></button> <i className="text-center fa fa-google"></i> -  Investimento total: R${(parseFloat(gs1)+parseFloat(gs2)+parseFloat(gs3)+parseFloat(gs4)).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>          
              </div>
            </Row>
            <Row className="w-100 fixed-bottom justify-content-left">
              <LastAccess/>
            </Row>
            <Modal className="selectMonthMetaInvest" data-keyboard={true} backdrop={true} show={show2} >
              <Button className="closeButton">Selecione o mês ({getYear()})</Button>
              <Modal.Body>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
                  </Col>
                </Row>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
                  </Col>
                </Row>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </div>    
        </Row>
      <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );
}

export default Investimento;