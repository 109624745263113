/*eslint-disable no-undefined */
import Register from "./views/Auth/Register";
import BusinessManager from "./views/Business/BusinessManager";
import RegisterBusiness from "./views/Business/RegisterBusiness";
import Dashboard from "./views/Dashboard";
import RegistrarProjecao from "./views/Projecao/RegistrarProjecao";
import EditarProjecao from "./views/Projecao/EditarProjecao";
import Planner from "./views/Planner/planejamento";
import novoPlanejamento from "./views/Planner/novoPlanejamento";
import LeadsConsolidados from "./views/Leads/LeadsConsolidados";
import MenuCompleto from './views/Admin/MenuCompleto';
import CriarLeads from './views/Leads/CriarLeads';
import EditarLeads from './views/Leads/EditarLeads';
import GerenciarUsers from './views/User/GerenciarUsers';
import GerenciarNoticias from "./views/Admin/GerenciarNoticias";
import RegistrarMateriais from "./views/Materiais/RegistrarMateriais";
import  Historico from "./views/Dados/Historico";
import TempoReal from './views/Projecao/TempoReal';
import FbAds from "views/Projecao/FbAds";
import Analytics from "views/Projecao/Analytics";
import DadosGerais from './views/User/DadosGerais';
import Materiais from "views/Materiais/Materiais";
import ProjecaoDeLeads from './views/Projecao/ProjecaoDeLeads';
import Metas from './views/Projecao/Metas';
import Funil from "views/Projecao/Funil";
import MetaFaturamento from './views/Projecao/MetaFaturamento';
import Investimento from './views/Projecao/Investimento';
import TotalInvestido from './views/Projecao/TotalInvestido';
import Cpl from './views/Projecao/Cpl';
import Documentos from "views/Dados/Documentos";
import RegisterHistorico from './views/Dados/RegisterHistorico';
import Contrato from './views/Dados/Contrato';
import Briefing from "views/Dados/Briefing";
import Servicos from './views/Dados/Servicos';
import AdicionarHistorico from './views/Dados/AdicionaHistorico'
import AdicionarBoletos from './views/Dados/AdicionarBoletos';
import ListarBoletos from './views/Dados/ListarBoletos';
import Boleto from './views/Dados/Boleto';
import AdicionarNotas from './views/Dados/AdicionarNotas';
import NotasFiscais from './views/Dados/NotasFiscais';
import SelectNegocio from "views/Business/SelectNegocio";
import NoticiasCliente from "views/User/NoticiasCliente";
import GerenciarEquipe from "views/Admin/GerenciarEquipe";
import SelectUsuario from './views/User/SelectUsuario';
import Criarfb from "views/TempoReal/Criarfb";
import CriarAnalytics from "views/TempoReal/CriarAnalytics";


const routes = [
  {
    "name": "Geral",
    "routes": [
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": "fas fa-home",
        "roles": ["admin", "equipe", "cliente"],
        "component": Dashboard
      }
    ]
  },
  {
    "name": "Geral",
    "routes": [
      {
        "path": "/historico",
        "name": "Histórico",
        "icon": "fas fa-home",
        "roles": ["admin", "equipe", "cliente"],
        "component": Historico
      }
    ]
  },
  {
    "name": "Geral",
    "routes": [
      {
        "path": "/gerenciar_noticias",
        "name": "Dashboard",
        "icon": "fas fa-home",
        "roles": ["admin", "equipe"],
        "component": GerenciarNoticias
      }
    ]
  },
  {
    "name": "Geral",
    "routes": [
      {
        "path": "/registrar_materiais",
        "name": "Dashboard",
        "icon": "fas fa-home",
        "roles": ["admin", "equipe"],
        "component": RegistrarMateriais
      }
    ]
  },
  {
    "name": "Administração",
    "routes": [
      {
        "path": "/registro_usuarios",
        "name": "Registrar Usuário",
        "icon": "fas fa-user-plus",
        "roles": ["admin", "equipe"],
        "component": Register
      },
      {
        "path": "/criar_negocio",
        "name": "Registrar Negócio",
        "icon": "fas fa-hospital",
        "roles": ["admin", "equipe"],
        "component": RegisterBusiness
      },
      {
        "path": "/gerenciar_negocios",
        "name": "Gerenciar Negócios",
        "icon": "fas fa-city",
        "roles": ["admin", "equipe"],
        "component": BusinessManager
      },
      {
        "path": "/projecoes",
        "name": "Registrar Projeção",
        "icon": "fas fa-plus",
        "roles": ["admin", "equipe"],
        "component": RegistrarProjecao
      },
      {
        "path": "/editar/projecao",
        "name": "Gerenciar Projeções",
        "icon": "fas fa-chart-line",
        "roles": ["admin", "equipe"],
        "component": EditarProjecao
      },
      {
        "path": "/planejamentos",
        "name": "Planejamento mensal",
        "icon": "fas fa-chart-line",
        "roles": ["admin", "equipe", "cliente"],
        "component": Planner
      },
      {
        "path": "/novoPlanejamento",
        "name": "Novo planejamento",
        "icon": "fas fa-chart-line",
        "roles":["admin", "equipe"],
        "component": novoPlanejamento
      },
  
      {
        "path": "/leads",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente","equipe"],
        "component": LeadsConsolidados
      },
      {
        "path": "/Novolead",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": CriarLeads
      },
      {
        "path": "/editarleads",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": EditarLeads
      },
      {
        "path": "/projecaoLeads",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": ProjecaoDeLeads
      },
      {
        "path": "/selectNegocio",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": SelectNegocio
      }, 
        {
        "path": "/noticias",
        "name": "Resultados mensais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": NoticiasCliente
      } 
    ]
  },

  {
    "name": "Configurações",
    "routes": [
      {
        "path": "/administradormais",
        "name": "Menu Administrador",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": MenuCompleto
      },
      {
        "path": "/gerenciar_usuarios",
        "name": "Gerenciar usuários",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": GerenciarUsers
      },
      {
        "path": "/realtime",
        "name": "Tempo Real",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": TempoReal
      },     

  
      {
        "path": "/fbAds",
        "name": "Tempo Real",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": FbAds
      },         
      {
        "path": "/registrar_fb",
        "name": "Registrar Facebook Ads",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": Criarfb
      },
      {
        "path": "/registrar_analytics",
        "name": "Registrar Analyticss",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": CriarAnalytics
      },
      {
        "path": "/analytics",
        "name": "Tempo Real",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Analytics
      },
       {
        "path": "/dados_gerais",
        "name": "Dados Gerais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": DadosGerais
      },
      {
        "path": "/projetos",
        "name": "Materiais",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Materiais
      }, 
       {
        "path": "/metas",
        "name": "Metas",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Metas
      },
      {
        "path": "/funil",
        "name": "Funil",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Funil
      },
      {
        "path": "/faturamento",
        "name": "Funil",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": MetaFaturamento
      },
      {
        "path": "/investimento",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Investimento
      },
      {
        "path": "/totalInvestido",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": TotalInvestido
      },
      {
        "path": "/cpl",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Cpl
      },
      {
        "path": "/documentos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "cliente", "equipe"],
        "component": Documentos
      },
      {
        "path": "/listarhistoricos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": RegisterHistorico
      },
      {
        "path": "/contrato",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": Contrato
      },
      {
        "path": "/briefing",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": Briefing
      },
      {
        "path": "/servicos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": Servicos
      },
      {
        "path": "/novo_historico",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": AdicionarHistorico
      },
      {
        "path": "/adicionar_boletos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles":["admin", "equipe"],
        "component": AdicionarBoletos
      },
      {
        "path": "/listar_boletos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": ListarBoletos
      },
      {
        "path": "/boletos",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": Boleto
      },
      {
        "path": "/adicionar_notas",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": AdicionarNotas
      },
      {
        "path": "/notas_fiscais",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": NotasFiscais
      },
      {
        "path": "/gerenciarequipe",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe"],
        "component": GerenciarEquipe
      },
      {
        "path": "/selectUsuario",
        "name": "Investimento",
        "icon": "fas fa-user-tie",
        "roles": ["admin", "equipe", "cliente"],
        "component": SelectUsuario
      }

    ]
  }

];

function getRoutesFor(role) {
  if (role) {
    return routes.map((group) => {
      const groupRoutes = group.routes.map((route) => {
        if (route.roles.includes(role)) {
          return route;
        }
        return undefined;
      }).filter((x) => typeof x !== "undefined");
      if (groupRoutes.length) {
        return {
          "name": group.name,
          "routes": groupRoutes
        };
      }
      return undefined;
    }).filter((x) => typeof x !== "undefined");
  }
  return undefined;
}

export {
  routes,
  getRoutesFor
};
