import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/g6.scss";
import "./assets/css/pe-icon-7-stroke.css";

import "./assets/fonts/font_stylesheet.css"
import Main from "layouts/Main.jsx";
import API from "utils/API";

import Cookies from "js-cookie";
import Login from "./views/Auth/Login";
import { AuthUserContext } from "./contexts/AuthUserContext.js";
import { PropTypes } from "prop-types";

function AuthenticatedGate(props) {
  const LOADING = -1;
  const UNAUTHENTICATED = 0;
  const AUTHENTICATED = 1;

  const [isOk, setOk] = useState(LOADING);
  const [authUser, setAuthUser] = useState();
  const [token, setToken] = useState(Cookies.get("accessToken"));

  useEffect(() => {
    if (token) {
      API.setAccessToken(token);
      const [run] = API.get("me");
      run()
        .then((response) => {
          setOk(AUTHENTICATED);
          setAuthUser(response);
          Cookies.set("accessToken", token);
        })
        .catch(() => {
          setOk(UNAUTHENTICATED);
          API.setAccessToken(null);
          setAuthUser(null);
          Cookies.set("accessToken", null);
        });
    } else {
      setOk(UNAUTHENTICATED);
      setAuthUser(null);
    }
  }, [token]);

  function renderSwitch() {
    switch (isOk) {
    case LOADING:
      return <><h4 className="text-center pt-5 text-danger">Loading...</h4></>;
    case UNAUTHENTICATED:
      return <Login setToken={setToken}/>;
    case AUTHENTICATED:
      return <>{props.children}</>;
    default:
      return <Login setToken={setToken}/>;
    }
  }

  function voidAuthUser() {
    API.setAccessToken("");

  }

  return (
    <AuthUserContext.Provider
      value={{ authUser,
        voidAuthUser }}
    >
      {renderSwitch()}
    </AuthUserContext.Provider>
  );
}

AuthenticatedGate.propTypes = {
  "children": PropTypes.element
};

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedGate>
          <Route path="/" render={(props) => <Main {...props} />} />
        </AuthenticatedGate>
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
