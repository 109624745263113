
import "swiper/swiper.scss";
import React, { useState, useContext, useEffect } from "react";
import { Form, Row, Col, FormGroup, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "components/CustomButton/CustomButton.jsx";
import API from "utils/API";
import { NotificationContext } from "../../contexts/NotificationContext";
import "../../assets/css/projecoes.css";
import MenuCompleto from "views/Admin/MenuCompleto";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import Alert from 'react-bootstrap/Alert';


function RegistrarProjecao() {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit} = useForm();
  const notification = useContext(NotificationContext);
  const [negocios, setNegocios] = useState();
  const [google, setGoogle] = useState();
  const [values, setValues] = useState();
  const { authUser } = useContext(AuthUserContext);
  const [usuarios, setUsuarios] = useState();
  const [show, setShow] = useState(false);

  function onSubmit(data) {
    const [run] = API.post("projections", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Projeção adicionada com sucesso.");
        setIsLoading(false);
      })
      .catch(() => {
        setShow(true);
        setIsLoading(false);
      });
  }

  function onChange(event) {
    setValues(event.target.value) //update your value here
  }

  function onChange2(event) {
    setGoogle(100-(values));
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
       setUsuarios(response);
    });
  }, []);

  return (
    <div className="content">
         <MenuCompleto/>  
          <div className="tituloProjecoes">Registrar projeção</div>
            {  
              usuarios && usuarios.map((usuario) => {
                if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
                  return(
                    <Form className="formProjecoes" onSubmit={handleSubmit(onSubmit)}>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo">Informações gerais</div>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label><div className="red">Negócio*</div></label>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="selectionProjecaoMes">
                            <FormControl  id="negocio" name="negocio_id" as="select" ref={register} >
                              {
                                negocios && negocios.map((negocio) => (
                                  <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                ))
                              }
                              </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label><div className="red">Mês*</div></label>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="selectionProjecaoMes">
                            <Form.Control id="mes" as="select" ref={register} name="mes" label="Mês">
                                <option>Janeiro</option>
                                <option>Fevereiro</option>
                                <option>Março</option>
                                <option>Abril</option>
                                <option>Maio</option>
                                <option>Junho</option>
                                <option>Julho</option>
                                <option>Agosto</option>
                                <option>Setembro</option>
                                <option>Outubro</option>
                                <option>Novembro</option>
                                <option>Dezembro</option>
                            </Form.Control>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label className="containerEquipe">Possui Analytics
                            <input type="checkbox" name="hasAnalytics" ref={register}/>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label className="containerEquipe">Possui Google
                            <input type="checkbox" name="hasGoogle" ref={register}/>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mt-2">* Preencher apenas com números e "." ou "," para casas decimais. Números acima de mil não devem conter ponto ou vírgula após a casa de milhares.</Col>
                        <Col md={6}>
                          <label className="mt-2"><div className="red">Meta de faturamento*</div></label>
                        </Col>
                        <Col md={6}>
                          <input  type="number" step="0.01" min="0.00" id="meta"  className="inputProjecoes mt-2" placeholder="Total - R$" ref={register} name="metaTotal"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label><div className="red"> Porcentagem redes sociais*</div></label>
                        </Col>
                        <Col md={6}>
                          <input  type="number" step="0.01" min="0.00"   id="porcentagemredes" className="inputProjecoes" placeholder="80%" name="metaPorcentagem" onChange={onChange} ref={register}></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label><div  className="red"> Porcentagem Google*</div></label>
                        </Col>
                        <Col md={6}>
                          <input  type="number" step="0.01" min="0.00" id="porcentagemgoogle"  className="inputProjecoes"  placeholder="20%" name="gpercent" value={google} onSelect={onChange2} ref={register}></input>
                        </Col>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo">Informações Redes Sociais</div>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo2">Investimentos Facebook/Instagram</div>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Facebook/Instagram - Semana 1</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="Semana 1 - R$" ref={register} name="fbq1"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Facebook/Instagram - Semana 2</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="Semana 2 - R$" ref={register} name="fbq2"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Facebook/Instagram - Semana 3</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="Semana 3 - R$" ref={register} name="fbq3"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Facebook/Instagram - Semana 4</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="Semana 4 - R$" ref={register} name="fbq4"></input>
                        </Col>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo2">CPL Facebook/Instagram</div>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label> CPL Anterior </label>
                        </Col>
                        <Col md={6}>                
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="CPL Anterior - R$" ref={register} name="cplAnterior"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label> CPL Redes Sociais </label>
                        </Col>
                        <Col md={6}>
                          <input type="number" step="0.01" min="0.00" className="inputProjecoes" placeholder="CPL Facebook/Instagram - R$"  ref={register} name="cplFb"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Row className="w-100 justify-content-center ">
                          <div className="allinfo2">Funil de Leads</div>
                        </Row>
                        <Col md={12} className="mt-2">* A porcentagem de descoberta do Instagram e do Facebook devem somar 100%.</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label className="mt-2">Porcentagem de Descoberta do Instagram</label>
                        </Col>
                        <Col md={6}>
                          <input type="text" className="inputProjecoes" placeholder="Porcentagem Descoberta Instagram - %"  ref={register} name="porcentagemInstaTotal"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Descoberta do Facebook</label>
                        </Col>
                        <Col md={6}>
                          <input type="text" className="inputProjecoes" placeholder="Porcentagem Descoberta Facebook - %"  ref={register} name="porcentagemFbTotal"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Leads</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Leads - %"  ref={register} name="porcentagemLeads"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Agendamentos</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Agendamentos - %"  ref={register} name="porcentagemAgendamento"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Comparecimento</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Comparecimento - %"  ref={register} name="porcentagemComparecimento"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Fechamento</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Fechamento - %" ref={register} name="porcentagemFechamento"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label><div className="red">Ticket médio atual*</div></label>
                        </Col>
                        <Col md={6}>
                        <input type="number" step="0.01" min="0.00" id="ticket" className="inputProjecoes" placeholder= "Ticket Médio R$" ref={register} name="ticketAtual"></input>
                        </Col>
                      </Row>
                     
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo">Informações Google</div>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo2">Investimentos Google</div>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Google Semana 1</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Semana 1 - R$" ref={register} name="ads1"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Google Semana 2</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Semana 2 - R$" ref={register} name="ads2"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Google Semana 3</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Semana 3 - R$" ref={register} name="ads3"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Google Semana 4</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Semana 4 - R$" ref={register} name="ads4"></input>
                        </Col>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo2">Projeção com base no mês anterior</div>
                      </Row> 
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Total Google</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Descoberta - %" ref={register} name="porcentagemGTotal"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Leads</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Leads - %" ref={register} name="porcentagemLeadsG"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Agendamento</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Agendamento - %" ref={register} name="porcentagemAgendamentoG"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Comparecimento</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes"  placeholder="Porcentagem Comparecimento - %" ref={register} name="porcentagemComparecimentoG"></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Porcentagem de Fechamento</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="Porcentagem Fechamento - %" ref={register} name="porcentagemFechamentoG"></input>
                        </Col>
                      </Row>    
                      <Row>
                        <Col md={6}>
                          <label><div className="red">Ticket projetado Google*</div></label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes"  id="ticketGoogle" defaultValue= "0" placeholder= "Ticket Projetado Google R$" ref={register} name="projetadosG"></input>
                        </Col>
                      </Row>
                      <Row className="w-100 justify-content-center ">
                        <div className="allinfo2">CPL Google</div>
                      </Row> 
                      <Row>
                        <Col md={6}>
                          <label>CPL Google</label>
                        </Col>
                        <Col md={6}>
                          <input type="number" className="inputProjecoes" placeholder="CPL Google"  ref={register} name="cplGoogle"></input>
                        </Col>
                      </Row>
                      <Button className="btnProjecoes" pullRight fill
                        type="submit"
                        disabled={isLoading}>
                              Registrar Projeção
                      </Button>
                      <div className="clearfix" />
                    </Form>
                  );
                }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.projecoes === 0)){
                  return(
                    <Row className="w-100 justify-content-center ">
                       <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
                    </Row>
                  )
                }
                return true;
              })
            }

          <Alert show={show} className="alertFields">        
            <Alert.Heading>Erro de cadastro</Alert.Heading>
            <p>
              Verifique se os campos <a href="#negocio">Negócio</a>, <a href="#mes">Mês</a>, <a href="meta">Meta de faturamento</a>, <a href="#porcentagemredes">Porcentagem redes sociais</a>, <a href="#porcentagemgoogle">Porcentagem Google</a>,
              <a href="#ticket">Ticket médio atual</a>  e <a href="#ticketGoogle">Ticket projetado Google</a> estão preenchidos! Eles são campos obrigatórios.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} className="btnOk">
                Ok!
              </Button>
            </div>
          </Alert>
    </div>
  );
}
export default RegistrarProjecao;
