
import React from "react";
import NavMenu from "components/Navbars/NavMenu";
import "../../assets/css/projecaoCliente.css";
import qshd from "../../assets/img/qs.png"
import { useHistory} from "react-router-dom";
import LastAccess from './../User/LastAccess';
import { Row } from "react-bootstrap";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function ProjecaoDeLeads(){
  const history = useHistory();
  let negocioId = 2;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

/*  useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);*/

  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[4]).toString();
    return  negocioId = IdNegocio;
  }
  
  getNegocioId();

  const Metas = () =>{ 
    history.push(`/metas/${negocioId}`);
  }

  const Funil = () =>{ 
    history.push(`/funil/${negocioId}`);
  }

  return(
    <div id="contentDash">
      <NavMenu/>
        <Row className="w-100 justify-content-center">
          <div className="projection">Projeção de Leads</div>
        </Row>
        <Row className="w-100 justify-content-center">
          <div className="menuLeads">
            <ul>
              <li><button className="buttonLeads" onClick={Metas}>Metas</button></li>
              <li><button className="buttonLeads" onClick={Funil}>Funil de Leads</button></li>
            </ul>
          </div>
        </Row>
        <Row className="w-100 justify-content-center">
          <img alt="qshd" className="hd" src={qshd}></img>
        </Row>
        <Row className="w-100 fixed-bottom justify-content-left">
          <LastAccess/>
        </Row>
        <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
        <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
        <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );
}

export default ProjecaoDeLeads;