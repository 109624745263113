import Axios from "axios";
import Echo from "laravel-echo";
require("pusher-js");
/*
  TODO
  <Subscription>
  Dar o controle de um estado totalmente a um método de subscrição da API
  que irá ficar aguardando mudanças do servidor e atualizar os componentes
  de acordo com as mudanças vindas do servidor.
  
*/
const BASE_PATH ="https://api.g6publicidade.com/api/";
const BASE_PATH_DOIS = "http://localhost:3000/api/";

const API = class {
  static accessToken;
  static axios = Axios.create({
    baseURL: BASE_PATH,
    responseType: "json",
  });

  static _createNewEcho(){
    return new Echo({
      //Public or Private
      broadcaster: "pusher",
      key: "Fzs58FVvvVBbte7yUnLe4pvC5jpJed2kAkdDD2aYY2YYCpwH",
      cluster: "mt1",
      wsHost: "api.g6publicidade.com", // Your domain
      encrypted: true,
      wssPort: 443, // Https port
      disableStats: true, // Change this to your liking this disables statistics
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      //disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'], // Can be removed
      auth: {
        headers: {
          Authorization: `Bearer ${API.accessToken}`,
        },
      },
      authEndpoint: "https://api.g6publicidade.com/broadcasting/auth",
    });
  }

  static subscribeTo(channel, event, callback) {
    if(!window.Echo){
      window.Echo = this._createNewEcho();
    }
    window.Echo.channel(channel).listen(event, callback);
  }

  static unsubscribeTo(channel){
    if(window.Echo){
      window.Echo.leave(channel);
    }
  }

  static setAccessToken(token) {
    API.accessToken = token;
    if(window.Echo){
      window.Echo.disconnect();
      window.Echo = null;
    }
  }

  static getAccessToken() {
    return API.accessToken;
  }

  static get(resourceUrl) {
    const source = Axios.CancelToken.source();
    const cancel = source.cancel;
    const run = async function () {
      let response;
      response = await API.axios.get(resourceUrl, {
        headers: {
          Authorization: `Bearer ${API.accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    };
    return [run, cancel];
  }


  // static getBase64(resourceUrl) {
  //   const source = Axios.CancelToken.source();
  //   const cancel = source.cancel;
  //   const run = async function () {
  //     let response;
  //     response = await API.axios.get(resourceUrl, {
  //       headers: {
  //         Authorization: `Bearer ${API.accessToken}`,
  //       },
  //       responseType: "arraybuffer",
  //       cancelToken: source.token,
  //     });
  //
  //     Buffer.from(response.data, "binary").toString("base64");
  //     return response.data;
  //   };
  //   return [run, cancel];
  // }

  static post(resourceUrl, data) {
    const source = Axios.CancelToken.source();
    const cancel = source.cancel;
    let preparedData = this._convertJsonToFormDataIfFile(data);

    const run = async function () {
      let response;
      response = await API.axios.post(resourceUrl, preparedData, {
        headers: {
          Authorization: `Bearer ${API.accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    };
    return [run, cancel];
  }

  static put(resourceUrl, data) {
    data["_method"] = "PUT";
    return this._putOrPatch(resourceUrl, data);
  }

  static patch(resourceUrl, data) {
    data["_method"] = "PATCH";
    return this._putOrPatch(resourceUrl, data);
  }

  static delete(resourceUrl) {
    const source = Axios.CancelToken.source();
    const cancel = source.cancel;
    const run = async function () {
      let response;
      response = await API.axios.delete(resourceUrl, {
        headers: {
          Authorization: `Bearer ${API.accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    };
    return [run, cancel];
  }

  static getBasePath() {
    return BASE_PATH;
  }
  static getBasePath2() {
    return   BASE_PATH_DOIS;
  }


  static _putOrPatch(resourceUrl, data) {
    const source = Axios.CancelToken.source();
    const cancel = source.cancel;
    let preparedData = this._convertJsonToFormDataIfFile(data);

    const run = async function () {
      let response;
      response = await API.axios.post(resourceUrl, preparedData, {
        headers: {
          Authorization: `Bearer ${API.accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    };
    return [run, cancel];
  }

  static _convertJsonToFormDataIfFile(json) {
    let formData = new FormData();
    let hasAFile = false;
    for (let key in json) {
      if (json[key] instanceof File) {
        hasAFile = true;
      }
      formData.append(key, json[key]);
    }
    return hasAFile ? formData : json;
  }
};

export default API;
