import React, {} from "react";
import ChangeUsers from "./ChangeUsers";

function SelectUsuario(){
  return(
    <div id="contentDash">
      <>
        <div id="selectYourProfile">
          <ChangeUsers/>
        </div>
      </>
    </div>  
  );
}
export default SelectUsuario;