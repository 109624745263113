import React, { useCallback, useEffect, useState, useContext } from "react";
import API from "utils/API";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../contexts/NotificationContext";
import MenuCompleto from "../Admin/MenuCompleto";
import "../../assets/css/GerenciaUsers.css";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import Alert from 'react-bootstrap/Alert';

function GerenciarUsers () {
  const [usuarios, setUsuarios] = useState();
  const [usuario, setUsuario] = useState();
  const [responsavel, setResponsavel] = useState();
  const [teste, setTeste] = useState();
  const [show, setShow] = useState(false);
  const [agencia, setAgencia] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show3, setShow3] = useState(false);
  const { authUser } = useContext(AuthUserContext);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [deletar, setDeletar] = useState();
  const [negocios, setNegocios] = useState();
  const notification = useContext(NotificationContext);
  const [filter, setFilter] = useState("");
  const [negocio] = useState();
  const [ativo, setAtivo] = useState(false);
  let empresa = "nome";
  const [show5, setShow5] = useState(false);

function Search(event) {
  setFilter(event.target.value);
}

  const setForm = useCallback(
    (n) => {
      const keys = [
        "id",
        "username",
        "path",
        "ativo",
        "nome_completo",
        "email",
        "role",
        "usuarios",
        "negocios",
        "dados",
        "projecoes",
        "calendario",
        "materiais",
        "equipe",
        "noticias",
        "password",
        "role"
      ];
      keys.forEach(key => setValue(key, n[key]));
    },
    [setValue]
  );


  useEffect(() => {
    const [run] = API.get("negocios");
      run().then((response) => {
          setNegocios(response);
      });
    }, []);


  useEffect(() => {
    const [run] = API.get("usuarios");
      run().then((response) => {
          setUsuarios(response);
      });
    }, []);

  useEffect(() => {
    if (show && usuario) {
      setForm(usuario);
    }
  }, [show, usuario, setForm]);

  useEffect(() => {
    if (show && negocio) {
      setForm(negocio);
    }
  }, [show, negocio, setForm]);


  function deleteThis(idd){
    const [run] = API.delete(`usuarios/${idd}/deletar`);
      run().then(() => {
        refreshPage();
      });
  }

  function clickInativar(ativo) {
    const data = {};
      data.ativo = ativo;
      const [run] = API.put(`usuarios/${usuario.id}/ativar`, data);
      run().then(() => {
        refreshPage();
    });
  }

  function removerEquipe(negocio){
    const data = {};
      data.equipe_id = 0;
    const [run] = API.put(`negocios/${negocio.id}/equiperemove`, data);
      run().then(() => {
        refreshPage();
      });
  }

  function removerEquipe1(negocio){
    const data = {};
      data.equipe1_id = 0;
      const [run] = API.put(`negocios/${negocio.id}/equipe1remove`, data);
      run().then(() => {
        refreshPage();
      });
  }

  function removerEquipe2(negocio){
    const data = {};
    data.equipe2_id = 0;
    const [run] = API.put(`negocios/${negocio.id}/equipe2remove`, data);
    run().then(() => {
      refreshPage();
    });
  }

  function removerEquipe3(negocio){
    const data = {};
    data.equipe3_id = 0;
    const [run] = API.put(`negocios/${negocio.id}/equipe3remove`, data);
    run().then(() => {
      refreshPage();
    });
  }

  function removerEquipe4(negocio){
    const data = {};
    data.equipe4_id = 0;
    const [run] = API.put(`negocios/${negocio.id}/equipe4remove`, data);
    run().then(() => {
      refreshPage();
    });
  }

  function removerAdmin(negocio){
    const data = {};
    data.admin_id = 0;
    const [run] = API.put(`negocios/${negocio.id}/adminremove`, data);
    run().then(() => {
      refreshPage();
    });
  }

  function EditarUsuarioModal(usuario) {
    setUsuario(usuario);
    setTeste(usuario.id);
    usuario.negocios.map((negocio) =>   setResponsavel((negocio.id)));
    handleShow();
  }

  function InativarUsuarioModal(usuario) {
    setUsuario(usuario);
    setTeste(usuario.id);
    usuario.negocios.map((negocio) =>   setResponsavel((negocio.id)));
    handleShow3(usuario);
  }

  function DeletarUsuarioModal(usuario) {
    setUsuario(usuario);
    setDeletar(usuario.id);
    setTeste(usuario);
    usuario.negocios.map((negocio) =>   setResponsavel((negocio.id)));
    handleShow4(usuario);
  }

  function removerNeg(neg){
    const [run] = API.delete(`usuarios/${usuario.id}/removerUser`);
    run().then(() => {
      refreshPage();
    });
  }

  function refreshPage() {
    window.location.reload();
  }

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
          );
      }
      return true;
    });
      return (" | " + empresa + " | ");
  }

  function updateUsuario(){
    const [run] = API.get("usuarios");
    run().then((response) => {
      setUsuarios(response);
    });
  }

  function onSubmit(data) {
    const [run] = API.put(`usuarios/${usuario.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setUsuario(response);
        notification.addNotification("Usuário atualizado com sucesso.");
        updateUsuario();
        handleClose();
      })
      .catch((error) => {
        setShow5(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function filtertipo(agencia){
    setAgencia(agencia);
    setAtivo("All");
  }

  return (
    <div className="content">
      <MenuCompleto/>
        <div className="tituloGerenciaUsers">Gerenciar Usuários</div>
          {
            usuarios && usuarios.map((usuario) => {
              if(usuario.id === authUser.id &&(authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.usuarios === 1))){
                return(
                  <div>
                    <div id="gerenciar_users">
                      <p className="filtering">
                        Mostrando apenas:
                          <button className="filter" onClick={() => setAtivo("All")}>
                            <i className="fas fa-filter"/> Todos
                          </button>
                          <button className="filter" onClick={() => setAtivo(!ativo)}>
                            <i className="fas fa-filter"/> {ativo ? "Usuários Inativos" : "Usuários Ativos"}
                          </button>
                          <button className="filter" onClick={() => filtertipo(!agencia)}>
                            <i className="fas fa-filter"/>  {agencia ? "Equipe G6" : "Clientes"}
                          </button>
                          <div className="input-group input-group-sm mb-3 rounded srch col-2 float-right">
                            <input id="search_box" onChange={Search} placeholder="Pesquisar" type="search" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                            <div className="input-group-prepend rounded">
                              <span className="rounded-right input-group-text" id="inputGroup-sizing-sm"><i className="fas fa-search"/></span>
                            </div>
                          </div>
                      </p>
                      <table className="tableusr">
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">User</th>
                            <th className="text-center">Usuário</th>
                            <th className="text-center">Empresa</th>
                            <th className="text-center">Opções</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          usuarios && usuarios.map((usuario) => {
                            let bo;
                            if (filter === "") {
                              if (ativo === true) {bo = 0;}
                              else if (ativo === false) {bo = 1;}

                              else if (agencia === true){bo = 2;}
                              else if (agencia === false){bo= 3;}

                              else if (ativo === "All"){bo = "All"}


                              if (usuario.ativo === bo && bo === 1) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr">{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes inativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Inativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                              else if (usuario.ativo === bo && bo === 0) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr">{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes ativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Ativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                              else if (usuario.ativo === 1 && bo === 2 && (usuario.role !== "cliente")) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr">{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes inativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Inativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }else if (usuario.ativo === 0 && bo === 2 &&(usuario.role !== "cliente")) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr">{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes ativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Ativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else if (usuario.ativo === 1 && bo === 3 && (usuario.role === "cliente")) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr">{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes inativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Inativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }else if (usuario.ativo === 0 && bo === 3 &&(usuario.role === "cliente")) {
                                return (
                                  <tr className="tr-border" key={usuario.id}>
                                    <td className="text-center">{usuario.id}</td>
                                    <td className="text-center usr" >{usuario.username}</td>
                                    <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                    <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                    <td className="text-center">
                                      <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                        Editar
                                      </button>
                                      <button className="acoes ativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                        Ativar
                                      </button>
                                      <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                        Deletar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            }
                            else if(usuario.id.toString().toLowerCase().includes(filter.toLowerCase()) || usuario.username.toString().toLowerCase().includes(filter.toLowerCase())){
                              return (
                                <tr className="tr-border" key={usuario.id}>
                                  <td className="text-center">{usuario.id}</td>
                                  <td className="text-center usr" >{usuario.username}</td>
                                  <td className="text-center usr_nome">{usuario.nome_completo}</td>
                                  <td className="text-center emp">{(usuario.negocios.map((negocio) => negocioEnvolvido((negocio.id) )))} </td>
                                  <td className="text-center">
                                    <button className="acoes editar" onClick={EditarUsuarioModal.bind(null, usuario)}>
                                      Editar
                                    </button>
                                    {usuario.ativo === 1 ? (
                                      <>
                                        <button className="acoes inativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                          Inativar
                                        </button>
                                          <>
                                            <button
                                              className="acoes deletarUser"
                                              onClick={DeletarUsuarioModal.bind(null, usuario)}
                                            >
                                              Deletar
                                            </button>
                                          </>
                                      </>
                                    ) : (
                                      <>
                                        <button className="acoes ativar" onClick={InativarUsuarioModal.bind(null, usuario)}>
                                          Ativar
                                        </button>
                                        <button className="acoes deletarUser" onClick={DeletarUsuarioModal.bind(null, usuario)}>
                                          Deletar
                                        </button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                            return true;
                          })
                        }
                        </tbody>
                      </table>
                    </div>



                    <Modal className="gerenUser" data-keyboard={true} backdrop={true} show={show} >
                      <Button className="closeButton" onClick={handleClose}>X</Button>
                      <Modal.Body>
                        <Row className="w-100 justify-content-center">
                          <div className="titleGenUser">Editar Usuário</div>
                        </Row>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div>
                            <input  className="inputUsersEdit d-none"  type="text" placeholder="Id"  name="id" ref={register} readOnly></input>
                            <Row>
                              <Col md="6">
                                <label><div className="red"> Nome Completo* </div></label><br></br>
                                <input  className="inputUsersEdit" id="nome"  type="text" placeholder="Nome Completo"  name="nome_completo" ref={register} ></input>
                              </Col>
                              <Col md="6">
                                <label><div className="red"> Nome de usuário* </div></label><br></br>
                                <input  className="inputUsersEdit"  id="user" type="text" placeholder="Nome de usuário"  name="username" ref={register} ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <label><div className="red">E-mail* </div></label><br></br>
                                <input  className="inputUsersEdit" id="email" type="text" placeholder="E-mail"  name="email" ref={register} ></input>
                              </Col>

                              <Col md={6}>
                                <label><div className="red">Tipo de usuário*</div></label><br></br>
                                <Form.Control className="inputUsersEdit" id="role" as="select" ref={register} name="role" label="Role">
                                  <option>cliente</option>
                                  <option>admin</option>
                                  <option>equipe</option>
                                </Form.Control>
                              </Col>
                            </Row>
                            <div className="listaPermissoes pt-3">
                              <Row>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar usuários
                                    <input type="checkbox" name="usuarios" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                <label className="containerEquipe">Gerenciar negócios
                                    <input type="checkbox" name="negocios" ref={register}/>
                                </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar dados
                                    <input type="checkbox" name="dados" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar projeções
                                    <input type="checkbox" name="projecoes" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar calendário
                                    <input type="checkbox" name="calendario" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar materiais
                                    <input type="checkbox" name="materiais" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar equipe
                                    <input type="checkbox" name="equipe" ref={register}/>
                                  </label>
                                </Col>
                                <Col md={3}>
                                  <label className="containerEquipe">Gerenciar notícias
                                    <input type="checkbox" name="noticias" ref={register}/>
                                  </label>
                                </Col>
                              </Row>
                            </div>
                            <Col className="col-md-12 pt-3 mt-4 text-center">
                              <Button className="botaoRosa" type="submit"
                                              disabled={isLoading}>
                                              Atualizar Usuário
                              </Button>
                            </Col>
                            <div className="clearfix" />
                          </div>
                        </Form>
                        <Row className="w-100 justify-content-center">
                          <div className="titleGenUser d-flex justify-content-center">Negócios relacionados</div>
                            {
                              negocios && negocios.map((negocio) =>{
                              if(teste === negocio.equipe_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Equipe</td>
                                    <td><button className="Remove" onClick={removerEquipe.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(teste === negocio.equipe1_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Equipe</td>
                                    <td><button className="Remove" onClick={removerEquipe1.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(teste === negocio.equipe2_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Equipe</td>
                                    <td><button className="Remove" onClick={removerEquipe2.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(teste === negocio.equipe3_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Equipe</td>
                                    <td><button className="Remove" onClick={removerEquipe3.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(teste === negocio.equipe4_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Equipe</td>
                                    <td><button className="Remove" onClick={removerEquipe4.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(teste === negocio.admin_id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Admin</td>
                                    <td><button className="Remove" onClick={removerAdmin.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }else if(responsavel === negocio.id){
                                return(
                                  <tr className="listA">
                                    <td>Empresa: {negocio.nome}</td>
                                    <td> Cargo: Responsável</td>
                                    <td><button className="Remove" onClick={removerNeg.bind(null,negocio)}>x Remover</button></td>
                                  </tr>
                                );
                              }
                              return true;
                              })
                            }
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                );
            }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.usuarios === 0)){
              return(
                <Row className="w-100 justify-content-center ">
                  <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
                </Row>
              )
            }
            return true;
          })
        }

        <Alert show={show5} className="alertFields">
          <Alert.Heading>Erro de cadastro</Alert.Heading>
            <p>
              Verifique se os campos <a href="#nome">Nome completo</a>, <a href="#user">Nome de usuário</a>, <a href="#email">E-mail</a> e
              <a href="role">Tipo</a> estão preenchidos! Eles são campos obrigatórios.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow5(false)} className="btnOk">
              Ok!
              </Button>
            </div>
        </Alert>

        <Modal className="aviso" data-keyboard={false} backdrop={false} show={show3} >
          <Button className="closeButton" onClick={handleClose3}>X</Button>
            <Modal.Body>
              Ação a ser realizada:
              <div className="buttons">
                <button className="areYouSure" onClick={clickInativar.bind(null,1)}>Ativar usuário</button>
                <button className="notSure" onClick={clickInativar.bind(null,0)}>Inativar usuário</button>
              </div>
            </Modal.Body>
        </Modal>

        <Modal className="deleteAviso" data-keyboard={false} backdrop={false} show={show4} >
          <Button className="closeButton" onClick={handleClose4}>X</Button>
            <Modal.Body>
              <Row className="w-100 justify-content-center">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><br></br>
              </Row>
              <Row className="w-100 justify-content-center">
                Tem certeza que deseja deletar o usuário?
              </Row>
              <Row className="w-100 justify-content-center">
                <div className="buttons">
                  <button className="areYouSure" onClick={deleteThis.bind(null,deletar)}>Sim, deletar.</button>
                  <button className="notSure" onClick={handleClose4}>Não, retornar.</button>
                </div>
              </Row>
            </Modal.Body>
        </Modal>
    </div>
  );
}

GerenciarUsers.propTypes = {
};
export default GerenciarUsers;
