import React, { useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import "../../assets/sass/navbarlinks.scss";
import "../../assets/css/side.css";
import Dropdown from 'react-bootstrap/Dropdown';
import API from "utils/API";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import Cookies from "js-cookie";
import {Row} from "react-bootstrap";

function MenuCompleto(){

  const history = useHistory();
  const [usuarios, setUsuarios] = useState();
  const { authUser } = useContext(AuthUserContext);
  var img = '0';
  var imgUser = '0';
  var nomeUser = 'Administrador';
  const defaultAvatar = 'https://i.imgur.com/iabZfY7.jpg';


  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

function ProfilePic(){
  usuarios && usuarios.map((usuario) => {
    if(usuario.id === authUser.id){
     img = usuario.path;
      return img;
    }
    return img;
  })
  if(img){
    return imgUser = img;
    }else{
      return imgUser = 0;
    }
}

function userName(){
  usuarios && usuarios.map((usuario) => {
   if(usuario.id === authUser.id){
     nomeUser = usuario.username;
   }
   return nomeUser;
 })
 return nomeUser;
 }

 function logout() {
  Cookies.set(
    "accessToken",
    "logout"
  );
  document.location.reload();
}
 
ProfilePic();
userName();

const Noticias = () =>{ 
  history.push("/gerenciar_noticias");
}

  const NewUser = () =>{ 
    history.push("/registro_usuarios");
  }
  const NewNegocio = () =>{ 
    history.push("/criar_negocio");
  }

  const Negocios = () =>{ 
    history.push("/gerenciar_negocios");
  }

  const NewProjecao = () =>{ 
    history.push("/projecoes");
  }


  const EditProjecao = () =>{ 
    history.push("/editar/projecao");
  }

  const RegistrarAds = () =>{ 
    history.push("/registrar_fb");
  }

  const RegistrarAnalytics = () =>{
    history.push("registrar_analytics");
  }


  const newData = () =>{ 
    history.push("/listarhistoricos");
  }
  const newHistory = () =>{ 
    history.push("/novo_historico");
  }



  const criarLeads = () =>{ 
    history.push("/Novolead");
  }
  
  const EditarLeads = () =>{
    history.push("/editarleads/negocios/1");
  }

  const NovoPlanejamento = () =>{
    history.push("/novoPlanejamento");
  }
  


  const GerenciaUser = () =>{ 
    history.push("/gerenciar_usuarios");
  }

  const RegistrarMateriais = () =>{ 
    history.push("/registrar_materiais");
  }

  const adiconarBoletos = () =>{ 
    history.push("/adicionar_boletos");
  }

  const adicionarNotas = () =>{ 
    history.push("/adicionar_notas/negocios/");
  }

  
  const listarBoletos = () =>{ 
    history.push("/listar_boletos");
  }


  const userModule = () =>{ 
    history.push("/selectNegocio");
  }

  
  const GerenciarEquipe = () =>{ 
    history.push("/gerenciarequipe");
  }

function Avatare(){
  if(imgUser !== 0){
    return API.getBasePath()+imgUser;
  }
  else{
    return defaultAvatar;
  }
}

// Close the dropdown menu if the user clicks outside of it
return(
  <Row className="w-100 justify-content-center"> 
    <div id="mySidenav" className="sidenav">
        {
          usuarios && usuarios.map((usuario) => {
            if(usuario.id === authUser.id){
              return(
                <button className="profilebutton w-100 text-center" key="">
                    <img className="profileAdmin pl-1" src={Avatare()} alt="ImagemPerfil"/>
                </button>
              );
            }
            return true;
          })   
        }

      <div className="nomePessoa">Olá, {nomeUser}</div>
      <Dropdown>
        <Dropdown.Toggle className="menuButton" id="dropdown-basic">
          Usuários 
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={NewUser}>Registrar usuário</Dropdown.Item>
          <Dropdown.Item onClick={GerenciaUser}>Gerenciar usuários</Dropdown.Item>
          <Dropdown.Item onClick={adicionarNotas}>Notas fiscais</Dropdown.Item>
          <Dropdown.Item onClick={adiconarBoletos}>Adicionar Boletos</Dropdown.Item>
          <Dropdown.Item onClick={listarBoletos}>Listar Boletos</Dropdown.Item>
          <Dropdown.Item onClick={newHistory}>Adicionar a histórico</Dropdown.Item>
          <Dropdown.Item onClick={newData}>Listar histórico</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle className="menuButton" id="dropdown-basic">
          Negócios
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={NewNegocio}>Registrar negócios</Dropdown.Item>
          <Dropdown.Item onClick={Negocios}>Gerenciar negócios</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle className="menuButton" id="dropdown-basic">
          Consolidados
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={criarLeads}>Registrar Consolidados</Dropdown.Item>
          <Dropdown.Item onClick={EditarLeads}>Gerenciar Consolidados</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle className="menuButton" id="dropdown-basic">
          Projeções
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={NewProjecao}>Registrar projeções</Dropdown.Item>
          <Dropdown.Item onClick={EditProjecao}>Gerenciar projeções</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      
      <Dropdown>
        <Dropdown.Toggle className="menuButton" id="dropdown-basic">
          Tempo Real
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={RegistrarAds}> Facebook Ads</Dropdown.Item>
          <Dropdown.Item onClick={RegistrarAnalytics}> Google Analytics</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <button className="singleButton" onClick={NovoPlanejamento}>Calendário</button>
      <button className="singleButton" onClick={RegistrarMateriais}>Materiais</button>
      <button className="singleButton" onClick={GerenciarEquipe}>Gerenciar equipe</button>
      <button className="singleButton" onClick={Noticias}>Gerenciar notícias</button>
      <button className="singleButton" onClick={userModule}>Modo usuário</button>
      <button className="singleButton" onClick={logout} >
              <a href="/">  <i className="fa fa-power-off"></i> Sair</a> 
      </button> 
    </div>
  </Row>

);
  
}
export default MenuCompleto;