import React, { useState, useEffect, useContext } from "react";
import NavMenu from "components/Navbars/NavMenu";
import {Tooltip} from "recharts";
import { PieChart, Pie } from 'recharts';
import {  Cell} from 'recharts';
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/css/projecaoCliente.css";
import API from "utils/API";
import {  Row, Modal, Button,Col } from "react-bootstrap";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function Cpl(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  var mes = 0;
  let cplanteriorC = "0";
  let cplFbC = "0";
  let cplgC = "0";
  var monthh = " ";

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

  const Metas = () =>{ 
    history.push(`/metas/${authUser.active_negocio_id}`);
  }

  function openModal2() {
    handleShow2();
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }
  
  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
      monthh = "Janeiro";
    } else if (mes === 2) {
      monthh = "Fevereiro";
    } else if (mes === 3) {
      monthh = "Março";
    } else if (mes === 4) {
      monthh = "Abril";
    } else if (mes === 5) {
      monthh = "Maio";
    } else if (mes === 6) {
      monthh = "Junho";
    } else if (mes === 7) {
      monthh = "Julho";
    } else if (mes === 8) {
      monthh = "Agosto";
    } else if (mes === 9) {
      monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
    }
   return monthh;
  }
  
  function getCPL(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.mes === translateMonth()) {
        const CPL_ant = projecao.cplAnterior;
        const CPL_fb = projecao.cplFb;
        const CPL_g = projecao.cplGoogle;
        return (
          cplanteriorC = parseFloat(CPL_ant),
          cplFbC = parseFloat(CPL_fb),
          cplgC = parseFloat(CPL_g)
        );
      }
      return(cplanteriorC, cplFbC, cplgC);
    });
  }

  const getIntroOfPage = (label) => {
    if (label === 'Page A') {
      return "Page A is about men's clothing";
    }
    if (label === 'Page B') {
      return "Page B is about women's dress";
    }
    if (label === 'Page C') {
      return "Page C is about women's bag";
    }
    if (label === 'Page D') {
      return 'Page D is about household goods';
    }
    if (label === 'Page E') {
      return 'Page E is about food';
    }
    if (label === 'Page F') {
      return 'Page F is about baby food';
    }
    return '';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Valor de CPL: ${payload[0].value.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}`}</p>
          <p className="intro">{getIntroOfPage(label)}</p>
        </div>
      );
    }
    return null;
  };

  getCPL();

  const dataCPL = [
    { name: 'CPL Anterior', value: cplanteriorC },
    { name: 'CPL Facebook', value: cplFbC },
    { name: 'CPL Google', value: cplgC },
  ];
  
  const COLORS = ['#f7325a', '#fe94b6', '#fe799d'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return(
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

return(
  <div id="contentDash">
    <NavMenu/> 
    <button className="back" onClick={Metas}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
    <Row className="w-100 justify-content-center">
      <div className="whiteBgCPL">
        <Row className="w-100 justify-content-center"> 
          <button className="btnCalCPL" onClick={openModal2}><i className="fa fa-calendar"></i></button>
          <div className="metaFaturamentoTitle">CPL </div>
        </Row>
        <Row className="w-100 justify-content-center">
          <div className="barchart">
            <PieChart  className="pchartCPL" width={900} height={550}>
              <Pie
                data={dataCPL}
                cx="50%"
                cy="40%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius="70%"
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={true}
               >
                {
                  dataCPL.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </div>
          <Row className="w-100 justify-content-center">
            <div className="legendascpl">
              <button className="legendButtonCplA"></button>Anterior: R${cplanteriorC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}       
              <button className="legendButtonCplFb"></button> Facebook: R${cplFbC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}  
              <button className="legendButtonCplG"></button> Google: R${cplgC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}
            </div>
          </Row>
        </Row>
        <Row className="w-100 fixed-bottom justify-content-left">
          <LastAccess/>
        </Row>
        <Modal className="selectMonthMetaInvest" data-keyboard={true} backdrop={true} show={show2} >
          <Button className="closeButton">Selecione o mês ({getYear()})</Button>
            <Modal.Body>
              <Row className="w-100 justify-content-center">
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
                </Col>      
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
                </Col>
              </Row>
              <Row className="w-100 justify-content-center">
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
                </Col>      
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
                </Col>
              </Row>
              <Row className="w-100 justify-content-center">
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
                </Col>      
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
                </Col>
                <Col md={3}>
                  <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
                </Col>
              </Row>
            </Modal.Body>
        </Modal>
      </div>
    </Row>
    <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
    <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
    <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
  </div>
);
}

export default Cpl;