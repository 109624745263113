import React, { useContext, useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { Modal, Button } from "react-bootstrap";
import { getRoutesFor } from "routes.js";
import { AuthUserContext } from "../contexts/AuthUserContext.js";
import { NotificationContext } from "../contexts/NotificationContext.js";
import styled from "styled-components";
import sup from "../assets/img/Sup.png";
import Suporte from "views/User/Suporte";

const style = {
  "NotificationItem": {
    "DefaultStyle": {
      "margin": "10px 5px 2px 1px"
    },

    "success": {
      "color": "#000000"
    }
  }
};

const Layout = styled.div`

@media only screen and (max-width: 1024px){

#bodyLayout{
  display: flex;
  flex-direction: column;
  margin-bottom:10rem;
  min-height: 100vh !important;
}

}


@media only screen and (max-width: 540px){

#bodyLayout{
  margin-bottom:25rem;
  min-height: 100vh !important;
}

}
@media only screen and (max-width: 500px){

#bodyLayout{
  margin-bottom:15rem;
  min-height: 100vh !important;
}

}
@media only screen and (max-width: 375px){

#bodyLayout{
  margin-bottom:15rem;
  min-height: 100vh !important;
}

}
`;


function Main(props) {
  const { authUser } = useContext(AuthUserContext);
  const [routes, setRoutes] = useState();
  const notificationSystem = useRef();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (authUser) {
      setRoutes(getRoutesFor(authUser.role));
    }
  }, [authUser]);
  function openModal() {
    handleShow();
  }
  function handleClose(){
    setShow(false);
  }

  function getRoutes() {
    if (routes) {
      return routes.map((group) => group.routes.map((route) => (
        <Route
          path={route.path}
          render={() => <route.component {...props} />}
          key={route.path}
        />
      )));
    }
    return null;
  }

  return (
    <>
      <NotificationContext.Provider
        value={{ "addNotification": (message, level = "success", position = "tc") => {
          notificationSystem.current.addNotification({
            message,
            level,
            position
          });
        } }}
      >
        <NotificationSystem style={style} ref={notificationSystem}/>
<Layout>
        <div id="bodyLayout" className="">

          <Switch>
            {getRoutes(routes)}
            <Route exact path="/">
              <Redirect to="dashboard" />
            </Route>
            <Route exact path="/login">
              <Redirect to="dashboard" />
            </Route>
          </Switch>

        </div>
        <button className="supButton" onClick={openModal}>
          <img alt="suporte" src={sup}/>
        </button>
          
        <Modal className="suporte"  data-keyboard={false} backdrop={false} show={show} >

        <Button className="closeButton" onClick={handleClose}>X</Button>
        <Modal.Body>
        <Suporte/>
        </Modal.Body>
        </Modal>
 </Layout>
 
      </NotificationContext.Provider>
    </>
    
  );
}

export default Main;
