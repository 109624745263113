import React, { useState, useEffect, useContext } from "react";
import NavMenu from "components/Navbars/NavMenu";
import { RadialBarChart, RadialBar } from 'recharts';
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/css/projecaoCliente.css";
import API from "utils/API";
import {  Row, Modal, Button,Col } from "react-bootstrap";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function TotalInvestido(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  let totalMensal = "0";
  let totalMensalPercent = "0";
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  var monthh = " ";
  var mes = 0;
  
  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);
  

  const Metas = () =>{ 
    history.push(`/metas/${authUser.active_negocio_id}`);
  }

  getInvestTotal();
  const data = [
    {
      name: "Investimento indicado",
      uv: parseFloat(totalMensalPercent).toFixed(2),
      pv: 2400,
      fill: "#f7325a"
    },
    {
      name: "Investimento atual",
      uv: parseFloat(totalMensal).toFixed(2),
      pv: 4567,
      fill: "#fe799d"
    }
  ];

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  function openModal2() {
    handleShow2();
  }
  
  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }

  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }


  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
      monthh = "Janeiro";
    } else if (mes === 2) {
      monthh = "Fevereiro";
    } else if (mes === 3) {
      monthh = "Março";
    } else if (mes === 4) {
      monthh = "Abril";
    } else if (mes === 5) {
      monthh = "Maio";
    } else if (mes === 6) {
      monthh = "Junho";
    } else if (mes === 7) {
      monthh = "Julho";
    } else if (mes === 8) {
      monthh = "Agosto";
    } else if (mes === 9) {
      monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
    }
    return monthh;
  }

  function getInvestTotal(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.mes === translateMonth()) {
        const f1 = projecao.fbq1;
        const f2 = projecao.fbq2;
        const f3 = projecao.fbq3;
        const f4 = projecao.fbq4;
        const g1 = projecao.ads1;
        const g2 = projecao.ads2;
        const g3 = projecao.ads3;
        const g4 = projecao.ads4;
        const meta = projecao.metaTotal;
        const redespercent = projecao.metaPorcentagem;
        const tot =  (parseFloat(f1) + parseFloat(f2) + parseFloat(f3) + parseFloat(f4) + parseFloat(g1) + parseFloat(g2) + parseFloat(g3) + parseFloat(g4));
        const totPercent = ((parseFloat(meta) * (redespercent/100) * 0.08));
        return (
          totalMensal = tot,
          totalMensalPercent = totPercent
        );
      }
      return (
        totalMensal,
        totalMensalPercent
        );
    });
  }
  
  return(
    <div id="contentDash">
      <NavMenu/>
        <button className="back" onClick={Metas}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
        <Row className="w-100 justify-content-center">
          <div className="whiteBg">
            <Row className="w-100 justify-content-center"> 
              <button className="btnCalInvest" onClick={openModal2}><i className="fa fa-calendar"></i></button>
              <div className="metaFaturamentoTitle">Total investido</div>
            </Row>
            <Row className="w-100 justify-content-center">
              <div className="barchart">
                <RadialBarChart
                    className="pchartTotal"
                      width={950}
                      height={550}
                      cx="50%"
                      cy="40%"
                      innerRadius="45%"
                      outerRadius="90%"
                      barSize={20}
                      data={data}
                    >
                    <RadialBar
                      isAnimationActive={true}
                      minAngle={30}
                      label={{ position: "insideStart", fill: "#fff"}}
                      background= {{fill:"#8c8c8c"}}
                      clockWise
                      dataKey="uv"
                    />
                </RadialBarChart>
                  <div className="legendaRadial"><button className="legendButtontot"></button> Investimento atual: R$ {totalMensal.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
                  <div className="legendaRadial2"><button className="legendButtonindi"></button>  Investimento indicado: R$ {totalMensalPercent.toLocaleString("pt-br", { "maximumFractionDigits": 2 })}</div>                 
              </div>
            </Row>
            <Row className="w-100 fixed-bottom justify-content-left">
              <LastAccess/>
            </Row>
            <Modal className="selectMonthMetaInvest" data-keyboard={true} backdrop={true} show={show2} >
              <Button className="closeButton">Selecione o mês ({getYear()})</Button>
              <Modal.Body>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
                  </Col>
                </Row>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
                  </Col>
                </Row>
                <Row className="w-100 justify-content-center">
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
                  </Col>      
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
                  </Col>
                  <Col md={3}>
                    <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
        </div>
      </Row>
      <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
      <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
      <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );
}

export default TotalInvestido;