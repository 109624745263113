import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import PrintLeads from './PrintLeads';

function ClickToPrintLeads(){
  let componentRef = useRef();
return(
  <>
    <div>
      <ReactToPrint
        trigger={() => <button className="printResultados"> <i className="fa fa-print"></i></button>}
        content={() => componentRef}
      />
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={(el) => (componentRef = el)} />
      </div>
    </div>
  </>
  );
};

class ComponentToPrint extends React.Component {
  render() {
    return (
      <PrintLeads/>
    );
  }
}


export default ClickToPrintLeads;