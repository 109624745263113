import { send, init } from "emailjs-com";

const serviceId = "service_a9rn1lt";
const templateId = "template_ymb5z0s";
const userID = "vd7Ti1sZ1pMwygtUR";

const sendEmail = (content) => {
  init(userID);
  const toSend = {
    from_name: content.from_name,
    to_name: content.to_name,
    subject: content.subject,
    to_email: content.to_email,
    message: content.message,
  };
  send(serviceId, templateId, toSend)
    .then((res) => {
      console.log(res);
      window.location.reload(false);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default { sendEmail };