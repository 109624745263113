import React, { useEffect, useState, useContext,useCallback } from "react";
import MenuCompleto from "../Admin/MenuCompleto";
import API from "utils/API";
import { Row,Modal, Col, FormGroup,Form, FormControl, Button } from "react-bootstrap";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useForm } from "react-hook-form";
import "../../assets/css/fbregister.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { AuthUserContext } from "../../contexts/AuthUserContext";
import ChangeAnalytics from "./ChangeAnalytics";


function CriarAnalytics(){
  const [negocios, setNegocios] = useState();
  const [analytics, setAnalytics] = useState();
  const [analytic, setAnalytic] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const notification = useContext(NotificationContext);
  const [usuarios, setUsuarios] = useState();
  const { authUser } = useContext(AuthUserContext);
  let empresa = "nome";
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

  const setForm = useCallback(
    (n) => {
      setValue("id", n.id);
      setValue("viewId", n.viewId);
      setValue("clientId", n.clientId);
      setValue("clientSecret", n.clientSecret);
      setValue("token", n.token);
      setValue("negocio_id", n.negocio_id);
    },
    [setValue]
  );

  function refreshPage() {
    window.location.reload();
  }

  function translateMonth() {
    const mes = new Date().getMonth() +1;
    if (mes === 1) {
      return "Janeiro";
    } else if (mes === 2) {
      return "Fevereiro";
    } else if (mes === 3) {
      return "Março";
    } else if (mes === 4) {
      return "Abril";
    } else if (mes === 5) {
      return "Maio";
    } else if (mes === 6) {
      return "Junho";
    } else if (mes === 7) {
      return "Julho";
    } else if (mes === 8) {
      return "Agosto";
    } else if (mes === 9) {
      return "Setembro";
    } else if (mes === 10) {
      return "Outubro";
    } else if (mes === 11) {
      return "Novembro";
    } else if (mes === 12) {
      return "Dezembro";
    }
  }

    /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const IdNegocio = (it[5])
    var neg = IdNegocio;
    return (neg);
  }

  useEffect(() => {
    if (show && analytic) {
      setForm(analytic);
    }
  }, [show, analytic, setForm]);

  function negocioEnvolvido(neg) {
    negocios && negocios.map((negocio) => {
      if (neg === negocio.id) {
        return (empresa = (negocio.nome)
        );
      }
      return true;
    });
    return (empresa);
  }

  function onSubmit2(data) {
    const [run] = API.put(`analytics/${analytic.id}`, data);
    setIsLoading(true);
    run()
      .then((response) => {
        setAnalytic(response);
        notification.addNotification("Projeção atualizada com sucesso.");
        handleClose();
      })
      .catch((error) => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente ", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const [run] = API.get("negocios");
    run().then((response) => {
        setNegocios(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("analytics");
    run().then((response) => {
       setAnalytics(response);
    });
  }, []);

  useEffect(() => {
    const [run] = API.get("usuarios");
    run().then((response) => {
        setUsuarios(response);
    });
  }, []);

  function onSubmit(data) {
    const [run] = API.post("analytics", data);
    setIsLoading(true);
    run()
      .then(() => {
        notification.addNotification("Leads adicionados com sucesso.");
        setIsLoading(false);        
        refreshPage();
      })
      .catch(() => {
        notification.addNotification("Campos Preenchidos de maneira incorreta, tente novamente", "error");
        setIsLoading(false);
      });
  }

  function openModal(matParam) {
    setAnalytic(matParam);
    handleShow();
  }

  
  function formatDate(dateFromAPI) {
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);
      
    if(dateDay === "01" && dateMonth === "01" && dateYear === "00"){
      return "Sem registros feitos no mês atual";
    }else{
      return `${dateDay} de ${translateMonth(dateMonth)} de 20${dateYear}`;
    }
  }

  
  return(
  <div id="bodyNoticias">
    <MenuCompleto/>
    <div className="tituloMat">Gerenciar Google Analytics</div>
      {  
        usuarios && usuarios.map((usuario) => {
          if(usuario.id === authUser.id && (authUser.role === "admin"|| (authUser.role ==="equipe" && authUser.projecoes === 1))){
            return(
              <div>
                <Dropdown>
                  <Dropdown.Toggle className="dropFb" id="dropdown-basic">
                  Cadastrar Analytics 
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropFbhover">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={4}>
                            <label><div className="fbInput">Negócio*</div></label>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="selectionFb">
                              <FormControl  name="negocio_id" as="select" ref={register} >
                                {
                                  negocios && negocios.map((negocio) => (
                                    <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                                    ))
                                }
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label className="datalabel">View Id:</label>
                          </Col>
                          <Col md={1}>
                            <input type="string"  className="inputAnalytics" id="viewId" placeholder= "ga:1234567" ref={register}  name="viewId" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label className="datalabel">Client Id:</label>
                          </Col>
                          <Col md={1}>
                            <input type="string"  className="inputAnalytics" id="clientId" placeholder= "3" ref={register} value="272740448358-6ssnvs2rhar866amgvgfof82a0ao9n9i.apps.googleusercontent.com" name="clientId" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label className="datalabel">Secret Id:</label>
                          </Col>
                          <Col md={1}>
                            <input type="string"  className="inputAnalytics" id="clientSecret" placeholder= "3" ref={register} value="TXrWzFP5d-pUGrkoDT057IbR" name="clientSecret" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label className="datalabel">Token:</label>
                          </Col>
                          <Col md={1}>
                            <input type="string"  className="inputAnalytics" id="token" placeholder= "1//30232020222222" ref={register} name="token"/>
                          </Col>
                        </Row>
                        <Row className="w-100 justify-content-center ">
                          <Button className="botaoAnalytics"
                            type="submit"
                            disabled={isLoading}>
                                Salvar
                          </Button>
                        </Row>
                        <div className="clearfix" />
                      </form>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <ChangeAnalytics/>
                <div id="facebookAdd">
                  <table className="tableFacebooks">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Negócio</th>
                        <th className="text-center">Última atualização</th>
                        <th className="text-center">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        analytics && analytics.map((analytic) => {
                          if (analytic.negocio_id.toString() === getNegocioId()) {
                          return(
                            <tr key={analytic.id}>
                              <td className="text-center">{analytic.id}</td>
                              <td className="text-center grayClass">{negocioEnvolvido(analytic.negocio_id)} </td>
                              <td className="text-center grayClass">{formatDate(analytic.updated_at)}</td>
                              <td className="">
                                <button className="text-center fbBtn" onClick={openModal.bind(null, analytic)}>
                                    Editar
                                </button>
                              </td>
                            </tr>
                            );
                          }
                          return true;
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }else if(usuario.id === authUser.id &&( authUser.role ==="equipe" && authUser.analytics === 0)){
            return(
              <Row className="w-100 justify-content-center ">
                <div className="negado">Você não tem permissão para acessar esse módulo. <br></br> Contate um administrador.</div>
              </Row>
            )
          }
      return true;
    })
  }

  <Modal className="edicaoAnalytics" data-keyboard={false}  backdrop={false} show={show} >
    <Button className="closeButton" onClick={handleClose}>X</Button>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit2)}>
          <Row className="w-100 justify-content-center ">
            <div className="allinfo2">Informações Google Analytics</div><br></br>
          </Row>
          <Row>
            <Col md={1}>
              <label className="datalabel">Id:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputProjecoes3" id="id" placeholder= "3" ref={register} name="id" readOnly/>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={4}>
              <label><div className="fbInput">Negócio*</div></label>
            </Col>
            <Col md={4}>
              <FormGroup className="selectionFb">
                <FormControl  name="negocio_id" as="select" ref={register} >
                  {
                    negocios && negocios.map((negocio) => (
                      <option key={negocio.id} value={negocio.id}>{negocio.nome}</option>
                    ))
                  }
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="datalabel">View Id:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputAnalytics" id="viewId" placeholder= "ga:1234567" ref={register}  name="viewId" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="datalabel">Client Id:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputAnalytics" id="clientId" placeholder= "3" ref={register} value="272740448358-6ssnvs2rhar866amgvgfof82a0ao9n9i.apps.googleusercontent.com" name="clientId" readOnly/>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="datalabel">Secret Id:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputAnalytics" id="clientSecret" placeholder= "3" ref={register} value="TXrWzFP5d-pUGrkoDT057IbR" name="clientSecret" readOnly/>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="datalabel">Token:</label>
            </Col>
            <Col md={1}>
              <input type="string"  className="inputAnalytics" id="token" placeholder= "1//30232020222222" ref={register} name="token"/>
            </Col>
          </Row>
          <Row className="w-100 justify-content-center mt-4">
            <Col className="col-md-12 pt-3 text-center">
              <Button className="btnEditProj" type="submit"
                disabled={isLoading}>
                Atualizar Analytics
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
  </Modal>
</div>
  );
}

export default CriarAnalytics;