/*eslint-disable no-magic-numbers */
/*eslint-disable max-lines-per-function */
import React, {useState} from "react";
import { Form, Container, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/sass/login.scss";
import API from "../../utils/API";
import { PropTypes } from "prop-types";
import Button from "components/CustomButton/CustomButton.jsx";

export default function Login({ setToken }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show, setShow] = useState(true);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleClose(){
    setShow(false);
    window.location.assign("https://g6publicidade.com/");
  }

  function handleSubmit(e) {
    e.preventDefault();
    const [run] = API.post("login", { "username": email,
      password });
    run()
      .then((response) => {
        setToken(response.access_token);
      })
      .catch((error) => {
     openModal2();
        
    })
  }

  function openModal2() {
    handleShow2();
  }

  return (
    <div className="LoginAll">
      <Container fluid>
        <Row className="w-100 justify-content-center"> 
          <Modal className="errorLogin" data-keyboard={false} backdrop={true} show={show2} >
           <Button className="closeButton" onClick={handleClose2}>X</Button>
            <Modal.Body>
              <div className="error">
                <i class="fa fa-times-circle text-warning" aria-hidden="true"></i><br></br>
                Nome de usuário ou senha incorretos.
              </div>
            </Modal.Body>
          </Modal>
        </Row>
        <Row>
          <div id="navbar" className="conteiner-fluid">
          <nav className="navbar navbar-expand-md fixed-top">
              <div className="container-md w-md-100 justify-content-between">
              <button
                        className="navbar-toggler   pl-4"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                         <span className="navbar-toggler-icon">  <i className="fa fa-bars color-white"></i>  </span>
              </button>  
              <Link className="navbar-brand mr-5" to="/">
                <img src="https://g6publicidade.com/assets/images/logo_branca.png" alt="Logotipo G6 Publicidade"/>
              </Link>
              <div className=" collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav w-100 justify-content-around">
                  <li className="navbar-item">
                    <a className="text-link" href="https://g6publicidade.com/">
                       Home
                    </a>
                  </li>
                  <li className="navbar-item">
                     <a className="text-link" href="https://g6publicidade.com/quem-somos">
                        Quem somos
                     </a>
                  </li>
                  <li className="navbar-item">
                    <a className="text-link" href="https://g6publicidade.com/servicos-e-portfolio">
                      Serviços e Portfolio
                    </a>
                  </li>
                  <li className="navbar-item">
                    <a className="text-link" href="https://g6publicidade.com/talentos">
                      Talentos
                    </a>
                  </li>
                  <li className="navbar-item">
                    <a className="text-link" href="https://g6publicidade.com/contato">
                      Contato
                    </a>
                  </li>
                  <li className="navbar-item">
                    <a className="text-link" href={"/"}>
                      Login
                    </a>
                  </li>
                </ul>
              </div>
              </div>
        </nav>
        </div>
        <div className="LoginForm">
          <div className="wrapper fadeInDown">
              <div className="whiteBgLogin">
                <div id="welcomeBack">
                  <div className="azulLeve">Que bom que <button className="azulLeveA">você</button><button className="azulLeveA"> está de volta</button></div>
                </div>
                <div id="formContent">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Control
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          id="login"
                          className="fadeIn second"
                          name="login"
                          placeholder="Nome de usuário"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          id="password"
                          className="fadeIn third"
                          name="login"
                          placeholder="Senha"
                        />
                        </Form.Group>

                      <input
                        disabled={!validateForm()}
                        type="submit"
                        className="fadeIn fourth"
                        value="Entrar"
                      />
                  </Form>
                <div id="formFooter">
                  <a className="underlineHover" href="https://g6publicidade.com/">
                    G6 </a> <a href="https://g6publicidade.com/" className="azul">Publicidade</a><br></br>
                  <div className="blank">Ainda não é cliente? <a href="https://g6publicidade.com/contato">Entre em contato conosco.</a></div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </Row>
</Container>


  <Modal className="warning" data-keyboard={false} backdrop={true} show={show} >
  <Button className="closeButton" onClick={handleClose}>X</Button>
    <Modal.Body>
      <div className="error">
        <i class="fa fa-times-circle" aria-hidden="true"></i><br></br>
        Desculpe! Nossa versão mobile ainda está em construção.
      </div>
    </Modal.Body>
  </Modal>
</div>
  );
}

Login.propTypes = {
  "setToken": PropTypes.func
};

