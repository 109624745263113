import React, { useContext } from "react";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function LastAccess(){
  
  const { authUser } = useContext(AuthUserContext);
  
  function formatDate(dateFromAPI) {
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
  
    const dateHours = `0${date.getHours()}`.slice(START);
    const dateMinutes = `0${date.getMinutes()}`.slice(START);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);
    return `${dateDay}/${dateMonth}/${dateYear} - ${dateHours-3}:${dateMinutes}`;
  }
  return(
    <div className="ultimo_acesso">Último acesso: {formatDate(authUser.logged_in)}</div>
  );

}
export default LastAccess;