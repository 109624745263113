import React, {useEffect, useState} from "react";
import NavMenu from "components/Navbars/NavMenu";
import "../../assets/css/temporeal.css"
import qshd from "../../assets/img/qs.png"
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis} from 'recharts';
import API from "utils/API";
import {useHistory} from "react-router-dom";
import LastAccess from './../User/LastAccess';


function FbAds(){

  const [projecoes, setProjecoes] = useState(null);
  const [atual, setAtual] = useState("none");
  const [show2, setShow2] = useState(false);
  const [keyData, setKeyData] = useState("leads");
  const [fillColor, setFillColor] = useState("#f7325a");

  const handleShow2 = () => setShow2(true);

  const history = useHistory();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio

  const nLeads = new Array(31).fill(0);
  const valorLeads = new Array(31).fill(0);


  let monthh = " ";
  let dom =[0,25];
  let [leadsTot, orcMen, orcMed, orcDia] = [undefined, undefined, undefined, undefined];
  let updates = "0";
  let negocioId = 2;

  const monthNames = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  };


  useEffect(() => {
    const [run] = API.get("facebooks");
    run().then((response) => {
      setProjecoes(response);
    });
  }, []);

  function openModal2() {
    handleShow2();
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }


  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    const month = atual !== 'none' ? atual : new Date().getMonth() + 1;
    monthh = monthNames[month] || '';
  }

  translateMonth();

  negocioId = getNegocioId();

  const RealTime = () =>{
    history.push(`/realtime/${negocioId}`);
  }

  /*Retorna a Id do negócio selecionado */
  function getNegocioId() {
    const it = getUrl.split("/");
    const negocioId = (it[4]).toString();
    return (negocioId);
  }

  function formatDate(dateFromAPI) {
    const START = -2;
    const MONTH_OFFSET = 1;
    const date = new Date(dateFromAPI);
    const dateDay = `0${date.getDate()}`.slice(START);
    const dateMonth = `0${date.getMonth() + MONTH_OFFSET}`.slice(START);
    const dateYear = `0${date.getYear()}`.slice(START);

    if(dateDay === "01" && dateMonth === "01" && dateYear === "00"){
      return "Sem registros feitos no mês atual";
    }else{
      return `${dateDay}/${dateMonth}/${dateYear}`;
    }
  }

  function getPreco() {
    projecoes && projecoes.map((projecao) => {
      if (projecao.negocio_id.toString() === getNegocioId() && projecao.mes.toString() === monthh) {
        for (let i = 1; i <= 31; i++) {
          nLeads[i-1] = projecao['array'+i] || 0;
        }
        for (let i = 1; i <= 31; i++) {
          valorLeads[i-1] = projecao['price'+i] || 0;
        }
      }
      return true;
    });
  }

  getPreco();

  const data = [];

  for (let i = 1; i <= 31; i++) {
    data.push({
      name: i.toString(),
      leads: nLeads[i-1],
      preco: valorLeads[i-1],
      amt: 2100
    });
  }

  function maxi() {
    const max = keyData === "leads" ? Math.max(...nLeads) : Math.max(...valorLeads);
    return [0, Math.ceil(max / 5) * 5];
  }

  dom = maxi();


  const getIntroOfPage = (label) => {
    const index = parseInt(label) - 1;
    const aVal = nLeads[index];
    const vVal = valorLeads[index];

    if (aVal !== null) {
      return `Valor do lead: R$ ${vVal.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}`;
    }
    return '';
  }


  const getLeads = (label) => {
    const index = parseInt(label) - 1; // adjust for 0-based index
    const value = nLeads[index];

    if (value !== null) {
      return `Número de leads: ${parseInt(value)} `;
    }

    return '';
  };


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Dia ${label}`}</p>
          <p className="label">{getLeads(label)}</p>
          <p className="intro">{getIntroOfPage(label)}</p>
        </div>
      );
    }
    return null;
  };


  function getYear() { ///Retorna o ano
    return new Date().getFullYear();
  }

  // function selectYear() {
  //   return (
  //       <select className="text-center border-0" aria-label="Default select example">
  //         <option value={getYear()}>{getYear()-4}</option>
  //         <option value={getYear()}>{getYear()-3}</option>
  //         <option value={getYear()}>{getYear()-2}</option>
  //         <option value={getYear()}>{getYear()-1}</option>
  //         <option selected value={getYear()}>{getYear()}</option>
  //       </select>
  //   );
  // }

  function leadsReturn(){
    setKeyData("leads");
    setFillColor("#f7325a");
  }

  function valorReturn(){
    setKeyData("preco")
    setFillColor ("#414141");
  }


  const renderLineChart2 = (
    <div>
      <BarChart
      className="pchartBar"
        width={900}
        height={450}
        data={data}
        margin={{
          top: 100,
          right: 0,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis  dataKey="name" axisLine={{ stroke: '#d5385a' }}  tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={16}/>
        <YAxis domain={dom} axisLine={{ stroke: '#d5385a' }}  dataKey="leads" tick={{ fill: '#d5385a' }} tickLine={{ stroke: '#d5385a' }}  fontSize={16}/>
        <Tooltip content={<CustomTooltip />} />
        <Bar name="Leads" stackId="a" type="monotone" dataKey={keyData} fill={fillColor} isAnimationActive={true} label={{position: 'top'}} />
      </BarChart>
      <Row  className="w-100 justify-content-center">
        <div className="lgndChart">
          <button className="lds mr-2" onClick={leadsReturn}>Leads</button>
          <button className="vlr" onClick={valorReturn}>Valor do lead</button>
        </div>
      </Row>
    </div>
  );

  function dia() {
    const filteredA = nLeads.filter((elem) => elem !== 0);
    return filteredA.length;
  }

  orcMen = OrcamentoMensal();
  leadsTot = TotalLeads();
  orcMed = mediaLeads();
  orcDia = OrcamentoDiario();

  function mediaLeads(){
    const media = (orcMen/leadsTot);
    return media.toFixed(2);
  }

  function OrcamentoDiario(){
    const diaUpdate = dia();
    const orcday = orcMen/diaUpdate;
    return orcday.toFixed(2);
  }


  function OrcamentoMensal() {
    let orcTotal = 0;

    for (let i = 0; i < nLeads.length; i++) {
      if(+nLeads[i] === 0){
        orcTotal += parseFloat(valorLeads[i]);
      } else {
        orcTotal += nLeads[i] * valorLeads[i];
      }
    }
    return orcTotal.toFixed(2);
  }


  function TotalLeads() {
    let totalLeads = 0;

    for (let i = 0; i < nLeads.length; i++) {
      totalLeads += parseInt(nLeads[i]);
    }
    return totalLeads;
  }

  return(
    <div>
    <NavMenu/>
      <div id="realTime">
      <button className="back" onClick={RealTime}><i className="fa fa-long-arrow-left"></i>Voltar</button>
        <Row className="w-100 justify-content-center">
          <button className="btnCalAds" onClick={openModal2}><i className="fa fa-calendar"></i></button>
          <div className="fbAdsTitle">Facebook Ads<img className="hd" alt="Método QSHD" src={qshd}></img></div>
        </Row>
        <Row  className="w-100 justify-content-center">
          <div className="blackbackground">
            <div className="infos">
              Total de Leads: {leadsTot}<br></br>
              Orçamento Mensal: R$ {parseFloat(orcMen).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}<br></br>
              Orçamento Diário: R$ {parseFloat(orcDia).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}<br></br>
              Preço Médio por Lead: R$ {parseFloat(orcMed).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}
            </div>
            <Row  className="w-100 justify-content-center">
              <div id = "leadchart">{renderLineChart2}</div>
            </Row>
            <div className="up">Última atualização: {formatDate(updates)}</div>
          </div>
        </Row>
      </div>

      <Modal className="selectMonthMetas" data-keyboard={true} backdrop={true} show={show2}>

        <Button className="closeButton">Selecione o Ano ({getYear()})</Button>


        {/*<Button className="closeButton">Selecione o ano abaixo:</Button>*/}
        {/*{selectYear()}*/}

        <Modal.Body>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
              </Col>
            </Row>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
              </Col>
            </Row>
            <Row className="w-100 justify-content-center">
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
              </Col>
              <Col md={3}>
                <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
              </Col>
            </Row>
          </Modal.Body>
      </Modal>
      <Row className="w-100 fixed-bottom justify-content-left">
        <LastAccess/>
      </Row>
    </div>
  );
}
export default FbAds;