import React, { useState, useEffect, useContext, useCallback } from "react";
import NavMenu from "components/Navbars/NavMenu";
import { PieChart, Pie, Sector } from 'recharts';
import { useHistory} from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import "../../assets/css/projecaoCliente.css";
import API from "utils/API";
import {  Row, Modal, Button,Col } from "react-bootstrap";
import LastAccess from './../User/LastAccess';
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function MetaFaturamento(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  const [activeIndex, setActiveIndex] = useState(0);
  let totalRetorno = "0";
  let totalMetaPorcento = "0";
  var monthh = " ";
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [atual, setAtual] = useState("none");
  var mes = 0;
  
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const Metas = () =>{ 
    history.push(`/metas/${authUser.active_negocio_id}`);
  }

  function openModal2() {
    handleShow2();
  }

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

  
  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }

  function handleClose2(mes){
    setAtual(mes)
    setShow2(false);
  }

  /*Passa as datas de numerico para extenso */
  function translateMonth() {
    if(atual !== "none"){
      mes = atual;
    }else{
      mes = getMonth()
    }
    if (mes === 1) {
      monthh = "Janeiro";
    } else if (mes === 2) {
      monthh = "Fevereiro";
    } else if (mes === 3) {
      monthh = "Março";
    } else if (mes === 4) {
      monthh = "Abril";
    } else if (mes === 5) {
      monthh = "Maio";
    } else if (mes === 6) {
      monthh = "Junho";
    } else if (mes === 7) {
      monthh = "Julho";
    } else if (mes === 8) {
      monthh = "Agosto";
    } else if (mes === 9) {
      monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
    }
    return monthh;
  }
       
  function getYear() { ///Retorna o ano
    var year = new Date().getFullYear();
    return year;
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';   
    return (
      <g> 
        <text x={cx} y={cy} dy={4} textAnchor="middle" fill="#f7325a"  fontWeight={600} fontSize={30}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill="#fe799d"
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#d5385a"
          fontSize={27}
          fontWeight={600}
        >{`R$ ${value.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey +10}
          dy={18}
          textAnchor={textAnchor}
          fill="#d5385a"
          fontSize={27}
          fontWeight={600}
        >
          {`( ${parseInt(percent * 100)}%)`}
        </text>
      </g>
    );
  };
  
  function getRedesMeta(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio  && projecao.mes === translateMonth()) {
        const redeMeta = projecao.metaPorcentagem;
        return (totalMetaPorcento = redeMeta);
      }
      return totalMetaPorcento;
    });
   return totalMetaPorcento;
  }

  function getTotal(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio  && projecao.mes === translateMonth()) {
        const totalMeta = projecao.metaTotal;
        return (totalRetorno = totalMeta);
      }
      return totalRetorno;
    });
   return totalRetorno;
  }

  let valorTotal = getTotal();
  let metaPorcento = getRedesMeta();
  let outros = (parseFloat(valorTotal) - (parseFloat(valorTotal) * parseFloat(metaPorcento/100)));
  const dataPie =[
  { name: 'Outros', value: outros },
  { name: 'Redes sociais', value: (parseFloat(valorTotal) * parseFloat(metaPorcento/100))},
  ];

  return(
    <div id="contentDash">
      <NavMenu/>
      <button className="back" onClick={Metas}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
        <Row className="w-100 justify-content-center">
          <div className="whiteBg">
            <Row className="w-100 justify-content-center"> 
              <button className="btnCalMetaFaturamento" onClick={openModal2}><i className="fa fa-calendar"></i></button>
              <div className="metaFaturamentoTitle">Metas Faturamento</div>
            </Row>
            <Row className="w-100 justify-content-center">
              <div className="chartPie">
                <PieChart  className="pchartMeta" width={900} height={550}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={dataPie}
                    cx="50%"
                    cy="50%"
                    innerRadius="60%"
                    outerRadius="75%"
                    fill=" #f7325a"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  />
                </PieChart>
              </div>
            </Row>
            <Row className="w-100 justify-content-center"> 
              <div className="legenda"><button className="legendButton"></button> Investimento total: R${parseFloat(getTotal()).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
            </Row>
            <Row className="w-100 fixed-bottom justify-content-left">
              <LastAccess/>
            </Row>
            <Modal className="selectMonthMetaInvest" data-keyboard={true} backdrop={true} show={show2} >
              <Button className="closeButton">Selecione o mês ({getYear()})</Button>
                <Modal.Body>
                  <Row className="w-100 justify-content-center">
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,1)}>Janeiro</button>
                    </Col>      
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,2)}>Fevereiro</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,3)}>Março</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,4)}>Abril</button>
                    </Col>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,5)}>Maio</button>
                    </Col>      
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,6)}>Junho</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,7)}>Julho</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,8)}>Agosto</button>
                    </Col>
                  </Row>
                  <Row className="w-100 justify-content-center">
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,9)}>Setembro</button>
                    </Col>      
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,10)}>Outubro</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,11)}>Novembro</button>
                    </Col>
                    <Col md={3}>
                      <button className="btnMes" onClick={handleClose2.bind(null,12)}>Dezembro</button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </div>
        </Row>
        <img className="position-absolute opacity line-1" src={linhas} alt=""></img>
        <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
        <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
    </div>
  );


}
export default MetaFaturamento;