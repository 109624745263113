export default (objects, objectId, defaultCallback) => {
  if (objectId) {
    let objectIsValid = false;
    for (const objectAr of objects) {
      if (objectAr.id === parseInt(objectId, 10)) {
        objectIsValid = true;
        break;
      }
    }
    if (!objectIsValid) {
      defaultCallback();
    }
  } else {
    defaultCallback();
  }
};

