import React, { useState, useEffect, useCallback } from "react";
import { PieChart, Pie, Sector } from 'recharts';
import {  Row } from "react-bootstrap";
import API from "utils/API";
import "../../assets/css/printmetas.css";
import { BarChart, Bar, Cell} from 'recharts';
import { CartesianGrid, XAxis, YAxis, Tooltip} from "recharts";
import { RadialBarChart, RadialBar } from 'recharts';

function PrintMetas(){
  const [activeIndex, setActiveIndex] = useState(0);
  const [projecoes, setProjecoes] = useState();
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
  let totalRetorno = 0;
  let totalMetaPorcento = 0;  
  var mes = 0;
  var monthh = " ";
  let fbs1 = 0;
  let fbs2 = 0;
  let fbs3 = 0;
  let fbs4 = 0;
  let gs1 = 0;
  let gs2 = 0;
  let gs3 = 0;
  let gs4 =0;
  var g1,g2,g3,g4,f1,f2,f3,f4 =0;
  let totalMensal = 0;
  let totalMensalPercent = 0;
  let cplanteriorC =0;
  let cplFbC = 0;
  let cplgC =0;

  useEffect(() => {
    const [run] = API.get("projections");
    run().then((response) => {
        setProjecoes(response);
    });
  }, []);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  getInvestTotal();

  const data = [
    {
      name: "Investimento indicado",
      uv: parseFloat(totalMensalPercent).toFixed(2),
      pv: 2400,
      fill: "#f7325a"
    },
    {
      name: "Investimento atual",
      uv: parseFloat(totalMensal).toFixed(2),
      pv: 4567,
      fill: "#fe799d"
    }
  ];

  function getSemanasfb(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.fbq1 !== null) {
        f1 = projecao.fbq1;
        return fbs1 = f1;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.fbq1 ===null){
        return fbs1 = 0;
      }
      if (projecao.negocio_id.toString() === IdNegocio && projecao.fbq2 !== null) {
        f2 = projecao.fbq2;
        return fbs2 = f2;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.fbq2 ===null){
        return fbs2 = 0;
      }
      if (projecao.negocio_id.toString() === IdNegocio && projecao.fbq3 !== null) {
        f3 = projecao.fbq3;
        return fbs3 = f3;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.fbq3 ===null){
        return fbs3 = 0;
      }      
      if (projecao.negocio_id.toString() === IdNegocio && projecao.fbq4 !== null) {
        f4 = projecao.fbq4;
        return fbs4 = f4;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.fbq4 ===null){
        return fbs4 = 0;
      }
      return (
        fbs1,
        fbs2,
        fbs3,
        fbs4
        );
    });
  }

  function getInvestTotal(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio) {
        const f1 = projecao.fbq1;
        const f2 = projecao.fbq2;
        const f3 = projecao.fbq3;
        const f4 = projecao.fbq4;
        const g1 = projecao.ads1;
        const g2 = projecao.ads2;
        const g3 = projecao.ads3;
        const g4 = projecao.ads4;
        const meta = projecao.metaTotal;
        const redespercent = projecao.metaPorcentagem;
        const tot =  (parseFloat(f1) + parseFloat(f2) + parseFloat(f3) + parseFloat(f4) + parseFloat(g1) + parseFloat(g2) + parseFloat(g3) + parseFloat(g4));
        const totPercent = ((parseFloat(meta) * (redespercent/100) * 0.08));
        return (
         totalMensal = tot,
         totalMensalPercent = totPercent
        );
      }
      return (
        totalMensal,
        totalMensalPercent
      );
    });
  }
  
  function getSemanasGoogle(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio && projecao.ads1 !== null) { 
         g1 = projecao.ads1;
         return gs1 = g1;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.ads1 ===null){
        return g1 = 0;
      }
      if (projecao.negocio_id.toString() === IdNegocio && projecao.ads2 !== null) { 
         g2 = projecao.ads2;
         return gs2 = g2;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.ads2 ===null){
        return g2 = 0;
      }
      if (projecao.negocio_id.toString() === IdNegocio && projecao.ads3 !== null) { 
        g3 =  projecao.ads3;
        return gs3 = g3;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.ads3 ===null){
        return g3 = 0;
      }
      if (projecao.negocio_id.toString() === IdNegocio && projecao.ads4 !== null) { 
        g4 =  projecao.ads3;
        return gs4 = g4;
      }else if(projecao.negocio_id.toString() === IdNegocio && projecao.ads4 ===null){
        return g4 = 0;
      }
       return true;
    });
  }

  getSemanasfb();
  getSemanasGoogle();  

  const dataInvest = [
    {
      name: 'Semana 1',
      fb: fbs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs1.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 1',
      amt: 2400,
    },
    {
      name: 'Semana 2',
      fb: fbs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs2.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 2',
      amt: 2210,
    },
    {
      name: 'Semana 3',
      fb: fbs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs3.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 3',
      amt: 2290,
    },
    {
      name: 'Semana 4',
      fb: fbs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      google: gs4.toLocaleString("pt-br", { "minimumFractionDigits": 2 }),
      week: 'Semana 4',
      amt: 2000,
    },

  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g> 
        <text x={cx} y={cy} dy={4} textAnchor="middle" fill="#000"  fontWeight={500} fontSize={18}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill="#fe799d"
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#fff"
          fontSize={17}
          fontWeight={500}
        >{`R$ ${value.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -3) * 12}
          y={ey +10}
          dy={18}
          textAnchor={textAnchor}
          fill="#fff"
          fontSize={17}
          fontWeight={500}
        >
          {`( ${parseInt(percent * 100)}%)`}
        </text>
      </g>
    );
  };

  function getRedesMeta(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio) {
        const redeMeta = projecao.metaPorcentagem;
        return (totalMetaPorcento = redeMeta);
      }
      return totalMetaPorcento;
    });
   return totalMetaPorcento;
  }

  function getTotal(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio) {
        const totalMeta = projecao.metaTotal;
        return (totalRetorno = totalMeta);
      }
      return totalRetorno;
    });
   return totalRetorno;
  }

  function getMonth() { ///Retorna o mês passado
    var mes = new Date().getMonth();
    return mes = mes + 1;
  }

  let valorTotal = getTotal();
  let metaPorcento = getRedesMeta();
  let outros = (parseFloat(valorTotal) - (parseFloat(valorTotal) * parseFloat(metaPorcento/100)));

  const dataPie =[
    { name: 'Investimentos', value: outros },
    { name: 'Redes sociais', value: (parseFloat(valorTotal) * parseFloat(metaPorcento/100))},
  ];

  function translateMonth() {
    mes = getMonth();
    if (mes === 1) {
      monthh = "Janeiro";
    } else if (mes === 2) {
      monthh = "Fevereiro";
    } else if (mes === 3) {
      monthh = "Março";
    } else if (mes === 4) {
      monthh = "Abril";
    } else if (mes === 5) {
      monthh = "Maio";
    } else if (mes === 6) {
      monthh = "Junho";
    } else if (mes === 7) {
      monthh = "Julho";
    } else if (mes === 8) {
      monthh = "Agosto";
    } else if (mes === 9) {
      monthh = "Setembro";
    } else if (mes === 10 || mes === -2) {
      monthh = "Outubro";
    } else if (mes === 11 || mes === -1) {
      monthh = "Novembro";
    } else if (mes === 12 || mes === 0) {
      monthh = "Dezembro";
    }
     return monthh;
  }

  function getCPL(){
    projecoes && projecoes.map((projecao) => {
      const it = getUrl.split("/");
      const IdNegocio = (it[4]).toString();
      if (projecao.negocio_id.toString() === IdNegocio) {
        const CPL_ant = projecao.cplAnterior;
        const CPL_fb = projecao.cplFb;
        const CPL_g = projecao.cplGoogle;
        return (
          cplanteriorC = parseFloat(CPL_ant),
          cplFbC = parseFloat(CPL_fb),
          cplgC = parseFloat(CPL_g)
        );
      }
        return(cplanteriorC, cplFbC, cplgC);
    });
  }

  getCPL();
  
  const dataCPL = [
    { name: 'CPL Anterior', value: cplanteriorC },
    { name: 'CPL Facebook', value: cplFbC },
    { name: 'CPL Google', value: cplgC },
  ];

  const COLORS = ['#f7325a', '#fe94b6', '#fe799d'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

    
  return(
    <div id="printMetasBg">
      <Row className="w-100 justify-content-center">
        <div className="tituloPrintMetas">Metas para o mês de {translateMonth()}</div>
      </Row>
      <div className="printDataPie">
        <Row className="w-100 justify-content-center">
          <div className="titleInvs">Meta de faturamento</div>
        </Row>
          <PieChart width={400} height={300}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={dataPie}
              cx="60%"
              cy="50%"
              innerRadius="40%"
              outerRadius="55%"
              fill=" #f7325a"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
          <Row className="w-100 justify-content-center"> 
            <div className="lgnd"><button className="metaFatPrint"></button> Investimento total: R${parseFloat(getTotal()).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
          </Row>
      </div>                  
      <div className="printBarChart">
        <Row className="w-100 justify-content-center">
          <div className="titleInvsBar">Investimentos</div>
        </Row>
          <BarChart
            width={300}
            height={300}
            data={dataInvest}
            margin={{
              top: 100,
              right: 30,
              left: 0,
              bottom: 40,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis  dataKey="week" axisLine={{ stroke: '#000' }}  tick={{ fill: '#000' }} tickLine={{ stroke: '#000' }}  fontSize={18}/>
            <YAxis axisLine={{ stroke: '#000' }}  tick={{ fill: '#000' }} tickLine={{ stroke: '#000' }}  fontSize={18}/>
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar name="Facebook/Instagram" type="monotone" dataKey="fb" fill="#f7325a" isAnimationActive={false} />
            <Bar name="Google" type="monotone" dataKey="google" fill="#fe799d" isAnimationActive={false}/>
          </BarChart>
          <div  className="lgndBar"><button className="btnfbPrint"></button> <i className="fa fa-facebook"></i> / <i className="fa fa-instagram"></i> -  Investimento Total: R${(parseFloat(fbs1)+parseFloat(fbs2)+parseFloat(fbs3)+parseFloat(fbs4)).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
          <div  className="lgndBar2" ><button className="btnGPrint"></button> <i className="text-center fa fa-google"></i> -  Investimento total: R${(parseFloat(gs1)+parseFloat(gs2)+parseFloat(gs3)+parseFloat(gs4)).toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>          
      </div>
      <div className="totalInvestPrint">
        <Row className="w-100 justify-content-center">
          <div className="titleInvsTot">Investimento total</div>
        </Row>
          <RadialBarChart
              width={300}
              height={300}
              cx="50%"
              cy="50%"
              innerRadius="35%"
              outerRadius="70%"
              barSize={20}
              data={data}
            >
          <RadialBar
            isAnimationActive={true}
            minAngle={30}
            label={{ position: "insideStart", fill: "#000"}}
            background= {{fill:"#8c8c8c"}}
            clockWise
            dataKey="uv"
          />
          </RadialBarChart>
          <div className="lgnd"><button className="btnInvPrint"></button> Investimento atual: R$ {totalMensal.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}</div>
          <div className="lgnd2"><button className="btnInvPrint2"></button>  Investimento indicado: R$ {totalMensalPercent.toLocaleString("pt-br", { "maximumFractionDigits": 2 })}</div>                 
      </div>
      <div className="cplPrint">
        <Row className="w-100 justify-content-center">
          <div className="titleInvsCPL">CPL</div>
        </Row>
          <PieChart width={300} height={300}>
            <Pie
              data={dataCPL}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius="60%"
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={true}
            >
              {dataCPL.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <div className="lgndCPL" ><button className="btnCPLPrint"></button> Anterior: R${cplanteriorC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}     
            <button className="btnCPLFbPrint ml-2"></button> Facebook: R${cplFbC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}  
            <button className="btnCPLGPrint ml-2"></button> Google: R${cplgC.toLocaleString("pt-br", { "minimumFractionDigits": 2 })}
          </div>
      </div>
    </div>                 
  );
}
export default PrintMetas