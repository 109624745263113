import React, { useState, useEffect, useContext } from "react";
import NavMenu from "components/Navbars/NavMenu";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { useHistory} from "react-router-dom";
import { Button, Modal, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/src/stylesheets/datepicker.scss";
import API from "utils/API";
import LastAccess from "views/User/LastAccess";
import "../../assets/css/notas.css";
import linhas from "../../assets/img/linhas.gif";
import linhas2 from "../../assets/img/linha_02.gif";
import linhas3 from "../../assets/img/linha_03.gif";

function NotasFiscais(){
  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const [startDate, setStartDate] = useState(new Date());
  const [starts, setStarts] = useState(new Date());
  const [periodo, setPeriodo] = useState(false);
  const [notas, setNotas] = useState(false);
  const [gerenciamento, setGerenciamento] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [google, setGoogle] = useState(false);
  const mes = getLastMonth();
  const ano = getYear();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  var year = 2022;
  var month = 10;
  var day = 1;
  var year_2 = 2022;
  var month_2 = 0;
  var day_2 = 1;
  var year_3 = 2022;
  var month_3 = 0;
  var day_3 = 1;
  var notaa=0;
  var aux = 0;
  var nomeNota = 0;
  let negocioId = 2;
  const getUrl = window.location.href; //Retorna a URL para obtenção do ID do negócio
      
  /*
  useEffect(() => {
    const [run] = API.get(`/usuarios/${authUser.id}/getNegocioAtivo`);
    let isMounted = true;
    run().then((response) => {
      if(isMounted){
        setId(response);
      }
    });
    return () => { isMounted = false }
  }, [id]);
*/

function getNegocioId() {
  const it = getUrl.split("/");
  const IdNegocio = (it[4]).toString();
  return  negocioId = IdNegocio;
}

getNegocioId();

  useEffect(() => {
    const [run] = API.get("notas");
    run().then((response) => {
        setNotas(response);
    });
  }, []);

  function openModal() {
    if(gerenciamento === true || facebook === true || google === true){
        handleShow2();
    }else{
      handleShow();
    }
  }

  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    }else{
      console.log('⛔️ Checkbox is NOT checked');
    }
    setPeriodo(current => !current);
  };

  const handleChange2 = event => {
    if (event.target.checked){
      console.log('✅ Checkbox is checked');
    }else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setGerenciamento(current => !current);
  };

  const handleChange3 = event => {
    if(event.target.checked){
      console.log('✅ Checkbox is checked');
    }else{
      console.log('⛔️ Checkbox is NOT checked');
    }
    setFacebook(current => !current);
  };

  const handleChange4 = event =>{
    if(event.target.checked){
      console.log('✅ Checkbox is checked');
    }else{
      console.log('⛔️ Checkbox is NOT checked');
    }
    setGoogle(current => !current);
  };


  const DadosGerais = () =>{ 
    history.push(`/documentos/${negocioId}`);
  }

  function getLastMonth() { ///Retorna o mês passado
    const mes = new Date().getMonth();
  return mes;
  }

  function getYear(){
    const ano =  new Date().getFullYear();
   return ano;
  }

/*Passa as datas de numerico para extenso */
  function translateMonth(mes) {
    if (mes === 1) {
      return "Jan";
    } else if (mes === 2) {
      return "Fev";
    } else if (mes === 3) {
      return "Mar";
    } else if (mes === 4) {
      return "Abril";
    } else if (mes === 5) {
      return "Maio";
    } else if (mes === 6) {
      return "Jun";
    } else if (mes === 7) {
      return "Jul";
    } else if (mes === 8) {
      return "Ago";
    } else if (mes === 9) {
      return "Set";
    } else if (mes === 10 || mes === -2) {
      return "Out";
    } else if (mes === 11 || mes === -1) {
      return "Nov";
    } else if (mes === 12 || mes === 0) {
      return "Dez";
    }
  }

 function monthToNumber(aux){
  var varia;
  if(aux === "Jan"){
     varia = 1;
  }else if(aux === "Feb"){
     varia = 2;
  }else if(aux === "Mar"){ 
     varia = 3;
  }else if(aux === "Apr"){
     varia =4;
  }else if(aux === "May"){
     varia =5;
  }else if(aux === "Jun"){
     varia =6;
  }else if(aux === "Jul"){
     varia = 7;
  }else if(aux === "Aug"){
     varia = 8;
  }else if(aux === "Sep"){
     varia = 9;
  }else if(aux === "Oct"){
     varia = 10;
  }else if(aux === "Nov"){
     varia = 11;
  }else if(aux === "Dec"){
     varia = 12;
  }
  return varia;
}

  
return(
  <div id="contentDash">
    <NavMenu/>
      <button className="back" onClick={DadosGerais}><i className="fa fa-long-arrow-left"></i>   Voltar</button>
      <Row className="w-100 justify-content-center"> 
        <div className="titleNotas">Notas fiscais</div>
      </Row>
      <div className="filterHist">
      <Row className="w-100 justify-content-center"> 
        <div className="txtHistoricoD">De:</div>  
        <div className="inputFltr">
            <DatePicker selected={startDate} locale="ptBR"
          onChange={date => setStartDate(date)} 
          dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="txtHistorico">a:</div>
        <div className="inputFltr">
            <DatePicker selected={starts} locale="ptBR"
          onChange={date => setStarts(date)} 
          dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="littleTxt">Todo o período: </div>
        <label className="containerAnalytics">
          <input type="checkbox" name="timeHist" onChange={handleChange}/>
          <span className="checkmarkH"></span>
          <div className="timeHist"></div> 
        </label>
      </Row>
    <div className="notasChoice">
      <ul>
        <li>
          <label className="containerAnalytics">Gerenciamento - {translateMonth(mes)} {ano}
            <input type="checkbox" name="timeHist" onChange={handleChange2}/>
            <span className="checkmarkH"></span>
            <div className="timeHist"></div> 
          </label>
        </li>
        <li>
          <label className="containerAnalytics">Facebook Ads - {translateMonth(mes)} {ano}
            <input type="checkbox" name="timeHist" onChange={handleChange3}/>
            <span className="checkmarkH"></span>
            <div className="timeHist"></div> 
          </label>
        </li>
        <li>
          <label className="containerAnalytics">Google Ads - {translateMonth(mes)} {ano}
            <input type="checkbox" name="timeHist" onChange={handleChange4}/>
              <span className="checkmarkH"></span>
              <div className="timeHist"></div> 
          </label>
        </li>
      </ul>
    </div>
  </div>
  <Row className="w-100 justify-content-center btnDownloadNotas"> 
    <button className="btnHistorico" onClick={openModal}> <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar o seu arquivo</button>
  </Row>
  <Row className="w-100 fixed-bottom justify-content-left">
    <LastAccess/>
  </Row>
  <Modal className="notasDoMes" data-keyboard={true} backdrop={true} show={show} >
    <Button className="closeButton" onClick={handleClose}>X</Button>
    <Modal.Body>
      <div className="titleNota">Notas fiscais do período</div>
      <div className="minip">* Clique na nota que deseja imprimir</div>
      <div className="notaLista">
        <ul>
          {
            notas && notas.map((nota)=>{
              if(periodo === true && (nota.negocio_id === authUser.active_negocio_id)){
                notas.sort((a,b) => (a.mes > b.mes && a.ano === b.ano) ? 1: -1);
                nomeNota = 0;
                if(nota.path !== null){
                  aux = nota.path.split("/");
                  nomeNota = aux[4];
                }
                notaa =(API.getBasePath() + nota.path);
                return(
                  <li> 
                    <a href={`${
                    notaa}?download=true`}
                            target="_blank" rel="noopener noreferrer"  
                            >{nomeNota} - {nota.data}
                    </a>
                  </li>
                )
              }else{ 
                aux = nota.data.split("/");
                day =parseInt(aux[0]);
                month = parseInt(aux[1]);
                year = parseInt(aux[2]);
                var data1 = year*1000+month*100+day;
                
                var ini = startDate.toString();
                var inicio = JSON.stringify(ini);
                aux = inicio.split(" ");
                month_2 = monthToNumber(aux[1]);
                 day_2=parseInt(aux[2]);
                year_2 =parseInt(aux[3]);
                var data2 = year_2*1000+month_2*100+day_2;
  
                var fimm = starts.toString();
                var fim = JSON.stringify(fimm);
                aux = fim.split(" ");
                month_3 = monthToNumber(aux[1]);
                day_3=parseInt(aux[2]);
                year_3 = parseInt(aux[3]);
                var data3 = year_3*1000+month_3*100+day_3;
  
                if((nota.negocio_id === authUser.active_negocio_id) && (data1 >= data2 && data1 <= data3)){
                  notas.sort((a,b) => (a.mes > b.mes && a.ano === b.ano) ? 1: -1);
                  nomeNota = 0;
                  if(nota.path !== null){
                    aux = nota.path.split("/");
                    nomeNota = aux[4];
                  }
                   notaa =(API.getBasePath() + nota.path);
                   return(
                      <li> 
                        <a href={`${
                        notaa}?download=true`}
                        target="_blank" rel="noopener noreferrer"  
                        >{nomeNota} - {nota.data}
                        </a>
                      </li>
                    )
                }
              }
                return true;
            })
          }
        
        </ul>
      </div>
    </Modal.Body>
  </Modal>


  <Modal className="notasDoMesEspec" data-keyboard={true} backdrop={true} show={show2} >
  <Button className="closeButton" onClick={handleClose2}>X</Button>
    <Modal.Body>
      <div className="titleNota">Notas do mês {translateMonth(mes)}/{ano}</div>
      <div className="notaLista">
        <Row className="w-100 justify-content-center"> 
          {
            notas && notas.map((nota)=>{
              if(gerenciamento === true){
                if((nota.gerenciamento.toString() === "1" && nota.mes === mes) ||(nota.gerenciamento.toString() === "1" && nota.mes=== "0" + mes)){
                  notaa =(API.getBasePath() + nota.path);
                  return(
                    <button className="btnContrato mt-3">
                      <a href={`${
                    notaa}?download=true`}
                          target="_blank" rel="noopener noreferrer"  
                          > <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar a nota de gerenciamento
                      </a>
                    </button>
  
                  );   
               }
             }else if(facebook === true){
                if((nota.facebook.toString() === "1" && nota.mes === mes) || (nota.facebook.toString() === "1"  && nota.mes=== "0" + mes)){
                  notaa =(API.getBasePath() + nota.path);
                    return(
                      <button className="btnContrato mt-3">
                        <a href={`${
                            notaa}?download=true`}
                            target="_blank" rel="noopener noreferrer"  
                            > <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar a nota de Facebook Ads
                        </a>
                      </button>
  
                     );
                }
              }else if(google === true){
                if((nota.ads.toString() === "1" && nota.mes === mes) || (nota.ads.toString() === "1" && nota.mes=== "0" + mes)){
                  notaa =(API.getBasePath() + nota.path);
                  return(
                    <button className="btnContrato mt-3">
                      <a href={`${
                      notaa}?download=true`}
                          target="_blank" rel="noopener noreferrer"  
                          > <i className="fa fa-file-download"></i>  Clique <b>aqui</b> para baixar a nota de Google Ads
                      </a>
                    </button>
                  );
                }
              }
              return true;
           })
          }
        </Row>
      </div>
    </Modal.Body>
  </Modal>

  <img className="position-absolute opacity line-V" src={linhas} alt=""></img>
  <img className="position-absolute opacity line-2" src={linhas2} alt=""></img>
  <img className="position-absolute opacity line-3" src={linhas3} alt=""></img>
</div>
  );
}

export default NotasFiscais;